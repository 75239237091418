import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { meSelector } from '../../../redux/User';

export const useWatermarkText = () => {
  const me = useSelector(meSelector);
  return `Viewing as ${[me.firstName, me.lastName]
    .filter(Boolean)
    .join(' ')} on ${format(new Date(), 'MMM Y')}`;
};
