import React, { createRef, PureComponent } from 'react';
import { Platform, StyleSheet, View } from 'react-native';
// eslint-disable-next-line import/default
import Swiper from 'react-native-swiper';
import { connect } from 'react-redux';
import SafeAreaView from 'react-native-safe-area-view';
import { Slide, SkipButton, Pagination } from './components';
import { onboardingSlides } from './lib';
import { setSeenOnboarding } from '../../redux/User';

class OnboardingComponent extends PureComponent {
  swiper = createRef();

  state = {
    slideIndex: 0,
  };

  onFinishOnboarding = async () => {
    this.props.setSeenOnboarding();
  };

  renderSlide = (slide, index) => (
    <Slide
      key={index}
      slide={slide}
      onFinishOnboarding={this.onFinishOnboarding}
    />
  );

  renderPagination = (index, total) => (
    <Pagination
      index={index}
      onChangeSlide={this.onChangeSlide}
      total={total}
      onEndButtonPress={this.onFinishOnboarding}
    />
  );

  onChangeSlide = (index) => {
    if (Platform.OS !== 'ios') {
      if (index !== this.state.slideIndex) {
        this.swiper.current &&
          this.swiper.current.scrollBy(index - this.state.slideIndex, true);
      }
    }

    this.setState({ slideIndex: index });
  };

  render() {
    return (
      <View style={styles.container}>
        <SafeAreaView
          forceInset={{ top: 'always' }}
          style={styles.skipButtonContainer}
        >
          <SkipButton style={styles.skip} onPress={this.onFinishOnboarding} />
        </SafeAreaView>
        <Swiper
          ref={this.swiper}
          loop={false}
          horizontal
          showsButtons={Platform.OS === 'web'}
          buttonColor="white"
          renderPagination={this.renderPagination}
          onIndexChanged={this.onChangeSlide}
          index={this.state.slideIndex}
          scrollEnabled={Platform.OS !== 'web'}
        >
          {onboardingSlides.map(this.renderSlide)}
        </Swiper>
      </View>
    );
  }
}

export const Onboarding = connect(null, { setSeenOnboarding })(
  OnboardingComponent
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  skipButtonContainer: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    left: 0,
    right: 0,
  },
  skip: {
    alignSelf: 'flex-end',
    marginTop: 10,
    marginRight: 20,
  },
});
