import { adaptSmallUser } from '../User/adapter';

const adaptArticleComment = (apiComment) => ({
  id: String(apiComment.id),
  user: adaptSmallUser(apiComment.user),
  content: apiComment.content,
  date: apiComment.created_at,
});

export const adaptArticle = (apiArticle) => ({
  id: String(apiArticle.id),
  user: adaptSmallUser(apiArticle.user),
  content: apiArticle.article,
  date: apiArticle.created_at,
});

export const adaptArticleComments = ({ data: apiComments }) =>
  apiComments.map(adaptArticleComment);
