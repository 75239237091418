import { Keyboard } from 'react-native';
import { put, call, takeEvery, select } from 'redux-saga/effects';
import { startLoading, finishLoading } from '../LoadingStatus';
import {
  adaptDiscussions,
  deleteDiscussion,
  leaveDiscussion,
  getDiscussions,
  getDiscussion,
  markDiscussionAsRead,
  changeDiscussionTitle,
  createDiscussion,
  adaptDiscussion,
  addUserToDiscussion,
  removeUserOfDiscussion,
  adaptPaginationMeta,
} from '../../api';
import { Alert, handleError } from '../../lib';
import {
  deleteUserFromStoredDiscussion,
  fetchDiscussions,
  refreshDiscussions,
  resetDiscussions,
  setDiscussion,
  setDiscussionAsRead,
  setDiscussions,
  setDiscussionTitle,
  setGroupDiscussionUsers,
} from './actions';
import {
  currentPageSelector,
  discussionByReferenceSelector,
} from './selectors';
import { markNotificationAsVisited } from '../Notification';

export function* fetchDiscussionsSaga(action) {
  yield put(startLoading('fetchDiscussions'));
  try {
    const currentPage = yield select(currentPageSelector);
    const response = yield call(getDiscussions, currentPage + 1);
    const { lastPage } = adaptPaginationMeta(response);
    const discussions = adaptDiscussions(response);
    yield put(setDiscussions(discussions, lastPage));

    if (action.payload.fromUserInteraction) {
      yield put(markNotificationAsVisited('discussion'));
    }
  } catch (e) {
    console.warn('[saga] fetchDiscussionsSaga', e);
  } finally {
    yield put(finishLoading('fetchDiscussions'));
  }
}

export function* fetchDiscussionSaga(action) {
  yield put(startLoading('fetchDiscussion'));
  try {
    const { discussionReference, onDiscussionFetched } = action.payload;
    const response = yield call(getDiscussion, discussionReference);
    const discussion = adaptDiscussion(response);
    yield put(setDiscussion(discussion));
    if (onDiscussionFetched) {
      yield call(onDiscussionFetched, discussion);
    }
  } catch (e) {
    console.warn('[saga] fetchDiscussionSaga', e);
  } finally {
    yield put(finishLoading('fetchDiscussion'));
  }
}

export function* refreshDiscussionsSaga(action) {
  const { showRefreshLoader } = action.payload;
  if (showRefreshLoader) {
    yield put(startLoading('refreshDiscussions'));
  }

  yield put(resetDiscussions());
  yield* fetchDiscussionsSaga(fetchDiscussions(true));

  if (showRefreshLoader) {
    yield put(finishLoading('refreshDiscussions'));
  }
}

export function* createGroupDiscussionSaga(action) {
  yield put(startLoading('createGroupDiscussion'));
  try {
    const apiDiscussion = yield call(createDiscussion);
    yield put(refreshDiscussions(false));
    if (action.payload.onSuccess) {
      const discussion = adaptDiscussion(apiDiscussion);
      action.payload.onSuccess(discussion);
    }
  } catch (error) {
    console.warn('[saga] createGroupDiscussionSaga', { error });
    handleError(error);
  } finally {
    yield put(finishLoading('createGroupDiscussion'));
  }
}

export function* addUserToGroupDiscussionSaga(action) {
  yield put(startLoading('addUserToGroupDiscussion'));
  try {
    const { discussionReference, otherUser } = action.payload;
    const apiDiscussion = yield call(
      addUserToDiscussion,
      discussionReference,
      otherUser.reference
    );
    const discussion = adaptDiscussion(apiDiscussion);
    yield put(
      setGroupDiscussionUsers(discussion.reference, discussion.otherUsers)
    );
  } catch (error) {
    console.warn('[saga] addUserToGroupDiscussionSaga', { error });
    handleError(error);
  } finally {
    yield put(finishLoading('addUserToGroupDiscussion'));
  }
}

export function* removeUserOfGroupDiscussionSaga(action) {
  yield put(startLoading('removeUserOfGroupDiscussion'));
  try {
    const { discussionReference, otherUserReference } = action.payload;
    yield call(removeUserOfDiscussion, discussionReference, otherUserReference);
    yield put(
      deleteUserFromStoredDiscussion(discussionReference, otherUserReference)
    );
  } catch (error) {
    console.warn('[saga] removeUserOfGroupDiscussionSaga', { error });
    handleError(error);
  } finally {
    yield put(finishLoading('removeUserOfGroupDiscussion'));
  }
}

export function* leaveOrDeleteDiscussionSaga(
  action,
  { sagaName, confirmText, apiCall }
) {
  yield put(startLoading(sagaName));
  try {
    const { response } = yield call(Alert.confirm, confirmText);
    if (response !== 'yes') {
      return;
    }

    yield call(apiCall, action.payload.discussionReference);
    yield put(refreshDiscussions(false));
    if (action.payload.onSuccess) {
      action.payload.onSuccess();
    }
  } catch (error) {
    console.warn(`[saga] ${sagaName}Saga`, { error });
    handleError(error);
  } finally {
    yield put(finishLoading(sagaName));
  }
}

export function* leaveDiscussionSaga(action) {
  yield call(leaveOrDeleteDiscussionSaga, action, {
    sagaName: 'leaveDiscussion',
    confirmText: 'Are you sure you want to leave this group?',
    apiCall: leaveDiscussion,
  });
}

export function* deleteDiscussionSaga(action) {
  yield call(leaveOrDeleteDiscussionSaga, action, {
    sagaName: 'deleteDiscussion',
    confirmText: 'Are you sure you want to delete this group?',
    apiCall: deleteDiscussion,
  });
}

export function* markDiscussionAsReadSaga(action) {
  yield put(startLoading('markDiscussionAsRead'));
  try {
    const { discussionReference } = action.payload;
    yield call(markDiscussionAsRead, discussionReference);
    const discussion = yield select(
      discussionByReferenceSelector(discussionReference)
    );
    yield put(setDiscussionAsRead(discussion));
  } catch (error) {
    console.warn('[saga] markDiscussionAsReadSaga', { error });
  } finally {
    yield put(finishLoading('markDiscussionAsRead'));
  }
}

export function* changeDiscussionTitleSaga(action) {
  yield put(startLoading('changeDiscussionTitle'));
  try {
    const { discussionReference, title } = action.payload;
    yield call(changeDiscussionTitle, title, discussionReference);
    yield put(setDiscussionTitle(title, discussionReference));
    Keyboard.dismiss();
    Alert.alert(`Group name has been changed to ${title}`);
  } catch (error) {
    console.warn('[saga] changeDiscussionTitleSaga', { error });
    handleError(error);
  } finally {
    yield put(finishLoading('changeDiscussionTitle'));
  }
}

export const discussionSagas = function* () {
  yield* [
    takeEvery('FETCH_DISCUSSIONS', fetchDiscussionsSaga),
    takeEvery('FETCH_DISCUSSION', fetchDiscussionSaga),
    takeEvery('REFRESH_DISCUSSIONS', refreshDiscussionsSaga),
    takeEvery('CREATE_GROUP_DISCUSSION', createGroupDiscussionSaga),
    takeEvery('ADD_USER_TO_GROUP_DISCUSSION', addUserToGroupDiscussionSaga),
    takeEvery(
      'REMOVE_USER_OF_GROUP_DISCUSSION',
      removeUserOfGroupDiscussionSaga
    ),
    takeEvery('DELETE_DISCUSSION', deleteDiscussionSaga),
    takeEvery('LEAVE_DISCUSSION', leaveDiscussionSaga),
    takeEvery('MARK_DISCUSSION_AS_READ', markDiscussionAsReadSaga),
    takeEvery('CHANGE_DISCUSSION_TITLE', changeDiscussionTitleSaga),
  ];
};
