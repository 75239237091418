export const formatDiscussionTitle = (discussion) => {
  if (discussion.isGroup) {
    const { title } = discussion;
    if (title) {
      return title;
    }

    const defaultTitle = 'Unnamed group';
    const { otherUsers } = discussion;
    if (!otherUsers.length) {
      return defaultTitle;
    }

    const formattedOtherUsers = otherUsers
      .map((user) => user.firstName)
      .filter(Boolean)
      .join(', ');
    return `${defaultTitle} with ${formattedOtherUsers}`;
  }

  if (!discussion.otherUser) {
    return 'Unknown user';
  }

  return `${discussion.otherUser.firstName} ${discussion.otherUser.lastName}`;
};
