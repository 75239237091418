import AsyncStorage from '@react-native-community/async-storage';

class LocalStore {
  async setString(key, value) {
    try {
      await AsyncStorage.setItem(key, value);
    } catch (error) {
      console.log('Error');
    }
  }

  getString(key) {
    return AsyncStorage.getItem(key);
  }

  async setItem(key, item) {
    try {
      await AsyncStorage.setItem(key, JSON.stringify(item));
    } catch (error) {
      console.log('Error');
    }
  }

  async getItem(key) {
    var item = await AsyncStorage.getItem(key);
    return JSON.parse(item);
  }

  async deleteKey(key) {
    try {
      await AsyncStorage.removeItem(key);
    } catch (error) {
      console.log('Error ' + error.value);
    }
  }

  async deleteAll() {
    try {
      await AsyncStorage.clear();
    } catch (error) {
      console.log('Error ' + error.value);
    }
  }
}

export const localstore = new LocalStore();
