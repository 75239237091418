import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { LoadingView } from './LoadingView';

const Stack = createStackNavigator();

export const LoadingNavigation = () => (
  <Stack.Navigator headerMode="none">
    <Stack.Screen name="Loading" component={LoadingView} />
  </Stack.Navigator>
);
