import React, { PureComponent } from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native';

import { formatNotificationContent, formatNotificationImage } from '../lib';
import { openUrl } from '../../../src/lib';
import { ParsedText } from '../../../src/components';

export class Notification extends PureComponent {
  openUrl = () => openUrl(this.props.notification.url);

  render() {
    const { notification } = this.props;
    const MaybeTouchable = notification.url ? TouchableOpacity : View;
    return (
      <MaybeTouchable
        style={styles.pairedData}
        onPress={notification.url ? this.openUrl : null}
      >
        <Image
          style={styles.profileImage}
          source={{ uri: formatNotificationImage(notification) }}
          width={25}
          height={25}
        />
        <View style={styles.contentAndDate}>
          {!!notification.title && (
            <Text style={styles.title}>{notification.title}</Text>
          )}
          <ParsedText style={styles.notificationContent}>
            {formatNotificationContent(notification)}
          </ParsedText>
          <Text style={styles.date}>{notification.date.substring(0, 10)}</Text>
        </View>
      </MaybeTouchable>
    );
  }
}

const styles = StyleSheet.create({
  pairedData: {
    flexDirection: 'row',
    marginHorizontal: 10,
  },
  profileImage: {
    borderRadius: 13,
    margin: 10,
    overflow: 'hidden',
  },
  contentAndDate: { flex: 1 },
  notificationContent: {
    flex: 1,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    marginRight: 20,
    flexWrap: 'wrap',
    color: '#b3b3b3',
  },
  title: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 18,
    color: '#b3b3b3',
  },
  date: {
    fontSize: 16,
    marginTop: 5,
    color: '#b3b3b3',
  },
});
