import React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import Carousel from 'react-native-snap-carousel';
import Banner from './Banner';
import BannerCoinvest from './BannerCoinvest';
import { getBannerSize } from './BannerSize';
import { withWindowDimensions } from '../../../hoc';
import { getInvestments } from '../../../api/Investments';
import { adaptInvestments } from '../../../api/Investments/adapter';
import { getHomeBanners } from '../../../api/Banners';
import { adaptBanners } from '../../../api/Banners/adapter';
import { theme } from '../../../lib';

class Carrousel extends React.Component {
  state = {
    regularBanners: [],
    coinvestBanners: [],
  };

  componentDidMount() {
    this.getBanners();
    this.getCoinvestBanners();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rerender !== prevProps.rerender) {
      this.getBanners();
      this.getCoinvestBanners();
    }
  }

  getBanners = () => {
    return getHomeBanners()
      .then(adaptBanners)
      .then((banners) =>
        this.setState({
          regularBanners: banners
            .filter((banner) => !!banner.imgSrc)
            .map((banner) => ({ ...banner, type: 'regular' })),
        })
      )
      .catch((error) =>
        console.warn('Error retrieving regularBanners data', error)
      );
  };

  getCoinvestBanners = () => {
    return getInvestments()
      .then(adaptInvestments)
      .then((banners) =>
        this.setState({
          coinvestBanners: banners
            .filter((banner) => !!banner.imgSrc || !!banner.heading)
            .map((banner) => ({ ...banner, type: 'coinvest' })),
        })
      )
      .catch((error) =>
        console.warn('Error retrieving coinvest banners data', error)
      );
  };

  renderItem = ({ item: banner }) => {
    switch (banner.type) {
      case 'coinvest':
        return (
          <BannerCoinvest
            key={banner.id}
            id={banner.id}
            imgSrc={banner.imgSrc}
            heading={banner.heading}
            navigation={this.props.navigation}
          />
        );
      case 'regular':
        return (
          <Banner
            key={banner.id}
            imgSrc={banner.imgSrc}
            heading={banner.heading}
            url={banner.url}
          />
        );
      default:
        return null;
    }
  };

  render() {
    if (
      !this.state.coinvestBanners.length &&
      !this.state.regularBanners.length
    ) {
      return null;
    }

    const bannerSize = getBannerSize(this.props.dimensions);

    const bannersOnOneScreen = Math.max(
      1,
      Math.floor(
        this.props.dimensions.width /
          (bannerSize.width + theme.margin.unit * 4 * 2)
      )
    );

    const banners = [
      ...this.state.coinvestBanners,
      ...this.state.regularBanners,
    ];

    if (banners.length <= bannersOnOneScreen) {
      return (
        <FlatList
          style={styles.flatList}
          contentContainerStyle={styles.flatListContainer}
          data={banners}
          renderItem={this.renderItem}
          horizontal
        />
      );
    }

    return (
      <Carousel
        data={banners}
        renderItem={this.renderItem}
        itemWidth={bannerSize.width}
        sliderWidth={this.props.dimensions.width}
        loop
        autoplay
      />
    );
  }
}

export default withWindowDimensions(Carrousel);

const styles = StyleSheet.create({
  flatList: {
    width: '100%',
  },
  flatListContainer: {
    width: '100%',
    justifyContent: 'space-around',
  },
});
