import React from 'react';
import {
  StyleSheet,
  View,
  FlatList,
  ActivityIndicator,
  Text,
  ScrollView,
} from 'react-native';
import { Divider } from 'react-native-elements';
import { isCloseToBottom } from '../src/lib/isCloseToBottom';
import Company from '../components/BookmarkCompany';
import { localstore } from '../helpers/LocalStore';
import { Page } from '../src/components';
import { environment } from '../src/environment';

const isCloseToTop = ({ contentOffset }) => {
  return contentOffset.y <= -1;
};

class LikesScreen extends React.Component {
  state = {
    bearerToken: '',
    currentPage: 1,
    lastPage: -1,
    loading: true,
    fetchingFromServer: false,
    projects: [],
  };

  async componentDidMount() {
    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result });
        this.getProjects();
      } else {
        this.setState({ loading: false });
      }
    });
  }

  getProjects = () => {
    const bearerToken = this.state.bearerToken;
    fetch('https://' + environment.API_HOST + '/v4/projects?loving=true', {
      // mode: 'no-cors',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          let usersObject = json.data;
          var count = Object.keys(usersObject).length;
          let wProjects = [];

          for (let i = 0; i < count; i++) {
            let sectorsObj = usersObject[i].sectors;
            var sCount = Object.keys(sectorsObj).length;
            let sSectors = [];

            for (let j = 0; j < sCount; j++) {
              sSectors.push({
                id: sectorsObj[j].id,
                tag: sectorsObj[j].name,
              });
            }

            let logoPic = 'https:' + usersObject[i].logo.url_xsmall;
            if (logoPic != null) {
              if (logoPic == 'https:/missing/project.png') {
                logoPic =
                  'https://' + environment.API_HOST + '/missing/project.png';
              } else {
                let url = logoPic.toString();
                url = url.replace('staging', 'prod');
                logoPic = url;
              }
            } else {
              logoPic =
                'https://' + environment.API_HOST + '/missing/project.png';
            }

            wProjects.push({
              reference: usersObject[i].ref,
              name: usersObject[i].label,
              description: usersObject[i].description,
              logoSrc: logoPic,
              status: usersObject[i].state,
              comments: usersObject[i].comment_count,
              country: usersObject[i].country,
              countryCode: usersObject[i].country_code,
              money: usersObject[i].fundraising_amount,
              score: usersObject[i].rating.average,
              sectors: [...sSectors],
            });
          }
          this.setState({
            projects: wProjects,
            loading: false,
            fetchingFromServer: false,
          });
        });
      } else {
        console.log('Error retrieving Liked Projects');
        this.setState({ loading: false, fetchingFromServer: false });
      }
    });
  };

  fetchMore = () => {
    if (
      !this.state.fetchingFromServer &&
      this.state.currentPage != this.state.lastPage
    ) {
      console.log('FETCH MORE!');
      this.setState({ fetchingFromServer: true }, () => {
        this.getProjects();
      });
    }
  };

  reloadProjects = () => {
    if (!this.state.fetchingFromServer) {
      console.log('RELOAD');
      this.setState(
        {
          loading: true,
          fetchingFromServer: true,
          currentPage: 1,
          lastPage: -1,
          projects: [],
        },
        () => {
          this.getProjects();
        }
      );
    }
  };

  renderItem = ({ item }) => {
    if (item.empty === true) {
      return <View style={[styles.itemInvisible]} />;
    }

    return (
      <View>
        <Company
          bearerToken={this.state.bearerToken}
          reference={item.reference}
          logoSrc={item.logoSrc}
          country={item.country}
          countryCode={item.countryCode}
          name={item.name}
          money={item.money}
          score={item.score}
          sectors={item.sectors}
          status={item.status}
          comments={item.comments}
          description={item.description}
          navigation={this.props.navigation}
        />

        <Divider style={styles.lineStyle} />
      </View>
    );
  };

  render() {
    let likes;
    let loading = this.state.loading;
    let fetching;

    if (this.state.fetchingFromServer) {
      fetching = (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="grey" />
        </View>
      );
    }

    if (!loading) {
      let projects = this.state.projects;
      if (projects.length > 0) {
        likes = (
          <FlatList
            numColumns={1}
            data={projects}
            style={styles.whiteContainer}
            renderItem={this.renderItem}
            keyExtractor={(item) => item.reference}
          />
        );
      } else {
        likes = (
          <View style={styles.loadingContainer}>
            <Text style={styles.title}>
              You don't have liked projects yet, you can add them inside a
              Project
            </Text>
          </View>
        );
      }
    } else {
      likes = (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="grey" />
        </View>
      );
    }

    return (
      <Page headerColor="#45a9ed" title="My likes">
        <ScrollView
          contentContainerStyle={{ paddingVertical: 20 }}
          onScroll={({ nativeEvent }) => {
            if (isCloseToTop(nativeEvent)) {
              this.reloadProjects();
            }
            if (isCloseToBottom(nativeEvent)) {
              this.fetchMore();
            }
          }}
        >
          {likes}
          {fetching}
        </ScrollView>
      </Page>
    );
  }
}
export default LikesScreen;

const styles = StyleSheet.create({
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
  whiteContainer: {
    paddingBottom: 10,
    marginBottom: 20,
    borderRadius: 20,
    backgroundColor: '#ffffff',
  },
  loadingContainer: {
    backgroundColor: '#ffffff',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
});
