import React, { useCallback } from 'react';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { theme } from '../../../../lib';
import CompanyGridItem from '../../../../../components/CompanyGridItem';
import Company from '../../../../../components/Company';
import { useResponsive } from '../../../../lib/responsive';

const projectKeyExtractor = (project) => project.reference;

export const BottomPanel = ({
  isLoading,
  isRefreshing,
  projects,
  projectCountPerColumns,
  daFlowType,
  showGoodProjectIndicator,
}) => {
  const { isDesktopOrLarger } = useResponsive();

  const renderItem = useCallback(
    ({ item: project }) => (
      <View
        style={[
          styles.companyContainer,
          isDesktopOrLarger && styles.desktopCompanyContainer,
          showGoodProjectIndicator &&
            !!project.rating.is_good && {
              borderColor: 'gold',
              borderWidth: 4,
            },
        ]}
      >
        {projectCountPerColumns === 1 ||
        (isDesktopOrLarger && projectCountPerColumns === 2) ? (
          <Company
            reference={project.reference}
            logoSrc={project.logo.url_medium}
            name={project.name}
            description={project.description}
            country={project.country}
            countryCode={project.countryCode}
            sectors={project.sectors}
            comments={project.commentCount}
            score={project.rating.average}
            money={project.fundraisingAmount}
          />
        ) : (
          <CompanyGridItem
            reference={project.reference}
            logoSrc={project.logo.url_medium}
            name={project.name}
            description={project.description}
            country={project.country}
            countryCode={project.countryCode}
            sectors={project.sectors}
            comments={project.commentCount}
            score={project.rating.average}
            money={project.fundraisingAmount}
          />
        )}
        {daFlowType === 'dropped' && !!project.droppedReason && (
          <Text style={styles.droppedReason}>
            Dropped reason: {project.droppedReason}
          </Text>
        )}
      </View>
    ),
    [
      daFlowType,
      isDesktopOrLarger,
      projectCountPerColumns,
      showGoodProjectIndicator,
    ]
  );

  const renderListFooter = useCallback(
    () => (
      <View style={styles.paginationLoaderContainer}>
        {isLoading && !isRefreshing ? (
          <ActivityIndicator
            size={projects.length ? 'small' : 'large'}
            color="white"
          />
        ) : null}
      </View>
    ),
    [projects.length, isLoading, isRefreshing]
  );

  const renderListEmpty = useCallback(
    () =>
      !isLoading && (
        <Text style={styles.emptyText}>
          There are no projects with this criteria
        </Text>
      ),
    [isLoading]
  );

  return (
    <View style={styles.container}>
      <Text style={styles.title}>All</Text>
      <FlatList
        key={projectCountPerColumns}
        numColumns={projectCountPerColumns}
        scrollEnabled={false}
        data={projects}
        keyExtractor={projectKeyExtractor}
        renderItem={renderItem}
        ListEmptyComponent={renderListEmpty}
        ListFooterComponent={renderListFooter}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: 20,
    backgroundColor: '#7479AE',
    borderRadius: 50,
    paddingVertical: 20,
  },
  title: {
    textAlign: 'center',
    ...theme.fonts.bold,
    color: 'white',
    fontSize: 18,
    marginBottom: 20,
  },
  emptyText: {
    textAlign: 'center',
    ...theme.fonts.normal,
    color: 'white',
  },
  paginationLoaderContainer: {
    marginTop: 20,
    minHeight: 40,
  },
  companyContainer: {
    flex: 1,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 20,
    margin: 5,
  },
  desktopCompanyContainer: {
    margin: 10,
  },
  droppedReason: {
    marginTop: 10,
  },
});
