import { connect } from 'react-redux';
import ChatScreenComponent from './ChatScreen.component';
import { myReferenceSelector } from '../../src/redux/User';
import {
  markDiscussionAsRead,
  discussionByReferenceSelector,
  setLastDiscussionMessage,
  refreshDiscussions,
  fetchDiscussion,
} from '../../src/redux/Discussion';
import { isLoadingSelector } from '../../src/redux/LoadingStatus';

const mapStateToProps = (state, ownProps) => {
  const discussionReference = ownProps.route.params?.discussionReference;
  return {
    isFetchingDiscussion: isLoadingSelector('fetchDiscussion')(state),
    myReference: myReferenceSelector(state),
    discussionReference,
    discussion: discussionReference
      ? discussionByReferenceSelector(discussionReference)(state)
      : null,
  };
};

const mapDispatchToProps = {
  markDiscussionAsRead,
  setLastDiscussionMessage,
  refreshDiscussions,
  fetchDiscussion,
};

export const ConnectedChatScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatScreenComponent);
