import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Platform,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../src/lib/StatusBarHeight';

import Flag from 'react-native-round-flags';
import { Overlay } from 'react-native-elements';

import ProjectCommentsOverlay from '../overlays/ProjectCommentsOverlay';
import { formatMoney } from '../src/lib';
import { Rating } from '../src/components/Rating';
import { NavigationTouchable } from '../src/components';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);

class CompanyGridItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProjectCommentsOverlay: false,
    };
    this.showProjectCommentsOverlay = this.showProjectCommentsOverlay.bind(
      this
    );
  }

  showProjectCommentsOverlay(show) {
    this.setState({ showProjectCommentsOverlay: show });
  }

  updateComments = () => {
    console.log('update comments');
  };

  render() {
    let money = this.props.money;
    if (money == null) {
      money = '0';
    } else {
      money = formatMoney(money);
    }

    let sectorsText;
    let sectors = this.props.sectors;

    if (sectors != null) {
      if (sectors.length > 3) {
        let temp = [sectors[0], sectors[1], sectors[2]];
        sectors = temp;
      }

      sectorsText = sectors.map((tag, id) => (
        <Text key={id} numberOfLines={1} style={styles.sectorText}>
          {tag.tag}
        </Text>
      ));
    } else {
      console.log('No sectors for this company');
      sectorsText = <Text style={styles.sectorText}>General</Text>;
    }

    let logoSrc = this.props.logoSrc;
    if (logoSrc == null) {
      logoSrc = 'https://daphni.com/favicon-128x128.png';
    }

    let projectCommentsOverlay = (
      <Overlay
        isVisible={this.state.showProjectCommentsOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          { width: WIDTH, height: HEIGHT * 0.9 },
        ]}
        onBackdropPress={() =>
          this.setState({ showProjectCommentsOverlay: false })
        }
      >
        <ProjectCommentsOverlay
          logoSrc={logoSrc}
          score={this.props.score || 0}
          name={this.props.name}
          money={money}
          updateComments={this.updateComments}
        />
      </Overlay>
    );

    return (
      <NavigationTouchable to={`/project/${this.props.reference}`}>
        <View style={styles.item}>
          {projectCommentsOverlay}
          <Image
            style={styles.logoImage}
            source={{ uri: logoSrc }}
            resizeMode="contain"
          />
          <View style={styles.pairedData}>
            <Text numberOfLines={1} style={styles.itemTitle}>
              {this.props.name}
            </Text>
            <Flag code={this.props.countryCode} style={styles.flag} />
          </View>
          <View>{sectorsText}</View>
          <View style={styles.triadData}>
            <TouchableOpacity
              style={{
                width: '30%',
                alignContent: 'center',
                justifyContent: 'center',
              }}
              onPress={() => this.showProjectCommentsOverlay(true)}
            >
              <View>
                <Text style={styles.itemText}>{this.props.comments}</Text>
                <Image
                  style={{
                    tintColor: '#666666',
                    alignSelf: 'center',
                    width: 10,
                    height: 10,
                  }}
                  source={require('../assets/icons/messagesIcon.png')}
                  resizeMode="contain"
                />
              </View>
            </TouchableOpacity>
            <Rating
              style={{
                width: '33%',
                alignContent: 'center',
                justifyContent: 'center',
              }}
              textStyle={styles.itemText}
              starsContainerStyle={styles.stars}
              starSize={8}
              value={this.props.score}
            />
            <View
              style={{
                width: '36%',
                alignContent: 'center',
                justifyContent: 'center',
              }}
            >
              <Text style={styles.itemText}>€{money}</Text>
              <Text style={styles.miniText}>Requested</Text>
            </View>
          </View>
        </View>
      </NavigationTouchable>
    );
  }
}

export default CompanyGridItem;

const styles = StyleSheet.create({
  item: {
    backgroundColor: '#ffffff',
    flex: 1,
    justifyContent: 'space-between',
  },
  bottomOverlay: {
    position: 'absolute',
    bottom: 0,
    padding: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  flag: {
    width: 16,
    height: 16,
  },
  itemTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#666666',
    fontSize: 16,
    marginBottom: 5,
  },
  itemText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    textAlign: 'center',
  },
  sectorText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginLeft: 10,
  },
  miniText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 11,
    textAlign: 'center',
  },
  logoImage: {
    width: 80,
    height: 80,
    alignSelf: 'center',
    margin: 10,
    marginTop: 20,
  },
  stars: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  pairedData: {
    flexDirection: 'row',
    marginLeft: 10,
  },
  triadData: {
    margin: 10,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
