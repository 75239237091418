import React from 'react';
import { Text, StyleSheet } from 'react-native';
import { Divider } from 'react-native-elements';
import { Page } from '../../src/components';
import { PaginatedFlatList } from '../../src/components/PaginatedFlatList';
import { Notification } from './components/Notification';

export class NotificationsScreenComponent extends React.Component {
  renderEmpty = () => (
    <Text style={styles.emptyText}>You don't have notifications yet</Text>
  );

  renderNotification = ({ item: notification }) => {
    return <Notification notification={notification} />;
  };

  renderSeparator = () => <Divider style={styles.line} />;
  keyExtractor = (notification) => String(notification.id);

  render() {
    return (
      <Page headerColor="#ffcf56" title="Notifications">
        <PaginatedFlatList
          keyExtractor={this.keyExtractor}
          renderItem={this.renderNotification}
          renderEmpty={this.renderEmpty}
          ItemSeparatorComponent={this.renderSeparator}
          fetchData={this.props.fetchNotifications}
          adaptData={this.props.adaptNotifications}
          onDataFetched={this.props.markAsVisited}
          fetchOnMount
        />
      </Page>
    );
  }
}

const styles = StyleSheet.create({
  line: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
  emptyText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    marginTop: 50,
    textAlign: 'center',
  },
});
