import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { useWatermarkText } from './useWatermarkText';

export const Watermark = (props) => {
  const watermarkText = useWatermarkText();
  return (
    <View style={[styles.container, props.style]}>
      <Text style={[styles.text, props.textStyle]}>{watermarkText}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    zIndex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    textAlign: 'center',
    color: 'white',
    backgroundColor: 'rgba(0,0,0,0.4)',
    paddingVertical: 2,
    paddingHorizontal: 4,
  },
});
