import { useCallback, useEffect, useRef, useState } from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';
import { adaptProjects } from '../../../api/Project/adapter';
import { adaptPaginationMeta, getProjects } from '../../../api';

export const useBottomPanelProjects = ({
  fetchQueryParams,
  isRefreshing,
  activeSectorsIds,
  isFilteringEnabled,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listCurrentPage, setListCurrentPage] = useState(0);
  const [listLastPage, setListLastPage] = useState(null);
  const [allProjects, setAllProjects] = useState([]);

  const canFetchMore =
    listCurrentPage !== listLastPage && !isLoading && !isRefreshing;

  const lastFilterParams = useRef();

  const fetchMore = useDeepCompareCallback(
    async (forceCurrentPage) => {
      setIsLoading(true);
      try {
        const response = await getProjects({
          ...(isFilteringEnabled ? { sectors: activeSectorsIds } : {}),
          sort: '-rating,-rating_count',
          ...fetchQueryParams,
          page:
            (forceCurrentPage != null ? forceCurrentPage : listCurrentPage) + 1,
        });
        const projects = adaptProjects(response);
        const { lastPage, currentPage } = adaptPaginationMeta(response);
        setListLastPage(lastPage);
        setListCurrentPage(currentPage);
        setAllProjects((currentProjects) => [...currentProjects, ...projects]);
      } catch (error) {
        console.warn('Error while fetching data', { error });
      } finally {
        setIsLoading(false);
      }
    },
    [activeSectorsIds, fetchQueryParams, isFilteringEnabled, listCurrentPage]
  );

  const resetProjectsRef = useRef(() => {
    setListCurrentPage(0);
    setAllProjects([]);
  });

  useEffect(() => {
    const newFilterParams = JSON.stringify({
      activeSectorsIds,
      isFilteringEnabled,
      fetchQueryParams,
    });
    if (
      lastFilterParams.current &&
      lastFilterParams.current === newFilterParams
    ) {
      return;
    }

    lastFilterParams.current = newFilterParams;
    resetProjectsRef.current();
    fetchMore(0);
  }, [fetchMore, activeSectorsIds, isFilteringEnabled, fetchQueryParams]);

  const setProject = useCallback((reference, newProject) => {
    setAllProjects((updatedProjects) =>
      updatedProjects
        .map((updatedProject) =>
          updatedProject.reference === reference ? newProject : updatedProject
        )
        .filter(Boolean)
    );
  }, []);

  return {
    isLoading,
    allProjects,
    canFetchMore,
    fetchMore,
    resetProjectsRef,
    setProject,
  };
};
