import React, { Component } from 'react';
import {
  StyleSheet,
  ActivityIndicator,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
} from 'react-native';
import { Avatar } from 'react-native-elements';
import { MultilineTextInput } from '../../../src/components/MultilineTextInput';
import { environment } from '../../../src/environment';

const deviceWidth = Dimensions.get('window').width;

export class ShareForm extends Component {
  state = {
    isLoading: false,
    message: '',
  };

  updateMessage = ({ nativeEvent }) => {
    this.setState({ message: nativeEvent.text });
  };

  shareProject = () => {
    const { receiver, bearerToken, reference } = this.props;

    this.setState({ isLoading: true });
    fetch(
      'https://' +
        environment.API_HOST +
        '/v4/projects/' +
        reference +
        '/share',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + bearerToken,
        },
        body: JSON.stringify({
          user_ref: receiver.ref,
          message: this.state.message,
        }),
      }
    ).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          let message = json.message;
          this.setState({ isLoading: false });
          this.props.successfullShare(message);
        });
      } else {
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { receiver } = this.props;
    return (
      <View style={styles.container}>
        <View style={styles.receiverContainer}>
          <Avatar rounded size={45} source={{ uri: receiver.avatar }} />
          <Text style={[styles.wText, styles.receiverName]}>
            {receiver.name}
          </Text>
        </View>
        <View style={styles.inputContainer}>
          <MultilineTextInput
            autoFocus={false}
            width="90%"
            onChange={this.updateMessage}
            style={styles.searchText}
            placeholder="Write your message (optional)"
            placeholderTextColor="#00b6ba"
          />
        </View>
        <TouchableOpacity style={styles.sendButton} onPress={this.shareProject}>
          {!this.state.isLoading ? (
            <Text style={styles.gText}>Send</Text>
          ) : (
            <ActivityIndicator size="small" color="grey" />
          )}
        </TouchableOpacity>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    width: deviceWidth,
    alignItems: 'center',
  },
  receiverContainer: {
    width: deviceWidth - 40,
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#fff',
    textAlign: 'center',
  },
  receiverName: {
    marginLeft: 5,
  },
  inputContainer: {
    flexDirection: 'row',
    width: deviceWidth - 40,
    minHeight: 90,
    marginTop: 10,
    marginBottom: 20,
    borderRadius: 10,
    backgroundColor: '#fff',
    padding: 10,
  },
  searchText: {
    flex: 1,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 15,
    color: '#666',
  },
  sendButton: {
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    width: 60,
    height: 20,
    borderRadius: 10,
  },
  gText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#00b6ba',
    textAlign: 'center',
  },
});
