import * as React from 'react';
import { linkingConfig } from '../navigation/linkingConfig';

const extractPathFromURL = (url) => {
  for (const prefix of linkingConfig.prefixes) {
    if (url.startsWith(prefix)) {
      return url.replace(prefix, '');
    }
  }
  return undefined;
};

class Navigation {
  navigationRef = React.createRef();
  deeplinkPath = null;

  setDeeplink = (url) => {
    this.deeplinkPath = '/' + extractPathFromURL(url);
  };

  getNavigator = () =>
    new Promise((resolve) => {
      if (this.navigationRef.current) {
        resolve(this.navigationRef.current);
        return;
      }

      let tryCount = 0;
      const interval = setInterval(() => {
        if (this.navigationRef.current) {
          resolve(this.navigationRef.current);
          clearInterval(interval);
        } else {
          tryCount++;
        }

        if (tryCount > 20) {
          throw new Error('Waited too long for navigator');
        }
      }, 50);
    });

  dispatch = (...args) =>
    this.getNavigator().then((navigator) => navigator.dispatch(...args));
}

export const navigation = new Navigation();
