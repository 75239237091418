import React, { useCallback, useState } from 'react';
import { TextInput } from 'react-native';

export const MultilineTextInput = (props) => {
  const [contentSizeHeight, setContentSizeHeight] = useState(0);
  const onContentSizeChange = useCallback(({ nativeEvent }) => {
    setContentSizeHeight(nativeEvent.contentSize.height);
  }, []);

  return (
    <TextInput
      multiline
      {...props}
      onContentSizeChange={onContentSizeChange}
      style={[
        props.style,
        {
          height: contentSizeHeight
            ? Math.min(75, contentSizeHeight)
            : undefined,
        },
      ]}
    />
  );
};
