import React from 'react';
import {
  StyleSheet,
  ActivityIndicator,
  View,
  InteractionManager,
} from 'react-native';
import { Avatar } from 'react-native-elements';
import { keyboardHeightListener } from '../../components/keyboardHeightListener';
import {
  adaptDiscussionMessages,
  getDiscussionMessages,
} from '../../src/api/Discussion';
import { MISSING_AVATAR_URL } from '../../src/api/base';
import { GroupSettingsButton, MessageList, SendForm } from './components';
import { Page } from '../../src/components';
import {
  formatDiscussionAvatar,
  formatDiscussionTitle,
} from '../ChatListScreen/lib';

class ChatScreenComponent extends React.Component {
  refreshInterval = null;

  state = {
    conversation: [],
    isLoadingMessages: false,
  };

  componentDidMount() {
    InteractionManager.runAfterInteractions(() => this.getDiscussionContent());

    this.refreshInterval = setInterval(
      () => this.getDiscussionContent(),
      10 * 1000
    );
  }

  componentWillUnmount() {
    if (this.refreshDiscussionsOnLeavingPage) {
      this.props.refreshDiscussions(false);
    }

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }
  }

  getDiscussionContent = () => {
    const { discussionReference, discussion } = this.props;
    if (discussionReference) {
      if (discussion) {
        return this.getChatComments(discussion);
      }

      this.props.fetchDiscussion({
        discussionReference,
        onDiscussionFetched: this.getChatComments,
      });
    }
  };

  getChatComments = async (discussion) => {
    this.setState({ isLoadingMessages: true });
    getDiscussionMessages(discussion.reference)
      .then((apiDiscussionMessages) => {
        const messages = adaptDiscussionMessages(apiDiscussionMessages);
        this.setState({
          conversation: messages,
          isLoadingMessages: false,
        });
        return messages;
      })
      .then((messages) => {
        if (messages.length) {
          this.props.markDiscussionAsRead(discussion.reference);
        }
      })
      .catch((error) => {
        console.log('Error retrieving chat data', error);
        this.setState({ isLoadingMessages: false });
      });
  };

  onSentMessage = (message) => {
    this.setState((state) => {
      if (
        state.conversation.some(
          (conversationMessage) => conversationMessage.id === message.id
        )
      ) {
        return state;
      }

      return {
        conversation: [message, ...state.conversation],
      };
    });
    if (this.props.discussion) {
      this.props.setLastDiscussionMessage(
        message,
        this.props.discussion.reference
      );
    } else {
      this.refreshDiscussionsOnLeavingPage = true;
    }
  };

  renderForm = () => (
    <SendForm
      style={{ marginBottom: this.props.keyboardHeight + 10 }}
      discussion={this.props.discussion}
      otherUserReference={this.props.route.params?.otherUserReference}
      onSent={this.onSentMessage}
    />
  );

  renderConversation = () => (
    <MessageList
      messages={this.state.conversation}
      isLoading={
        this.state.isLoadingMessages || this.props.isFetchingDiscussion
      }
      myReference={this.props.myReference}
    />
  );

  renderHeaderRight = () => (
    <View style={styles.headerRight}>
      {this.state.isLoadingMessages &&
        this.state.conversation.length > 0 &&
        !this.props.isFetchingDiscussion && <ActivityIndicator color="white" />}
      <GroupSettingsButton
        style={styles.discussionButton}
        discussion={this.props.discussion}
      />
    </View>
  );

  renderHeaderLeft = () => (
    <Avatar
      rounded
      size={40}
      source={{
        uri: this.getAvatar(),
      }}
    />
  );

  getAvatar = () => {
    const { discussion, route } = this.props;
    if (!discussion) {
      return route.params?.avatar || MISSING_AVATAR_URL();
    }

    return formatDiscussionAvatar(discussion);
  };

  getTitle = () => {
    const { discussion, route } = this.props;
    if (!discussion) {
      return route.params?.title;
    }

    return formatDiscussionTitle(discussion);
  };

  render() {
    return (
      <Page
        headerColor="#45a9ed"
        backgroundColor="#70bcf3"
        imageBackground={require('../../assets/lightBackground.png')}
        title={this.getTitle()}
        alignTitleLeft
        renderHeaderLeft={this.renderHeaderLeft}
        renderHeaderRight={this.renderHeaderRight}
      >
        {this.renderConversation()}
        {this.renderForm()}
      </Page>
    );
  }
}

export default keyboardHeightListener({ ios: true, android: true })(
  ChatScreenComponent
);

const styles = StyleSheet.create({
  discussionButton: {
    marginLeft: 10,
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
