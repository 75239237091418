import React, { PureComponent } from 'react';
import { StyleSheet, ScrollView } from 'react-native';
import { SafeAreaConsumer } from 'react-native-safe-area-context';

export class SafeAreaScrollView extends PureComponent {
  render() {
    const { contentContainerStyle, ...props } = this.props;
    const flattenedContentContainerStyle = StyleSheet.flatten(
      contentContainerStyle
    );

    return (
      <SafeAreaConsumer>
        {(insets) => (
          <ScrollView
            {...props}
            contentContainerStyle={{
              ...flattenedContentContainerStyle,
              paddingTop:
                insets.top +
                (contentContainerStyle.paddingTop ||
                  contentContainerStyle.paddingVertical ||
                  0),
              paddingBottom:
                insets.bottom +
                (contentContainerStyle.paddingBottom ||
                  contentContainerStyle.paddingVertical ||
                  0),
              paddingLeft:
                insets.left +
                (contentContainerStyle.paddingLeft ||
                  contentContainerStyle.paddingHorizontal ||
                  0),
              paddingRight:
                insets.right +
                (contentContainerStyle.paddingRight ||
                  contentContainerStyle.paddingHorizontal ||
                  0),
            }}
          />
        )}
      </SafeAreaConsumer>
    );
  }
}
