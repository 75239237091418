import React from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';

export const Rating = ({
  value,
  style,
  textStyle,
  starSize,
  starsContainerStyle,
}) => {
  const rating = value || 0;
  const maxRating = 4;

  const roundedRating = Math.round(rating);
  const fullStars = new Array(roundedRating).fill('full');
  const emptyStars = new Array(maxRating - roundedRating).fill('empty');

  return (
    <View style={style}>
      <Text style={[styles.text, textStyle]}>{rating}</Text>
      <View style={[styles.starsContainer, starsContainerStyle]}>
        {[...fullStars, ...emptyStars].map((type, index) => (
          <Image
            key={index}
            style={{
              width: starSize || 10,
              height: starSize || 10,
              tintColor: type === 'full' ? '#666666' : '#cdcdcd',
            }}
            source={require('../../assets/icons/starIcon.png')}
            resizeMode="contain"
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  starsContainer: {
    flexDirection: 'row',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
});
