/* eslint-disable import/no-unused-modules */

const CodePush = () => (RootComponent) => RootComponent;
CodePush.getUpdateMetadata = () => Promise.resolve(null);
CodePush.InstallMode = {
  IMMEDIATE: 'IMMEDIATE',
  ON_NEXT_RESTART: 'ON_NEXT_RESTART',
};
CodePush.CheckFrequency = { MANUAL: 'MANUAL', ON_APP_START: 'ON_APP_START' };
export default CodePush;
