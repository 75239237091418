export const allDaFlowTypeInfo = [
  {
    key: 'pending',
    label: 'Pending',
    routeName: 'DaFlowPending',
    needsRole: ['team', 'family'],
  },
  {
    key: 'new',
    label: 'New',
    routeName: 'DaFlowNew',
  },
  {
    key: 'exploration',
    label: 'Exploration',
    routeName: 'DaFlowNew',
  },
  {
    key: 'termsheet',
    label: 'Term Sheet',
    routeName: 'DaFlowTermsheet',
  },
  {
    key: 'dropped',
    label: 'Dropped',
    routeName: 'DaFlowDropped',
  },
];
