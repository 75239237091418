import BaseDaphnipolitan from './BaseDaphnipolitan';
import { StyleSheet } from 'react-native';
import React from 'react';

const SearchDaphnipolitan = (props) => (
  <BaseDaphnipolitan {...props} containerStyle={styles.container} />
);

const styles = StyleSheet.create({
  container: {
    marginVertical: 10,
    marginHorizontal: 10,
  },
});

export default SearchDaphnipolitan;
