import React, { useCallback, useState } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from 'react-native';

import { Icon } from 'react-native-elements';
import { openUrl } from '../src/lib';
import { tabletOrLargerMinPixel } from '../src/lib/responsive';

const EVENT_IMAGE_ASPECT_RATIO = 1 / 2;

const DaphniEvent = (props) => {
  const dimensions = useWindowDimensions();
  const [layout, setLayout] = useState({ x: 0, y: 0, width: 0, height: 0 });

  const onLayout = useCallback(
    ({ nativeEvent }) => {
      setLayout(nativeEvent.layout);
    },
    [setLayout]
  );

  let eventSrc = props.imageSrc;
  if (eventSrc == null) {
    eventSrc = 'https://daphni.com/favicon-128x128.png';
  }

  let eventURL = props.url;
  if (eventURL == null) {
    eventURL = 'https://www.eventbrite.com/';
  }

  const isTabletOrLarger = dimensions.width > tabletOrLargerMinPixel;
  const maxImageWidth = isTabletOrLarger ? layout.width / 4 : layout.width;

  return (
    <TouchableOpacity onPress={() => openUrl(eventURL)}>
      <View onLayout={onLayout} style={styles.container}>
        <Image
          style={[
            styles.bannerImage,
            {
              maxWidth: maxImageWidth,
              height: maxImageWidth * EVENT_IMAGE_ASPECT_RATIO,
            },
          ]}
          source={{ uri: eventSrc }}
          resizeMode="cover"
        />
        {!!props.address && (
          <View style={styles.addressContainer}>
            <Icon name="place" size={15} marginLeft={8} color="#666666" />
            <Text style={styles.address}>{props.address}</Text>
          </View>
        )}
        <Text numberOfLines={2} style={styles.description}>
          {props.summary}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default DaphniEvent;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 10,
  },
  addressContainer: {
    flexDirection: 'row',
  },
  address: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
  description: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'justify',
  },
  bannerImage: {
    width: '100%',
    marginBottom: 10,
    borderRadius: 20,
  },
});
