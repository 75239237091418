import React from 'react';
import { DaFlowGenericPage } from './DaFlowGenericPage';

export const DaFlowExploration = () => {
  return (
    <DaFlowGenericPage
      daFlowType="exploration"
      bottomPanel={{
        fetchProjectsParams: {
          state: 'exploration',
        },
      }}
      topPanel={{
        title: 'Help us on:',
        fetchProjectsParams: {
          state: 'exploration',
          dig_into: true,
        },
      }}
    />
  );
};
