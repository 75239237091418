const loadingStatusSelector = (state, key) => state.loadingStatus[key];

export const isLoadingSelector = (key) => (state) => {
  const loadingStatus = loadingStatusSelector(state, key);
  return loadingStatus ? loadingStatus.isLoading : false;
};

export const hasErroredSelector = (key) => (state) => {
  const loadingStatus = loadingStatusSelector(state, key);
  return loadingStatus ? !!loadingStatus.error : false;
};

export const errorSelector = (key) => (state) => {
  const loadingStatus = loadingStatusSelector(state, key);
  return loadingStatus ? loadingStatus.error : undefined;
};
