import React from 'react';
import { Text, View, TouchableOpacity, Image } from 'react-native';
import { withNavigation } from '../../src/navigation/withNavigation';
import styles from './Daphnipolitan.json';
import Flag from 'react-native-round-flags';
import { environment } from '../../src/environment';

class Daphnipolitan extends React.Component {
  render() {
    let sectorsTxt;
    let sectors = this.props.sectors;

    if (sectors != null) {
      if (sectors.length > 3) {
        let temp = [sectors[0], sectors[1], sectors[2]];
        sectors = temp;
      }
      sectorsTxt = sectors.map((sector) => (
        <Text
          key={sector.id}
          style={[
            styles.Daphnipolitan.BottomContainer.Text,
            { fontFamily: 'Freight-Sans-Medium' },
          ]}
        >
          {sector.name}
        </Text>
      ));
    } else {
      console.log('No sectors where passed');
    }

    let profilePic = this.props.avatar;
    if (profilePic == null) {
      profilePic = 'https://' + environment.API_HOST + '/missing/avatar.png';
    }

    let likes = this.props.likes;
    if (likes == null || likes == '') {
      likes = 0;
    }

    let pings = this.props.pings;
    if (pings == null || pings == '') {
      pings = 0;
    }

    return (
      <View style={styles.Daphnipolitan}>
        <TouchableOpacity
          onPress={() =>
            this.props.navigation.navigate('Daphnipolitan', {
              reference: this.props.reference,
              defaultUserData: {
                sectors: this.props.sectors,
                name: this.props.name,
                job: this.props.job,
                city: this.props.city,
                company: this.props.company,
                connected_to: this.props.connected_to,
                country: this.props.country,
                countryCode: this.props.countryCode,
                socials: this.props.social,
                avatar: profilePic,
              },
            })
          }
        >
          <View style={styles.Daphnipolitan.TopContainer}>
            <Image
              source={{ uri: profilePic }}
              resizeMode="contain"
              style={[
                styles.Daphnipolitan.ProfileImage,
                { width: 60, height: 60 },
              ]}
            />
            {this.props.likes > 0 && (
              <View>
                <View style={styles.Daphnipolitan.TopContainer.PairedData}>
                  <Image
                    source={require('../../assets/icons/likeIcon.png')}
                    resizeMode="contain"
                    style={[
                      styles.Daphnipolitan.TopContainer.Icon,
                      { width: 15, height: 15 },
                    ]}
                  />
                  <Text
                    style={[
                      styles.Daphnipolitan.TopContainer.Text,
                      { fontFamily: 'Freight-Sans-Book' },
                    ]}
                  >
                    {' '}
                    +{likes}
                  </Text>
                </View>
                <View style={styles.Daphnipolitan.TopContainer.PairedData}>
                  <Image
                    source={require('../../assets/icons/waveIcon.png')}
                    resizeMode="contain"
                    style={[
                      styles.Daphnipolitan.TopContainer.Icon,
                      { width: 20, height: 20 },
                    ]}
                  />
                  <Text
                    style={[
                      styles.Daphnipolitan.TopContainer.Text,
                      { fontFamily: 'Freight-Sans-Book' },
                    ]}
                  >
                    {' '}
                    +{pings}
                  </Text>
                </View>
              </View>
            )}
          </View>
          <View style={styles.Daphnipolitan.BottomContainer}>
            <View style={{ flexDirection: 'row' }}>
              <Text
                style={[
                  styles.Daphnipolitan.Name,
                  { fontFamily: 'Freight-Sans-Black' },
                ]}
              >
                {this.props.name}
              </Text>
              <Flag
                code={this.props.countryCode}
                style={{ marginLeft: 3, width: 16, height: 16 }}
              />
            </View>
            <View>
              <Text
                style={[
                  styles.Daphnipolitan.BottomContainer.Text,
                  { fontFamily: 'Freight-Sans-Medium' },
                ]}
              >
                {this.props.job}
              </Text>
              <Text
                style={[
                  styles.Daphnipolitan.BottomContainer.Text,
                  { fontFamily: 'Freight-Sans-Medium' },
                ]}
              >
                {this.props.company}
              </Text>
              {sectorsTxt}
            </View>
          </View>
        </TouchableOpacity>
      </View>
    );
  }
}
export default withNavigation(Daphnipolitan);
