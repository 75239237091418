import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  ActivityIndicator,
} from 'react-native';
import { environment } from '../src/environment';

const WIDTH = Dimensions.get('window').width - 10;

class PingOverlay extends React.Component {
  state = {
    loading: false,
    sent: false,
  };

  pingProject() {
    const bearerToken = this.props.bearerToken;
    const reference = this.props.reference;
    fetch(
      'https://' + environment.API_HOST + '/v4/projects/' + reference + '/ping',
      {
        // mode: 'no-cors',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + bearerToken,
        },
      }
    ).then((response) => {
      if (response.ok) {
        console.log('Pinged!');
        this.setState({ sent: true }, () => {
          this.setState({ loading: false });
          if (this.props.successfullPing) {
            this.props.successfullPing();
          }
        });
      } else {
        console.log('Error with this company ping, try again');
        this.setState({ loading: false });
      }
    });
  }

  agreeButton() {
    console.log('In ping project reference ' + this.props.reference);
    this.setState({ loading: true }, () => {
      this.pingProject();
    });
  }

  cancelButton() {
    this.props.showPingOverlay(false);
  }

  render() {
    let overlay;
    if (this.state.loading) {
      overlay = (
        <View style={styles.whiteContainer}>
          <ActivityIndicator
            style={{ alignSelf: 'center' }}
            size="large"
            color="grey"
          />
        </View>
      );
    } else {
      if (!this.state.sent) {
        overlay = (
          <View style={styles.whiteContainer}>
            <Text style={styles.text}>
              Ping daphni team to share an info! A member of the team will
              contact you in few minutes or as soon as someone is available.
            </Text>
            <View style={[styles.buttonContainer]}>
              <TouchableOpacity
                style={{
                  width: '50%',
                  backgroundColor: '#ffffff',
                  borderBottomLeftRadius: 20,
                }}
                onPress={() => this.cancelButton()}
              >
                <Text style={styles.title}>Cancel</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={{
                  width: '50%',
                  backgroundColor: '#00b6ba',
                  borderBottomRightRadius: 20,
                }}
                onPress={() => this.agreeButton()}
              >
                <Text style={styles.wTitle}>OK</Text>
              </TouchableOpacity>
            </View>
          </View>
        );
      } else {
        overlay = (
          <View style={[styles.whiteContainer]}>
            <Text style={[styles.text, { alignSelf: 'center' }]}>
              Your Ping was sent
            </Text>
          </View>
        );
      }
    }
    return <View>{overlay}</View>;
  }
}

export default PingOverlay;

const styles = StyleSheet.create({
  whiteContainer: {
    backgroundColor: '#ffffff',
    height: 133,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  buttonContainer: {
    position: 'absolute',
    left: -10,
    bottom: 0,
    width: WIDTH,
    height: 40,
    flexDirection: 'row',
    borderColor: '#999999',
    borderTopWidth: 1,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center',
    color: '#666666',
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center',
    color: '#ffffff',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    marginHorizontal: 10,
    marginTop: 20,
  },
  line: {
    borderBottomColor: '#666666',
    borderBottomWidth: 1,
  },
});
