import React from 'react';
import {
  StyleSheet,
  View,
  FlatList,
  ActivityIndicator,
  Text,
  TextInput,
  ScrollView,
  Keyboard,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Divider } from 'react-native-elements';

import DaflowCompany from '../components/Company';
import DaHouseCompany from '../src/pages/DaHouse/components/HouseCompany';
import DaphniNews from '../components/DaphniNews';
import DaphniEvent from '../components/DaphniEvent';
import { localstore } from '../helpers/LocalStore';
import SearchDaphnipolitan from '../components/Daphnipolitan/SearchDaphnipolitan';
import { searchNews } from '../src/api';
import { adaptNews } from '../src/api/News/adapter';
import { Button, Page } from '../src/components';
import { environment } from '../src/environment';

class SearchOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bearerToken: '',
      index: 0,
      showSearchOptions: false,
      search: '',
      loadingProjects: false,
      loadingUsers: false,
      loadingNews: false,
      projects: null,
      news: null,
      users: null,
    };
  }

  async componentDidMount() {
    let index = this.props.route.params?.index ?? -1;
    console.log('Searchbar index ' + index);
    this.setState({ index });

    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result }, () => {
          let searchWord = this.props.route.params?.tag ?? '';
          if (searchWord != '') {
            Keyboard.dismiss();
            this.setState({ search: searchWord });
            this.searchAll(searchWord);
          }
        });
      } else {
        console.log('Error retrieving token');
        this.setState({ bearerToken: 'none' });
      }
    });
  }

  updateSearch = (event) => {
    this.setState({ search: event.nativeEvent.text, showSearchOptions: true });
  };

  searchButton = (event) => {
    const keyword = event.nativeEvent.text;
    this.searchAll(keyword);
  };

  searchAll(keyword) {
    this.setState(
      {
        search: keyword,
        loadingProjects: true,
        loadingUsers: true,
        loadingNews: true,
        showSearchOptions: false,
        projects: null,
        users: null,
        news: null,
      },
      () => {
        this.getProjects();
        this.getUsers();
        this.getNews();
      }
    );
  }

  searchAllButton = () => {
    Keyboard.dismiss();
    this.searchAll(this.state.search);
  };

  searchProjectsButton = () => {
    console.log(
      'Search Projects button pressed, fetch suff with ' + this.state.keyword
    );
    Keyboard.dismiss();
    this.setState(
      {
        loadingProjects: true,
        showSearchOptions: false,
        projects: null,
        users: null,
        news: null,
      },
      () => {
        this.getProjects();
      }
    );
  };

  searchUsersButton = () => {
    console.log(
      'Search Users button pressed, fetch suff with ' + this.state.keyword
    );
    Keyboard.dismiss();
    this.setState(
      {
        loadingUsers: true,
        showSearchOptions: false,
        projects: null,
        users: null,
        news: null,
      },
      () => {
        this.getUsers();
      }
    );
  };

  searchNewsButton = () => {
    console.log(
      'Search News button pressed, fetch suff with ' + this.state.keyword
    );
    Keyboard.dismiss();
    this.setState(
      {
        loadingNews: true,
        showSearchOptions: false,
        projects: null,
        users: null,
        news: null,
      },
      () => {
        this.getNews();
      }
    );
  };

  getProjects = () => {
    const bearerToken = this.state.bearerToken;
    const keyword = this.state.search;

    console.log('Search ' + keyword);

    fetch(
      'https://' +
        environment.API_HOST +
        '/v4/projects?per=200&keyword=' +
        keyword,
      {
        // mode: 'no-cors',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + bearerToken,
        },
      }
    ).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          let usersObject = json.data;
          var count = Object.keys(usersObject).length;
          let fProjects = [];

          for (let i = 0; i < count; i++) {
            let sectorsObj = usersObject[i].sectors;
            var sCount = Object.keys(sectorsObj).length;
            let sSectors = [];

            for (let j = 0; j < sCount; j++) {
              sSectors.push({
                id: sectorsObj[j].id,
                tag: sectorsObj[j].name,
              });
            }

            let logoPic = 'https:' + usersObject[i].logo.url_xsmall;
            if (logoPic != null) {
              if (logoPic == 'https:/missing/project.png') {
                logoPic =
                  'https://' + environment.API_HOST + '/missing/project.png';
              } else {
                let url = logoPic.toString();
                url = url.replace('staging', 'prod');
                logoPic = url;
              }
            } else {
              logoPic =
                'https://' + environment.API_HOST + '/missing/project.png';
            }

            fProjects.push({
              reference: usersObject[i].ref,
              name: usersObject[i].label,
              description: usersObject[i].description,
              logoSrc: logoPic,
              comments: usersObject[i].comment_count,
              country: usersObject[i].country,
              countryCode: usersObject[i].country_code,
              money: usersObject[i].fundraising_amount,
              score: usersObject[i].rating.average,
              state: usersObject[i].state,
              activity: usersObject[i].business_activity,
              type: usersObject[i].funds && usersObject[i].funds[0],
              sectors: [...sSectors],
            });
          }

          this.setState({ projects: fProjects }, () => {
            this.setState({ loadingProjects: false });
          });
        });
      } else {
        console.log('Error retrieving DaFlow new projects');
        this.setState({ loadingProjects: false });
      }
    });
  };

  getUsers = () => {
    const bearerToken = this.state.bearerToken;
    const keyword = this.state.search;
    fetch('https://' + environment.API_HOST + '/v4/users?keyword=' + keyword, {
      // mode: 'no-cors',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          let usersObject = json.data;
          var count = Object.keys(usersObject).length;
          let sUsers = [];

          for (let i = 0; i < count; i++) {
            let sectorsObj = usersObject[i].sectors;
            var sCount = Object.keys(sectorsObj).length;
            let sSectors = [];

            for (let j = 0; j < sCount; j++) {
              sSectors.push({
                id: sectorsObj[j].id,
                name: sectorsObj[j].name,
              });
            }

            let profilePic = 'https:' + usersObject[i].avatar.url_xsmall;
            if (profilePic != null) {
              if (profilePic == 'https:/missing/avatar.png') {
                profilePic =
                  'https://' + environment.API_HOST + '/missing/avatar.png';
              } else {
                let url = profilePic.toString();
                url = url.replace('staging', 'prod');
                profilePic = url;
              }
            } else {
              profilePic =
                'https://' + environment.API_HOST + '/missing/avatar.png';
            }

            //console.log("User " + profilePic);

            sUsers.push({
              reference: usersObject[i].ref,
              name: usersObject[i].first_name + ' ' + usersObject[i].last_name,
              company: usersObject[i].company,
              avatar: profilePic,
              city: usersObject[i].city,
              country: usersObject[i].country,
              countryCode: usersObject[i].country_code,
              job: usersObject[i].position,
              sectors: [...sSectors],
            });
          }
          this.setState({ users: sUsers }, () => {
            this.setState({ loadingUsers: false });
          });
        });
      } else {
        this.setState({ loadingUsers: false });
      }
    });
  };

  getNews = () => {
    searchNews(this.state.search)
      .then(adaptNews)
      .then((news) => {
        this.setState({ news });
      })
      .catch((error) => console.warn('SearchOverlay.getNews', { error }))
      .finally(() => this.setState({ loadingNews: false }));
  };

  renderProjectItem = ({ item }) => {
    return (
      <View>
        {item.state !== 'In da house' ? (
          <DaflowCompany
            reference={item.reference}
            bearerToken={this.state.bearerToken}
            logoSrc={item.logoSrc}
            country={item.country}
            countryCode={item.countryCode}
            name={item.name}
            money={item.money}
            score={item.score}
            sectors={item.sectors}
            comments={item.comments}
            description={item.description}
            navigation={this.props.navigation}
          />
        ) : (
          <DaHouseCompany
            reference={item.reference}
            logoSrc={item.logoSrc}
            country={item.country}
            countryCode={item.countryCode}
            name={item.name}
            money={item.money}
            activity={item.activity}
            type={item.type}
            description={item.description}
            navigation={this.props.navigation}
          />
        )}
        <Text style={styles.stateText}>State: {item.state}</Text>
        <Divider style={styles.lineStyle} />
      </View>
    );
  };

  renderUserItem = ({ item }) => {
    return (
      <SearchDaphnipolitan
        bearerToken={this.state.bearerToken}
        reference={item.reference}
        profileSrc={item.avatar}
        name={item.name}
        company={item.company}
        job={item.job}
        sectors={item.sectors}
        connected_to={item.connected_to}
        city={item.city}
        country={item.country}
        countryCode={item.countryCode}
        navigation={this.props.navigation}
      />
    );
  };

  renderNewsItem = ({ item }) => {
    return (
      <View>
        <DaphniNews
          picSrc={item.picSrc}
          title={item.title}
          description={item.description}
          date={item.date}
          style={{ marginTop: 5, marginHorizontal: 10 }}
        />
        <Divider style={styles.lineStyle} />
      </View>
    );
  };

  renderEventItem = ({ item }) => {
    return (
      <View>
        <DaphniEvent
          imageSrc={item.imageSrc}
          address={item.address}
          description={item.description}
          summary={item.summary}
          url={item.url}
        />
        <Divider style={styles.lineStyle} />
      </View>
    );
  };

  renderHeaderContent = () => {
    const backgroundColor = this.getBackgroundColor();
    return (
      <View style={styles.searchInputContainer}>
        <TextInput
          autoFocus
          onChange={this.updateSearch}
          onSubmitEditing={this.searchButton}
          style={[styles.searchInput, { color: backgroundColor }]}
          placeholderTextColor={backgroundColor}
          returnKeyType="search"
        />
        <Ionicons
          name="ios-close-circle"
          size={30}
          color={backgroundColor}
          onPress={() => this.props.navigation.goBack()}
        />
      </View>
    );
  };

  getBackgroundColor = () => {
    switch (this.state.index) {
      case 0: //HOME
        return '#7e0d86';
      case 1: //DaFlow
        return '#7479ae';
      case 2: //DaHouse
        return '#00b6ba';
      case 3: //Daphnipolis
        return '#45a9ed';
      default:
        return '#000';
    }
  };

  render() {
    let loadingProjects = this.state.loadingProjects;
    let loadingUsers = this.state.loadingUsers;
    let loadingNews = this.state.loadingNews;
    let searchButtons;
    let searchResults;
    let projectResults;
    let userResults;
    let newsResults;

    const isLoading = loadingProjects || loadingUsers || loadingNews;
    if (isLoading) {
      searchResults = (
        <ActivityIndicator
          size="large"
          color="grey"
          style={{ marginTop: 20 }}
        />
      );
    } else {
      const hasSearchedSomething =
        !!this.state.news || !!this.state.projects || !!this.state.users;
      const foundSomething =
        this.state.news?.length > 0 ||
        this.state.projects?.length > 0 ||
        this.state.users?.length > 0;
      if (!hasSearchedSomething) {
        searchResults = (
          <Text style={[styles.text, { margin: 20 }]}>
            Find companies, users, news or anything in the Daphni universe!
          </Text>
        );
      } else if (!foundSomething) {
        searchResults = (
          <Text style={[styles.text, { margin: 20 }]}>
            Nothing found{'\n'}Try another word
          </Text>
        );
      }
    }

    if (this.state.showSearchOptions) {
      searchButtons = (
        <View>
          <Button
            onPress={this.searchProjectsButton}
            style={styles.searchButton}
            title="Search companies"
            mode="secondary"
          />
          <Button
            onPress={this.searchUsersButton}
            style={styles.searchButton}
            title="Search users"
            mode="secondary"
          />
          <Button
            onPress={this.searchNewsButton}
            style={styles.searchButton}
            title="Search news"
            mode="secondary"
          />
          <Button
            onPress={this.searchAllButton}
            style={styles.searchButton}
            title="Search all"
            mode="primary"
          />
        </View>
      );
    }

    if (!loadingProjects) {
      let projects = this.state.projects;
      if (projects != null && projects.length > 0) {
        projectResults = (
          <View style={{ marginTop: 20 }}>
            <Text style={styles.title}>Companies</Text>
            <FlatList
              numColumns={1}
              data={projects}
              renderItem={this.renderProjectItem}
              keyExtractor={(item) => item.reference}
            />
          </View>
        );
      }
    }

    if (!loadingUsers) {
      let users = this.state.users;
      if (users != null && users.length > 0) {
        userResults = (
          <View style={{ marginTop: 20 }}>
            <Text style={styles.title}>Users</Text>
            <FlatList
              numColumns={1}
              data={users}
              renderItem={this.renderUserItem}
              keyExtractor={(item) => item.reference}
            />
            <Divider style={styles.lineStyle} />
          </View>
        );
      }
    }

    if (!loadingNews) {
      let news = this.state.news;
      if (news != null && news.length > 0) {
        newsResults = (
          <View style={{ marginTop: 20 }}>
            <Text style={styles.title}>News</Text>
            <FlatList
              numColumns={1}
              data={news}
              renderItem={this.renderNewsItem}
              keyExtractor={(item) => item.id}
            />
          </View>
        );
      }
    }

    return (
      <Page
        headerColor={this.getBackgroundColor()}
        renderHeaderContent={this.renderHeaderContent}
      >
        <ScrollView contentContainerStyle={{ paddingVertical: 20 }}>
          {searchResults}
          {searchButtons}
          {projectResults}
          {userResults}
          {newsResults}
        </ScrollView>
      </Page>
    );
  }
}
export default SearchOverlay;

const INPUT_HEIGHT = 38;

const styles = StyleSheet.create({
  searchInputContainer: {
    flexDirection: 'row',
    width: '100%',
    paddingHorizontal: 10,
    margin: 10,
    borderRadius: INPUT_HEIGHT / 3,
    backgroundColor: '#ffffff',
    height: INPUT_HEIGHT,
    alignItems: 'center',
  },
  searchInput: {
    flex: 1,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 15,
    height: '100%',
  },
  stateText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    marginHorizontal: 25,
    marginVertical: 5,
  },
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#666666',
    marginLeft: 10,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
  searchButton: {
    alignSelf: 'center',
    marginVertical: 4,
    paddingHorizontal: 20,
  },
});
