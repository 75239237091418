import numbro from 'numbro';

export const formatMoney = (money, handleEmptyMoney) => {
  if ((money == null || money === '') && handleEmptyMoney) {
    return '--';
  }

  return numbro(money).format({
    average: true,
    mantissa: 1,
    optionalMantissa: true,
    totalLength: 2,
  });
};
