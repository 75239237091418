import formatRelative from 'date-fns/formatRelative';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

export const formatRelativeDate = (isoDate) => {
  const date = new Date(isoDate);
  if (Date.now() - date.getTime() > 3600 * 1000) {
    return formatRelative(date, new Date(), {
      weekStartsOn: 1,
    });
  }

  return formatDistanceToNow(date, { addSuffix: true, includeSeconds: true });
};
