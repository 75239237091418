import { StyleSheet, View } from 'react-native';
import React from 'react';

export const Circle = (props) => {
  const { size, color, filled, style, ...rest } = props;
  return (
    <View
      {...rest}
      style={[
        styles.circle,
        style,
        !filled && styles.holedCircle,
        {
          width: size,
          height: size,
          borderRadius: size / 2,
        },
      ]}
    />
  );
};

const styles = StyleSheet.create({
  circle: {
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: 'white',
    backgroundColor: 'white',
  },
  holedCircle: {
    borderWidth: 3,
    backgroundColor: 'transparent',
  },
});
