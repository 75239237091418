export const fetchMe = ({ onSuccess, onFailure }) => ({
  type: 'FETCH_ME',
  payload: { onSuccess, onFailure },
});

export const setMe = (me) => ({
  type: 'SET_ME',
  payload: { me },
});

export const setSeenOnboarding = () => ({
  type: 'SET_SEEN_ONBOARDING',
});

export const setAuthToken = (authToken) => ({
  type: 'SET_AUTH_TOKEN',
  payload: { authToken },
});

export const logout = () => ({
  type: 'LOGOUT',
});
