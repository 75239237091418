import { BatchPush, BatchUser, BatchInbox } from '@bam.tech/react-native-batch';
import { getPotentiallyMissingAvatarUrl } from '../api/base';

export const configureBatchUser = (customUserId) => {
  BatchPush.registerForRemoteNotifications();

  if (customUserId) {
    BatchUser.editor().setIdentifier(String(customUserId)).save();
  }
};

const filterBatchNotification = (notification) => {
  const { payload } = notification;
  if (!payload) {
    return true;
  }

  const type = payload.type || (payload['com.batch'] && payload['com.batch'].i);
  const blacklistedTypes = ['discussion', 'weekly'];
  return !blacklistedTypes.some((blacklistedType) =>
    type.includes(blacklistedType)
  );
};

export const getBatchNotifications = async (me) => {
  const { reference: customUserId, batchInboxKey } = me;
  const fetcher = await BatchInbox.getFetcher({
    user: {
      identifier: customUserId,
      authenticationKey: batchInboxKey,
    },
  });
  const { notifications } = await fetcher.fetchNewNotifications();
  await fetcher.destroy();
  return {
    areFromBatch: true,
    data: notifications.filter(filterBatchNotification),
  };
};

const getBatchNotificationImage = (notification) => {
  const { payload } = notification;
  if (!payload) {
    return null;
  }

  const { user_avatar_url, project_logo_url } = payload;
  const url = user_avatar_url || project_logo_url;
  if (!url) {
    return null;
  }

  return getPotentiallyMissingAvatarUrl(url);
};

const adaptBatchNotification = (batchNotification) => ({
  id: batchNotification.identifier,
  title: batchNotification.title,
  text: batchNotification.body,
  date: new Date(batchNotification.date).toISOString(),
  isRead: !batchNotification.isUnread,
  image: getBatchNotificationImage(batchNotification),
  url:
    batchNotification.payload && batchNotification.payload['com.batch']
      ? batchNotification.payload['com.batch'].l
      : null,
});

export const adaptBatchNotifications = ({ data: batchNotifications }) =>
  batchNotifications.map(adaptBatchNotification);
