const adaptNewsPiece = (apiNewsPiece) => ({
  id: apiNewsPiece._id,
  title: apiNewsPiece.heading,
  description: apiNewsPiece.body,
  date: apiNewsPiece.date,
  source: apiNewsPiece.source,
  url: apiNewsPiece.externalLink,
  picSrc: 'https://CMS.daphni.com' + apiNewsPiece.imageWeb,
});

export const adaptNews = ({ data: apiNews }) => apiNews.map(adaptNewsPiece);

const adaptDaCommunityNewsPiece = (apiNewsPiece) => ({
  id: apiNewsPiece._id,
  title: apiNewsPiece.heading,
  content: apiNewsPiece.text,
});

export const adaptDaCommunityNews = ({ data: apiNews }) =>
  apiNews.map(adaptDaCommunityNewsPiece);
