export const setDiscussions = (discussions, lastPage) => ({
  type: 'SET_DISCUSSIONS',
  payload: { discussions, lastPage },
});

export const resetDiscussions = () => ({ type: 'RESET_DISCUSSIONS' });

export const refreshDiscussions = (showRefreshLoader = true) => ({
  type: 'REFRESH_DISCUSSIONS',
  payload: { showRefreshLoader },
});

export const fetchDiscussions = (fromUserInteraction = false) => ({
  type: 'FETCH_DISCUSSIONS',
  payload: { fromUserInteraction },
});

export const fetchDiscussion = ({
  discussionReference,
  onDiscussionFetched,
}) => ({
  type: 'FETCH_DISCUSSION',
  payload: { discussionReference, onDiscussionFetched },
});

export const setDiscussion = (discussion) => ({
  type: 'SET_DISCUSSION',
  payload: { discussion },
});

export const createGroupDiscussion = (onSuccess) => ({
  type: 'CREATE_GROUP_DISCUSSION',
  payload: { onSuccess },
});

export const addUserToGroupDiscussion = (discussionReference, otherUser) => ({
  type: 'ADD_USER_TO_GROUP_DISCUSSION',
  payload: { discussionReference, otherUser },
});

export const removeUserOfGroupDiscussion = (
  discussionReference,
  otherUserReference
) => ({
  type: 'REMOVE_USER_OF_GROUP_DISCUSSION',
  payload: { discussionReference, otherUserReference },
});

export const deleteUserFromStoredDiscussion = (
  discussionReference,
  otherUserReference
) => ({
  type: 'DELETE_USER_FROM_STORED_DISCUSSION',
  payload: { discussionReference, otherUserReference },
});

export const setGroupDiscussionUsers = (discussionReference, otherUsers) => ({
  type: 'SET_GROUP_DISCUSSION_USERS',
  payload: { discussionReference, otherUsers },
});

export const deleteDiscussion = (discussionReference, onSuccess) => ({
  type: 'DELETE_DISCUSSION',
  payload: { discussionReference, onSuccess },
});

export const leaveDiscussion = (discussionReference, onSuccess) => ({
  type: 'LEAVE_DISCUSSION',
  payload: { discussionReference, onSuccess },
});

export const markDiscussionAsRead = (discussionReference) => ({
  type: 'MARK_DISCUSSION_AS_READ',
  payload: { discussionReference },
});

export const setDiscussionAsRead = (discussion) => ({
  type: 'SET_DISCUSSION_AS_READ',
  payload: { discussion },
});

export const setLastDiscussionMessage = (message, discussionReference) => ({
  type: 'SET_LAST_DISCUSSION_MESSAGE',
  payload: { message, discussionReference },
});

export const changeDiscussionTitle = (title, discussionReference) => ({
  type: 'CHANGE_DISCUSSION_TITLE',
  payload: { title, discussionReference },
});

export const setDiscussionTitle = (title, discussionReference) => ({
  type: 'SET_DISCUSSION_TITLE',
  payload: { title, discussionReference },
});
