import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  ScrollView,
  Image,
} from 'react-native';

import { Overlay } from 'react-native-elements';

import ProjectConversation from '../components/Comments/ProjectConversation';
import NewComment from '../components/Comments/NewComment';
import { Rating } from '../src/components/Rating';
import { adaptProjectComments } from '../src/api/Project/adapter';
import { getProjectComments } from '../src/api/Project';
import { keyboardHeightListener } from '../components/keyboardHeightListener';
import { environment } from '../src/environment';

const WIDTH = Dimensions.get('window').width;

class ProjectCommentsOverlay extends React.Component {
  state = {
    loading: true,
    conversation: [],
    showShareMessageOverlay: false,
    shareMessage: '',
  };

  async componentDidMount() {
    if (this.props.reference != null) {
      this.getProjectComments();
    } else {
      this.setState({ loading: false });
    }
  }

  showShareMessageOverlay = (show) => {
    this.setState({ showShareMessageOverlay: show });
  };

  updateComments = () => {
    this.setState({ loading: true });
    this.getProjectComments();
  };

  successfullMessageSent = () => {
    this.setState({ shareMessage: 'Your message was sent' }, () => {
      this.showShareMessageOverlay(true);
      this.getProjectComments();
      this.props.updateComments();
    });
  };

  getProjectComments = () => {
    getProjectComments(this.props.reference)
      .then(adaptProjectComments)
      .then(({ comments }) => {
        this.setState({
          loading: false,
          conversation: comments,
        });
      })
      .catch((error) => {
        console.warn('Error retrieving comments', { error });
        this.setState({ loading: false });
      });
  };

  render() {
    let loading = this.state.loading;
    let conversation;
    if (!loading) {
      if (this.state.conversation.length > 0) {
        conversation = (
          <ProjectConversation messageList={this.state.conversation} />
        );
      } else {
        conversation = (
          <Text style={styles.wTitle}>Be the first to comment</Text>
        );
      }
    } else {
      conversation = <ActivityIndicator color="grey" />;
    }

    let money = this.props.money;
    if (money == null) {
      money = '0';
    }

    let logoSrc = this.props.logoSrc;
    if (logoSrc == null) {
      logoSrc = 'https://' + environment.API_HOST + '/missing/project.png';
    }

    let sentMessageOverlay = (
      <Overlay
        isVisible={this.state.showShareMessageOverlay}
        overlayStyle={{ width: WIDTH - 20, height: 60, borderRadius: 10 }}
        onBackdropPress={() =>
          this.setState({ showShareMessageOverlay: false })
        }
      >
        <View style={{ alignItems: 'center' }}>
          <Text style={[styles.text, { textAlign: 'center' }]}>
            {this.state.shareMessage}
          </Text>
        </View>
      </Overlay>
    );

    return (
      <View style={styles.mainContainer}>
        {sentMessageOverlay}
        <View style={styles.topContainer}>
          <View style={{ width: '50%' }}>
            <Image
              source={{ uri: logoSrc }}
              resizeMode="contain"
              style={styles.logo}
            />
            <Text style={styles.title}>{this.props.name}</Text>
          </View>
          <View style={{ width: '25%' }}>
            <Rating style={{ alignItems: 'center' }} value={this.props.score} />
          </View>
          <View style={{ width: '25%' }}>
            <Text style={[styles.text, { marginBottom: 4 }]}>€{money}</Text>
            <Text style={styles.miniText}>Requested</Text>
          </View>
        </View>

        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={{
            flexGrow: 1,
            marginBottom: 20,
            bottom: this.props.keyboardHeight,
          }}
        >
          <View
            style={[
              styles.commentsContainer,
              {
                flexGrow: 1,
                justifyContent: 'space-between',
              },
            ]}
          >
            <View style={{ flex: 1, marginBottom: 10 }}>
              <Text style={styles.wTitle}>Comments</Text>
              {conversation}
            </View>
            <NewComment
              successfullMessageSent={this.successfullMessageSent}
              updateComments={this.updateComments}
              reference={this.props.reference}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}

export default keyboardHeightListener({ ios: true })(ProjectCommentsOverlay);

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  topContainer: {
    padding: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  scrollView: {
    backgroundColor: '#7479ae',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  commentsContainer: {
    paddingVertical: 10,
  },
  logo: {
    marginTop: 5,
    borderRadius: 25,
    width: 100,
    height: 40,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    color: '#666666',
    fontSize: 16,
    marginTop: 5,
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    textAlign: 'center',
  },
  miniText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 13,
    textAlign: 'center',
  },
});
