import React from 'react';
import {
  View,
  StyleSheet,
  TouchableOpacity,
  FlatList,
  Text,
  Image,
  Platform,
} from 'react-native';
import { withWindowDimensions } from '../src/hoc';
import { tabletOrLargerMinPixel } from '../src/lib/responsive';
import { Watermark } from '../src/pages/PDFDeck/components/Watermark';

const DECK_HEIGHT = 200;

class Deck extends React.Component {
  renderImages = ({ item, index }) => {
    let imageURL = item.smallURL;
    const width = Math.min(this.props.dimensions.width, tabletOrLargerMinPixel);

    return (
      <View>
        <TouchableOpacity
          onPress={() =>
            this.props.navigation.navigate('PDFDeck', {
              deckUrl: this.props.deckUrl,
            })
          }
        >
          <Watermark
            style={styles.watermark}
            textStyle={styles.watermarkText}
          />
          <Image
            source={{ uri: imageURL }}
            style={{ height: (DECK_HEIGHT * 16) / 9, width: width - 20 }}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <Text style={styles.text}>
          Page {index + 1} / {this.props.deckPages.length}{' '}
        </Text>
      </View>
    );
  };

  render() {
    return (
      <FlatList
        pagingEnabled={Platform.OS !== 'web'}
        showsHorizontalScrollIndicator={Platform.OS === 'web'}
        horizontal={true}
        data={this.props.deckPages}
        style={[styles.container, { width: this.props.dimensions.width - 20 }]}
        renderItem={this.renderImages}
        keyExtractor={(item) => item.id}
      />
    );
  }
}

export default withWindowDimensions(Deck);

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 5,
  },
  text: {
    marginTop: 10,
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    textAlign: 'center',
  },
  watermark: {
    position: 'absolute',
    bottom: '25%',
    right: 0,
    left: 0,
  },
  watermarkText: {
    fontSize: 10,
  },
});
