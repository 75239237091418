import {
  ActivityIndicator,
  Image,
  StyleSheet,
  Text,
  TextInput,
  View,
} from 'react-native';
import React, { useCallback, useState } from 'react';
import { Overlay } from 'react-native-elements';
import { handleError, theme } from '../../../../../lib';
import { tabletOrLargerMinPixel } from '../../../../../lib/responsive';
import { DaFlowButton } from './DaFlowButton';
import { submitReferral } from '../../../../../api/Referrals';
import SubmitDealImage from './submitDeal.png';
import { keyboardHeightListener } from '../../../../../../components/keyboardHeightListener';

const SubmitDealButtonModalComponent = ({ keyboardHeight }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);

  const [entrepreneurEmail, setEntrepreneurEmail] = useState('');
  const [companyName, setCompanyName] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const submit = useCallback(async () => {
    if (!entrepreneurEmail || !companyName) {
      return;
    }
    setIsLoading(true);
    try {
      await submitReferral(entrepreneurEmail, companyName);
      setIsSubmitted(true);
      setTimeout(() => {
        setIsModalOpened(false);
        setTimeout(() => {
          setIsSubmitted(false);
          setEntrepreneurEmail('');
          setCompanyName('');
        }, 500);
      }, 1500);
    } catch (error) {
      console.warn('Error: submitReferral', { error });
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }, [entrepreneurEmail, companyName]);

  const cancel = useCallback(() => {
    setEntrepreneurEmail('');
    setCompanyName('');
    setIsModalOpened(false);
  }, []);

  return (
    <>
      <Overlay
        isVisible={isModalOpened}
        transparent
        animated
        animationType="fade"
        overlayStyle={[
          styles.overlay,
          keyboardHeight && { marginBottom: keyboardHeight },
        ]}
        onBackdropPress={cancel}
      >
        <View>
          <Text style={styles.title}>Submit Deal</Text>
          <Image source={SubmitDealImage} style={styles.image} />
          <View style={styles.bottomContainer}>
            {isSubmitted ? (
              <View style={styles.sloganContainer}>
                <Text style={styles.slogan}>Successful submit!</Text>
              </View>
            ) : (
              <>
                <View style={styles.sloganContainer}>
                  <Text style={styles.slogan}>VIP deals are all about,</Text>
                  <Text style={styles.slogan}>
                    We will take an extra special care of them!
                  </Text>
                </View>
                <Text style={styles.textInputLabel}>Entrepreneur email</Text>
                <TextInput
                  style={styles.textInput}
                  placeholder="mail@sample.com"
                  onChangeText={setEntrepreneurEmail}
                  value={entrepreneurEmail}
                />
                <Text style={styles.textInputLabel}>Company name</Text>
                <TextInput
                  style={styles.textInput}
                  placeholder="Sample name"
                  onChangeText={setCompanyName}
                  value={companyName}
                />
                <View style={styles.modalConfirmButtons}>
                  {isLoading ? (
                    <ActivityIndicator color="white" size="small" />
                  ) : (
                    <>
                      <DaFlowButton label="Cancel" onPress={cancel} />
                      <View style={styles.confirmButtonSeparator} />
                      <DaFlowButton
                        isActive
                        label="Submit"
                        onPress={submit}
                        style={styles.submitButton}
                        textStyle={styles.submitButtonText}
                      />
                    </>
                  )}
                </View>
              </>
            )}
          </View>
        </View>
      </Overlay>
      <DaFlowButton
        label="Submit deal"
        onPress={() => setIsModalOpened(true)}
      />
    </>
  );
};

export const SubmitDealButtonModal = keyboardHeightListener({ ios: true })(
  SubmitDealButtonModalComponent
);

const styles = StyleSheet.create({
  title: {
    marginTop: 20,
    marginBottom: 30,
    color: '#858585',
    textAlign: 'center',
    ...theme.fonts.bold,
    fontSize: 17,
  },
  image: {
    alignSelf: 'center',
    width: 222,
    height: 100,
    aspectRatio: 398 / 179,
    resizeMode: 'contain',
  },
  bottomContainer: {
    backgroundColor: '#2BA596',
    paddingHorizontal: 20,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  sloganContainer: {
    marginVertical: 40,
  },
  slogan: {
    textAlign: 'center',
    color: 'white',
    ...theme.fonts.bold,
    fontSize: 17,
  },
  textInputLabel: {
    ...theme.fonts.normal,
    color: 'white',
    marginBottom: 4,
  },
  textInput: {
    marginBottom: 20,
    backgroundColor: 'white',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 10,
  },
  overlay: {
    maxWidth: tabletOrLargerMinPixel,
    alignSelf: 'stretch',
    margin: 'auto',
    paddingVertical: 0,
    paddingHorizontal: 0,
    borderRadius: 20,
    backgroundColor: 'white',
    ...theme.shadow,
  },
  modalConfirmButtons: {
    marginBottom: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  confirmButtonSeparator: {
    width: 10,
  },
  submitButton: {
    backgroundColor: 'white',
  },
  submitButtonText: {
    color: '#2BA596',
  },
});
