import { myReferenceSelector } from '../User';

export const discussionStateSelector = (state) => state.discussion;

const lastPageSelector = (state) => discussionStateSelector(state).lastPage;

export const currentPageSelector = (state) =>
  discussionStateSelector(state).currentPage;

export const isLastPageSelector = (state) =>
  lastPageSelector(state) === currentPageSelector(state);

const getAllOtherUsersOfDiscussion = (discussion) =>
  discussion.otherUsers || [discussion.otherUser];

export const discussionsSelector = (state) => {
  const discussionState = discussionStateSelector(state);
  return discussionState.list
    .map((reference) => discussionState.byReference[reference])
    .filter(Boolean);
};

export const discussionByReferenceSelector = (reference) => (state) => {
  const discussionState = discussionStateSelector(state);
  return discussionState.byReference[reference];
};

export const discussionUsersSelector = (reference) => (state) => {
  const discussion = discussionByReferenceSelector(reference)(state);
  if (!discussion) {
    return [];
  }

  return getAllOtherUsersOfDiscussion(discussion);
};

export const isUserAdminOfDiscussion = (reference) => (state) => {
  const discussion = discussionByReferenceSelector(reference)(state);
  if (!discussion || !discussion.adminUser) {
    return false;
  }

  const myReference = myReferenceSelector(state);
  return discussion.adminUser.reference === myReference;
};
