import React, { PureComponent } from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import SafeAreaView from 'react-native-safe-area-view';
import { theme } from '../../../../lib';
import { Button } from '../../../../components';
import { Circle } from '../Circle';

export class Pagination extends PureComponent {
  renderContent = () => {
    const {
      index: activeIndex,
      total,
      onEndButtonPress,
      onChangeSlide,
    } = this.props;
    if (activeIndex === total - 1) {
      return (
        <Button
          style={styles.endButton}
          textStyle={styles.endButtonText}
          title="Let's go!"
          mode="inversed-primary"
          onPress={onEndButtonPress}
        />
      );
    }

    return new Array(total).fill(0).map((_, index) => (
      <TouchableOpacity key={index} onPress={() => onChangeSlide(index)}>
        <Circle
          size={20}
          filled={index !== activeIndex}
          style={index !== total - 1 && styles.spaceBetweenCircles}
        />
      </TouchableOpacity>
    ));
  };

  render() {
    return (
      <SafeAreaView style={styles.container}>
        {this.renderContent()}
      </SafeAreaView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: theme.margin.unit,
  },
  spaceBetweenCircles: {
    marginRight: theme.margin.unit,
  },
  endButton: {
    paddingHorizontal: 2 * theme.margin.unit,
    height: 40,
    borderRadius: 20,
  },
  endButtonText: {
    ...theme.fonts.normal,
    ...theme.fonts.bold,
    color: theme.colors.secondary,
    fontSize: 25,
  },
});
