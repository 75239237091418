import React from 'react';
import { View, StyleSheet, Switch } from 'react-native';
import { ActiveSectorsButtonModal } from './SubMenu/components/ActiveSectorsButtonModal';
import { theme } from '../../../lib';
import { RenderTypeButton } from '../../../components/RenderTypeButton';

export const Settings = ({
  style,
  activeSectorsIds,
  setActiveSectorsIds,
  projectCountPerColumns,
  setProjectCountPerColumns,
  isFilteringEnabled,
  setIsFilteringEnabled,
  AdditionalComponents,
}) => {
  return (
    <View style={[styles.container, style]}>
      <RenderTypeButton
        projectCountPerColumns={projectCountPerColumns}
        setProjectCountPerColumns={setProjectCountPerColumns}
      />
      <View style={styles.separator} />
      <ActiveSectorsButtonModal
        activeSectorsIds={activeSectorsIds}
        setActiveSectorsIds={setActiveSectorsIds}
      />
      <View style={{ marginHorizontal: 8 }} />
      <Switch
        ios_backgroundColor="#ccc"
        onValueChange={setIsFilteringEnabled}
        value={isFilteringEnabled}
      />
      {!!AdditionalComponents &&
        AdditionalComponents.filter(Boolean).map((Component) => (
          <>
            <View style={styles.separator} />
            {Component}
          </>
        ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    width: 1,
    marginHorizontal: 20,
    alignSelf: 'stretch',
    backgroundColor: theme.colors.text,
  },
});
