import { useWindowDimensions } from 'react-native';

export const tabletOrLargerMinPixel = 768;
export const desktopOrLargerMinPixel = 1024;

export const useResponsive = () => {
  const dimensions = useWindowDimensions();

  return {
    isDesktopOrLarger: dimensions.width >= desktopOrLargerMinPixel,
    isTabletOrLarger: dimensions.width >= tabletOrLargerMinPixel,
  };
};
