import React from 'react';
import {
  Dimensions,
  Platform,
  StyleSheet,
  View,
  FlatList,
  ActivityIndicator,
  Text,
  TextInput,
  TouchableOpacity,
  KeyboardAvoidingView,
  InteractionManager,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import ShareDealDaphnipolitan from '../../components/Daphnipolitan/ShareDealDaphnipolitan';
import { ShareForm } from './components';
import { environment } from '../../src/environment';

const deviceWidth = Dimensions.get('window').width;
const deviceHeight = Dimensions.get('window').height * 0.9;

export class ShareDealOverlay extends React.Component {
  state = {
    alreadySearched: false,
    currentPage: 0,
    lastPage: null,
    search: '',
    fetchingFromServer: false,
    selectedUser: null,
    users: [],
  };

  componentDidMount() {
    InteractionManager.runAfterInteractions(() => this.getUsers());
  }

  updateSearch = (event) => {
    this.setState({ search: event.nativeEvent.text, showSearchOptions: true });
  };

  searchButton = (event) => {
    const keyword = event.nativeEvent.text;
    this.setState(
      {
        search: keyword,
        users: [],
        currentPage: 0,
        lastPage: null,
        fetchingFromServer: true,
      },
      () => {
        this.getUsers();
      }
    );
  };

  fetchMore = () => {
    if (
      !this.state.fetchingFromServer &&
      this.state.currentPage !== this.state.lastPage
    ) {
      this.setState(
        {
          fetchingFromServer: true,
        },
        () => {
          this.getUsers();
        }
      );
    }
  };

  getUsers = () => {
    const { bearerToken, reference } = this.props;
    const { search: keyword, currentPage, lastPage } = this.state;

    if (currentPage !== lastPage) {
      const nextPage = currentPage + 1;
      fetch(
        'https://' +
          environment.API_HOST +
          '/v4/projects/' +
          reference +
          '/optins?page=' +
          nextPage +
          '&keyword=' +
          keyword,
        {
          // mode: 'no-cors',
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + bearerToken,
          },
        }
      ).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            let usersObject = json.data;
            var count = Object.keys(usersObject).length;
            let sUsers = [];

            for (let i = 0; i < count; i++) {
              // let sectorsObj = usersObject[i]["sectors"];
              //var sCount = Object.keys(sectorsObj).length;
              let sSectors = [];

              /*  for (let j = 0; j < sCount; j++) {
                                  sSectors.push({ id: sectorsObj[j]["id"], name: sectorsObj[j]["name"] });
                              }*/

              let profilePic = 'https:' + usersObject[i].avatar.url_xsmall;
              if (profilePic != null) {
                if (profilePic == 'https:/missing/avatar.png') {
                  profilePic =
                    'https://' + environment.API_HOST + '/missing/avatar.png';
                } else {
                  let url = profilePic.toString();
                  url = url.replace('staging', 'prod');
                  profilePic = url;
                }
              } else {
                profilePic =
                  'https://' + environment.API_HOST + '/missing/avatar.png';
              }

              sUsers.push({
                reference: usersObject[i].ref,
                name:
                  usersObject[i].first_name + ' ' + usersObject[i].last_name,
                //   company: usersObject[i]["company"],
                company: 'undefined',
                avatar: profilePic,
                //  city: usersObject[i]["city"],
                city: 'undefined',
                //country: usersObject[i]["country"],
                country: 'undefined',
                //countryCode: usersObject[i]["country_code"],
                countryCode: 'FR',
                job: usersObject[i].position,
                sectors: [...sSectors],
              });
            }

            this.setState({
              users: [...this.state.users, ...sUsers],
              currentPage: nextPage,
              lastPage: json.meta ? json.meta.total_pages : null,
              alreadySearched: true,
              fetchingFromServer: false,
            });
          });
        } else {
          this.setState({ fetchingFromServer: false });
        }
      });
    } else {
      this.setState({ fetchingFromServer: false });
    }
  };

  textShareProject = (ref, avatar, name) =>
    this.setState({ selectedUser: { ref, avatar, name } });

  renderShareForm = () => (
    <ShareForm
      receiver={this.state.selectedUser}
      successfullShare={this.props.successfullShare}
      bearerToken={this.props.bearerToken}
      reference={this.props.reference}
    />
  );

  renderUserItem = ({ item }) => {
    return (
      <View
        style={{
          width: deviceWidth,
          flexDirection: 'row',
          alignItems: 'center',
          marginVertical: 10,
          marginHorizontal: 10,
        }}
      >
        <ShareDealDaphnipolitan
          loadAllData={true}
          reference={item.reference}
          profileSrc={item.avatar}
          name={item.name}
          job={item.job}
          successfullChat={this.props.successfullChat}
          navigation={this.props.navigation}
        />
        <TouchableOpacity
          style={styles.sendButton}
          onPress={() =>
            this.textShareProject(item.reference, item.avatar, item.name)
          }
        >
          <Text style={styles.gText}>Send</Text>
        </TouchableOpacity>
      </View>
    );
  };

  renderSearchLoader = () => (
    <View style={{ minHeight: 40 }}>
      {this.state.fetchingFromServer ? (
        <ActivityIndicator color="white" style={{ marginTop: 10 }} />
      ) : null}
    </View>
  );

  renderEmptySearch = () =>
    !this.state.fetchingFromServer && this.state.alreadySearched ? (
      <Text style={[styles.wText, { margin: 20 }]}>
        Nothing found{'\n'}Try another word
      </Text>
    ) : null;

  searchKeyExtractor = (item) => item.reference;

  renderSearchedUsers = () => (
    <FlatList
      contentContainerStyle={{ paddingBottom: 40 }}
      keyExtractor={this.searchKeyExtractor}
      data={this.state.users}
      renderItem={this.renderUserItem}
      onEndReached={this.fetchMore}
      onEndReachedThreshold={0.2}
      ListFooterComponent={this.renderSearchLoader}
      ListEmptyComponent={this.renderEmptySearch}
      extraData={{
        empty: this.state.alreadySearched,
        loading: this.state.fetchingFromServer,
      }}
    />
  );

  renderSearch = () => (
    <View>
      <View style={styles.topPanel}>
        <View style={styles.searchContainer}>
          <Ionicons
            name="ios-search"
            size={20}
            style={{ marginVertical: 5, marginHorizontal: 10 }}
            color="#666"
          />
          <TextInput
            autoFocus={true}
            width="90%"
            onChange={this.updateSearch}
            onSubmitEditing={this.searchButton}
            style={styles.searchText}
            placeholderTextColor="#666"
            returnKeyType="search"
          />
        </View>
      </View>
      <View style={styles.usersContainer}>{this.renderSearchedUsers()}</View>
    </View>
  );

  renderContent = () => {
    return (
      <View style={styles.container}>
        {!this.state.selectedUser
          ? this.renderSearch()
          : this.renderShareForm()}
      </View>
    );
  };

  render() {
    if (Platform.OS === 'ios') {
      return (
        <KeyboardAvoidingView
          behavior="position"
          style={styles.container}
          enabled={true}
        >
          {this.renderContent()}
        </KeyboardAvoidingView>
      );
    }

    return this.renderContent();
  }
}

const INPUT_SIZE = 30;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    zIndex: 2,
    left: 0,
    top: 0,
    width: deviceWidth,
    height: deviceHeight,
    backgroundColor: '#00b6ba',
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  topPanel: {
    width: deviceWidth,
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
  },
  searchContainer: {
    flexDirection: 'row',
    width: deviceWidth - 40,
    height: INPUT_SIZE,
    margin: 20,
    borderRadius: 10,
    backgroundColor: '#fff',
  },
  usersContainer: {
    width: deviceWidth,
    height: deviceHeight * 0.5,
    paddingTop: 5,
    paddingBottom: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  sendButton: {
    position: 'absolute',
    zIndex: 2,
    right: 40,
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    width: 60,
    height: 20,
    borderRadius: 10,
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#fff',
    textAlign: 'center',
  },
  gText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#00b6ba',
    textAlign: 'center',
  },
  searchText: {
    flex: 1,
    height: INPUT_SIZE,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 15,
    color: '#666',
  },
});
