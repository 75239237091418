const adaptBanner = (apiBanner) => ({
  id: apiBanner._id,
  heading: apiBanner.heading,
  imgSrc: apiBanner.imageMobile
    ? `https://CMS.daphni.com${apiBanner.imageMobile}`
    : null,
  url: apiBanner.linkTo,
});

export const adaptBanners = ({ data: apiBanners }) =>
  apiBanners.map(adaptBanner);
