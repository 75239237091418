import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Platform,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../../../../lib/StatusBarHeight';

import Flag from 'react-native-round-flags';
import { Overlay } from 'react-native-elements';

import ProjectCommentsOverlay from '../../../../../overlays/ProjectCommentsOverlay';
import { formatMoney } from '../../../../lib';
import { Rating } from '../../../../components/Rating';
import { NavigationTouchable } from '../../../../components';
import { withNavigation } from '../../../../navigation/withNavigation';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);

class HomeCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProjectCommentsOverlay: false,
    };
    this.showProjectCommentsOverlay = this.showProjectCommentsOverlay.bind(
      this
    );
  }

  showProjectCommentsOverlay(show) {
    this.setState({ showProjectCommentsOverlay: show });
  }

  updateComments = () => {
    console.log('update comments');
  };

  render() {
    let projectCommentsOverlay;

    let sectorsText;
    let sectors = [...this.props.sectors];

    let money = this.props.money;
    if (money == null) {
      money = '0';
    } else {
      money = formatMoney(money);
    }

    if (sectors != null) {
      if (sectors.length > 3) {
        let temp = [sectors[0], sectors[1], sectors[2]];
        sectors = temp;
      }

      sectorsText = sectors.map((tag, id) => (
        <Text key={id} style={styles.text}>
          {tag.tag}
        </Text>
      ));
    } else {
      console.log('No sectors for this company');
      sectorsText = <Text style={styles.text}>General</Text>;
    }

    let logoSrc = this.props.logoSrc;
    if (logoSrc == null) {
      logoSrc = 'https://daphni.com/favicon-128x128.png';
    }

    let messagePic = {
      source: require('../../../../../assets/icons/messagesIcon.png'),
    };

    let comments = this.props.comments;
    if (comments == null) {
      comments = 0;
    }

    projectCommentsOverlay = (
      <Overlay
        isVisible={this.state.showProjectCommentsOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          { width: WIDTH, height: HEIGHT * 0.9 },
        ]}
        onBackdropPress={() =>
          this.setState({ showProjectCommentsOverlay: false })
        }
      >
        <ProjectCommentsOverlay
          reference={this.props.reference}
          logoSrc={logoSrc}
          score={this.props.score || 0}
          name={this.props.name}
          updateComments={this.updateComments}
          money={money}
        />
      </Overlay>
    );

    return (
      <NavigationTouchable to={`/project/${this.props.reference}`}>
        <View>
          {projectCommentsOverlay}
          <View style={styles.container}>
            <View style={styles.leftContainer}>
              <Image
                source={{ uri: logoSrc }}
                resizeMode="contain"
                style={{ height: 80 }}
              />
              <View style={styles.nameFlag}>
                <Text style={styles.title}>
                  {this.props.name}{' '}
                  <Flag code={this.props.countryCode} style={styles.flag} />
                </Text>
              </View>
              {sectorsText}
            </View>
            <View style={styles.rightContainer}>
              <Text style={styles.text}>€{money}</Text>
              <Text style={[styles.text, { fontSize: 13 }]}>Requested</Text>
              <Rating
                value={this.props.score}
                style={{ alignItems: 'center' }}
              />
              <TouchableOpacity
                onPress={() => this.showProjectCommentsOverlay(true)}
              >
                <View style={styles.pairedData}>
                  <Image
                    source={messagePic.source}
                    resizeMode="contain"
                    style={[styles.icon, { width: 20, height: 20 }]}
                  />
                  <Text style={styles.text}>{comments}</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </NavigationTouchable>
    );
  }
}

export default withNavigation(HomeCompany);

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  leftContainer: {
    width: '70%',
    marginLeft: 10,
    marginTop: 10,
  },
  rightContainer: {
    flex: 1,
    marginTop: 10,
    alignItems: 'center',
  },
  pairedData: {
    flexDirection: 'row',
    marginVertical: 5,
  },
  bottomOverlay: {
    position: 'absolute',
    bottom: 0,
    padding: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  nameFlag: {
    flexDirection: 'row',
  },
  flag: {
    width: 16,
    height: 16,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 10,
    color: '#666666',
    justifyContent: 'center',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
  icon: {
    tintColor: '#666666',
  },
});
