const baseColors = {
  primary: '#7e0d86',
  primaryAlt: '#7479ae',
  secondary: '#45a9ed',
  secondaryAlt: '#00B6BA',
  orange: '#FF9233',
  text: '#666',
};

export const theme = {
  shadow: {
    shadowColor: '#000000',
    shadowOpacity: 0.3,
    shadowRadius: 8,
  },
  margin: {
    unit: 10,
  },
  colors: {
    ...baseColors,
    danews: baseColors.primary,
    daflow: baseColors.primaryAlt,
    daphnipolis: baseColors.secondary,
    datimeline: baseColors.secondary,
    dahouse: baseColors.secondaryAlt,
    funds: {
      Default: {
        primary: 'black',
        textOnPrimary: 'white',
      },
      Purple: {
        primary: '#7479ae',
        textOnPrimary: '#fff',
      },
      Yellow: {
        primary: '#ffcf56',
        textOnPrimary: '#666',
      },
      Jaïna: {
        primary: '#57B3BA',
        textOnPrimary: '#fff',
      },
    },
  },
  fonts: {
    normal: {
      fontFamily: 'Freight-Sans-Medium',
      fontSize: 16,
      color: baseColors.text,
    },
    bold: {
      fontFamily: 'Freight-Sans-Black',
    },
  },
};
