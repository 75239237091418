import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Platform,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../src/lib/StatusBarHeight';

import Flag from 'react-native-round-flags';
import { Overlay } from 'react-native-elements';

import ProjectCommentsOverlay from '../overlays/ProjectCommentsOverlay';
import { formatMoney } from '../src/lib';
import { Rating } from '../src/components/Rating';
import { NavigationTouchable } from '../src/components';
import { environment } from '../src/environment';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProjectCommentsOverlay: false,
    };
    this.showProjectCommentsOverlay = this.showProjectCommentsOverlay.bind(
      this
    );
  }

  showProjectCommentsOverlay(show) {
    this.setState({ showProjectCommentsOverlay: show });
  }

  updateComments = () => {
    console.log('update comments');
  };

  render() {
    let money = this.props.money;
    if (money == null) {
      money = '0';
    } else {
      money = formatMoney(money);
    }

    let sectorsText;
    let sectors = this.props.sectors;

    if (sectors != null) {
      if (sectors.length > 3) {
        let temp = [sectors[0], sectors[1], sectors[2]];
        sectors = temp;
      }

      sectorsText = sectors.map((tag, id) => (
        <Text key={id} style={styles.text}>
          {tag.tag}
        </Text>
      ));
    } else {
      console.log('No sectors for this company');
      sectorsText = <Text style={styles.text}>General</Text>;
    }

    let logoSrc = this.props.logoSrc;
    if (logoSrc == null) {
      logoSrc = 'https://' + environment.API_HOST + '/missing/project.png';
    }

    let messagePic = {
      source: require('../assets/icons/messagesIcon.png'),
    };

    let comments = this.props.comments;
    if (comments == null) {
      comments = 0;
    }

    let projectCommentsOverlay = (
      <Overlay
        isVisible={this.state.showProjectCommentsOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          { width: WIDTH, height: HEIGHT * 0.9 },
        ]}
        onBackdropPress={() =>
          this.setState({ showProjectCommentsOverlay: false })
        }
      >
        <ProjectCommentsOverlay
          reference={this.props.reference}
          bearerToken={this.props.bearerToken}
          logoSrc={logoSrc}
          score={this.props.score || 0}
          name={this.props.name}
          updateComments={this.updateComments}
          money={money}
        />
      </Overlay>
    );

    return (
      <NavigationTouchable to={`/project/${this.props.reference}`}>
        <View>
          {projectCommentsOverlay}
          <View style={styles.container}>
            <View style={styles.leftContainer}>
              <Image
                source={{ uri: logoSrc }}
                resizeMethod="scale"
                resizeMode="contain"
                style={styles.logoImage}
              />
              <View style={styles.nameFlag}>
                <Text style={styles.title}>{this.props.name}</Text>
                <Flag code={this.props.countryCode} style={styles.flag} />
              </View>
              {sectorsText}
            </View>
            <View style={styles.rightContainer}>
              <Text style={styles.text}>€{money}</Text>
              <Text style={[styles.text, { fontSize: 13 }]}>Requested</Text>
              <Rating value={this.props.score} />
              <TouchableOpacity
                onPress={() => this.showProjectCommentsOverlay(true)}
              >
                <View style={[styles.commentBackground]}>
                  <Image
                    source={messagePic.source}
                    resizeMode="contain"
                    style={{ width: 20, height: 20 }}
                  />
                  <Text style={styles.wText}>{comments}</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </NavigationTouchable>
    );
  }
}

export default Company;

const styles = StyleSheet.create({
  container: {
    padding: 5,
    flexDirection: 'row',
  },
  leftContainer: {
    width: '70%',
    marginLeft: 10,
    marginTop: 10,
  },
  rightContainer: {
    marginTop: 10,
  },
  logoImage: {
    width: 40,
    height: 40,
  },
  nameFlag: {
    flexDirection: 'row',
  },
  commentBackground: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    height: 25,
    width: 60,
    borderRadius: 13,
    flexDirection: 'row',
    backgroundColor: '#7479ae',
  },
  bottomOverlay: {
    position: 'absolute',
    bottom: 0,
    padding: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  flag: {
    marginTop: 10,
    marginLeft: 3,
    width: 16,
    height: 16,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 10,
    color: '#666666',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    marginHorizontal: 2,
  },
});
