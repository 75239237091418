import { getPrivateApi } from '../base';
export * from './adapter';

const resourceUrl = '/discussions';

export const getDiscussions = async (page) => {
  const api = await getPrivateApi();
  return api
    .url(resourceUrl)
    .query({
      page,
    })
    .get()
    .json();
};

export const getDiscussion = async (reference) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${reference}`)
    .get()
    .json()
    .then((json) => json.data);
};

export const markDiscussionAsRead = async (discussionReference) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${discussionReference}`)
    .put({
      discussion: {
        read: true,
      },
    })
    .json();
};

export const changeDiscussionTitle = async (title, discussionReference) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${discussionReference}`)
    .put({
      discussion: {
        title,
      },
    })
    .json();
};

export const createDiscussion = async () => {
  const api = await getPrivateApi();
  return api
    .url(resourceUrl)
    .post()
    .json()
    .then((json) => json.data);
};

export const addUserToDiscussion = async (
  discussionReference,
  otherUserReference
) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${discussionReference}/add_user`)
    .query({ user_ref: otherUserReference })
    .post()
    .json()
    .then((json) => json.data);
};

export const removeUserOfDiscussion = async (
  discussionReference,
  otherUserReference
) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${discussionReference}/remove_user`)
    .query({ user_ref: otherUserReference })
    .delete()
    .res();
};

export const getDiscussionMessages = async (discussionReference) => {
  const api = await getPrivateApi();
  return api.url(`/discussion_messages/${discussionReference}`).get().json();
};

export const sendMessageToOtherUser = async (message, otherUserRef) => {
  const api = await getPrivateApi();
  return api
    .url('/discussion_messages')
    .post({
      discussion: {
        user_ref: otherUserRef,
        message,
      },
    })
    .json();
};

export const sendMessageOnDiscussion = async (message, discussionRef) => {
  const api = await getPrivateApi();
  return api
    .url('/discussion_messages')
    .post({
      discussion: {
        discussion_ref: discussionRef,
        message,
      },
    })
    .json();
};

export const deleteDiscussion = async (discussionReference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${discussionReference}`).delete().res();
};

export const leaveDiscussion = async (discussionReference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${discussionReference}/leave`).delete().res();
};
