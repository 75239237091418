import { compose, mapProps } from 'recompose';
import { connect } from 'react-redux';
import { meSelector } from '../../src/redux/User';
import { NotificationsScreenComponent } from './NotificationsScreen.component';
import { getNotifications } from '../../src/api/Notification';
import {
  adaptBatchNotifications,
  getBatchNotifications,
} from '../../src/lib/Batch';
import { adaptNotifications } from '../../src/api/Notification/adapter';
import { markNotificationAsVisited } from '../../src/redux/Notification';

const createFetchNotifications = (me) => async (page) => {
  try {
    return await getBatchNotifications(me);
  } catch (error) {
    console.warn(
      'Error fetching Batch notifications, fallback to API notifications',
      error
    );
  }

  return getNotifications(page);
};

const adaptBatchOrAPINotifications = (notifications) => {
  if (notifications.areFromBatch) {
    return adaptBatchNotifications(notifications);
  }

  return adaptNotifications(notifications);
};

const mapStateToProps = (state) => ({
  me: meSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  markAsVisited: () => dispatch(markNotificationAsVisited('notification')),
});

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  mapProps((ownProps) => ({
    fetchNotifications: createFetchNotifications(ownProps.me),
    adaptNotifications: adaptBatchOrAPINotifications,
  }))
);

export const ConnectedNotificationsScreen = enhance(
  NotificationsScreenComponent
);
