import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Divider } from 'react-native-elements';

export const StatLine = ({ label, value }) => {
  return (
    <View>
      <View style={styles.pairedData}>
        <Text style={styles.leftText}>{label}</Text>
        <Text style={styles.rightText}>{value}</Text>
      </View>
      <Divider style={styles.divider} />
    </View>
  );
};

const styles = StyleSheet.create({
  divider: {
    marginTop: 10,
    marginBottom: 20,
  },
  pairedData: {
    flexDirection: 'row',
    marginLeft: 20,
  },
  leftText: {
    flex: 1,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'left',
  },
  rightText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'right',
  },
});
