import React from 'react';
import {
  StyleSheet,
  ActivityIndicator,
  Text,
  TouchableOpacity,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { theme } from '../lib';

const getStyleFromMode = (mode) => {
  switch (mode) {
    case 'primary':
      return {
        button: styles.primaryButton,
        text: styles.primaryText,
        contentColor: 'white',
      };
    case 'inversed-primary':
      return {
        button: styles.inversedPrimaryButton,
        text: styles.inversedPrimaryText,
        contentColor: '#45a9ed',
      };
    case 'outline-primary':
      return {
        button: styles.outlinePrimaryButton,
        text: styles.outlinePrimaryText,
        contentColor: theme.colors.primary,
      };
    default:
      return {
        button: null,
        text: null,
        contentColor: 'grey',
      };
  }
};

const renderContent = (props, modeStyle) => {
  if (props.isLoading) {
    return <ActivityIndicator color={modeStyle.contentColor} />;
  }

  if (props.icon && props.iconInsteadOfText) {
    return (
      <Ionicons name={props.icon} size={28} color={modeStyle.contentColor} />
    );
  }

  return (
    <Text style={[styles.text, modeStyle.text, props.textStyle]}>
      {props.title}
    </Text>
  );
};

export const Button = (props) => {
  const modeStyle = getStyleFromMode(props.mode);
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[
        styles.button,
        modeStyle.button,
        props.disabled && styles.disabledButton,
        props.style,
      ]}
      disabled={props.isLoading || props.disabled}
      activeOpacity={0.7}
    >
      {renderContent(props, modeStyle)}
    </TouchableOpacity>
  );
};

const HEIGHT = 30;
const styles = StyleSheet.create({
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    height: HEIGHT,
    borderRadius: HEIGHT / 2,
    backgroundColor: '#eaeaea',
  },
  primaryButton: {
    backgroundColor: '#45a9ed',
  },
  inversedPrimaryButton: {
    backgroundColor: 'white',
  },
  outlinePrimaryButton: {
    borderColor: theme.colors.primary,
    borderWidth: 1,
    backgroundColor: 'transparent',
  },
  disabledButton: {
    opacity: 0.8,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
  primaryText: {
    color: 'white',
  },
  inversedPrimaryText: {
    color: '#45a9ed',
  },
  outlinePrimaryText: {
    color: theme.colors.primary,
  },
});
