const adaptInvestment = (apiInvestment) => ({
  id: apiInvestment.id,
  heading: apiInvestment.project.label,
  imgSrc: apiInvestment.project.logo.url_small
    ? `https:${apiInvestment.project.logo.url_small}`
    : null,
  url: null,
});

export const adaptInvestments = ({ data: apiInvestments }) =>
  apiInvestments.map(adaptInvestment);
