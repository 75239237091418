import React from 'react';
import { StyleSheet, Text, View, FlatList, Dimensions } from 'react-native';

import CategoryButton from '../components/CategoryButton';

const data = [
  {
    key: 'Select all',
    icon: require('../assets/icons/categories/category_01.png'),
  },
  { key: 'Da Flow', icon: require('../assets/icons/menuFlowIcon.png') },
  { key: 'Da House', icon: require('../assets/icons/menuHouseIcon.png') },
  { key: 'Legal', icon: require('../assets/icons/categories/category_16.png') },
  { key: 'Events', icon: require('../assets/icons/eventsIcon.png') },
  { key: 'General', icon: require('../assets/icons/menuHomeIcon.png') },
];

const formatData = (data, numColumns) => {
  const numberOfFullRows = Math.floor(data.length / numColumns);

  let numberOfElementsLastRow = data.length - numberOfFullRows * numColumns;
  while (
    numberOfElementsLastRow !== numColumns &&
    numberOfElementsLastRow !== 0
  ) {
    data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
    numberOfElementsLastRow++;
  }

  return data;
};

const numColumns = 3;

class CategorySelectionScreen extends React.Component {
  constructor(props) {
    super(props);
    this._onStateChange = this._onStateChange.bind(this);
  }

  state = {
    selected: 0,
  };

  _onStateChange(newState) {
    let nSelected = this.state.selected;
    if (newState) {
      nSelected += 1;
    } else {
      nSelected -= 1;
    }

    this.setState({ selected: nSelected });
    console.log('Selected items ' + nSelected);
  }

  renderItem = ({ item, index }) => {
    if (item.empty === true) {
      return <View style={[styles.itemInvisible]} />;
    }

    if (index % 3 === 1) {
      //It's the middle item
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.verticalSpacer} />
          <CategoryButton
            onStateChange={this._onStateChange}
            icon={item.icon}
            name={item.key}
            numColumns={numColumns}
          />
          <View style={styles.verticalSpacer} />
        </View>
      );
    }

    return (
      <View>
        <CategoryButton
          onStateChange={this._onStateChange}
          icon={item.icon}
          name={item.key}
          numColumns={numColumns}
        />
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <FlatList
          data={formatData(data, numColumns)}
          style={styles.container}
          renderItem={this.renderItem}
          numColumns={numColumns}
        />
        <View style={styles.buttonContainer}>
          <Text
            style={styles.wText}
            onPress={() => this.props.navigation.goBack()}
          >
            Cancel
          </Text>
          <View style={styles.vLine} />
          <Text
            style={styles.wText}
            onPress={() => this.props.navigation.goBack()}
          >
            Apply
          </Text>
        </View>
      </View>
    );
  }
}

export default CategorySelectionScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 10,
  },
  itemInvisible: {
    backgroundColor: 'transparent',
    flex: 1,
    margin: 1,
    height: Dimensions.get('window').width / numColumns,
  },
  verticalSpacer: {
    backgroundColor: '#666666',
    width: 1,
    height: '90%',
    alignSelf: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#45a9ed',
    zIndex: 2,
    bottom: 0,
    borderRadius: 15,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 30,
    width: '70%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    marginHorizontal: 35,
    textAlign: 'center',
  },
  vLine: {
    width: 1,
    height: '70%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
});
