import React, { useEffect, useState } from 'react';
import { Text, View, StyleSheet, ActivityIndicator } from 'react-native';
import { Card } from '../Card';
import { getStatistics } from '../../../../api';
import { StatLine } from '../StatLine';

export const DaphnipolisStats = () => {
  const [stats, setStats] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getStatistics()
      .then((data) => {
        setIsLoading(false);
        setStats({
          daphnipolitans: data.users_count.all,
          continents: data.localisation_count.continents,
          countries: data.localisation_count.countries,
          cities: data.localisation_count.cities,
          northAmerica: data.localisation_count.north_america,
          europe: data.localisation_count.europe,
          asia: data.localisation_count.asia,
          women: data.users_count.woman,
          men: data.users_count.man,
        });
      })

      .catch((error) => {
        console.error('Error when loading', error);
        setIsLoading(false);
      });
  }, []);

  if (!stats) {
    if (!isLoading) {
      return null;
    }

    return (
      <Card>
        <ActivityIndicator size="large" color="grey" />
      </Card>
    );
  }

  return (
    <Card>
      <Text style={styles.title}>Daphnipolis</Text>

      <StatLine label={stats.daphnipolitans} value={'Daphnipolitans'} />
      <StatLine label={stats.continents} value={'Continents'} />
      <StatLine label={stats.countries} value={'Countries'} />
      <StatLine label={stats.cities} value={'Cities'} />

      <View style={styles.divider} />

      <StatLine label={stats.northAmerica} value={'North America'} />
      <StatLine label={stats.europe} value={'Europe'} />
      <StatLine label={stats.asia} value={'Asia'} />

      <View style={styles.divider} />

      <StatLine label={stats.women} value={'Women'} />
      <StatLine label={stats.men} value={'Men'} />
    </Card>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666666',
    fontFamily: 'Freight-Sans-Black',
    marginBottom: 20,
  },
  divider: { height: 20 },
});
