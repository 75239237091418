import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Dimensions,
  TouchableOpacity,
  Image,
} from 'react-native';

import CategoryButton from '../components/CategoryButton';

const formatData = (data, numColumns) => {
  const numberOfFullRows = Math.floor(data.length / numColumns);

  let numberOfElementsLastRow = data.length - numberOfFullRows * numColumns;
  while (
    numberOfElementsLastRow !== numColumns &&
    numberOfElementsLastRow !== 0
  ) {
    data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
    numberOfElementsLastRow++;
  }

  return data;
};

class CategorySelectionScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      selectAll: false,
      categories: [],
    };
    this._onStateChange = this._onStateChange.bind(this);
  }

  componentDidMount() {
    this.setState({ categories: this.props.categories }, () => {
      this.refreshActiveCategories();
    });
  }

  refreshActiveCategories() {
    let activeCategories = 0;
    let categories = this.state.categories;
    let allSelected;

    for (let i = 0; i < categories.length - 1; i++) {
      if (categories[i].active) {
        console.log(categories[i].label + ' is ' + categories[i].active);
        activeCategories++;
      }
    }

    if (activeCategories === categories.length - 1) {
      console.log('All categories are selected');
      allSelected = true;
    } else {
      allSelected = false;
    }

    console.log('There are ' + activeCategories + ' active');
    this.setState({ selected: activeCategories, selectAll: allSelected });
  }

  _onStateChange = (active, id) => {
    const catIndex = this.state.categories.findIndex((c) => {
      return c.id === id;
    });

    let newCategories = Object.assign(this.state.categories);
    let category = Object.assign(newCategories[catIndex]);
    category.active = active;

    newCategories[catIndex] = category;

    this.setState({ categories: newCategories }, () => {
      this.refreshActiveCategories();
    });
  };

  toggleAll() {
    console.log('Select all');
    const newState = !this.state.selectAll;
    let newCategories = Object.assign(this.state.categories);
    let category;
    for (let i = 0; i < newCategories.length; i++) {
      category = Object.assign(newCategories[i]);
      category.active = newState;
      newCategories[i] = category;
    }
    this.setState({ selectAll: newState, categories: newCategories });
  }

  cancelButton() {
    this.props.showOverlay(false);
  }

  applyButton() {
    this.props.updateCategories(this.state.categories, this.state.selected);
    this.props.showOverlay(false);
  }

  showInConsole() {
    console.log('Button pressed in category selection');
  }

  renderItem = ({ item, index }) => {
    if (item.empty === true) {
      return (
        <View
          style={[
            styles.itemInvisible,
            { height: Dimensions.get('window').width / this.props.numColumns },
          ]}
        />
      );
    }

    if (index === 0) {
      //The Select all Button
      const buttonColor = this.state.selectAll ? '#06b025' : '#666666';
      return (
        <TouchableOpacity onPress={() => this.toggleAll()}>
          <View
            style={[
              styles.item,
              {
                height: Dimensions.get('window').width / this.props.numColumns,
                width:
                  Dimensions.get('window').width / this.props.numColumns - 3,
              },
            ]}
          >
            <Image
              style={[styles.itemIcon, { tintColor: buttonColor }]}
              source={item.icon}
              resizeMode="contain"
            />
            <Text
              style={[styles.itemText, { color: buttonColor, fontSize: 16 }]}
            >
              {item.label}
            </Text>
          </View>
          <View style={styles.bottomLine} />
        </TouchableOpacity>
      );
    }

    if (index % 3 === 1) {
      //It's the middle item
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.verticalSpacer} />
          <CategoryButton
            onStateChange={(event) => this._onStateChange(event, item.id)}
            id={item.id}
            active={item.active}
            icon={item.icon}
            name={item.label}
            numColumns={this.props.numColumns}
          />
          <View style={styles.verticalSpacer} />
        </View>
      );
    }
    return (
      <View>
        <CategoryButton
          ref={item.id}
          onStateChange={(event) => this._onStateChange(event, item.id)}
          id={item.id}
          active={item.active}
          icon={item.icon}
          name={item.label}
          numColumns={this.props.numColumns}
        />
      </View>
    );
  };

  render2Item = ({ item, index }) => {
    if (item.empty === true) {
      return (
        <View
          style={[
            styles.itemInvisible,
            { height: Dimensions.get('window').width / this.props.numColumns },
          ]}
        />
      );
    }

    if (index % 2 === 0) {
      return (
        <View style={{ flexDirection: 'row' }}>
          <CategoryButton
            onStateChange={(event) => this._onStateChange(event, item.id)}
            id={item.id}
            active={item.active}
            icon={item.icon}
            name={item.label}
            numColumns={this.props.numColumns}
          />
          <View style={styles.verticalSpacer} />
        </View>
      );
    }
    return (
      <View>
        <CategoryButton
          onStateChange={(event) => this._onStateChange(event, item.id)}
          id={item.id}
          active={item.active}
          icon={item.icon}
          name={item.label}
          numColumns={this.props.numColumns}
        />
      </View>
    );
  };

  render() {
    let paired;
    if (this.props.numColumns == 2) {
      paired = true;
    }

    return (
      <View style={styles.container}>
        <FlatList
          key={this.state.selectAll ? 1 : 0}
          data={formatData(this.state.categories, this.props.numColumns)}
          style={styles.container}
          renderItem={paired ? this.render2Item : this.renderItem}
          numColumns={this.props.numColumns}
          keyExtractor={(item) => item.id}
        />
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            style={[styles.bCtouch, { flexDirection: 'row' }]}
            onPress={() => this.cancelButton()}
          >
            <Text style={styles.wText}>Cancel</Text>
          </TouchableOpacity>
          <View style={styles.vLine} />
          <TouchableOpacity
            style={[styles.bCtouch, { flexDirection: 'row' }]}
            onPress={() => this.applyButton()}
          >
            <Text style={styles.wText}>Apply</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default CategorySelectionScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  itemInvisible: {
    backgroundColor: 'transparent',
    flex: 1,
    margin: 1,
  },
  verticalSpacer: {
    backgroundColor: '#666666',
    width: 1,
    height: '60%',
    alignSelf: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#7479ae',
    zIndex: 3,
    bottom: 0,
    borderRadius: 30,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 40,
    width: '70%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  bCtouch: {
    height: 40,
    paddingHorizontal: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    marginHorizontal: 35,
    textAlign: 'center',
  },
  vLine: {
    width: 1,
    height: '80%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
  item: {
    backgroundColor: '#ffffff',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 1,
  },
  itemText: {
    fontFamily: 'Freight-Sans-Medium',
    textAlign: 'center',
  },
  itemIcon: {
    width: 35,
    height: 35,
    margin: 5,
    alignSelf: 'center',
  },
  bottomLine: {
    backgroundColor: '#666666',
    width: '90%',
    height: 1,
    alignSelf: 'center',
  },
});
