const formatPreviewUser = (user, myReference) => {
  if (!user) {
    return 'Unknown user';
  }

  if (user.reference === myReference) {
    return 'You';
  }

  return user.firstName;
};

export const formatDiscussionPreview = (lastMessage, myReference) => {
  if (!lastMessage) {
    return 'No messages yet';
  }

  const user = formatPreviewUser(lastMessage.user, myReference);

  return `${user}: ${lastMessage.message}`;
};
