import React, { PureComponent } from 'react';
import { InteractionManager } from 'react-native';
import { ControlledPaginatedFlatList } from './ControlledPaginatedFlatList.component';
import { adaptPaginationMeta } from '../../api';

const initialPaginationState = {
  currentPage: 0,
  lastPage: null,
  data: [],
  isRefreshing: false,
  isLoading: false,
};

export class PaginatedFlatList extends PureComponent {
  state = initialPaginationState;

  componentDidMount() {
    if (this.props.fetchOnMount) {
      InteractionManager.runAfterInteractions(() => this.fetchData());
    }
  }

  fetchData = async () => {
    this.setState({ isLoading: true });
    try {
      const { fetchData, adaptData } = this.props;
      const response = await fetchData(this.state.currentPage + 1);
      const data = adaptData(response);
      const { lastPage, currentPage: newCurrentPage } = adaptPaginationMeta(
        response
      );
      this.setState((state) => ({
        data: [...state.data, ...data],
        lastPage,
        currentPage: newCurrentPage,
      }));

      if (this.props.onDataFetched) {
        this.props.onDataFetched(data);
      }
    } catch (error) {
      console.warn('Error while fetching data', { error });
    } finally {
      this.setState({ isLoading: false });
    }
  };

  canFetchMore = () =>
    this.state.currentPage !== this.state.lastPage &&
    !this.state.isLoading &&
    !this.state.isRefreshing;

  resetData = () => {
    this.setState({ ...initialPaginationState }, () => this.fetchData());
  };

  refreshData = () => {
    this.setState(
      {
        ...initialPaginationState,
        isRefreshing: true,
      },
      async () => {
        await this.fetchData();
        this.setState({
          isRefreshing: false,
        });
      }
    );
  };

  render() {
    const { fetchData, adaptData, ...rest } = this.props;
    const { data, isLoading, isRefreshing } = this.state;
    return (
      <ControlledPaginatedFlatList
        {...rest}
        data={data}
        fetchData={this.fetchData}
        isLoading={isLoading}
        onRefresh={this.refreshData}
        refreshing={isRefreshing}
        canFetchMore={this.canFetchMore()}
      />
    );
  }
}
