import { connect } from 'react-redux';
import NewChatScreenComponent from './NewChatScreen.component';
import { createGroupDiscussion } from '../../src/redux/Discussion';
import { isLoadingSelector } from '../../src/redux/LoadingStatus';

const mapStateToProps = (state) => ({
  isCreatingGroupDiscussion: isLoadingSelector('createGroupDiscussion')(state),
});

const mapDispatchToProps = {
  createGroupDiscussion,
};

export const ConnectedNewChatScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewChatScreenComponent);
