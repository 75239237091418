import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Avatar, Icon } from 'react-native-elements';
import Flag from 'react-native-round-flags';

export const ProfileComponent = ({ navigation, me }) => {
  return (
    <View style={styles.container}>
      <View style={styles.imageView}>
        <Avatar
          onPress={() => navigation.navigate('EditProfile')}
          rounded
          size={85}
          source={{ uri: me.avatar }}
          borderWidth={5}
          borderColor="#ffffff"
        />
        <View style={styles.flag}>
          <Flag
            code={me.countryCode}
            style={{ width: 30, height: 30, marginRight: 10 }}
          />
          <Icon
            name="edit"
            type="font-awesome"
            color="#fff"
            size={24}
            onPress={() => navigation.navigate('EditProfile')}
          />
        </View>
      </View>
      <Text style={styles.profileName}>
        {me.firstName} {me.lastName}
      </Text>
      <Text style={styles.profileText}>{me.job}</Text>
      <Text style={styles.profileText}>{me.company}</Text>
      <View style={styles.spaceLine} />
      {me.sectors.map((tag, id) => (
        <Text key={id} style={styles.profileText}>
          {tag.tag}
        </Text>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#45a9ed',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  imageView: {
    justifyContent: 'center',
  },
  flag: {
    position: 'absolute',
    zIndex: 2,
    alignItems: 'baseline',
    flexDirection: 'row',
    bottom: 5,
    right: -45,
  },
  profileName: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#ffffff',
    marginTop: 25,
  },
  profileText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    marginHorizontal: 20,
  },
  spaceLine: {
    height: 1,
    margin: 5,
  },
});
