import { adaptSmallUser } from '../User/adapter';

export const adaptNotificationsCounts = ({ data: counts }) => ({
  discussion: counts.message_count,
  timeline: counts.timeline_count,
  notification: counts.notification_count,
});

const adaptNotification = (apiNotification) => {
  const user = apiNotification.user
    ? adaptSmallUser(apiNotification.user)
    : null;

  return {
    id: apiNotification.id,
    user,
    text: apiNotification.phrase,
    isRead: apiNotification.is_seen,
    image: user ? user.avatar : null,
    date: apiNotification.created_at,
  };
};

export const adaptNotifications = ({ data: apiNotifications }) => {
  return apiNotifications.map(adaptNotification);
};
