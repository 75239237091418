import React from 'react';
import { DaFlowGenericPage } from './DaFlowGenericPage';

export const DaFlowNew = () => {
  return (
    <DaFlowGenericPage
      daFlowType="new"
      bottomPanel={{
        fetchProjectsParams: {
          state: 'new',
        },
      }}
      topPanel={{
        title: 'Featured',
        fetchProjectsParams: {
          state: 'new',
          is_featured: true,
        },
      }}
    />
  );
};
