import { formatMoney, theme } from '../../../lib';
import { FlatList, Platform, StyleSheet, Text, View } from 'react-native';
import React from 'react';

const renderFund = ({ item: fund }) => (
  <View style={styles.container}>
    <View style={{ flexGrow: 1 }}>
      <Text style={styles.text}>Fund: {fund.label}</Text>
      {!!fund.year && (
        <Text style={styles.text}>Vintage year: {fund.year}</Text>
      )}
      <Text style={styles.text}>Commitment: €{fund.commitment} </Text>
    </View>
    <View style={styles.tLine} />
    <View style={{ flexGrow: 1 }}>
      <Text style={styles.text}># Companies: {fund.nbCompanies}</Text>
      <Text style={styles.text}>Investment: €{fund.investment}</Text>
      <Text style={styles.text}>NAV: €{fund.fairMoney} </Text>
    </View>
  </View>
);

export const FundsStats = ({ funds, isLoadingFunds }) => {
  const fundsInfo = funds
    ?.filter((fund) => fund.nbCompanies > 0)
    .map((fund) => ({
      ...fund,
      year: fund.vintageYear,
      investment: formatMoney(fund.investment, true),
      fairMoney: formatMoney(fund.fairMoneyValue, true),
      commitment: formatMoney(fund.commitment, true),
    }));

  if (isLoadingFunds || !fundsInfo || !fundsInfo.length) {
    return null;
  }

  return (
    <FlatList
      data={fundsInfo}
      contentContainerStyle={{
        paddingHorizontal: 20,
        flexGrow: 1,
        justifyContent: 'center',
      }}
      pagingEnabled={Platform.OS !== 'web'}
      horizontal
      showsHorizontalScrollIndicator={false}
      renderItem={renderFund}
      keyExtractor={(fundInfo) => String(fundInfo.id)}
      ItemSeparatorComponent={() => (
        <View style={{ width: 20, height: '100%' }} />
      )}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 30,
    paddingHorizontal: 15,
    borderRadius: 20,
    backgroundColor: '#ffffff',
  },
  text: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 13,
    color: theme.colors.dahouse,
  },
  tLine: {
    width: 1,
    height: '100%',
    backgroundColor: theme.colors.dahouse,
    alignSelf: 'center',
    marginHorizontal: 15,
  },
});
