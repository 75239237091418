import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Keyboard,
} from 'react-native';
import { Avatar } from 'react-native-elements';
import { searchUsers } from '../../src/api/User';
import { adaptUsers } from '../../src/api/User/adapter';
import { Button, Page, PaginatedFlatList } from '../../src/components';
import { SearchBar } from '../../src/components/SearchBar';

class ChatAddUsersOverlayComponent extends React.Component {
  state = {
    search: '',
    userBeingAdded: null,
  };

  list = null;
  setListRef = (ref) => (this.list = ref);

  updateSearch = (search) => this.setState({ search });

  searchButton = (event) => {
    const keyword = event.nativeEvent.text;
    Keyboard.dismiss();
    this.setState({ search: keyword }, () => {
      this.list.resetData();
    });
  };

  addPersonToChat = (otherUser) => {
    this.props.addUserToGroupDiscussion(
      this.props.route.params?.discussionReference,
      otherUser
    );
  };

  renderUserItem = ({ item: user }) => {
    const addUser = () => {
      this.setState({ userBeingAdded: user.reference });
      this.addPersonToChat(user);
    };

    const { isAddingUser, discussionUsers } = this.props;
    const isAddingThisUser =
      isAddingUser && user.reference === this.state.userBeingAdded;
    const isUserAlreadyAdded = discussionUsers.find(
      (discussionUser) => discussionUser.reference === user.reference
    );
    const isButtonDisabled = isAddingUser || isUserAlreadyAdded;
    return (
      <View>
        <TouchableOpacity
          style={styles.userRowButton}
          onPress={addUser}
          disabled={isButtonDisabled}
        >
          <Avatar rounded size={45} source={{ uri: user.avatar }} />
          <Text style={[styles.text, styles.userName]} numberOfLines={1}>
            {user.firstName || ''} {user.lastName || ''}
          </Text>
          <Button
            style={styles.addUserButton}
            mode="primary"
            title="Add"
            icon="md-checkmark"
            iconInsteadOfText={isUserAlreadyAdded}
            isLoading={isAddingThisUser}
            disabled={isButtonDisabled}
            onPress={addUser}
          />
        </TouchableOpacity>
        <View style={[styles.line, styles.separator]} />
      </View>
    );
  };

  renderNothingFound = () =>
    this.state.search !== '' ? (
      <Text style={[styles.text, styles.emptyText]}>
        Nothing found{'\n'}Try another word
      </Text>
    ) : null;

  fetchUsers = (page) => searchUsers(this.state.search, page);

  renderList = () => {
    const { isAddingUser, discussionUsers } = this.props;
    const { userBeingAdded } = this.state;
    return (
      <PaginatedFlatList
        ref={this.setListRef}
        renderItem={this.renderUserItem}
        renderEmpty={this.renderNothingFound}
        fetchData={this.fetchUsers}
        adaptData={adaptUsers}
        fetchOnMount
        extraData={JSON.stringify({
          isAddingUser,
          userBeingAdded,
          discussionUsers,
        })}
      />
    );
  };

  render() {
    return (
      <Page withoutTopPanel>
        <SearchBar
          containerStyle={styles.searchBar}
          onChangeText={this.updateSearch}
          onSubmitEditing={this.searchButton}
        />
        <View style={styles.line} />
        {this.renderList()}
      </Page>
    );
  }
}

export default ChatAddUsersOverlayComponent;

const styles = StyleSheet.create({
  searchBar: {
    marginTop: 10,
    marginHorizontal: 20,
  },
  userRowButton: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: 20,
  },
  addUserButton: {
    width: 60,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
  userName: {
    flex: 1,
    marginLeft: 5,
  },
  emptyText: {
    margin: 20,
    textAlign: 'center',
  },
  line: {
    borderBottomColor: '#666666',
    borderBottomWidth: 1,
    marginTop: 20,
    marginHorizontal: 20,
  },
  separator: {
    marginBottom: 20,
  },
});
