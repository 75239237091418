import React from 'react';
import { Dimensions } from 'react-native';
import BaseOverlayDaphnipolitan from './BaseOverlayDaphnipolitan';

const ShareDealDaphnipolitan = (props) => (
  <BaseOverlayDaphnipolitan
    {...props}
    containerStyle={{ width: Dimensions.get('window').width * 0.55 }}
    textColor="white"
  />
);

export default ShareDealDaphnipolitan;
