import React, { Fragment, PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';
import { Button } from '../../../../src/components';
import { DeleteGroupButton } from '../DeleteGroupButton';
import { LeaveGroupButton } from '../LeaveGroupButton';

export class GroupActionsComponent extends PureComponent {
  goToAddUsers = () =>
    this.props.navigation.navigate('ChatAddUser', {
      discussionReference: this.props.discussionReference,
    });

  renderNonAdminActions = () => {
    if (this.props.isUserAdmin) {
      return null;
    }

    return (
      <LeaveGroupButton
        style={styles.action}
        discussionReference={this.props.discussionReference}
      />
    );
  };

  renderAdminActions = () => {
    if (!this.props.isUserAdmin) {
      return null;
    }

    if (!this.props.isEditingGroup) {
      return (
        <Button
          title="Edit group"
          onPress={this.props.onEditGroup}
          style={styles.action}
        />
      );
    }

    return (
      <Fragment>
        <Button
          title="Add members"
          onPress={this.goToAddUsers}
          style={styles.action}
        />
        <DeleteGroupButton
          style={styles.action}
          discussionReference={this.props.discussionReference}
        />
      </Fragment>
    );
  };

  render() {
    return (
      <View style={this.props.style}>
        {this.renderNonAdminActions()}
        {this.renderAdminActions()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  action: {
    marginBottom: 15,
  },
});
