import { BatchPush } from '@bam.tech/react-native-batch';

export const linkingConfig = {
  prefixes: ['https://my.daphni.com/', 'daphni://'],
  getInitialURL: () => BatchPush.getInitialURL(),
  config: {
    screens: {
      Onboarding: 'onboarding',
      Login: 'login',
      Confirmation: 'login/confirmation',
      Loading: 'loading',
      Drawer: {
        path: '',
        screens: {
          Main: {
            path: '',
            initialRouteName: 'Home',
            screens: {
              Home: 'home',
              AllEvents: 'home/events',
              AllNews: 'home/news',
              DaFlowNew: 'daflow/new',
              DaFlowExploration: 'daflow/exploration',
              DaFlowPending: 'daflow/pending',
              DaFlowTermsheet: 'daflow/termsheet',
              DaFlowDropped: 'daflow/dropped',
              DaHouse: 'dahouse',
              DaHouseCompany01: 'dahouse/project/:reference',
              Daphnipolitans: 'daphnipolis/daphnipolitans',
              Timeline: 'daphnipolis/datimeline',
              Bookmarks: 'account/bookmarks',
              Investments: 'account/investments',
              Pings: 'account/pings',
              MyLikes: 'account/likes',
              AllNotifications: 'account/notifications',
              CompleteCompany: 'project/:reference',
              Comments: 'daphnipolis/datimeline/article/:id/comments',
              EditProfile: 'account/edit',
              ChatList: 'home/chat',
              CoInvest01: 'home/coinvest',
              ChatWindow: 'home/chat/discussion/:discussionReference',
              GroupSettings: 'home/chat/group-settings/:discussionReference',
              PDFDeck: 'PDFDeck',
            },
          },
        },
      },
      Daphnipolitan: 'daphnipolis/daphnipolitans/:reference',
      SearchModal: 'search',
      NewChat: 'home/chat/new',
      ChatAddUser: 'home/chat/add-user/:discussionReference',
      ChatFindUsers: 'home/chat/find-user',
    },
  },
};
