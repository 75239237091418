import { connect } from 'react-redux';
import {
  addUserToGroupDiscussion,
  discussionUsersSelector,
} from '../../src/redux/Discussion';
import { isLoadingSelector } from '../../src/redux/LoadingStatus';
import ChatAddUsersOverlayComponent from './ChatAddUsersOverlay.component';

const mapStateToProps = (state, ownProps) => ({
  isAddingUser: isLoadingSelector('addUserToGroupDiscussion')(state),
  discussionUsers: discussionUsersSelector(
    ownProps.route.params?.discussionReference
  )(state),
});

const mapDispatchToProps = {
  addUserToGroupDiscussion,
};

export const ConnectedChatAddUsersOverlay = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatAddUsersOverlayComponent);
