import React, { useEffect, useState } from 'react';
import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  Linking,
  ActivityIndicator,
} from 'react-native';
import { Card } from '../Card';
import { getDaCommunityNews } from '../../../../api';
import { adaptDaCommunityNews } from '../../../../api/News/adapter';
import HTML from 'react-native-render-html';

const IMAGES_MAX_WIDTH = Dimensions.get('window').width - 50;
const DEFAULT_HTML_PROPS = {
  baseFontStyle: { fontSize: 16 },
  tagsStyles: {
    p: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    em: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    i: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
  },
  ignoredStyles: [
    'background-color',
    'padding',
    'font-family',
    'font-size',
    'line-height',
  ],
  imagesMaxWidth: IMAGES_MAX_WIDTH,
  onLinkPress: (evt, href) => {
    Linking.openURL(href);
  },
  debug: false,
};

export const DaCommunityNews = () => {
  const [news, setNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getDaCommunityNews()
      .then(adaptDaCommunityNews)
      .then((newNews) => {
        setIsLoading(false);
        setNews(newNews);
      })
      .catch((error) => {
        console.error('Error when loading', error);
        setIsLoading(false);
      });
  }, []);

  if (!news.length) {
    if (!isLoading) {
      return null;
    }

    return (
      <Card>
        <ActivityIndicator size="large" color="grey" />
      </Card>
    );
  }

  return (
    <Card>
      <Text style={styles.title}>Da Community News</Text>
      <View>
        <HTML {...DEFAULT_HTML_PROPS} html={news[0].content} />
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666666',
    fontFamily: 'Freight-Sans-Black',
    marginBottom: 20,
  },
});
