import { getUrlWithScheme } from '../base';

export const adaptFund = (fund) => ({
  id: fund.id,
  label: fund.label,
  nbCompanies: fund.nb_companies,
  vintageYear: fund.vintage_year,
  reportingUrl:
    fund.reporting_url === '/missing/reporting.png'
      ? null
      : getUrlWithScheme(fund.reporting_url),
  commitment: fund.commitment,
  fairMoneyValue: fund.fair_money_value,
  investment: fund.investment,
});

export const adaptFunds = ({ data: apiFunds }) => apiFunds.map(adaptFund);
