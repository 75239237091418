import { Platform } from 'react-native';
import { getPotentiallyMissingAvatarUrl } from '../base';

export const adaptMe = ({ data: apiMe }) => ({
  reference: apiMe.ref,
  firstName: apiMe.first_name || '',
  lastName: apiMe.last_name || '',
  role: apiMe.role || '',
  bio: apiMe.bio || '',
  job: apiMe.job || '',
  avatar: getPotentiallyMissingAvatarUrl(apiMe.avatar.url_small),
  countryCode: apiMe.country_code || 'ZZ',
  country: apiMe.country || 'Unknown',
  funds: apiMe.funds || [],
  projectsAttribution: apiMe.projects_attribution,
  batchInboxKey: Platform.select({
    ios: apiMe.inbox_ios,
    android: apiMe.inbox_android,
  }),
  sectors: apiMe.sectors.map((sector) => ({
    id: sector.id,
    tag: sector.name,
  })),
});

export const adaptSmallUser = (user) => ({
  reference: user.ref,
  firstName: user.first_name,
  lastName: user.last_name,
  name: [user.first_name, user.last_name].filter(Boolean).join(' '),
  position: user.position,
  avatar: getPotentiallyMissingAvatarUrl(
    user.small_avatar_url || (user.avatar && user.avatar.url_small)
  ),
});

export const adaptUser = (apiUser) => ({
  ...adaptSmallUser(apiUser),
  bio: apiUser.bio,
  city: apiUser.city,
  company: apiUser.company,
  country: apiUser.country,
  countryCode: apiUser.country_code,
  connected_to: apiUser.connected_to,
  job: apiUser.job,
  social: {
    facebook: apiUser.facebook,
    twitter: apiUser.twitter,
    linkedin: apiUser.linkedin,
  },
  sectors: apiUser.sectors.map((sector) => ({
    id: sector.id,
    name: sector.name,
  })),
});

export const adaptUsers = ({ data: apiUsers }) => apiUsers.map(adaptUser);
