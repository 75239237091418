import React, { Component } from 'react';
import {
  View,
  FlatList,
  StyleSheet,
  ActivityIndicator,
  Text,
} from 'react-native';
import TimelineComment from './TimelineComment';

import { Divider } from 'react-native-elements';

const renderItem = ({ item }) => (
  <View>
    <TimelineComment
      id={item.id}
      user={item.user}
      comment={item.content}
      date={item.date.substring(0, 10)}
    />
    <Divider style={styles.lineStyle} />
  </View>
);

class TimelineConversation extends Component {
  renderEmpty = () =>
    !this.props.isLoading ? <Text>There are no comments yet</Text> : null;

  renderLoader = () => (
    <View style={{ minHeight: 40 }}>
      {this.props.isLoading ? (
        <ActivityIndicator
          size="large"
          color="black"
          style={{ marginTop: 10 }}
        />
      ) : null}
    </View>
  );

  renderArticle = () => {
    const { article } = this.props;
    if (!article) {
      return null;
    }

    return renderItem({ item: article });
  };

  keyExtractor = (item) => item.id;

  render() {
    return (
      <FlatList
        style={this.props.style}
        contentContainerStyle={styles.contentContainer}
        data={this.props.messages}
        renderItem={renderItem}
        keyExtractor={this.keyExtractor}
        ListHeaderComponent={this.renderArticle}
        ListFooterComponent={this.renderLoader}
        ListEmptyComponent={this.renderEmpty}
        extraData={JSON.stringify({
          isLoading: this.props.isLoading,
          article: this.props.article,
        })}
      />
    );
  }
}

export default TimelineConversation;

const styles = StyleSheet.create({
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
  contentContainer: {
    paddingVertical: 20,
    paddingHorizontal: 20,
  },
});
