import React, { Fragment } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TextInput,
  ImageBackground,
  TouchableOpacity,
  ActivityIndicator,
  SafeAreaView,
} from 'react-native';

import { localstore } from '../helpers/LocalStore';
import { AppVersion } from '../src/components/AppVersion';
import { theme } from '../src/lib';
import { environment } from '../src/environment';
import { ChangeEnvironmentButton } from '../src/components/ChangeEnvironmentButton';

class LoginScreen extends React.Component {
  state = {
    isLoading: false,
    email: '',
    errorTxt: '',
  };

  _handleTextChange = (event) => {
    this.setState({ email: event.nativeEvent.text });
    this.setState({ errorTxt: '' });
  };

  async loginButton() {
    console.log('Login Button pressed with mail ' + this.state.email);
    let response = false;

    this.setState({ isLoading: true });
    //Have a try and catch block for catching errors.
    try {
      //Assign the promise unresolved first then get the data using the json method.
      let obj = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token: {
            email: this.state.email,
          },
        }),
      };

      const daphniServer = await fetch(
        'https://' + environment.API_HOST + '/v4/tokens',
        obj
      );
      response = await daphniServer.ok;
    } catch (err) {
      console.log('Error fetching data-----------', err);
    }

    if (response) {
      console.log('Code was sent to mail');
      localstore.setString('email', this.state.email);
      this.props.navigation.navigate('Confirmation');

      this.setState({ isLoading: false });
    } else {
      console.log('The user does not exist');
      this.setState({ errorTxt: 'The user does not exist' });
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <Fragment>
        <SafeAreaView style={{ backgroundColor: '#45a9ed', flex: 0 }} />
        <SafeAreaView style={{ backgroundColor: '#45a9ed', flex: 1 }}>
          <View style={styles.container}>
            <ImageBackground
              source={require('../assets/profileBackground.png')}
              style={styles.imageBackground}
            >
              <View>
                <Text style={styles.daphniText}>daphni</Text>
                <TextInput
                  onChange={this._handleTextChange}
                  placeholderTextColor={'#45a9ed'}
                  style={styles.inputText}
                  keyboardType="email-address"
                  textContentType="emailAddress"
                  autoCapitalize="none"
                  autoCompleteType="email"
                  placeholder="email@company.com"
                />

                <TouchableOpacity
                  onPress={this.loginButton.bind(this)}
                  disabled={this.state.isLoading}
                >
                  {!this.state.isLoading ? (
                    <View style={styles.buttonContainer}>
                      <Text style={styles.buttonText}>Enter</Text>
                    </View>
                  ) : (
                    <View style={styles.buttonContainer}>
                      <ActivityIndicator color={theme.colors.secondary} />
                    </View>
                  )}
                </TouchableOpacity>
                <Text style={styles.errorText}>{this.state.errorTxt}</Text>
              </View>
              <View>
                {environment.allEnvs?.length > 1 && <ChangeEnvironmentButton />}
                <Text style={styles.disclaimerText}>
                  Restricted to the daphni community
                </Text>
                <AppVersion
                  style={styles.appVersion}
                  textStyle={styles.appVersionText}
                />
              </View>
            </ImageBackground>
          </View>
        </SafeAreaView>
      </Fragment>
    );
  }
}
export default LoginScreen;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#45a9ed',
  },
  imageBackground: {
    flex: 1,
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
  },
  daphniText: {
    fontFamily: 'DaphniFont',
    fontSize: 100,
    marginTop: 120,
    color: '#ffffff',
    textAlign: 'center',
  },
  inputText: {
    fontFamily: 'Freight-Sans-Medium',
    width: '60%',
    height: 35,
    textAlign: 'center',
    fontSize: 15,
    color: '#45a9ed',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    alignSelf: 'center',
  },
  buttonContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 20,
    width: 100,
    height: 30,
    margin: 20,
    justifyContent: 'center',
    alignSelf: 'center',
  },
  buttonText: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#45a9ed',
    marginVertical: 5,
    marginHorizontal: 10,
    textAlign: 'center',
  },
  disclaimerText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    textAlign: 'center',
  },
  errorText: {
    marginTop: 20,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: 'red',
    textAlign: 'center',
  },
  appVersion: {
    marginVertical: 8,
  },
  appVersionText: {
    color: 'white',
  },
});
