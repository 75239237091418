import { getPrivateApi } from '../base';

const resourceUrl = '/investments';

export const getInvestments = async () => {
  const api = await getPrivateApi();
  return api.url(resourceUrl).get().json();
};

export const getInvestment = async (id) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${id}`).get().json();
};
