import React, { PureComponent } from 'react';
import { ActivityIndicator, Dimensions, StyleSheet, View } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import {
  adaptSentDiscussionMessage,
  sendMessageOnDiscussion,
  sendMessageToOtherUser,
} from '../../../src/api/Discussion';
import { MultilineTextInput } from '../../../src/components/MultilineTextInput';
import { theme } from '../../../src/lib';

export class SendForm extends PureComponent {
  state = {
    isLoading: false,
    message: '',
  };

  handleTextChange = ({ nativeEvent }) =>
    this.setState({ message: nativeEvent.text });

  sendMessage = async () => {
    this.setState({ isLoading: true });
    try {
      const apiSentMessage = this.props.discussion
        ? await sendMessageOnDiscussion(
            this.state.message,
            this.props.discussion.reference
          )
        : await sendMessageToOtherUser(
            this.state.message,
            this.props.otherUserReference
          );
      const sentMessage = adaptSentDiscussionMessage(apiSentMessage);

      if (this.props.onSent) {
        this.props.onSent(sentMessage);
      }
      this.setState({
        message: '',
        isLoading: false,
      });
    } catch (err) {
      console.log('There was a problem sending your message, try again', err);
      this.setState({ isLoading: false });
    }
  };

  renderSendButton = () => {
    if (this.state.isLoading) {
      return <ActivityIndicator size="small" color="grey" />;
    }

    return (
      <Ionicons
        name="ios-send"
        size={35}
        color="#45a9ed"
        onPress={this.sendMessage}
      />
    );
  };

  render() {
    const canSendMessage =
      (!!this.props.discussion && !!this.props.discussion.reference) ||
      !!this.props.otherUserReference;
    if (!canSendMessage) {
      return null;
    }

    return (
      <View style={[styles.form, this.props.style]}>
        <MultilineTextInput
          onChange={this.handleTextChange}
          style={styles.textInput}
          value={this.state.message}
          placeholder="Leave your comment"
          placeholderTextColor="gray"
        />
        {this.renderSendButton()}
      </View>
    );
  }
}

const WIDTH = Dimensions.get('window').width;
const styles = StyleSheet.create({
  form: {
    width: WIDTH - 20,
    paddingVertical: theme.margin.unit,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    borderRadius: 20,
    backgroundColor: '#fff',
  },
  textInput: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#555',
    fontSize: 16,
    width: WIDTH - 80,
    marginHorizontal: 10,
  },
});
