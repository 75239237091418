import React, { PureComponent } from 'react';
import { ActivityIndicator, FlatList, StyleSheet, View } from 'react-native';
import { Message } from './Message';

export class MessageList extends PureComponent {
  getTimeAgoRefreshTimestamp = () => Math.trunc(Date.now() / 15000);

  renderMessage = ({ item: message }) => {
    const isMyMessage = message.user.reference === this.props.myReference;
    return (
      <Message
        message={message}
        isMyMessage={isMyMessage}
        timeAgoRefreshTimestamp={this.getTimeAgoRefreshTimestamp()}
      />
    );
  };

  renderLoader = () => {
    if (!this.props.isLoading) {
      return null;
    }

    return (
      <ActivityIndicator style={styles.loader} size="large" color="white" />
    );
  };

  renderSeparator = () => <View style={styles.separator} />;

  keyExtractor = (item) => item.id;

  render() {
    return (
      <FlatList
        keyExtractor={this.keyExtractor}
        data={this.props.messages}
        extraData={JSON.stringify({
          isLoading: this.props.isLoading,
          timeAgoRefreshTimestamp: this.getTimeAgoRefreshTimestamp(),
        })}
        style={styles.messages}
        contentContainerStyle={styles.messagesContentContainer}
        renderItem={this.renderMessage}
        ListEmptyComponent={this.renderLoader}
        ItemSeparatorComponent={this.renderSeparator}
        inverted
      />
    );
  }
}

const styles = StyleSheet.create({
  messages: {
    padding: 10,
  },
  separator: {
    height: 20,
  },
  messagesContentContainer: {
    flexGrow: 1,
    justifyContent: 'flex-end',
    paddingVertical: 20,
  },
  loader: {
    margin: 50,
  },
});
