import React from 'react';
import {
  Platform,
  Text,
  View,
  StyleSheet,
  Dimensions,
  Linking,
  ScrollView,
  TouchableOpacity,
  ActivityIndicator,
  FlatList,
  Image,
  DeviceEventEmitter,
} from 'react-native';
import { StatusBarHeight } from '../src/lib/StatusBarHeight';

import Ionicons from 'react-native-vector-icons/Ionicons';
import { Avatar, Overlay, Divider } from 'react-native-elements';
import Flag from 'react-native-round-flags';

import ProjectConversation from '../components/Comments/ProjectConversation';
import Deck from '../components/Deck';
import NewComment from '../components/Comments/NewComment';
import HouseDaphnipolitan from '../components/Daphnipolitan/HouseDaphnipolitan';
import PingOverlay from '../overlays/PingOverlay';
import RatingOverlay from '../overlays/RatingOverlay';
import { ShareDealOverlay } from '../overlays/ShareDealOverlay';

import { localstore } from '../helpers/LocalStore';
import SearchDaphnipolitan from '../components/Daphnipolitan/SearchDaphnipolitan';
import { formatMoney } from '../src/lib';
import {
  getProject,
  getProjectComments,
  likeProject,
  dislikeProject,
  followProject,
  unfollowProject,
} from '../src/api';
import { Rating } from '../src/components/Rating';
import {
  adaptProjectComments,
  adaptProjectDeckPages,
  adaptProjectDroppedReason,
} from '../src/api/Project/adapter';
import { innerViewBaseStyles, Page } from '../src/components';
import { keyboardHeightListener } from '../components/keyboardHeightListener';
import { CompanyDisclaimer } from '../src/pages/Company/components/CompanyDisclaimer';
import { environment } from '../src/environment';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);

class CompleteCompanyScreen extends React.Component {
  state = {
    hasAccessToProject: true,
    bearerToken: '',
    sError: false,
    loading: true,
    teamOverlayVisible: false,
    showMoreOverlay: false,
    showPingOverlay: false,
    showBookmarkOverlay: false,
    showShareOverlay: false,
    showRatingOverlay: false,
    showShareMessageOverlay: false,
    shareMessage: '',
    reference: '',
    logo: '',
    name: '',
    city: '',
    country: '',
    countryCode: '',
    tags: [],
    kpis: [],
    companySpeech: '',
    comments: '',
    score: '',
    money: '',
    stage: '',
    teamCount: '',
    team: [],
    followers: [],
    website: '',
    deckPages: [],
    deckUrl: null,
    video: '',
    videoThumbnail: '',
    foundedOn: '',
    launchedOn: '',
    isLoving: false,
    loveCount: '',
    isFollowing: false,
    conversation: [],
  };

  scrollView;
  setScrollView = (ref) => (this.scrollView = ref);

  async componentDidMount() {
    const reference = this.props.route.params?.reference || 'nothing';
    localstore.getString('role').then((result) => {
      if (result !== null) {
        this.setState({ role: result });
      } else {
        this.setState({ role: 'dp' });
      }
    });

    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result, reference }, async () => {
          await this.getCompanyData();
          await this.getProjectComments();
        });
      }
    });
  }

  getCompanyData = () => {
    this.setState({ loading: true });
    return getProject(this.state.reference)
      .then((json) => {
        let usersObject = json.data;

        let sectors = [];
        let objArray = usersObject.sectors;
        var oCount = Object.keys(objArray).length;
        for (let j = 0; j < oCount; j++) {
          sectors.push({ id: objArray[j].id, tag: objArray[j].name });
        }

        let kpis = [];
        objArray = usersObject.kpis;
        oCount = Object.keys(objArray).length;
        for (let j = 0; j < oCount; j++) {
          kpis.push({
            id: objArray[j].type_of,
            attribute: objArray[j].attributes.name,
            value: objArray[j].value,
            unit: objArray[j].attributes.unit,
          });
        }

        let steam = [];
        //Lead, contact and Referer

        if (usersObject.contact != null) {
          let leadAvatar =
            'https://' + environment.API_HOST + '/missing/avatar.png';
          steam.push({
            reference: 'contact',
            name: usersObject.contact,
            job: 'Contact',
            avatar: leadAvatar,
          });
        }

        if (usersObject.referral_contact != null) {
          let leadAvatar = usersObject.referral_contact.avatar.url_small;
          let url = leadAvatar.toString();
          url = url.replace('staging', 'prod');
          leadAvatar = 'https:' + url;
          steam.push({
            reference: 'rf' + usersObject.referral_contact.ref,
            name:
              usersObject.referral_contact.first_name +
              ' ' +
              usersObject.referral_contact.last_name,
            job: 'Referral',
            avatar: leadAvatar,
          });
        }

        if (usersObject.lead != null) {
          let leadAvatar = 'https:' + usersObject.lead.avatar.url_small;
          if (leadAvatar != null) {
            if (leadAvatar == 'https:/missing/avatar.png') {
              leadAvatar =
                'https://' + environment.API_HOST + '/missing/avatar.png';
            } else {
              let url = leadAvatar.toString();
              url = url.replace('staging', 'prod');
              leadAvatar = url;
            }
          } else {
            leadAvatar =
              'https://' + environment.API_HOST + '/missing/avatar.png';
          }
          steam.push({
            reference: 'ld' + usersObject.lead.ref,
            name:
              usersObject.lead.first_name + ' ' + usersObject.lead.last_name,
            job: 'Lead',
            avatar: leadAvatar,
          });
        }

        let followers = [];
        objArray = usersObject.followers;
        oCount = Object.keys(objArray).length;
        for (let j = 0; j < oCount; j++) {
          let profilePic = objArray[j].user.avatar.url_xsmall;
          if (profilePic != null) {
            profilePic = 'https:' + objArray[j].user.avatar.url_xsmall;
            if (profilePic == 'https:/missing/avatar.png') {
              profilePic =
                'https://' + environment.API_HOST + '/missing/avatar.png';
            } else {
              let url = profilePic.toString();
              url = url.replace('staging', 'prod');
              profilePic = url;
            }
          } else {
            profilePic =
              'https://' + environment.API_HOST + '/missing/avatar.png';
          }
          followers.push({
            reference: objArray[j].user.ref,
            name:
              objArray[j].user.first_name + ' ' + objArray[j].user.last_name,
            imgSrc: profilePic,
          });
        }

        let reference = usersObject.ref;
        if (reference == null) {
          reference = 'none';
        }

        let logoPic = 'https:' + usersObject.logo.url_xsmall;
        if (logoPic != null) {
          if (logoPic == 'https:/missing/project.png') {
            logoPic =
              'https://' + environment.API_HOST + '/missing/project.png';
          } else {
            let url = logoPic.toString();
            url = url.replace('staging', 'prod');
            logoPic = url;
          }
        } else {
          logoPic = 'https://' + environment.API_HOST + '/missing/project.png';
        }

        let name = usersObject.label;
        if (name == null) {
          name = 'Unknown';
        }

        let city = usersObject.city;
        if (city == null) {
          city = 'Unknown';
        }

        let country = usersObject.country;
        if (country == null) {
          country = 'Unknown';
        }

        let countryCode = usersObject.country_code;
        if (countryCode == null) {
          countryCode = 'ZZ';
        }

        let companySpeech = usersObject.description;
        if (companySpeech == null) {
          companySpeech = '';
        }

        let teamCount = usersObject.team_count;
        if (teamCount == null) {
          teamCount = 0;
        }

        let score = usersObject.rating.average;
        if (score == null) {
          score = 0;
        }

        let money = usersObject.fundraising_amount;
        if (money == null || money == '') {
          money = '0';
        }

        let stage = usersObject.stage.name;
        if (stage == null) {
          stage = '';
        }

        let cstate = usersObject.state;
        if (cstate == null) {
          cstate = '';
        }

        let website = usersObject.website;
        if (website == null) {
          website = 'https://daphni.com';
        }

        const deckPages = adaptProjectDeckPages(usersObject);

        const deckUrl =
          usersObject.deck_url && usersObject.deck_url !== 'missing'
            ? 'https:' + usersObject.deck_url
            : null;

        let video = usersObject.video;
        /*if (video == null)
                        video = 'https://youtube.com';*/

        let videoThumbnail = usersObject.video_thumbnail;
        if (videoThumbnail != null && videoThumbnail != '') {
          videoThumbnail = usersObject.video_thumbnail;
        } else {
          videoThumbnail =
            'https://' + environment.API_HOST + '/missing/project.png';
        }

        let founded = usersObject.founded_on;
        if (founded == null) {
          founded = 'error';
        }

        let launched = usersObject.launched_on;
        if (launched == null) {
          launched = 'not available';
        }

        let isLoving = usersObject.is_loving_it;
        if (isLoving == null) {
          isLoving = false;
        }
        let loveCount = usersObject.love_count;
        if (loveCount == null) {
          loveCount = 0;
        }

        let isFollowing = usersObject.is_following;
        if (isFollowing == null) {
          isFollowing = false;
        }

        this.setState({
          hasAccessToProject: true,
          sError: false,
          reference,
          logo: logoPic,
          name,
          city,
          country,
          tags: sectors,
          team: steam,
          followers,
          countryCode,
          companySpeech,
          teamCount,
          kpis,
          score,
          money,
          stage,
          cstate,
          ...adaptProjectDroppedReason(usersObject),
          website,
          deckPages,
          deckUrl,
          video,
          videoThumbnail,
          foundedOn: founded,
          launchedOn: launched,
          isLoving,
          loveCount,
          isFollowing,
        });
      })
      .catch((error) => {
        if (error.status === 401) {
          this.setState({ hasAccessToProject: false });
          return;
        }

        console.warn('Error fetching project', { error });
        this.setState({ sError: true });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  getProjectComments = () => {
    getProjectComments(this.state.reference)
      .then(adaptProjectComments)
      .then(({ totalCount, comments }) => {
        this.setState({
          comments: totalCount,
          conversation: comments,
        });
      })
      .catch((error) => {
        console.warn('Error retrieving comments', { error });
      });
  };

  scrollToComments = () => {
    this.scrollView.scrollToEnd();
    this.showMoreOverlay(false);
  };

  likeButtonPressed = () => {
    if (!this.state.isLoving) {
      likeProject(this.state.reference)
        .then(() => {
          this.setState((state) => ({
            isLoving: true,
            loveCount: state.loveCount + 1,
          }));
        })
        .catch((error) => {
          console.warn('Error liking project', { error });
        });
    } else {
      dislikeProject(this.state.reference)
        .then(() => {
          this.setState((state) => ({
            isLoving: false,
            loveCount: state.loveCount - 1,
          }));
        })
        .catch((error) => {
          console.warn('Error disliking project', { error });
        });
    }
  };

  shareButtonPressed = () => {
    this.showMoreOverlay(false);
    this.showShareOverlay(true);
  };

  successfullChat = () => {
    this.showShareOverlay(false);
  };

  bookmarksButtonPressed = () => {
    if (!this.state.isFollowing) {
      followProject(this.state.reference)
        .then(() => {
          this.setState({ isFollowing: true });
          this.showBookmarkOverlay(true);
        })
        .catch((error) => {
          console.warn('Error following project', { error });
        });
    } else {
      unfollowProject(this.state.reference)
        .then(() => {
          this.setState({ isFollowing: false });
          this.showBookmarkOverlay(true);
        })
        .catch((error) => {
          console.warn('Error unfollowing project', { error });
        });
    }
  };

  showFollowersOverlay = () => {
    if (this.state.followers.length > 0) {
      this.setState({ teamOverlayVisible: true });
    }
  };

  showMoreOverlay = (show) => this.setState({ showMoreOverlay: show });

  showPingOverlay = (show) => this.setState({ showPingOverlay: show });

  successfullPing = () => {};

  showShareOverlay = (show) => this.setState({ showShareOverlay: show });

  showShareMessageOverlay = (show) =>
    this.setState({ showShareMessageOverlay: show });

  successfullShare = (message) => {
    this.setState({ shareMessage: message }, () => {
      this.showShareOverlay(false);
      this.showShareMessageOverlay(true);
    });
  };

  successfullRating = () => {
    this.setState({ shareMessage: 'Thank you for rating!' }, () => {
      this.showRatingOverlay(false);
      this.showShareMessageOverlay(true);
      this.getProjectComments();
      DeviceEventEmitter.emit(
        'project_rated',
        this.props.route.params?.reference
      );
    });
  };

  closeRatingModal = () => this.showRatingOverlay(false);

  successfullMessageSent = () => {
    this.setState({ shareMessage: 'Your message was sent' }, () => {
      this.showShareMessageOverlay(true);
      this.getProjectComments();
    });
  };

  showRatingOverlay = (show) => this.setState({ showRatingOverlay: show });

  showBookmarkOverlay = (show) => this.setState({ showBookmarkOverlay: show });

  addToBookmarks = () => {
    this.showMoreOverlay(false);
    this.bookmarksButtonPressed();
  };

  renderKPIs = ({ item, index }) => {
    let bg = '#f2f2f2';
    if (index % 2 == 0) {
      bg = '#e6e6e6';
    }

    return (
      <View
        style={{
          flexDirection: 'row',
          backgroundColor: bg,
          height: 20,
          alignContent: 'center',
        }}
      >
        <Text style={[styles.text, { width: '55%', marginLeft: 15 }]}>
          {item.attribute}
        </Text>
        <Text style={[styles.text, { width: '45%', marginLeft: 10 }]}>
          {item.value} {item.unit}
        </Text>
      </View>
    );
  };

  renderTeamItem = ({ item }) => {
    let reference = item.reference.substring(2, item.reference.length);

    return (
      <HouseDaphnipolitan
        bearerToken={this.state.bearerToken}
        reference={reference}
        profileSrc={item.avatar}
        name={item.name}
        job={item.job}
        navigation={this.props.navigation}
      />
    );
  };

  renderUserItem = ({ item }) => {
    return (
      <SearchDaphnipolitan
        onBeforePress={() =>
          new Promise((resolve) => {
            this.setState({ teamOverlayVisible: false }, () => {
              setTimeout(() => resolve(), 200);
            });
          })
        }
        bearerToken={this.state.bearerToken}
        reference={item.reference}
        profileSrc={item.imgSrc}
        name={item.name}
        job={item.job}
        navigation={this.props.navigation}
      />
    );
  };

  renderDisclaimer = () => {
    const onCancel = () => this.props.navigation.goBack();
    return (
      <Overlay
        isVisible={!this.state.hasAccessToProject}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '95%', height: '90%', borderRadius: 20 }}
        onBackdropPress={onCancel}
      >
        <CompanyDisclaimer
          reference={this.props.route.params.reference}
          onCancel={onCancel}
          onAgree={async () => {
            this.setState({ hasAccessToProject: true });
            await this.getCompanyData();
            await this.getProjectComments();
          }}
        />
      </Overlay>
    );
  };

  render() {
    let loading = this.state.loading;
    let commentsLoading = this.state.commentsLoading;
    let serverError = this.state.sError;
    let teamList;
    let teamOverlay;
    let conversation;

    let bText;
    let bOverlayText;

    if (serverError) {
      return (
        <Page headerColor="#00b6ba" title={'Error'}>
          <View style={styles.purpleContainer}>
            <Text style={styles.text}>
              Error loading company, please come back later
            </Text>
          </View>
        </Page>
      );
    }

    if (loading) {
      return (
        <Page
          headerColor="#00b6ba"
          backgroundColor="#7479ae"
          title="Loading..."
        >
          <ScrollView
            style={[
              styles.scrollView,
              innerViewBaseStyles.contentTopPanelMaxFix,
            ]}
            bounces={false}
          >
            <View
              style={{
                alignContent: 'center',
                alignItems: 'center',
                marginTop: 30,
              }}
            >
              <ActivityIndicator size="large" color="grey" />
            </View>
            <View style={styles.purpleContainer}>
              <ActivityIndicator size="large" color="grey" />
            </View>
            <Divider style={styles.lineStyle} />
            <ActivityIndicator size="large" color="grey" />
            <Divider style={styles.lineStyle} />
            <View style={{ alignContent: 'center', alignItems: 'center' }}>
              <Text style={styles.title}>Deck</Text>
              <ActivityIndicator size="large" color="grey" />
            </View>
            <Divider style={styles.lineStyle} />
            <Text style={[styles.title, { textAlign: 'center' }]}>Team</Text>
            <ActivityIndicator size="large" color="grey" />
            <Divider style={styles.lineStyle} />
            <View style={{ alignContent: 'center', alignItems: 'center' }}>
              <ActivityIndicator size="large" color="grey" />
            </View>
            <View style={styles.commentsContainer}>
              <Text style={styles.wTitle}>Comments</Text>
              <ActivityIndicator size="large" color="grey" />
              <NewComment />
            </View>
          </ScrollView>
        </Page>
      );
    }

    if (!commentsLoading) {
      if (this.state.conversation.length > 0) {
        if (this.state.conversation.length > 5) {
          conversation = (
            <View style={{ marginBottom: 30 }}>
              <ProjectConversation messageList={this.state.conversation} />
            </View>
          );
        } else {
          conversation = (
            <ProjectConversation messageList={this.state.conversation} />
          );
        }
      } else {
        conversation = (
          <Text style={styles.wTitle}>Be the first to comment</Text>
        );
      }
    } else {
      conversation = <ActivityIndicator color="grey" />;
    }

    let miniFollowers = this.state.followers;
    let miniFollowersList;
    if (miniFollowers.length > 0) {
      miniFollowersList = miniFollowers.map((user, reference) => (
        <Avatar
          key={reference}
          rounded
          size={18}
          source={{ uri: user.imgSrc }}
        />
      ));
    }

    let team = this.state.team;
    if (team != null && team != []) {
      teamList = (
        <FlatList
          numColumns={1}
          horizontal={true}
          data={team}
          renderItem={this.renderTeamItem}
          keyExtractor={(item) => item.reference}
        />
      );
    }

    let allFollowersList;
    let allFollowers = this.state.followers;
    if (allFollowers != null && allFollowers != []) {
      allFollowersList = (
        <FlatList
          numColumns={1}
          data={allFollowers}
          renderItem={this.renderUserItem}
          keyExtractor={(item) => item.reference}
        />
      );
    }

    teamOverlay = (
      <Overlay
        isVisible={this.state.teamOverlayVisible}
        style={styles.whiteContainer}
        overlayStyle={{ height: '40%', borderRadius: 20 }}
        onBackdropPress={() => this.setState({ teamOverlayVisible: false })}
      >
        <View>
          <ScrollView>{allFollowersList}</ScrollView>
        </View>
      </Overlay>
    );

    let pingOverlay = (
      <Overlay
        isVisible={this.state.showPingOverlay}
        overlayStyle={{ width: WIDTH - 10, height: 142, borderRadius: 20 }}
        onBackdropPress={() => this.setState({ showPingOverlay: false })}
      >
        <View>
          <PingOverlay
            reference={this.state.reference}
            bearerToken={this.state.bearerToken}
            showPingOverlay={this.showPingOverlay}
            successfullPing={this.successfullPing}
          />
        </View>
      </Overlay>
    );

    if (!this.state.isFollowing) {
      bText = 'Add to';
      bOverlayText = 'Removed from your bookmarks!';
    } else {
      bText = 'Remove from';
      bOverlayText = "It's now in your bookmarks!";
    }

    let moreOverlay = (
      <Overlay
        isVisible={this.state.showMoreOverlay}
        overlayStyle={[styles.bottomOverlay, { width: WIDTH, height: 145 }]}
        onBackdropPress={() => this.setState({ showMoreOverlay: false })}
      >
        <View>
          <TouchableOpacity onPress={() => this.shareButtonPressed()}>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 15,
                alignItems: 'center',
              }}
            >
              <Image
                style={{
                  tintColor: '#666666',
                  marginHorizontal: 15,
                  width: 20,
                  height: 20,
                }}
                source={require('../assets/icons/sharesIcon.png')}
                resizeMode="contain"
              />
              <Text style={[styles.text]}>Share deal</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.addToBookmarks()}>
            <View
              style={{
                flexDirection: 'row',
                marginTop: 15,
                alignItems: 'center',
              }}
            >
              <Image
                style={{
                  tintColor: '#666666',
                  marginHorizontal: 15,
                  width: 20,
                  height: 20,
                }}
                source={require('../assets/icons/bookmarksIcon.png')}
                resizeMode="contain"
              />
              <Text style={[styles.text]}>{bText} my bookmarks</Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => this.scrollToComments()}>
            <View
              style={{
                flexDirection: 'row',
                marginVertical: 15,
                alignItems: 'center',
              }}
            >
              <Image
                style={{
                  tintColor: '#666666',
                  marginHorizontal: 15,
                  width: 20,
                  height: 20,
                }}
                source={require('../assets/icons/notificationIcon02.png')}
                resizeMode="contain"
              />
              <Text style={[styles.text]}>Comment</Text>
            </View>
          </TouchableOpacity>
        </View>
      </Overlay>
    );

    let bookmarkOverlay = (
      <Overlay
        isVisible={this.state.showBookmarkOverlay}
        overlayStyle={[styles.topOverlay, { width: WIDTH, height: 50 }]}
        onBackdropPress={() => this.setState({ showBookmarkOverlay: false })}
      >
        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
          <Avatar
            rounded
            size={35}
            source={{
              uri: 'https://' + environment.API_HOST + '/missing/avatar.png',
            }}
          />
          <Ionicons
            name="ios-arrow-back"
            size={32}
            style={{ marginHorizontal: 15 }}
            color="#7479ae"
          />
          <Text style={[styles.text, { color: '#7479ae' }]}>
            {bOverlayText}
          </Text>
        </View>
      </Overlay>
    );

    let ratingOverlay = (
      <Overlay
        isVisible={this.state.showRatingOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          { width: WIDTH, height: HEIGHT * 0.9 },
        ]}
        onBackdropPress={() => this.setState({ showRatingOverlay: false })}
      >
        <RatingOverlay
          successfullRating={this.successfullRating}
          closeRatingModal={this.closeRatingModal}
          reference={this.state.reference}
          bearerToken={this.state.bearerToken}
          logoSrc={this.state.logo}
          name={this.state.name}
        />
      </Overlay>
    );

    let shareOverlay = (
      <Overlay
        isVisible={this.state.showShareOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          {
            width: WIDTH,
            height: Platform.OS === 'ios' ? HEIGHT * 0.6 : HEIGHT * 0.5,
          },
        ]}
        onBackdropPress={() => this.setState({ showShareOverlay: false })}
      >
        <ShareDealOverlay
          reference={this.state.reference}
          bearerToken={this.state.bearerToken}
          successfullShare={this.successfullShare}
          navigation={this.props.navigation}
          successfullChat={this.successfullShare}
        />
      </Overlay>
    );

    let sentDealOverlay = (
      <Overlay
        isVisible={this.state.showShareMessageOverlay}
        overlayStyle={{ width: WIDTH - 20, height: 60, borderRadius: 10 }}
        onBackdropPress={() =>
          this.setState({ showShareMessageOverlay: false })
        }
      >
        <View style={{ alignItems: 'center' }}>
          <Text style={[styles.text, { textAlign: 'center' }]}>
            {this.state.shareMessage}
          </Text>
        </View>
      </Overlay>
    );

    let tags = this.state.tags;
    let fTags;
    if (tags.length > 0) {
      fTags = tags.map((tag, id) => (
        <Text key={id} style={styles.wText}>
          {tag.tag}
        </Text>
      ));
    }

    let money = !this.state.money ? '0' : formatMoney(this.state.money);

    let kpiList;
    let kpis = this.state.kpis;
    if (kpis.length > 0) {
      kpiList = (
        <FlatList
          numColumns={1}
          data={kpis}
          renderItem={this.renderKPIs}
          keyExtractor={(item) => item.id}
        />
      );
    }

    let likeColor;
    if (this.state.isLoving) {
      likeColor = '#09413a';
    } else {
      likeColor = '#ffffff';
    }

    const dpButton = (
      <View style={[styles.buttonContainer]}>
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.likeButtonPressed()}
        >
          <Image
            style={{ tintColor: likeColor, width: 18, height: 18 }}
            source={require('../assets/icons/likeIcon.png')}
            resizeMode="contain"
          />
        </TouchableOpacity>
        <View style={styles.vLine} />
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.showPingOverlay(true)}
        >
          <Image
            source={require('../assets/icons/waveIcon.png')}
            resizeMode="contain"
            style={{ width: 18, height: 18 }}
          />
        </TouchableOpacity>
        <View style={styles.vLine} />
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.showMoreOverlay(true)}
        >
          <Text style={{ color: '#ffffff', fontSize: 13 }}>● ● ● </Text>
        </TouchableOpacity>
      </View>
    );

    const tfButtonpne = (
      <View style={[styles.buttonContainer]}>
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.showRatingOverlay(true)}
        >
          <Image
            source={require('../assets/icons/rateicon.png')}
            resizeMode="contain"
            style={{ width: 60, height: 18 }}
          />
        </TouchableOpacity>
        <View style={styles.vLine} />
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.scrollToComments()}
        >
          <Image
            source={require('../assets/icons/commentsicon.png')}
            resizeMode="contain"
            style={{ width: 18, height: 18 }}
          />
        </TouchableOpacity>
        <View style={styles.vLine} />
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.showMoreOverlay(true)}
        >
          <Text style={{ color: '#ffffff', fontSize: 13 }}>● ● ●</Text>
        </TouchableOpacity>
      </View>
    );

    const tfButtontd = (
      <View style={[styles.buttonContainer]}>
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.scrollToComments()}
        >
          <Image
            source={require('../assets/icons/commentsicon.png')}
            resizeMode="contain"
            style={{ width: 18, height: 18 }}
          />
        </TouchableOpacity>
        <View style={styles.vLine} />
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => ''}
        >
          <Image
            source={require('../assets/icons/bookmarksIcon.png')}
            resizeMode="contain"
            style={{ width: 18, height: 18 }}
          />
        </TouchableOpacity>
        <View style={styles.vLine} />
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.showMoreOverlay(true)}
        >
          <Image
            source={require('../assets/icons/shareicon.png')}
            resizeMode="contain"
            style={{ width: 18, height: 18 }}
          />
        </TouchableOpacity>
      </View>
    );

    let projectVideo;
    if (this.state.video == null) {
      projectVideo = null;
    } else {
      projectVideo = (
        <View style={{ alignContent: 'center', alignItems: 'center' }}>
          <Image
            onPress={() => Linking.openURL(this.state.video)}
            source={{ uri: this.state.videoThumbnail }}
            style={{
              width: Dimensions.get('window').width * 0.8,
              height: Dimensions.get('window').height * 0.25,
            }}
            resizeMode="contain"
          />
          <Ionicons
            name="ios-play-circle"
            size={64}
            style={{
              position: 'absolute',
              top: Dimensions.get('window').height * 0.062,
            }}
            color="#cdcdcd"
            onPress={() => Linking.openURL(this.state.video)}
          />
        </View>
      );
    }

    let buttonToShow;
    if (this.state.role == 'team' || this.state.role == 'family') {
      buttonToShow = tfButtonpne;
      if (this.state.cstate == 'Termsheet' || this.state.cstate == 'Dropped') {
        buttonToShow = tfButtontd;
      }
    } else {
      buttonToShow = dpButton;
    }

    return (
      <Page
        headerColor="#00b6ba"
        backgroundColor="#7479ae"
        title={this.state.name || ''}
      >
        {this.renderDisclaimer()}
        {teamOverlay}
        {pingOverlay}
        {moreOverlay}
        {bookmarkOverlay}
        {shareOverlay}
        {ratingOverlay}
        {sentDealOverlay}
        <ScrollView
          style={[styles.scrollView, innerViewBaseStyles.contentTopPanelMaxFix]}
          bounces={false}
          ref={this.setScrollView}
        >
          <View style={[styles.rowView, { marginTop: 35 }]}>
            <View style={{ width: '70%', marginLeft: 15 }}>
              {!!this.state.logo && (
                <Image
                  source={{ uri: this.state.logo }}
                  style={{
                    width: 100,
                    height: 100,
                  }}
                  resizeMode="contain"
                />
              )}
              <View style={{ marginTop: 20 }}>
                <Text style={styles.title}>{this.state.name}</Text>
                <View style={styles.rowView}>
                  <Text style={styles.text}>
                    {this.state.city}, {this.state.country}
                  </Text>
                  <Flag
                    code={this.state.countryCode}
                    style={{ height: 16, width: 16 }}
                  />
                </View>
                <View style={(styles.rowView, { marginTop: 5 })}>
                  <Text
                    style={styles.text}
                    onPress={() => Linking.openURL(this.state.website)}
                  >
                    {this.state.website}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ width: '30%', marginVertical: 3 }}>
              <View>
                <Text style={styles.text}>€{money}</Text>
                <Text style={styles.miniText}>Requested</Text>
              </View>
              <Rating value={this.state.score} style={{ marginVertical: 4 }} />
              <TouchableOpacity onPress={() => this.scrollToComments()}>
                <View
                  style={[
                    styles.rowView,
                    {
                      backgroundColor: '#7479ae',
                      height: 18,
                      width: 40,
                      borderRadius: 8,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginVertical: 3,
                    },
                  ]}
                >
                  <Image
                    style={{
                      tintColor: '#ffffff',
                      width: 10,
                      height: 10,
                    }}
                    source={require('../assets/icons/messagesIcon.png')}
                    resizeMode="contain"
                  />
                  <Text style={styles.wText}> {this.state.comments}</Text>
                </View>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => this.showFollowersOverlay()}>
                <View style={[styles.rowView, { alignItems: 'center' }]}>
                  <View
                    style={[styles.iconView, { backgroundColor: '#7479ae' }]}
                  >
                    <Image
                      source={require('../assets/icons/bookmarksIcon.png')}
                      style={{ width: 9, height: 9 }}
                      resizeMode="contain"
                    />
                  </View>
                </View>
                <View style={[styles.rowView, { alignItems: 'center' }]}>
                  {miniFollowersList}
                </View>
              </TouchableOpacity>
              <View style={[styles.rowView, { alignItems: 'center' }]}>
                <View style={[styles.iconView]}>
                  <Image
                    source={require('../assets/icons/likeIcon.png')}
                    resizeMode="contain"
                    style={{
                      tintColor: '#666666',
                      width: 15,
                      height: 15,
                    }}
                  />
                </View>
                <Text style={styles.text}>{this.state.loveCount}</Text>
              </View>
              {!!this.state.cstate && (
                <View>
                  <Text style={styles.text}>{this.state.cstate}</Text>
                  {!!this.state.droppedReason && (
                    <Text style={styles.miniText}>
                      Reason: {this.state.droppedReason}
                    </Text>
                  )}
                </View>
              )}
            </View>
          </View>
          <View style={styles.purpleContainer}>
            <View style={{ width: '50%', marginHorizontal: 15 }}>{fTags}</View>
            <View style={[styles.vLine]} />
            <View style={{ marginHorizontal: 15 }}>
              <Text style={[styles.wText]}>{this.state.stage}</Text>
              <Text style={[styles.wText]}>
                {this.state.teamCount} team members
              </Text>
            </View>
          </View>
          <View style={{ marginTop: 30, marginHorizontal: 0 }}>{kpiList}</View>
          <Divider style={styles.lineStyle} />
          <Text
            style={[
              styles.text,
              { marginHorizontal: 15, textAlign: 'justify' },
            ]}
          >
            {this.state.companySpeech}
          </Text>

          <Divider style={styles.lineStyle} />
          <View style={{ alignContent: 'center', alignItems: 'center' }}>
            <Text style={styles.title}>Deck</Text>
            <Deck
              deckPages={this.state.deckPages}
              deckUrl={this.state.deckUrl}
              navigation={this.props.navigation}
            />
          </View>

          <Divider style={styles.lineStyle} />
          <Text style={[styles.title, { textAlign: 'center' }]}>Team</Text>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {teamList}
          </ScrollView>
          <Divider style={styles.lineStyle} />
          {projectVideo}
          <View style={{ alignContent: 'center', alignItems: 'center' }}>
            <Text style={[styles.text, { marginTop: 10 }]}>
              {`Founded: ${this.state.foundedOn} Launched: ${this.state.launchedOn}`}
            </Text>
          </View>
          <View
            style={[
              styles.commentsContainer,
              { paddingBottom: this.props.keyboardHeight + 70 },
            ]}
          >
            <Text style={styles.wTitle}>Comments</Text>
            {conversation}
            <View style={{ height: 20 }} />
            <NewComment
              successfullMessageSent={this.successfullMessageSent}
              updateComments={this.getProjectComments}
              reference={this.state.reference}
              bearerToken={this.state.bearerToken}
            />
          </View>
        </ScrollView>
        {buttonToShow}
      </Page>
    );
  }
}

export default keyboardHeightListener({ ios: true, android: true })(
  CompleteCompanyScreen
);

const styles = StyleSheet.create({
  scrollView: {
    backgroundColor: 'white',
  },
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
  purpleContainer: {
    backgroundColor: '#7479ae',
    marginHorizontal: 0,
    paddingVertical: 10,
    borderRadius: 15,
    flexDirection: 'row',
    marginTop: 10,
  },
  whiteContainer: {
    marginHorizontal: 20,
    padding: 10,
    backgroundColor: '#ffffff',
    borderRadius: 20,
  },
  commentsContainer: {
    backgroundColor: '#7479ae',
    width: WIDTH,
    marginTop: 20,
    paddingTop: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    justifyContent: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#00b6ba',
    width: 240,
    zIndex: 2,
    bottom: 20,
    borderRadius: 30,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 40,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  bCtouch: {
    width: 80,
    height: 40,
    paddingHorizontal: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  topOverlay: {
    position: 'absolute',
    top: Platform.OS === 'ios' ? 0 : StatusBarHeight.height,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
  },
  bottomOverlay: {
    position: 'absolute',
    bottom: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    color: '#666666',
    fontSize: 16,
    marginBottom: 5,
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 5,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#ffffff',
    fontSize: 16,
  },
  miniText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 13,
  },
  iconView: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 13,
    width: 25,
    height: 25,
    marginVertical: 3,
  },
  rowView: {
    flexDirection: 'row',
  },
  vLine: {
    width: 1,
    height: '70%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
});
