export const fetchNotificationsCount = () => ({
  type: 'FETCH_NOTIFICATIONS_COUNT',
});

export const setNotificationsCount = (countsByType) => ({
  type: 'SET_NOTIFICATIONS_COUNT',
  payload: { countsByType },
});

export const markNotificationAsVisited = (type) => ({
  type: 'MARK_NOTIFICATION_VISITED',
  payload: { type },
});
