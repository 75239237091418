import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ImageBackground,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import { Avatar } from 'react-native-elements';
import Flag from 'react-native-round-flags';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { getUser } from '../../src/api';
import { adaptUser } from '../../src/api/User/adapter';
import SafeAreaView from 'react-native-safe-area-view';
import { openUrl } from '../../src/lib';
import { environment } from '../../src/environment';

class DaphnipolitanOverlayComponent extends React.Component {
  state = {
    loading: false,
    name: this.props.name,
    avatar: this.props.avatar,
    countryCode: this.props.countryCode,
    country: this.props.country,
    city: this.props.city,
    socials: this.props.socials,
    sectors: this.props.sectors || [],
    company: this.props.company,
    job: this.props.job,
    connected_to: this.props.connected_to,
  };

  componentDidMount() {
    if (this.props.loadAllData) {
      this.getDaphnipolitanData();
    }
  }

  getDaphnipolitanData = () => {
    this.setState({ loading: true });
    getUser(this.props.reference)
      .then((json) => adaptUser(json.data))
      .then((user) => this.setState({ ...user, loading: false }))
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  createNewChat = () => {
    if (this.props.successfullChat) {
      this.props.successfullChat();
    }

    const navigationArgs = [
      'ChatWindow',
      {
        otherUserReference: this.props.reference,
        title: this.state.name,
        avatar: this.state.avatar,
      },
    ];
    const hasRedirected = this.props.navigation.push(...navigationArgs);
    if (!hasRedirected) {
      this.props.navigation.navigate(...navigationArgs);
    }
  };

  render() {
    let sectorsTxt;
    let sectors = this.state.sectors;

    if (sectors != null && sectors.length > 0) {
      if (sectors.length > 3) {
        let temp = [sectors[0], sectors[1], sectors[2]];
        sectors = temp;
      }
      sectorsTxt = sectors.map((sector) => (
        <Text key={sector.id} style={styles.wText}>
          {sector.name}
        </Text>
      ));
    } else {
      sectorsTxt = <Text style={styles.wText}>General</Text>;
    }

    let avatar = this.state.avatar;
    if (avatar == null || avatar == '') {
      avatar = 'https://' + environment.API_HOST + '/missing/avatar.png';
    }

    let showSocials;
    let socials;
    if (this.state.socials != null) {
      socials = Object.assign(this.state.socials);
      let facebook;
      if (socials.facebook != null) {
        facebook = (
          <Ionicons
            name="logo-facebook"
            size={40}
            style={{ marginHorizontal: 15 }}
            color="#ffffff"
            onPress={() => openUrl(this.state.socials.facebook)}
          />
        );
      }

      let twitter;
      if (socials.twitter != null) {
        twitter = (
          <Ionicons
            name="logo-twitter"
            size={40}
            style={{ marginHorizontal: 15 }}
            color="#ffffff"
            onPress={() => openUrl(this.state.socials.twitter)}
          />
        );
      }

      let linkedin;
      if (socials.linkedin != null) {
        linkedin = (
          <Ionicons
            name="logo-linkedin"
            size={40}
            style={{ marginHorizontal: 15 }}
            color="#ffffff"
            onPress={() => openUrl(this.state.socials.linkedin)}
          />
        );
      }

      showSocials = (
        <View style={styles.socialMedia}>
          {linkedin}
          {facebook}
          {twitter}
        </View>
      );
    }

    let connected_cities;
    if (this.state.connected_to != null && this.state.connected_to != '') {
      connected_cities = (
        <View>
          <Text style={styles.wText}>
            {'Connected cities:'} {'\n'} {this.state.connected_to}{' '}
          </Text>
        </View>
      );
    }

    let userData;
    if (this.state.loading) {
      userData = (
        <ActivityIndicator size="large" color="grey" style={{ margin: 30 }} />
      );
    } else {
      userData = (
        <View>
          {showSocials}
          <View style={{ marginHorizontal: 20 }}>
            <Text style={styles.wTitle}>{this.state.job}</Text>
            <Text style={styles.wTitle}>{this.state.company}</Text>
            {sectorsTxt}
            <View style={styles.pairedData}>
              <Flag code={this.state.countryCode} style={styles.flag} />
              <Text style={styles.wText}>
                {' '}
                {this.state.city}, {this.state.country}
              </Text>
            </View>
            {connected_cities}
          </View>
        </View>
      );
    }

    return (
      <View>
        <View style={styles.avatar}>
          <Avatar
            rounded
            size={100}
            source={{ uri: avatar }}
            borderWidth={8}
            borderColor="#ffffff"
          />
        </View>
        <ImageBackground
          source={require('../../assets/profileBackground.png')}
          style={{
            width: '100%',
            height: '100%',
          }}
          imageStyle={{
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
          }}
        >
          <Text style={[styles.wTitle, { marginTop: 70, textAlign: 'center' }]}>
            {this.state.name}
          </Text>
          {userData}
          <SafeAreaView>
            <TouchableOpacity
              style={styles.sendButton}
              onPress={this.createNewChat}
            >
              <Text style={styles.sendText}>Chat</Text>
            </TouchableOpacity>
          </SafeAreaView>
        </ImageBackground>
      </View>
    );
  }
}

export default DaphnipolitanOverlayComponent;

const styles = StyleSheet.create({
  socialMedia: {
    width: '80%',
    alignSelf: 'center',
    margin: 10,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  sendButton: {
    backgroundColor: '#fff',
    flexDirection: 'row',
    marginVertical: 20,
    borderRadius: 15,
    height: 30,
    width: 100,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pairedData: {
    marginVertical: 20,
    alignItems: 'center',
    flexDirection: 'row',
  },
  avatar: {
    position: 'absolute',
    zIndex: 3,
    top: -50,
    alignSelf: 'center',
  },
  flag: {
    width: 32,
    height: 32,
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#ffffff',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
  },
  sendText: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#45a9ed',
  },
});
