import React from 'react';
import { StyleSheet, Text, View, ActivityIndicator } from 'react-native';
import DaphniNews from '../../../../components/DaphniNews';
import { getHomeNews } from '../../../api';
import { adaptNews } from '../../../api/News/adapter';
import { Card } from './Card';
import { Divider } from 'react-native-elements';
import { Button } from '../../../components';

class HomeNews extends React.Component {
  state = {
    loading: true,
    homeNews: [],
  };

  async componentDidMount() {
    this.getHomeNews();
  }

  componentDidUpdate(prevProps) {
    if (this.props.rerender !== prevProps.rerender) {
      this.setState({ loading: true });
      this.getHomeNews();
    }
  }

  getHomeNews = (isFeatured = true) => {
    getHomeNews(isFeatured)
      .then(adaptNews)
      .then((homeNews) => {
        if (isFeatured && !homeNews.length) {
          return this.getHomeNews(false);
        }

        this.setState({ loading: false, homeNews });
      })
      .catch((error) => {
        this.setState({ loading: false });
        console.warn('HomeNews.getHomeNews', { error });
      });
  };

  renderContent = () => {
    if (this.state.loading) {
      return (
        <View style={styles.loadingContainer}>
          <ActivityIndicator size="large" color="grey" />
        </View>
      );
    }

    const { homeNews: news } = this.state;

    if (!news.length) {
      return (
        <View style={styles.loadingContainer}>
          <Text style={styles.text}>There are no news</Text>
        </View>
      );
    }

    const [firstNews, secondNews] = news;

    return (
      <View>
        {!!firstNews && (
          <>
            <DaphniNews
              picSrc={firstNews.picSrc}
              title={firstNews.title}
              description={firstNews.description}
              date={firstNews.date}
              source={firstNews.source}
              url={firstNews.url}
            />
            {!!secondNews && (
              <>
                <Divider style={styles.lineStyle} />
                <DaphniNews
                  picSrc={secondNews.picSrc}
                  title={secondNews.title}
                  description={secondNews.description}
                  date={secondNews.date}
                  source={secondNews.source}
                  url={secondNews.url}
                />
              </>
            )}
          </>
        )}
        <Button
          onPress={() => this.props.navigation.navigate('AllNews')}
          style={{ alignSelf: 'center', paddingHorizontal: 20, marginTop: 20 }}
          title="See all news"
          mode="outline-primary"
        />
      </View>
    );
  };

  render() {
    return (
      <Card style={{ paddingLeft: 16 }}>
        <Text style={styles.title}>News</Text>
        {this.renderContent()}
      </Card>
    );
  }
}

export default HomeNews;

const styles = StyleSheet.create({
  loadingContainer: {
    backgroundColor: '#ffffff',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginBottom: 20,
    textAlign: 'center',
    color: '#666666',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    textAlign: 'center',
    color: '#666666',
  },
  lineStyle: {
    marginVertical: 10,
  },
});
