import { Linking, Platform } from 'react-native';
import { Alert } from './Alert';

export const askToOpenUrl = async (url) => {
  if (Platform.OS === 'web') {
    return openUrl(url);
  }

  if (!url) {
    console.warn('Empty url');
    return;
  }

  const { response } = await Alert.confirm(
    'Do you want to open this link?',
    url,
    {
      positiveText: 'OK',
      negativeText: 'Cancel',
    },
    true
  );

  if (response !== 'yes') {
    return;
  }

  return openUrl(url);
};

export const openUrl = async (url) => {
  try {
    const supported = await Linking.canOpenURL(url);
    if (!supported) {
      return Alert.alert('Sorry! The app cannot open this link', url);
    }

    await Linking.openURL(url);
  } catch (error) {
    console.warn('Cannot check if url can be opened', { error, url });
    return Alert.alert('Sorry! An error occurred when opening the link', url);
  }
};
