import { tabletOrLargerMinPixel } from '../../../lib/responsive';

const ASPECT_RATIO = 750 / 250;

export const getBannerSize = (dimensions) => {
  const width =
    dimensions.width > tabletOrLargerMinPixel
      ? (2 * dimensions.width) / 7
      : dimensions.width - 10;

  return {
    height: width / ASPECT_RATIO,
    width: width,
    aspectRatio: ASPECT_RATIO,
  };
};
