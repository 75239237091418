import { adaptSmallUser } from '../User/adapter';
import { getPotentiallyMissingProjectUrl, getUrlWithScheme } from '../base';

const adaptProjectComment = (comment) => ({
  id: comment.id,
  user: adaptSmallUser(comment.user),
  comment: comment.content,
  private: comment.is_private ? 'yes' : 'no',
  date: comment.created_at.substring(0, 10),
});

export const adaptProjectComments = ({ data: apiComments, meta }) => {
  const totalCount = (meta && meta.total_count) || 0;

  return {
    totalCount,
    comments: apiComments.map(adaptProjectComment),
  };
};

const adaptProjectSectors = (project) => {
  if (!project.sectors) {
    return [];
  }

  return project.sectors.map((sector) => ({
    id: sector.id,
    tag: sector.name,
  }));
};

const adaptProjectLogo = (project) => ({
  ...project.logo,
  url: getPotentiallyMissingProjectUrl(project.url),
  url_xsmall: getPotentiallyMissingProjectUrl(project.url_xsmall),
  url_small: getPotentiallyMissingProjectUrl(project.url_small),
  url_medium: getPotentiallyMissingProjectUrl(project.url_medium),
  url_large: getPotentiallyMissingProjectUrl(project.url_large),
});

export const adaptProjectDroppedReason = (project) => {
  if (!project.drop_reason) {
    return {};
  }

  return {
    droppedReason: project.drop_reason.replace(
      'AUTO',
      "Didn't pass our Monday committee"
    ),
  };
};

export const adaptProject = (project) => ({
  ...adaptProjectPreview(project),
  ...adaptProjectDroppedReason(project),
  country: project.country,
  countryCode: project.country_code,
  funds: project.funds,
  rating: project.rating,
  sectors: adaptProjectSectors(project),
  fundraisingAmount: project.fundraising_amount,
  commentCount: project.comment_count,
  createdAt: project.created_at,
  daphniInvestedAmount: project.daphni_invested_amount,
  businessActivity: project.business_activity,
});

export const adaptProjects = ({ data: apiProjects }) =>
  apiProjects.map(adaptProject);

export const adaptProjectPreview = (projectPreview) => ({
  reference: projectPreview.ref,
  name: projectPreview.label,
  description: projectPreview.description,
  logo: adaptProjectLogo(projectPreview.logo),
});

export const adaptProjectDeckPages = (project) => {
  return project.deck.map((deckPage, index) => ({
    id: index,
    smallURL: getUrlWithScheme(deckPage.url_large),
    largeURL: getUrlWithScheme(deckPage.url_xlarge),
  }));
};
