import { compose } from 'recompose';
import { connect } from 'react-redux';
import { GroupActionsComponent } from './GroupActions.component';
import { isUserAdminOfDiscussion } from '../../../../src/redux/Discussion';
import { withNavigation } from '../../../../src/navigation/withNavigation';

const mapStateToProps = (state, ownProps) => ({
  isUserAdmin: isUserAdminOfDiscussion(ownProps.discussionReference)(state),
});

const enhance = compose(withNavigation, connect(mapStateToProps));

export const ConnectedGroupActions = enhance(GroupActionsComponent);
