import 'react-native-gesture-handler';
import { AppRegistry, Platform } from 'react-native';
import App from './App';

AppRegistry.registerComponent('Daphnipolis', () => App);

if (Platform.OS === 'web') {
  AppRegistry.runApplication('Daphnipolis', {
    rootTag: document.getElementById('root'),
  });

  const style = document.createElement('style');
  style.textContent = 'textarea, input { outline: none !important; }';
  document.head.append(style);
}
