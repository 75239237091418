import { useCallback, useEffect, useState } from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';
import { getProjects } from '../../../api';
import { adaptProjects } from '../../../api/Project/adapter';

export const useTopPanelProjects = ({ fetchQueryParams, skip }) => {
  const [projects, setProjects] = useState([]);

  const fetchProjects = useDeepCompareCallback(() => {
    if (skip) {
      return;
    }

    return getProjects(fetchQueryParams)
      .then(adaptProjects)
      .then((loadedProjects) => {
        setProjects(loadedProjects);
      })
      .catch((error) => {
        console.warn('Error when loading top panel projects', {
          error,
          fetchQueryParams,
        });
      });
  }, [fetchQueryParams, skip]);

  useEffect(() => {
    if (skip) {
      return;
    }

    fetchProjects();
  }, [skip, fetchProjects]);

  const setProject = useCallback((reference, newProject) => {
    setProjects((updatedProjects) =>
      updatedProjects
        .map((updatedProject) =>
          updatedProject.reference === reference ? newProject : updatedProject
        )
        .filter(Boolean)
    );
  }, []);

  return {
    projects,
    setProject,
    fetchProjects,
  };
};
