import React from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { ParsedText } from '../../src/components';
import BaseDaphnipolitan from '../Daphnipolitan/BaseDaphnipolitan';

const TimelineComment = (props) => {
  return (
    <View>
      <View style={styles.topContainer}>
        <BaseDaphnipolitan
          name={props.user.name}
          reference={props.user.reference}
          profileSrc={props.user.avatar}
          profileImageStyle={styles.profileImage}
          avatarSize={35}
        />
      </View>
      <View style={styles.whiteContainer}>
        <ParsedText style={styles.text}>{props.comment}</ParsedText>
        <View style={{ flexDirection: 'row', marginTop: 5 }}>
          <Text style={styles.text}>{props.date}</Text>
        </View>
      </View>
    </View>
  );
};

export default TimelineComment;

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: -10,
  },
  whiteContainer: {
    marginTop: 26,
    marginLeft: 20,
    marginRight: 10,
    padding: 10,
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  profileImage: {
    marginRight: 10,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginHorizontal: 10,
  },
});
