/* eslint-disable import/no-unused-modules */

export const Batch = {
  start: () => {},
};

export const BatchPush = {
  registerForRemoteNotifications: () => {},
  clearBadge: () => {},
};

export const BatchUser = {
  editor: () => ({
    setIdentifier: () => ({
      save: () => {},
    }),
  }),
};

export const BatchInbox = {
  getFetcher: () => ({
    fetchNewNotifications: () => {
      throw new Error('Not yet implemented');
    },
    destroy: () => {},
  }),
};
