export const startLoading = (key) => ({
  type: 'LOADING_START',
  payload: { key },
});

export const finishLoading = (key) => ({
  type: 'LOADING_END',
  payload: { key },
});

export const setError = (key, error) => ({
  type: 'LOADING_ERROR',
  payload: { key, error },
});
