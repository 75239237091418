import React from 'react';
import Orientation from 'react-native-orientation-locker';

export const allowLandscape = (forceLandscape = false) => (Component) =>
  class AllowLandscape extends React.Component {
    componentDidMount() {
      if (forceLandscape) {
        Orientation.lockToLandscape();
      } else {
        Orientation.unlockAllOrientations();
      }
    }

    componentWillUnmount() {
      Orientation.lockToPortrait();
    }

    render() {
      return <Component {...this.props} />;
    }
  };
