export const navigationMenuRoutes = [
  {
    name: 'Home',
    displayName: 'Home',
    color: '#7e0d86',
    source: require('../../assets/icons/menuHomeIcon.png'),
    inactiveSize: 40,
  },
  {
    name: 'DaFlowNew',
    displayName: 'Da Flow',
    color: '#7479ae',
    source: require('../../assets/icons/menuFlowIcon.png'),
    activeSize: 30,
  },
  {
    name: 'DaHouse',
    displayName: 'Da House',
    color: '#00b6ba',
    source: require('../../assets/icons/menuHouseIcon.png'),
  },
  {
    name: 'Timeline',
    displayName: 'Timeline',
    color: '#45a9ed',
    source: require('../../assets/icons/menuDaphnipolisIcon.png'),
  },
];
