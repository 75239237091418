import React, { useCallback } from 'react';
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  Image,
} from 'react-native';
import { getBannerSize } from './BannerSize';

const BannerCoinvest = (props) => {
  const dimensions = useWindowDimensions();
  const onPress = useCallback(() => {
    props.navigation.navigate('CoInvest01', { id: props.id });
  }, [props.navigation, props.id]);

  const bannerSize = getBannerSize(dimensions);
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={styles.container}
      onPress={onPress}
    >
      <View style={[styles.contentContainer, bannerSize]}>
        <Image
          source={{ uri: props.imgSrc }}
          style={{
            height: bannerSize.height / 3,
            width: (bannerSize.height / 3) * bannerSize.aspectRatio,
          }}
          resizeMode="contain"
        />
        {props.heading && (
          <Text style={[styles.text, styles.heading]}>{props.heading}</Text>
        )}
        <Text style={styles.text}>{'Co-Invest Opportunity'}</Text>
      </View>
    </TouchableOpacity>
  );
};

export default BannerCoinvest;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentContainer: {
    backgroundColor: 'white',
    borderRadius: 20,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    marginTop: 10,
  },
  text: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 18,
    color: '#666666',
    textAlign: 'center',
  },
});
