import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import { theme } from '../../../lib';
import CheckIcon from '../../../../assets/icons/check.svg';
import { useResponsive } from '../../../lib/responsive';

export const ActiveFundsFilter = ({
  funds,
  activeFundsMap,
  setActiveFundsMap,
}) => {
  const { isDesktopOrLarger } = useResponsive();

  return funds.map((fund) => {
    const isActive = activeFundsMap[fund.id];

    const fundColors =
      theme.colors.funds[fund.label] || theme.colors.funds.Default;

    return (
      <TouchableOpacity
        key={fund.id}
        onPress={() => {
          setActiveFundsMap((current) => ({
            ...current,
            [fund.id]: !current[fund.id],
          }));
        }}
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginRight: theme.margin.unit * 2,
        }}
        activeOpacity={0.8}
      >
        <View
          style={[
            {
              width: 30,
              height: 30,
              borderRadius: 15,
              borderColor: isDesktopOrLarger ? fundColors.primary : 'white',
              borderWidth: 0.5,
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: theme.margin.unit / 2,
              backgroundColor: fundColors.primary,
            },
          ]}
        >
          {isActive && <CheckIcon />}
        </View>
        <Text
          style={{
            ...theme.fonts.normal,
            fontSize: 18,
            ...(!isDesktopOrLarger && { color: 'white' }),
          }}
        >
          {fund.label} Fund
        </Text>
      </TouchableOpacity>
    );
  });
};
