import React from 'react';
import { useNavigation } from '@react-navigation/native';
import { StyleSheet, TextInput, View } from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';

export const SearchBar = (props) => {
  const navigation = useNavigation();
  return (
    <View style={[styles.container, props.containerStyle]}>
      <Ionicons
        name="ios-search"
        style={styles.lookIcon}
        size={20}
        color={'#666'}
      />
      <TextInput
        onChangeText={props.onChangeText}
        onSubmitEditing={props.onSubmitEditing}
        placeholderTextColor={'#666'}
        returnKeyType="search"
        style={[styles.input, props.style]}
      />
      <Ionicons
        name="ios-close-circle"
        style={styles.closeIcon}
        size={24}
        color="#666"
        onPress={navigation.goBack}
      />
    </View>
  );
};

const BAR_HEIGHT = 30;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    height: BAR_HEIGHT,
    borderRadius: BAR_HEIGHT / 2,
    backgroundColor: '#eaeaea',
    alignItems: 'center',
  },
  input: {
    flex: 1,
    height: '100%',
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 15,
    color: '#666',
  },
  lookIcon: {
    marginHorizontal: 10,
  },
  closeIcon: {
    marginHorizontal: 10,
  },
});
