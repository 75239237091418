import React, { useState } from 'react';
import { Button } from './Button';
import { Text, View } from 'react-native';
import { environment } from '../environment';

export const ChangeEnvironmentButton = () => {
  const allEnvs = environment.allEnvs;
  const [currentEnvIndex, setCurrentEnvIndex] = useState(
    allEnvs.findIndex(
      (envPredicate) => envPredicate.API_HOST === environment.API_HOST
    )
  );
  const currentEnv = allEnvs[currentEnvIndex];
  const nextEnvIndex =
    currentEnvIndex === allEnvs.length - 1 ? 0 : currentEnvIndex + 1;
  const nextEnv = allEnvs[nextEnvIndex];
  if (!currentEnv || !nextEnv) {
    return null;
  }

  return (
    <View style={{ alignItems: 'center', margin: 10 }}>
      <Button
        title={`Switch to ${nextEnv.name}`}
        style={{ padding: 10, marginBottom: 5 }}
        onPress={() => {
          environment.API_HOST = nextEnv.API_HOST;
          setCurrentEnvIndex(nextEnvIndex);
        }}
      />
      <Text style={{ color: 'white' }}>
        Current is {currentEnv.name} ({currentEnv.API_HOST})
      </Text>
    </View>
  );
};
