import React from 'react';
import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import { localstore } from '../helpers/LocalStore';
import AllEvents from '../components/AllEvents/AllEvents';
import { Page } from '../src/components';
import { environment } from '../src/environment';

class EventsScreen extends React.Component {
  state = {
    bearerToken: '',
    currentPage: 1,
    lastPage: -1,
    loading: true,
    allEvents: [],
  };

  async componentDidMount() {
    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result }, () => {
          this.getAllEvents();
        });
      } else {
        console.log('Error retrieving token');
        this.setState({ loading: false });
      }
    });
  }

  getAllEvents = () => {
    const bearerToken = this.state.bearerToken;
    let currentPage = this.state.currentPage;
    let lastPage = this.state.lastPage;

    if (currentPage != lastPage) {
      fetch(
        `https://${environment.API_HOST}/v4/events?page_size=10&page_number=` +
          currentPage,
        {
          // mode: 'no-cors',
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + bearerToken,
          },
        }
      ).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            let metaObject = json.pagination;
            if (metaObject != null) {
              lastPage = metaObject.page_count;
              if (metaObject.has_more_items) {
                currentPage++;
              }
            }

            let eventsObject = json.events;
            var count = Object.keys(eventsObject).length;
            let aEvents = [];

            if (count > 0) {
              for (let i = 0; i < count; i++) {
                aEvents.push({
                  id: eventsObject[i].id,
                  name: eventsObject[i].name.text,
                  description: eventsObject[i].description.text,
                  summary: eventsObject[i].summary,
                  date: eventsObject[i].start.local,
                  url: eventsObject[i].url,
                  imageSrc: eventsObject[i].logo.url,
                  address:
                    eventsObject[i].venue && eventsObject[i].venue.address
                      ? eventsObject[i].venue.address.localized_address_display
                      : null,
                });
              }
              this.setState(
                {
                  allEvents: [...this.state.allEvents, ...aEvents],
                  currentPage,
                  lastPage,
                },
                () => {
                  this.setState({ loading: false, fetchingFromServer: false });
                }
              );
            } else {
              this.setState({ loading: false, fetchingFromServer: false });
            }
          });
        } else {
          console.log('Error retrieving data');
          this.setState({ loading: false, fetchingFromServer: false });
        }
      });
    } else {
      this.setState({ loading: false, fetchingFromServer: false });
    }
  };

  renderContent = () => {
    if (!this.state.allEvents.length) {
      return (
        <View style={styles.loadingContainer}>
          {this.state.loading ? (
            <ActivityIndicator size="large" color="#f55074" />
          ) : (
            <Text>No news</Text>
          )}
        </View>
      );
    }

    return (
      <AllEvents
        allEvents={this.state.allEvents}
        navigation={this.props.navigation}
      />
    );
  };

  render() {
    return (
      <Page headerColor="#f55074" title="Events">
        {this.renderContent()}
      </Page>
    );
  }
}

export default EventsScreen;

const styles = StyleSheet.create({
  loadingContainer: {
    marginTop: 50,
    backgroundColor: '#ffffff',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 20,
  },
});
