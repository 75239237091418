import { useNavigation } from '@react-navigation/native';
import { useResponsive } from '../../../lib/responsive';
import { useCallback, useEffect, useState } from 'react';
import { availableSectors } from '../availableSectors';
import { useTopPanelProjects } from './useTopPanelProjects';
import { useBottomPanelProjects } from './useBottomPanelProjects';
import { DeviceEventEmitter } from 'react-native';
import { getProject } from '../../../api';
import { adaptProject } from '../../../api/Project/adapter';

export const useDaflowGenericPage = ({ topPanel, bottomPanel, daFlowType }) => {
  const navigation = useNavigation();
  const { isDesktopOrLarger } = useResponsive();

  const [activeSectorsIds, setActiveSectorsIds] = useState(() =>
    availableSectors.map((sector) => sector.id)
  );
  const [projectCountPerColumns, setProjectCountPerColumns] = useState(
    isDesktopOrLarger ? 3 : 2
  );
  const [isFilteringEnabled, setIsFilteringEnabled] = useState(false);

  const {
    projects: topPanelProjects,
    fetchProjects: fetchTopPanelProjects,
    setProject: setTopPanelProject,
  } = useTopPanelProjects({
    fetchQueryParams: topPanel ? topPanel.fetchProjectsParams : null,
    skip: !topPanel,
  });

  const [isRefreshing, setIsRefreshing] = useState(false);
  const {
    isLoading,
    canFetchMore,
    allProjects,
    fetchMore,
    resetProjectsRef,
    setProject: setBottomPanelProject,
  } = useBottomPanelProjects({
    fetchQueryParams: bottomPanel.fetchProjectsParams,
    isRefreshing,
    isFilteringEnabled,
    activeSectorsIds,
  });

  const onRefresh = useCallback(() => {
    setIsRefreshing(true);
    resetProjectsRef.current();
    return Promise.all([fetchMore(0), fetchTopPanelProjects()]).finally(() => {
      setIsRefreshing(false);
    });
  }, [fetchMore, fetchTopPanelProjects, resetProjectsRef]);

  useEffect(() => {
    const subscription = DeviceEventEmitter.addListener(
      'project_rated',
      (projectReference) => {
        const existingProject =
          allProjects.find(
            (project) => project.reference === projectReference
          ) ??
          topPanelProjects.find(
            (project) => project.reference === projectReference
          );

        if (!existingProject) {
          return;
        }

        if (
          daFlowType === 'pending' &&
          existingProject.rating.average === null
        ) {
          setTopPanelProject(projectReference, null);
          setBottomPanelProject(projectReference, null);
          return;
        }

        getProject(projectReference)
          .then(({ data }) => adaptProject(data))
          .then((newProject) => {
            setTopPanelProject(projectReference, newProject);
            setBottomPanelProject(projectReference, newProject);
          })
          .catch((error) => {
            console.warn(
              'Error when refreshing project',
              projectReference,
              error
            );
          });
      }
    );
    return () => {
      subscription.remove();
    };
  }, [
    allProjects,
    daFlowType,
    onRefresh,
    setBottomPanelProject,
    setTopPanelProject,
    topPanelProjects,
  ]);

  return {
    navigation,
    isDesktopOrLarger,
    isRefreshing,
    onRefresh,
    isLoading,
    canFetchMore,
    allProjects,
    fetchMore,
    topPanelProjects,
    activeSectorsIds,
    setActiveSectorsIds,
    isFilteringEnabled,
    setIsFilteringEnabled,
    projectCountPerColumns,
    setProjectCountPerColumns,
  };
};
