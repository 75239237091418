import { compose } from 'recompose';
import { connect } from 'react-redux';
import { LeaveGroupButtonComponent } from './LeaveGroupButton.component';
import { leaveDiscussion } from '../../../../src/redux/Discussion';
import { isLoadingSelector } from '../../../../src/redux/LoadingStatus';
import { withNavigation } from '../../../../src/navigation/withNavigation';

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector('leaveDiscussion')(state),
});

const mapDispatchToProps = {
  leaveDiscussion,
};

const enhance = compose(
  withNavigation,
  connect(mapStateToProps, mapDispatchToProps)
);

export const ConnectedLeaveGroupButton = enhance(LeaveGroupButtonComponent);
