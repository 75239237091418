import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  Image,
  ActivityIndicator,
} from 'react-native';
import Flag from 'react-native-round-flags';
import { Divider } from 'react-native-elements';
import { theme } from '../../src/lib';

const renderFunds = ({ item }) => {
  const fundColors =
    theme.colors.funds[item.funds] || theme.colors.funds.Default;

  return (
    <View
      style={[
        styles.MyInvestmentsFunds,
        { backgroundColor: fundColors.primary },
      ]}
    >
      <Text
        style={[
          styles.MyInvestmentsFundsText,
          {
            fontFamily: 'Freight-Sans-Black',
            color: fundColors.textOnPrimary,
            marginLeft: 20,
          },
        ]}
      >
        €{item.money}
      </Text>
      <Text
        style={[
          styles.MyInvestmentsFundsText,
          { fontFamily: 'Freight-Sans-Black', color: fundColors.textOnPrimary },
        ]}
      >
        {item.funds}
      </Text>
      <Text
        style={[
          styles.MyInvestmentsFundsText,
          {
            fontFamily: 'Freight-Sans-Black',
            color: fundColors.textOnPrimary,
            marginRight: 20,
          },
        ]}
      >
        {item.investDate}
      </Text>
    </View>
  );
};

const renderCompany = ({ item }) => {
  const fundColors =
    theme.colors.funds[item.fund] || theme.colors.funds.Default;

  if (item.state == 'pending') {
    item.investDate = 'pending';
  }

  if (item.state == 'Unvalidated') {
    item.investDate = 'unvalidated';
  }

  return (
    <View style={styles.MyInvestmentsCompany}>
      <View style={styles.MyInvestmentsCompanyIconType}>
        <Image
          source={{ uri: item.logoSrc }}
          resizeMode="contain"
          style={{ width: 100, height: 100 }}
        />
      </View>

      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
        }}
      >
        <Text
          style={[
            styles.MyInvestmentsCompanyName,
            { fontFamily: 'Freight-Sans-Black' },
          ]}
        >
          {item.companyName}
        </Text>
        <Flag code={item.countryCode} style={styles.MyInvestmentsflag} />
      </View>

      <View style={styles.MyInvestmentsCompanyNumbers}>
        <View>
          <Text
            style={[
              styles.MyInvestmentsCompanyNumberText,
              { fontFamily: 'Freight-Sans-Medium' },
            ]}
          >
            €{item.money}
          </Text>
          <Text
            style={[
              {
                fontSize: 13,
                marginHorizontal: 10,
                color: '#666666',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                fontFamily: 'Freight-Sans-Medium',
              },
            ]}
          >
            My invest
          </Text>
        </View>
        <View>
          <Text
            style={[
              styles.MyInvestmentsCompanyNumberText,
              { fontFamily: 'Freight-Sans-Medium' },
            ]}
          >
            {item.investDate}
          </Text>
          <Text
            style={[
              {
                fontSize: 13,
                marginHorizontal: 10,
                color: '#666666',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                fontFamily: 'Freight-Sans-Medium',
              },
            ]}
          >
            My invest date
          </Text>
        </View>
        <View
          style={[
            styles.MyInvestmentsCompanytypeContainer,
            { backgroundColor: fundColors.primary },
          ]}
        >
          <Text
            style={[
              styles.MyInvestmentsCompanytypeText,
              { color: fundColors.textOnPrimary },
            ]}
          >
            {item.funds}
          </Text>
        </View>
      </View>
      <Divider style={styles.MyInvestmentslineStyle} />
    </View>
  );
};

const MyInvestments = (props) => {
  let notdisplay = {};
  if (props.investments.length == 0) {
    notdisplay = {
      display: 'none',
    };
  } else {
    notdisplay = {};
  }

  return (
    <ScrollView>
      <View style={styles.MyInvestmentsScrollFix}>
        <Text
          style={[
            styles.MyInvestmentsTitle,
            notdisplay,
            { fontFamily: 'Freight-Sans-Black' },
          ]}
        >
          Funds
        </Text>
        <FlatList
          numColumns={1}
          data={props.funds}
          renderItem={renderFunds}
          keyExtractor={(item) => item.id}
        />

        <Text
          style={[
            styles.MyInvestmentsTitle,
            notdisplay,
            { fontFamily: 'Freight-Sans-Black' },
          ]}
        >
          Co-Investment
        </Text>

        <Divider style={[styles.MyInvestmentslineStyle, notdisplay]} />
        <FlatList
          numColumns={1}
          data={props.investments}
          renderItem={renderCompany}
          keyExtractor={(item) => item.id}
        />
        {props.isLoading && <ActivityIndicator color="#ff9233" />}
        {!props.isLoading && props.investments.length === 0 ? (
          <Text
            style={[
              styles.MyInvestmentsTitle,
              { fontFamily: 'Freight-Sans-Black' },
            ]}
          >
            You haven't co-invested
          </Text>
        ) : null}
      </View>
    </ScrollView>
  );
};

export default MyInvestments;

const styles = StyleSheet.create({
  MyInvestmentsTitle: {
    marginTop: 20,
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
  MyInvestmentslineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
  MyInvestmentsCompanyName: {
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
  MyInvestmentsFunds: {
    margin: 10,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 20,
  },
  MyInvestmentsFundsText: {
    margin: 10,
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
  },
  MyInvestmentsflag: {
    marginLeft: 3,
    width: 16,
    height: 16,
  },
  MyInvestmentsCompany: {
    marginVertical: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
  MyInvestmentsCompanyNumbers: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  MyInvestmentsCompanyNumberText: {
    margin: 10,
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
  MyInvestmentsCompanytypeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    width: 50,
    borderRadius: 10,
  },
  MyInvestmentsCompanytypeText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 13,
    color: '#666666',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  MyInvestmentsCompanyIconType: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  MyInvestmentsScrollFix: {
    backgroundColor: 'white',
    marginTop: 60,
    marginBottom: 20,
  },
});
