import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  Image,
} from 'react-native';
import { Batch } from '@bam.tech/react-native-batch';
import { Divider } from 'react-native-elements';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Profile } from './components';
import { localstore } from '../../helpers/LocalStore';
import { AppVersion } from '../../src/components/AppVersion';

class MenuContentComponent extends React.Component {
  componentDidMount() {
    if (!this.props.me) {
      // handle case where user comes from deep link
      this.props.fetchMe({
        onFailure: () => this.logout(),
      });
    }
  }

  navLink(nav, text, icon, bgColor, onLongPress) {
    return (
      <TouchableOpacity
        style={{ height: 40 }}
        onPress={() => this.props.navigation.navigate(nav)}
        onLongPress={onLongPress}
      >
        <View style={styles.pairedData}>
          <View style={[styles.iconView, { backgroundColor: bgColor }]}>
            <Image
              source={icon}
              resizeMode="contain"
              style={{ width: 13, height: 13 }}
            />
          </View>
          <Text style={styles.link}>{text}</Text>
        </View>
      </TouchableOpacity>
    );
  }

  logout() {
    this.props.logout();
    localstore.deleteAll();
  }

  render() {
    const { me } = this.props;
    const showable =
      me && (me.role === 'team' || me.role === 'family')
        ? { display: 'none' }
        : {};

    return (
      <View style={styles.container}>
        {!!me && <Profile me={this.props.me} />}
        <View style={styles.bottomContainer}>
          {this.navLink(
            'AllNotifications',
            'My Notifications',
            require('../../assets/icons/sharesIcon.png'),
            '#ffcf56',
            () => Batch.showDebugView()
          )}
          {this.navLink(
            'Investments',
            'My Investments',
            require('../../assets/icons/investmentsIcon.png'),
            '#ff9233'
          )}
          <View style={showable}>
            {this.navLink(
              'Pings',
              'My Pings',
              require('../../assets/icons/waveIcon.png'),
              '#7479ae'
            )}
            {this.navLink(
              'Bookmarks',
              'My Bookmarks',
              require('../../assets/icons/bookmarksIcon.png'),
              '#2ba596'
            )}
            {this.navLink(
              'MyLikes',
              'My Likes',
              require('../../assets/icons/likeIcon.png'),
              '#45a9ed'
            )}
          </View>

          <TouchableOpacity
            style={{ height: 100 }}
            onPress={() => this.logout()}
          >
            <Divider style={styles.lineStyle} />
            <View style={styles.pairedData}>
              <View style={[styles.iconView, { backgroundColor: '#666666' }]}>
                <Ionicons name="ios-power" size={15} color="#ffffff" />
              </View>
              <Text style={styles.link}>Logout</Text>
            </View>
          </TouchableOpacity>
        </View>
        <SafeAreaView>
          <AppVersion />
        </SafeAreaView>
      </View>
    );
  }
}

export default MenuContentComponent;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  bottomContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
    paddingTop: 10,
  },
  pairedData: {
    marginVertical: 5,
    marginHorizontal: 20,
    flexDirection: 'row',
  },
  link: {
    flex: 1,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    marginLeft: 10,
    marginVertical: 5,
    textAlign: 'left',
    color: '#666666',
  },
  iconView: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
    width: 30,
    height: 30,
  },
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginHorizontal: 10,
    marginTop: 20,
    marginBottom: 10,
  },
});
