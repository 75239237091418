import { getPrivateApi } from '../base';

const resourceUrl = '/users';

export const getMe = async () => {
  const api = await getPrivateApi();
  return api.url('/me').get().json();
};

export const getUser = async (userReference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${userReference}`).get().json();
};

export const getAppointedUsers = async () => {
  const api = await getPrivateApi();
  return api.url(resourceUrl).query({ appointed: true }).get().json();
};

export const getReferrersUsers = async () => {
  const api = await getPrivateApi();
  return api.url(resourceUrl).query({ referrals: true }).get().json();
};

export const searchUsers = async (searchText, page) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}`)
    .query({
      keyword: searchText,
      page,
    })
    .get()
    .json();
};
