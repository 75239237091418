import React, { useCallback } from 'react';
import {
  Image,
  StyleSheet,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { Divider } from 'react-native-elements';
import { openUrl } from '../lib';

export const Video = (props) => {
  const dimensions = useWindowDimensions();

  const contentDimensions = {
    width: dimensions.width * 0.8,
    height: dimensions.height * 0.25,
  };

  const openVideo = useCallback(() => {
    openUrl(props.url);
  }, [props.url]);

  return (
    <View style={{ alignContent: 'center', alignItems: 'center' }}>
      {props.thumbnailUrl ? (
        <Image
          onPress={openVideo}
          source={{ uri: props.thumbnailUrl }}
          style={contentDimensions}
          resizeMode="contain"
        />
      ) : (
        <TouchableWithoutFeedback onPress={openVideo}>
          <View style={contentDimensions} />
        </TouchableWithoutFeedback>
      )}
      <Ionicons
        name="ios-play-circle"
        size={64}
        style={{
          position: 'absolute',
          top: dimensions.height * 0.062,
        }}
        color="#cdcdcd"
        onPress={openVideo}
      />
      {!!props.withDivider && <Divider style={styles.lineStyle} />}
    </View>
  );
};

const styles = StyleSheet.create({
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
});
