import React from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import { useLinkProps } from '@react-navigation/native';

export const NavigationTouchable = ({
  to,
  action,
  children,
  style,
  ...rest
}) => {
  const { onPress, ...props } = useLinkProps({ to, action });

  const [isHovered, setIsHovered] = React.useState(false);

  if (Platform.OS === 'web') {
    return (
      <View
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={[
          style,
          { transitionDuration: '100ms', opacity: isHovered ? 0.8 : 1 },
        ]}
        {...props}
        {...rest}
      >
        {children}
      </View>
    );
  }

  return (
    <TouchableOpacity onPress={onPress} style={style} {...props} {...rest}>
      {children}
    </TouchableOpacity>
  );
};
