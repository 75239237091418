import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
  KeyboardAvoidingView,
} from 'react-native';
import { Overlay } from 'react-native-elements';
import CommentWithMentions from './CommentWithMentions';
import { environment } from '../../src/environment';

class NewArticle extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      message: '',
      typeOf: '',
      sentMessageOverlay: false,
      errorMessageOverlay: false,
    };
  }

  showSentMessageOverlay() {
    this.props.successfullArticleSent();
  }

  showErrorMessageOverlay() {
    this.setState({ errorMessageOverlay: true, loading: false });
  }

  setMessage = (newMessage) => {
    this.setState({ message: newMessage });
  };

  cancelButton = () => {
    this.props.showNewArticleOverlay(false);
  };

  sendMessage = async () => {
    this.setState({ loading: true });
    let response = false;
    let bearerToken = this.props.bearerToken;
    let typeOf = this.props.typeOf;

    try {
      //Assign the promise unresolved first then get the data using the json method.
      let obj = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + bearerToken,
        },
        body: JSON.stringify({
          article: {
            article: this.state.message,
            type_of: typeOf,
          },
        }),
      };

      //environment.API_HOST:80/v4/articles/:id/comments
      const daphniServer = await fetch(
        `https://${environment.API_HOST}/v4/articles/`,
        obj
      );
      response = await daphniServer;
      //this.setState({ loading: false });
    } catch (err) {
      console.log('Error fetching data-----------', err);
    }

    if (response.ok) {
      console.log('Article created!');
      this.showSentMessageOverlay();
      //this.setState({ loading: false });
    } else {
      console.log('There was a problem creating your article, try again');
      this.showErrorMessageOverlay();
    }
  };

  render() {
    let sentMessageOverlay = (
      <Overlay
        isVisible={this.state.sentMessageOverlay}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '90%', height: '10%', borderRadius: 20 }}
        onBackdropPress={() => this.setState({ sentMessageOverlay: false })}
      >
        <Text
          style={[
            styles.text,
            { textAlign: 'center', marginTop: 8, fontSize: 16 },
          ]}
        >
          Your message was sent
        </Text>
      </Overlay>
    );

    let errorMessageOverlay = (
      <Overlay
        isVisible={this.state.errorMessageOverlay}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '90%', height: '15%', borderRadius: 20 }}
        onBackdropPress={() => this.setState({ errorMessageOverlay: false })}
      >
        <Text
          style={[
            styles.text,
            { textAlign: 'center', marginTop: 8, fontSize: 16 },
          ]}
        >
          There was a problem sending your message, try again
        </Text>
      </Overlay>
    );

    return (
      <View style={styles.whiteContainer}>
        {sentMessageOverlay}
        {errorMessageOverlay}
        <View style={{ flex: 1, marginTop: 20 }}>
          <KeyboardAvoidingView behavior="position">
            <CommentWithMentions
              bearerToken={this.props.bearerToken}
              setMessage={this.setMessage}
              style={styles.commentInput}
            />
          </KeyboardAvoidingView>
        </View>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            onPress={this.cancelButton}
            hitSlop={{ top: 15, bottom: 15 }}
            style={[styles.button, styles.cancelButton]}
          >
            <Text style={[styles.sendText, { color: '#666666' }]}>Cancel</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={this.sendMessage}
            hitSlop={{ top: 15, bottom: 15 }}
            disabled={this.state.loading}
            style={[styles.button, styles.sendButton]}
          >
            {this.state.loading ? (
              <ActivityIndicator size="small" color="grey" />
            ) : (
              <Text style={[styles.sendText, { color: '#ffffff' }]}>Post</Text>
            )}
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default NewArticle;

const styles = StyleSheet.create({
  whiteContainer: {
    width: '100%',
    height: 200,
    backgroundColor: '#ffffff',
    borderRadius: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  button: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
    height: 35,
  },
  sendButton: {
    flex: 1,
    backgroundColor: '#45a9ed',
    borderBottomRightRadius: 20,
  },
  cancelButton: {
    borderBottomLeftRadius: 20,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginHorizontal: 20,
  },
  sendText: {
    fontFamily: 'Freight-Sans-Black',
    color: '#45a9ed',
    fontSize: 16,
    textAlign: 'center',
  },
  commentInput: {
    marginBottom: 20,
  },
});
