import React from 'react';
import { Text, StyleSheet, Dimensions, TouchableOpacity } from 'react-native';
import { Overlay, Avatar } from 'react-native-elements';

import DaphnipolitanOverlay from '../../overlays/DaphnipolitanOverlay';
import { environment } from '../../src/environment';

const WIDTH = Dimensions.get('window').width;

class HouseDaphnipolitan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
    };
    this.showOverlay = this.showOverlay.bind(this);
  }

  showOverlay(show) {
    this.setState({ showOverlay: show });
  }

  successfullChat = () => {
    console.log('Chat creado correctamente, esconder el modal');
    this.setState({ showOverlay: false });
  };

  render() {
    let sectors = this.props.sectors;

    if (sectors != null) {
      if (sectors.length > 3) {
        let temp = [sectors[0], sectors[1], sectors[2]];
        sectors = temp;
      }
    } else {
      console.log('No sectors where passed');
    }

    let profilePic = this.props.profileSrc;
    if (profilePic == null) {
      profilePic = 'https://' + environment.API_HOST + '/missing/avatar.png';
    }

    let likes = this.props.likes;
    if (likes == null || likes == '') {
      likes = 0;
    }

    let pings = this.props.pings;
    if (pings == null || pings == '') {
      pings = 0;
    }

    let daphnipolitanOverlay = (
      <Overlay
        isVisible={this.state.showOverlay}
        overlayStyle={[styles.bottomOverlay, { width: WIDTH }]}
        onBackdropPress={() => this.setState({ showOverlay: false })}
      >
        <DaphnipolitanOverlay
          loadAllData={true}
          reference={this.props.reference}
          navigation={this.props.navigation}
          sectors={this.props.sectors}
          name={this.props.name}
          job={this.props.job}
          city={this.props.city}
          company={this.props.company}
          connected_to={this.props.connected_to}
          country={this.props.country}
          countryCode={this.props.countryCode}
          socials={this.props.socials}
          avatar={profilePic}
          successfullChat={this.successfullChat}
        />
      </Overlay>
    );

    return (
      <TouchableOpacity
        style={{ width: 100, alignItems: 'center' }}
        onPress={() => this.showOverlay(true)}
      >
        {daphnipolitanOverlay}
        <Avatar rounded size={45} source={{ uri: profilePic }} />
        <Text style={[styles.text]}>{this.props.job}</Text>
        <Text style={[styles.text]}>{this.props.name}</Text>
      </TouchableOpacity>
    );
  }
}
export default HouseDaphnipolitan;

const styles = StyleSheet.create({
  ItemContainer: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  bottomOverlay: {
    backgroundColor: '#53a7dc',
    position: 'absolute',
    bottom: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    maxHeight: 400,
  },
  flag: {
    width: 64,
    height: 64,
    marginVertical: 20,
    marginHorizontal: 30,
  },
  bannerFlag: {
    position: 'absolute',
    right: -10,
    zIndex: 3,
    width: 20,
    height: 20,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    color: '#666666',
    fontSize: 16,
    marginBottom: 5,
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 5,
    textAlign: 'center',
  },
  mainTitle: {
    width: WIDTH - 100,
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
    textAlign: 'center',
    alignSelf: 'center',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    textAlign: 'center',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#ffffff',
    fontSize: 16,
  },
  miniText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 13,
  },
});
