import React from 'react';
import {
  Platform,
  Text,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  ScrollView,
  Linking,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../src/lib/StatusBarHeight';

import { Overlay, Divider } from 'react-native-elements';
import HTML from 'react-native-render-html';

import PingOverlay from '../overlays/PingOverlay';
import { ShareDealOverlay } from '../overlays/ShareDealOverlay';
import { localstore } from '../helpers/LocalStore';
import { formatMoney } from '../src/lib';
import { innerViewBaseStyles, Page } from '../src/components';
import { HouseCompanyHeader } from '../src/pages/HouseCompany/components/Header';
import { withWindowDimensions } from '../src/hoc';
import { tabletOrLargerMinPixel } from '../src/lib/responsive';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);
const IMAGES_MAX_WIDTH = Dimensions.get('window').width - 50;

const DEFAULT_PROPS = {
  baseFontStyle: {
    fontSize: 16,
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
  },
  tagsStyles: {
    p: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    em: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    i: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    ul: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    b: {
      fontSize: 14,
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
  },
  ignoredStyles: [
    'background-color',
    'padding',
    'font-family',
    'font-size',
    'line-height',
    'color',
  ],
  imagesMaxWidth: IMAGES_MAX_WIDTH,
  onLinkPress: (evt, href) => {
    Linking.openURL(href);
  },
  debug: false,
};

class HouseCompanyScreen03 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bearerToken: '',
      loading: true,
      showPingOverlay: false,
      showShareOverlay: false,
      showShareMessageOverlay: false,
      shareMessage: '',
      reference: '',
      logo: '',
      name: '',
      city: '',
      country: '',
      countryCode: '',
      type: '',
      cover: '',
      website: '',
      investmentText: '',
      lastUpdates: '',
      esg: '',
      jobrequests: '',
      portfolioNews: '',
      role: '',
      funds_role: '',
      daphni_Invested_amount: '',
      share_capital_ownership: '',
      fair_value: '',
      multiple_to_cost: '',
      main_coinvestors: '',
      funds: [],
    };
    this.showPingOverlay = this.showPingOverlay.bind(this);
    this.successfullShare = this.successfullShare.bind(this);
  }

  componentDidMount() {
    const reference = this.props.route.params?.reference || 'nothing';
    const name = this.props.route.params?.name || '';
    const logo =
      this.props.route.params?.logo ?? 'https://daphni.com/favicon-128x128.png';
    const cover =
      this.props.route.params?.cover ??
      'https://daphni.com/favicon-128x128.png';
    const city = this.props.route.params?.city || '';
    const country = this.props.route.params?.country || '';
    const countryCode = this.props.route.params?.countryCode || 'ZZ';
    const type = this.props.route.params?.type;
    const website = this.props.route.params?.website ?? 'https://daphni.com/';
    const deckUrl = this.props.route.params?.deckUrl;
    const investmentText = this.props.route.params?.investmentText;
    const lastUpdates = this.props.route.params?.lastUpdates;
    const esg = this.props.route.params?.esg;
    const jobrequests = this.props.route.params?.jobrequests;
    const portfolioNews = this.props.route.params?.portfolioNews;

    const funds_role = this.props.route.params?.funds_role || 'nothing';
    const daphni_Invested_amount =
      this.props.route.params?.daphni_Invested_amount || 'nothing';
    const share_capital_ownership =
      this.props.route.params?.share_capital_ownership || 'nothing';
    const fair_value = this.props.route.params?.fair_value || 'nothing';
    const multiple_to_cost =
      this.props.route.params?.multiple_to_cost || 'nothing';
    const main_coinvestors =
      this.props.route.params?.main_coinvestors || 'nothing';

    this.setState(
      {
        reference,
        name,
        logo,
        cover,
        city,
        country,
        countryCode,
        type,
        website,
        deckUrl,
        investmentText,
        lastUpdates,
        esg,
        jobrequests,
        portfolioNews,
        funds_role,
        daphni_Invested_amount,
        share_capital_ownership,
        fair_value,
        multiple_to_cost,
        main_coinvestors,
        loading: false,
      },
      () => {
        localstore.getString('token').then((result) => {
          if (result !== null) {
            this.setState({ bearerToken: result });
          }
        });

        localstore.getString('role').then((result) => {
          if (result !== null) {
            this.setState({ role: result });
          } else {
            this.setState({ role: 'dp' });
          }
        });

        localstore.getItem('funds').then((result) => {
          this.setState({ funds: result }, () => {
            // console.log(' los fondos en proyecto son: ' + this.state.funds);
          });
        });
      }
    );
  }

  showPingOverlay(show) {
    this.setState({ showPingOverlay: show });
  }

  shareButtonPressed = () => {
    this.showShareOverlay(true);
  };

  showShareOverlay(show) {
    this.setState({ showShareOverlay: show });
  }

  showShareMessageOverlay(show) {
    this.setState({ showShareMessageOverlay: show });
  }

  successfullShare(message) {
    console.log(message);
    this.setState({ shareMessage: message }, () => {
      this.showShareOverlay(false);
      this.showShareMessageOverlay(true);
    });
  }

  successfullChat = () => {
    this.showShareOverlay(false);
  };

  renderReportImage = () => {
    const width = Math.min(this.props.dimensions.width, tabletOrLargerMinPixel);
    return (
      <Image
        source={require('../assets/dummy_data/report01.jpg')}
        style={{
          alignSelf: 'center',
          width: width - 60,
          height: ((width - 60) * 543) / 1024,
        }}
        resizeMode="contain"
      />
    );
  };

  render() {
    let pingOverlay = (
      <Overlay
        isVisible={this.state.showPingOverlay}
        overlayStyle={{ width: WIDTH - 10, height: 142, borderRadius: 20 }}
        onBackdropPress={() => this.setState({ showPingOverlay: false })}
      >
        <View>
          <PingOverlay
            reference={this.state.reference}
            bearerToken={this.state.bearerToken}
            showPingOverlay={this.showPingOverlay}
          />
        </View>
      </Overlay>
    );

    let shareOverlay = (
      <Overlay
        isVisible={this.state.showShareOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          {
            width: WIDTH,
            height: Platform.OS === 'ios' ? HEIGHT * 0.6 : HEIGHT * 0.5,
          },
        ]}
        onBackdropPress={() => this.setState({ showShareOverlay: false })}
      >
        <ShareDealOverlay
          reference={this.state.reference}
          bearerToken={this.state.bearerToken}
          successfullShare={this.successfullShare}
          successfullChat={this.successfullShare}
          navigation={this.props.navigation}
        />
      </Overlay>
    );

    let sentDealOverlay = (
      <Overlay
        isVisible={this.state.showShareMessageOverlay}
        overlayStyle={{ width: WIDTH - 20, height: 60, borderRadius: 10 }}
        onBackdropPress={() =>
          this.setState({ showShareMessageOverlay: false })
        }
      >
        <View style={{ alignItems: 'center' }}>
          <Text style={[styles.text, { textAlign: 'center' }]}>
            {this.state.shareMessage}
          </Text>
        </View>
      </Overlay>
    );

    let lastUpdates = this.state.lastUpdates;
    if (lastUpdates != null && lastUpdates != '') {
      lastUpdates = (
        <View style={{ marginHorizontal: 20 }}>
          <HTML {...DEFAULT_PROPS} html={lastUpdates} />
        </View>
      );
    } else {
      lastUpdates = (
        <Text style={[styles.text, { marginLeft: 20 }]}>Nothing new</Text>
      );
    }

    let ESG = this.state.esg;
    if (ESG != null && ESG != '') {
      ESG = <HTML {...DEFAULT_PROPS} html={this.state.esg} />;
    } else {
      ESG = (
        <Text style={[styles.text, { marginLeft: 10 }]}>
          There is not more information for this project
        </Text>
      );
    }

    const buttondp = (
      <View style={[styles.buttonContainer, { width: '40%' }]}>
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.showPingOverlay(true)}
        >
          <Image
            source={require('../assets/icons/waveIcon.png')}
            resizeMode="contain"
            style={{ width: 20, height: 20 }}
          />
        </TouchableOpacity>
        <View style={styles.vLine} />
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.shareButtonPressed()}
        >
          <Image
            source={require('../assets/icons/shareicon.png')}
            resizeMode="contain"
            style={{ width: 20, height: 20 }}
          />
        </TouchableOpacity>
      </View>
    );

    const buttonft = (
      <View style={[styles.buttonContainer, { width: '20%' }]}>
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.shareButtonPressed()}
        >
          <Image
            source={require('../assets/icons/shareicon.png')}
            resizeMode="contain"
            style={{ width: 20, height: 20 }}
          />
        </TouchableOpacity>
      </View>
    );

    let buttonToShow = null;
    let roleStyle = {};
    let clickable = false;

    let myfund = this.state.funds;

    if (
      this.state.role == 'team' ||
      this.state.role == 'family' ||
      myfund.includes(this.state.type)
    ) {
      buttonToShow = buttonft;
      roleStyle = {};
      clickable = false;
    } else {
      buttonToShow = buttondp;
      roleStyle = {
        height: 0,
      };
      clickable = true;
    }

    return (
      <Page
        headerColor="#00b6ba"
        title={this.state.name}
        loading={this.state.loading}
      >
        {pingOverlay}
        {shareOverlay}
        {sentDealOverlay}
        <ScrollView
          style={innerViewBaseStyles.contentTopPanelMaxFix}
          contentContainerStyle={innerViewBaseStyles.scrollViewContentContainer}
        >
          <HouseCompanyHeader
            type={this.state.type}
            city={this.state.city}
            country={this.state.country}
            countryCode={this.state.countryCode}
            website={this.state.website}
            coverImage={{ uri: this.state.cover }}
          />
          <View style={styles.tabContainer}>
            <TouchableOpacity
              style={[styles.tabTouchable]}
              onPress={() => this.props.navigation.navigate('DaHouseCompany01')}
            >
              <View style={styles.tab}>
                <Text style={styles.text}>At a glance</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={[styles.tabTouchable, roleStyle]}
              disabled={clickable}
            >
              <View style={[styles.tab, roleStyle]}>
                <Text style={[styles.text, roleStyle]}>Report</Text>
                <View style={[styles.selectedTab, roleStyle]} />
              </View>
            </TouchableOpacity>
          </View>

          <View style={styles.greenContainer}>
            <Text style={styles.wTitle}>Investment</Text>
            <View style={[styles.table]}>
              <View style={styles.leftTable}>
                <Text style={styles.textTable}>Fund's role: </Text>
                <Text style={styles.textTable}>Daphni invested amount: </Text>
                <Text style={styles.textTable}>Share capital ownership: </Text>
                <Text style={styles.textTable}>Fair value: </Text>
                <Text style={styles.textTable}>Multiple to cost: </Text>
                <Text style={styles.textTable}>Main co-Investors: </Text>
              </View>
              <View style={styles.rightTable}>
                <Text style={styles.textTable}>{this.state.funds_role}</Text>
                <Text style={styles.textTable}>
                  €{this.state.daphni_Invested_amount}
                </Text>
                <Text style={styles.textTable}>
                  {this.state.share_capital_ownership}
                </Text>
                <Text style={styles.textTable}>
                  {this.state.fair_value
                    ? `€${formatMoney(this.state.fair_value)}`
                    : '--'}
                </Text>
                <Text style={styles.textTable}>
                  {this.state.multiple_to_cost}
                </Text>
                <Text style={styles.textTable}>
                  {this.state.main_coinvestors}
                </Text>
              </View>
            </View>
          </View>
          <Divider style={styles.lineStyle} />

          <Text style={[styles.title, { margin: 10 }]}>
            Updates since last quarter
          </Text>
          {lastUpdates}
          <Divider style={styles.lineStyle} />
          <Text style={[styles.title, { textAlign: 'center' }]}>ESG</Text>
          <View style={{ marginHorizontal: 25 }}>{ESG}</View>
          {!!this.state.deckUrl && (
            <>
              <Text style={[styles.link, { margin: 20, textAlign: 'center' }]}>
                Get full report here
              </Text>
              <TouchableOpacity
                onPress={() =>
                  this.props.navigation.navigate('PDFDeck', {
                    deckUrl: this.state.deckUrl,
                    name: this.state.name,
                  })
                }
              >
                {this.renderReportImage()}
              </TouchableOpacity>
            </>
          )}
        </ScrollView>
        {buttonToShow}
      </Page>
    );
  }
}

export default withWindowDimensions(HouseCompanyScreen03);

const styles = StyleSheet.create({
  tab: {
    marginTop: 10,
    alignItems: 'center',
  },
  tabTouchable: {
    height: 60,
    paddingBottom: 10,
    width: '50%',
  },
  table: {
    flexDirection: 'row',
    marginHorizontal: 15,
    marginTop: 10,
  },
  leftTable: {
    width: '50%',
  },
  rightTable: {
    width: '50%',
  },
  textTable: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 5,
  },
  greenContainer: {
    backgroundColor: '#00b6ba',
    marginHorizontal: 5,
    marginVertical: 20,
    paddingVertical: 15,
    borderRadius: 20,
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#00b6ba',
    zIndex: 2,
    bottom: 20,
    borderRadius: 30,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  bCtouch: {
    height: 40,
    paddingHorizontal: 25,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  tabContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomOverlay: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'transparent',
  },
  selectedTab: {
    width: '100%',
    height: 6,
    marginTop: 5,
    backgroundColor: '#00b6ba',
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    color: '#666666',
    fontSize: 16,
    marginBottom: 5,
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 5,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginLeft: 5,
  },
  link: {
    fontFamily: 'Freight-Sans-Black',
    color: '#00b6ba',
    fontSize: 16,
  },
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
  vLine: {
    width: 1,
    height: '70%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
});
