import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image } from 'react-native';

import Flag from 'react-native-round-flags';
import { formatMoney, theme } from '../../../lib';

class HouseCompany extends React.Component {
  viewCompanyDetails() {
    this.props.navigation.navigate('DaHouseCompany01', {
      reference: this.props.reference,
      fundId: this.props.fundId,
    });
  }

  render() {
    let logoSrc = this.props.logoSrc;
    if (logoSrc == null) {
      logoSrc = 'https://daphni.com/favicon-128x128.png';
    }

    let money = this.props.money;
    let moneydash = {};
    if (money == null || money == 0) {
      money = '';
      moneydash = {
        display: 'none',
      };
    } else {
      money = '€' + formatMoney(money);
      moneydash = {};
    }

    return (
      <TouchableOpacity onPress={() => this.viewCompanyDetails()}>
        <View style={styles.container}>
          <View style={styles.iconPairedData}>
            <Image
              source={{ uri: logoSrc }}
              resizeMethod="scale"
              resizeMode="contain"
              style={styles.logoImage}
            />
            <View style={{ flexDirection: 'row' }}>
              {(this.props.funds?.length
                ? this.props.funds
                : this.props.type
                ? [{ id: this.props.type, label: this.props.type }]
                : []
              ).map((fund, index, all) => {
                const fundColors =
                  theme.colors.funds[fund.label] || theme.colors.funds.Default;

                return (
                  <View
                    key={fund.id}
                    style={[
                      styles.typeContainer,
                      { backgroundColor: fundColors.primary },
                      index !== all.length - 1 && { marginRight: 5 },
                    ]}
                  >
                    <Text
                      style={[
                        styles.typeText,
                        { color: fundColors.textOnPrimary },
                      ]}
                    >
                      {fund.label}
                    </Text>
                  </View>
                );
              })}
            </View>
          </View>
          <View style={styles.pairedData}>
            <Text style={styles.title}>{this.props.name} </Text>
            <Flag code={this.props.countryCode} style={styles.flag} />
          </View>
          <View style={styles.pairedData}>
            <Text style={styles.title}>{money}</Text>
            <Text style={moneydash}>-</Text>
            <Text style={styles.text}>{this.props.activity} </Text>
          </View>
          <Text numberOfLines={3} style={styles.descriptionText}>
            {this.props.description}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

export default HouseCompany;

const styles = StyleSheet.create({
  container: {
    padding: 5,
  },
  logoImage: {
    flex: 1,
    height: 40,
    maxWidth: 150,
  },
  typeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    minWidth: 50,
    paddingHorizontal: 8,
    borderRadius: 10,
  },
  typeText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 13,
  },
  iconPairedData: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 5,
    marginHorizontal: 15,
  },
  pairedData: {
    flexDirection: 'row',
    marginVertical: 5,
    marginHorizontal: 15,
  },
  flag: {
    width: 16,
    height: 16,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#666666',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
  descriptionText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    marginTop: 5,
    marginHorizontal: 15,
    flexWrap: 'wrap',
  },
  icon: {
    tintColor: '#666666',
  },
});
