import React, { useCallback, useRef, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Document, Page, pdfjs } from 'react-pdf';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useWatermarkText } from './useWatermarkText';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const limit = 3;

// eslint-disable-next-line import/no-unused-modules
export const PDFView = (props) => {
  const watermarkText = useWatermarkText();
  const [numPages, setNumPages] = useState(0);
  const onDocumentLoadSuccess = useCallback((pdf) => {
    setNumPages(pdf.numPages);
  }, []);
  const dimensions = useWindowDimensions();
  const pageWidth = dimensions.width;

  const [offset, setOffset] = useState(0);

  const loadMore = useCallback(() => {
    setOffset((prevOffset) => prevOffset + limit);
  }, []);

  const shownPages = Math.min(offset + limit, numPages);

  return (
    <div
      id="scrollableDiv"
      style={{
        flex: 1,
        overflow: 'auto',
        margin: 'auto',
      }}
    >
      <Document
        file={props.url}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={props.onError}
        onSourceError={props.onError}
      >
        {numPages ? (
          <InfiniteScroll
            dataLength={shownPages}
            next={loadMore}
            hasMore={shownPages < numPages}
            scrollableTarget="scrollableDiv"
          >
            {Array.from(new Array(shownPages), (_, index) => (
              <PDFPage
                key={`page_${index + 1}`}
                onFinish={props.onLoad}
                watermark={watermarkText}
                pageNumber={index + 1}
                loading={null}
                width={pageWidth}
              />
            ))}
          </InfiniteScroll>
        ) : null}
      </Document>
    </div>
  );
};

const PDFPage = ({ onFinish, watermark, windowWidth, ...props }) => {
  const canvasRef = useRef();

  const renderWatermark = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) {
      return;
    }

    const context = canvas.getContext('2d');

    context.globalAlpha = 0.15;
    context.font = '55px bold Arial';
    context.translate(canvas.width / 2, canvas.height / 2);
    context.rotate(-Math.atan(canvas.height / canvas.width));
    const metrics = context.measureText(watermark);
    context.fillText(watermark, -metrics.width / 2, 55 / 2);

    onFinish();
  }, [canvasRef, onFinish, watermark]);
  return (
    <Page canvasRef={canvasRef} onRenderSuccess={renderWatermark} {...props} />
  );
};
