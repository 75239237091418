const initialState = {
  me: null,
  authToken: undefined,
  hasSeenOnboarding: undefined,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ME':
      return {
        ...state,
        me: action.payload.me,
      };
    case 'SET_SEEN_ONBOARDING':
      return {
        ...state,
        hasSeenOnboarding: true,
      };
    case 'SET_AUTH_TOKEN':
      return {
        ...state,
        authToken: action.payload.authToken,
      };
    default:
      return state;
  }
};
