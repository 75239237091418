import { connect } from 'react-redux';
import ChatListScreenComponent from './ChatListScreen.component';
import { isLoadingSelector } from '../../src/redux/LoadingStatus';
import {
  discussionsSelector,
  fetchDiscussions,
  isLastPageSelector,
  refreshDiscussions,
} from '../../src/redux/Discussion';
import { myReferenceSelector } from '../../src/redux/User';

const mapStateToProps = (state) => {
  const isLoading = isLoadingSelector('fetchDiscussions')(state);
  const isRefreshing = isLoadingSelector('refreshDiscussions')(state);
  return {
    canFetchMore: !isLastPageSelector(state) && !isLoading && !isRefreshing,
    isLoading,
    isRefreshing,
    discussions: discussionsSelector(state),
    myReference: myReferenceSelector(state),
  };
};

const mapDispatchToProps = {
  fetchDiscussions,
  refreshDiscussions,
};

export const ConnectedChatListScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatListScreenComponent);
