import { adaptSmallUser } from '../User/adapter';

const adaptTimelineItemUserInfo = (apiTimelineItem) => {
  if (!apiTimelineItem.user) {
    return {};
  }

  return {
    user: adaptSmallUser(apiTimelineItem.user),
  };
};

const adaptCommonInformationTimelineItem = (apiTimelineItem) => ({
  ...adaptTimelineItemUserInfo(apiTimelineItem),
  id: String(apiTimelineItem.id),
  date: apiTimelineItem.created_at.substring(0, 10),
});

const adaptProjectTimelineItem = (apiTimelineItem) => {
  const commonInfo = adaptCommonInformationTimelineItem(apiTimelineItem);

  const {
    description,
    small_logo_url: projectLogo,
    ref: reference,
    label: companyTitle,
    comment_count: commentCount,
  } = apiTimelineItem.project;

  return {
    ...commonInfo,
    typeOf: 'project',
    content: apiTimelineItem.content,
    commentCount,
    reference,
    companyTitle: companyTitle || '',
    companyDescription: description,
    companyLogo: projectLogo != null ? 'https:' + projectLogo : null,
  };
};

const adaptArticleTimelineItem = (apiTimelineItem) => {
  const commonInfo = adaptCommonInformationTimelineItem(apiTimelineItem);

  return {
    ...commonInfo,
    reference: String(apiTimelineItem.id),
    commentCount: apiTimelineItem.comment_count || 0,
    content: apiTimelineItem.article,
    typeOf: apiTimelineItem.type_of,
  };
};

const adaptArticleCommentTimelineItem = (apiTimelineItem) => {
  const commonInfo = adaptCommonInformationTimelineItem(apiTimelineItem);
  const parentArticle = adaptArticleTimelineItem(apiTimelineItem.article);

  return {
    ...parentArticle,
    latestComment: {
      ...commonInfo,
      content: apiTimelineItem.content,
    },
  };
};

const adaptQuestionTimelineItem = (apiTimelineItem) => {
  const commonInfo = adaptCommonInformationTimelineItem(apiTimelineItem);
  return {
    ...commonInfo,
    content: apiTimelineItem.title,
    typeOf: apiTimelineItem.type_of,
  };
};

const adaptTimelineItem = (apiTimelineItem) => {
  if (apiTimelineItem.project_id != null) {
    return adaptProjectTimelineItem(apiTimelineItem);
  }

  if (apiTimelineItem.article != null) {
    if (typeof apiTimelineItem.article === 'string') {
      return adaptArticleTimelineItem(apiTimelineItem);
    } else if (apiTimelineItem.article.id != null) {
      return adaptArticleCommentTimelineItem(apiTimelineItem);
    }

    console.log('Unhandled timeline article type', apiTimelineItem);
    return null;
  }

  return adaptQuestionTimelineItem(apiTimelineItem);
};

export const adaptTimeline = ({ data: apiTimeline }) => {
  const timelineById = {};
  const timelineOrder = [];

  apiTimeline.forEach((apiTimelineItem) => {
    const timelineItem = adaptTimelineItem(apiTimelineItem);
    if (!timelineItem) return;

    const potentiallyExistingTimelineItem = timelineById[timelineItem.id];
    if (
      !potentiallyExistingTimelineItem ||
      (!potentiallyExistingTimelineItem.latestComment &&
        timelineItem.latestComment)
    ) {
      timelineOrder.push(timelineItem.id);
      timelineById[timelineItem.id] = timelineItem;
    }
  });

  return timelineOrder.map((id) => timelineById[id]);
};
