import React, { useEffect, useState, Fragment } from 'react';
import { Text, StyleSheet, ActivityIndicator, View } from 'react-native';
import { Card } from '../Card';
import { getReferrersUsers } from '../../../../api';
import { adaptUsers } from '../../../../api/User/adapter';
import Daphnipolitan from '../../../../../components/Daphnipolitan/Daphnipolitan';

export const DaBestReferers = () => {
  const [referers, setReferers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getReferrersUsers()
      .then(adaptUsers)
      .then((users) => {
        setIsLoading(false);
        setReferers(users);
      })
      .catch((error) => {
        console.error('Error when loading', error);
        setIsLoading(false);
      });
  }, []);

  if (!referers.length) {
    if (!isLoading) {
      return null;
    }

    return (
      <Card>
        <ActivityIndicator size="large" color="grey" />
      </Card>
    );
  }

  return (
    <Card>
      <Text style={styles.title}>Da best referers</Text>
      {referers.map((referer, index) => (
        <Fragment key={referer.reference}>
          <Daphnipolitan {...referer} />
          {index !== referers.length && <View style={styles.separator} />}
        </Fragment>
      ))}
    </Card>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666666',
    fontFamily: 'Freight-Sans-Black',
    marginBottom: 20,
  },
  separator: {
    height: 10,
  },
});
