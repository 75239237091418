import React from 'react';
import {
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../../src/lib/StatusBarHeight';
import { Icon, Overlay, Divider } from 'react-native-elements';
import DaphniHeader from '../../components/DaphniHeader';
import DaphniFooter from '../../components/DaphniFooter';
import CategorySelectionScreen from '../../overlays/TimelinePostCategoryOverlay';
import TimelineCategorySelectionOverlay from '../../overlays/TimelineCategorySelectionOverlay';
import Company from '../../components/TimelineCompany';
import TimelineArticle from '../../components/TimelineArticle';
import NewArticle from '../../components/Comments/TimelineNewArticle';
import { localstore } from '../../helpers/LocalStore';
import { createGetTimeline } from '../../src/api/Timeline';
import { adaptTimeline } from '../../src/api/Timeline/adapter';
import { PaginatedFlatList } from '../../src/components/PaginatedFlatList';
import { Page } from '../../src/components';
import { desktopOrLargerMinPixel } from '../../src/lib/responsive';
import { DESKTOP_HEADER_OFFSET } from '../../components/DaphniHeader/DaphniHeader.component';
import { withWindowDimensions } from '../../src/hoc';

const WIDTH = Dimensions.get('window').width;

const timelineCategories = [
  'cancel',
  'da_flow',
  'da_house',
  'legal',
  'events',
  'general',
];

class TimelineScreenComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bearerToken: '',
      typeOf: '',
      showTimelineCategoriesOverlay: false,
      showPostCategoriesOverlay: false,
      showNewArticleOverlay: false,
      showMessageOverlay: false,
      shareMessage: '',
    };
    this.updateSelectedCategory = this.updateSelectedCategory.bind(this);
    this.showTimelineCategoriesOverlay = this.showTimelineCategoriesOverlay.bind(
      this
    );
    this.showPostCategoriesOverlay = this.showPostCategoriesOverlay.bind(this);
    this.showNewArticleOverlay = this.showNewArticleOverlay.bind(this);
    this.successfullArticleSent = this.successfullArticleSent.bind(this);
  }

  async componentDidMount() {
    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result });
      }
    });
  }

  listRef = null;
  setListRef = (ref) => (this.listRef = ref);

  reloadTimeline = () => {
    if (this.listRef) {
      this.listRef.resetData();
    }
  };

  renderItem = ({ item }) => {
    let timelineObject;
    if (item.typeOf == 'project') {
      //A Project
      timelineObject = (
        <View key={item.id}>
          <Company
            reference={item.reference}
            bearerToken={this.state.bearerToken}
            user={item.user}
            comments={item.commentCount}
            content={item.content}
            companyTitle={item.companyTitle}
            companyDescription={item.companyDescription}
            companyLogo={item.companyLogo}
            date={item.date}
          />
          <Divider style={styles.lineStyle} />
        </View>
      );
    } else {
      //An article
      timelineObject = (
        <View key={item.id}>
          <TimelineArticle
            navigation={this.props.navigation}
            id={item.reference}
            bearerToken={this.state.bearerToken}
            typeOf={item.typeOf}
            user={item.user}
            date={item.date}
            latestComment={item.latestComment}
            comments={item.commentCount}
            content={item.content}
          />
          <Divider style={styles.lineStyle} />
        </View>
      );
    }
    return timelineObject;
  };

  showTimelineCategoriesOverlay(show) {
    this.setState({ showTimelineCategoriesOverlay: show });
  }

  updateSelectedCategory = (catIndex) => {
    let filter;
    switch (catIndex) {
      case 1:
        filter = 'da_flow';
        break;
      case 2:
        filter = 'da_house';
        break;

      case 3:
        filter = 'legal';
        break;

      case 4:
        filter = 'events';
        break;

      case 5:
        filter = 'general';
        break;

      default:
        filter = '';
        break;
    }
    this.setState({ typeOf: filter }, () => {
      this.reloadTimeline();
    });
  };

  showPostCategoriesOverlay(show) {
    this.setState({ showPostCategoriesOverlay: show });
  }

  updateCategoryAndPost = (catIndex) => {
    let typeOf = timelineCategories[catIndex];
    this.setState({ type: typeOf }, () => {
      this.showNewArticleOverlay(true);
    });
  };

  showMessageOverlay(show) {
    this.setState({ showMessageOverlay: show });
  }

  showNewArticleOverlay(show) {
    this.setState({ showNewArticleOverlay: show });
  }

  successfullArticleSent() {
    this.setState({ shareMessage: 'Your message was sent!' }, () => {
      this.showNewArticleOverlay(false);
      this.showMessageOverlay(true);
      this.reloadTimeline();
    });
  }

  renderListHeader = () => (
    <View>
      <View
        style={[
          styles.titleSection,
          {
            paddingTop:
              this.props.dimensions.width >= desktopOrLargerMinPixel
                ? DESKTOP_HEADER_OFFSET
                : undefined,
          },
        ]}
      >
        <Text style={styles.daphnipolisText}>daphnipolis</Text>
      </View>
      <View style={styles.tabContainer}>
        <TouchableOpacity
          style={{ width: '50%' }}
          onPress={() => this.props.navigation.navigate('Timeline')}
        >
          <View>
            <Text
              style={[styles.text, { color: '#45a9ed' }]}
              onPress={() => this.props.navigation.navigate('Timeline')}
            >
              Da Timeline
            </Text>
            <View style={styles.selectedTab} />
          </View>
        </TouchableOpacity>
        <TouchableOpacity
          style={{ width: '50%' }}
          onPress={() => this.props.navigation.navigate('Daphnipolitans')}
        >
          <View>
            <Text
              style={[styles.text]}
              onPress={() => this.props.navigation.navigate('Daphnipolitans')}
            >
              Daphnipolitans
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );

  renderHeaderContent = () => (
    <DaphniHeader navigation={this.props.navigation} index={3} />
  );

  render() {
    let timelineCatOverlay;
    let postCatOverlay;
    let newArticleOverlay;

    timelineCatOverlay = (
      <Overlay
        isVisible={this.state.showTimelineCategoriesOverlay}
        overlayStyle={[styles.bottomOverlay, { width: WIDTH, height: 270 }]}
        onBackdropPress={() =>
          this.setState({ showTimelineCategoriesOverlay: false })
        }
      >
        <TimelineCategorySelectionOverlay
          numColumns={3}
          showOverlay={this.showTimelineCategoriesOverlay}
          updateSelectedCategory={this.updateSelectedCategory}
        />
      </Overlay>
    );

    let messageOverlay = (
      <Overlay
        isVisible={this.state.showMessageOverlay}
        overlayStyle={{ width: WIDTH - 20, height: 60, borderRadius: 10 }}
        onBackdropPress={() => this.setState({ showMessageOverlay: false })}
      >
        <View style={{ alignItems: 'center' }}>
          <Text style={[styles.text, { textAlign: 'center' }]}>
            {this.state.shareMessage}
          </Text>
        </View>
      </Overlay>
    );

    postCatOverlay = (
      <Overlay
        isVisible={this.state.showPostCategoriesOverlay}
        overlayStyle={[styles.bottomOverlay, { width: WIDTH, height: 270 }]}
        onBackdropPress={() =>
          this.setState({ showPostCategoriesOverlay: false })
        }
      >
        <CategorySelectionScreen
          numColumns={3}
          showOverlay={this.showPostCategoriesOverlay}
          updateSelectedCategory={this.updateCategoryAndPost}
        />
      </Overlay>
    );

    newArticleOverlay = (
      <Overlay
        isVisible={this.state.showNewArticleOverlay}
        overlayStyle={{
          flex: 1,
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
        }}
        onBackdropPress={() => this.setState({ showNewArticleOverlay: false })}
      >
        <NewArticle
          showNewArticleOverlay={this.showNewArticleOverlay}
          successfullArticleSent={this.successfullArticleSent}
          typeOf={this.state.type}
          bearerToken={this.state.bearerToken}
        />
      </Overlay>
    );

    return (
      <Page
        headerColor="#45a9ed"
        renderHeaderContent={this.renderHeaderContent}
        withoutAutoOffsetOnDesktop
      >
        {messageOverlay}
        {timelineCatOverlay}
        {postCatOverlay}
        {newArticleOverlay}
        <PaginatedFlatList
          ref={this.setListRef}
          fetchOnMount
          fetchData={createGetTimeline(this.state.typeOf)}
          adaptData={adaptTimeline}
          style={styles.list}
          contentContainerStyle={styles.listContentContainer}
          renderItem={this.renderItem}
          keyExtractor={(item) => item.id}
          extraData={this.state.typeOf}
          ListHeaderComponent={this.renderListHeader()}
          onDataFetched={this.props.markAsVisited}
        />
        <View style={[styles.buttonContainer, { width: '60%' }]}>
          <TouchableOpacity
            style={[styles.bCtouch, { flexDirection: 'row' }]}
            onPress={() => this.showTimelineCategoriesOverlay(true)}
          >
            <Text style={styles.wText}>Topic's filter</Text>
            <Image
              source={require('../../assets/icons/filters.png')}
              resizeMode="contain"
              style={{ width: 15, height: 15 }}
            />
          </TouchableOpacity>
          <View style={styles.vLine} />
          <TouchableOpacity
            style={[styles.bCtouch, { flexDirection: 'row' }]}
            onPress={() => this.showPostCategoriesOverlay(true)}
          >
            <Text style={styles.wText}>Post</Text>
            <Icon
              name="plus-circle"
              type="font-awesome"
              color="#ffffff"
              size={15}
            />
          </TouchableOpacity>
        </View>
        <DaphniFooter />
      </Page>
    );
  }
}

export default withWindowDimensions(TimelineScreenComponent);

const styles = StyleSheet.create({
  list: {
    marginTop: -10,
  },
  listContentContainer: {
    paddingTop: 0,
  },
  titleSection: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffcf56',
  },
  tabContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomOverlay: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    bottom: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#45a9ed',
    zIndex: 2,
    bottom: 65,
    borderRadius: 30,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  bCtouch: {
    height: 40,
    paddingHorizontal: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  selectedTab: {
    width: '100%',
    height: 6,
    marginTop: 4.5,
    marginBottom: 1,
    backgroundColor: '#45a9ed',
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 20,
    textAlign: 'center',
    color: '#666666',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
    marginTop: 12,
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    marginHorizontal: 5,
  },
  daphnipolisText: {
    fontFamily: 'DaphniFont',
    fontSize: 72,
    marginTop: Platform.OS === 'ios' ? 10 : StatusBarHeight.height,
    color: '#ffffff',
    textAlign: 'center',
  },
  vLine: {
    width: 1,
    height: '70%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
});
