import { getPrivateApi } from '../base';

const resourceUrl = '/articles';

export const getArticle = async (id) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${id}`)
    .get()
    .json()
    .then((json) => json.data);
};

export const getArticleComments = async (id) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${id}/comments`).get().json();
};

export const sendArticleComments = async (id, message) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${id}/comments`)
    .post({
      comment: {
        content: message,
        is_private: false,
      },
    })
    .json();
};
