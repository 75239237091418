import React from 'react';
import { StyleSheet } from 'react-native';
import { CommonActions } from '@react-navigation/native';
import RNParsedText from 'react-native-parsed-text';
import { askToOpenUrl, navigation } from '../lib';

const usernameRegex = /@\[([^\]|]+)\|([^\]]+)\]/gim;
const alternateUsernameRegex = /@\[([^\]]+)]\(id:(.+)\)/gim;

const adaptUsername = (matches) => ({
  reference: matches[1],
  name: matches[2],
});

const adaptAlternateUsername = (matches) => ({
  reference: matches[2],
  name: matches[1],
});

const formatUsername = (matchingString, matches) =>
  `@${adaptUsername(matches).name}`;
const formatAlternateUsername = (matchingString, matches) =>
  `@${adaptAlternateUsername(matches).name}`;

const getUserFromText = (text) => {
  if (usernameRegex.test(text)) {
    return adaptUsername(new RegExp(usernameRegex).exec(text));
  }

  if (alternateUsernameRegex.test(text)) {
    return adaptAlternateUsername(
      new RegExp(alternateUsernameRegex).exec(text)
    );
  }

  return null;
};

const onUserPress = (text) => {
  const user = getUserFromText(text);
  if (user) {
    navigation.dispatch(
      CommonActions.navigate({
        name: 'Daphnipolitan',
        params: {
          reference: user.reference,
          name: user.name,
        },
      })
    );
  }
};

const getOpenableUrl = (url) => {
  if (!url.includes('http') && !url.includes('://')) {
    return 'https://' + url;
  }

  return url;
};

const onUrlPress = (url) => askToOpenUrl(getOpenableUrl(url));

const onEmailPress = (email) => {
  const link = `mailto:${email}`;
  return askToOpenUrl(link);
};

export const ParsedText = ({ style, childrenProps, ...props }) => (
  <RNParsedText
    {...props}
    style={[styles.text, style]}
    childrenProps={childrenProps}
    parse={[
      {
        pattern: usernameRegex,
        style: styles.username,
        renderText: formatUsername,
        onPress: onUserPress,
      },
      {
        pattern: alternateUsernameRegex,
        style: styles.username,
        renderText: formatAlternateUsername,
        onPress: onUserPress,
      },
      {
        type: 'url',
        style: styles.url,
        onPress: onUrlPress,
      },
      {
        type: 'email',
        style: styles.email,
        onPress: onEmailPress,
      },
    ]}
  />
);

const styles = StyleSheet.create({
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
  },
  username: {
    backgroundColor: 'rgba(36, 77, 201, 0.05)',
    color: '#244dc9',
  },
  url: {
    textDecorationLine: 'underline',
  },
  email: {
    textDecorationLine: 'underline',
  },
});
