import React, { PureComponent } from 'react';
import { StyleSheet, View, TextInput } from 'react-native';
import { Button } from '../../../../src/components';

export class EditGroupTitleFormComponent extends PureComponent {
  state = {
    title: this.props.discussionTitle || '',
  };

  onChange = (title) => this.setState({ title });

  onSubmit = () =>
    this.props.onSubmit(this.state.title, this.props.discussionReference);

  render() {
    const disabled = this.props.isLoading || this.state.title === '';

    return (
      <View style={styles.container}>
        <TextInput
          value={this.state.title}
          onChangeText={this.onChange}
          onSubmitEditing={!disabled ? this.onSubmit : null}
          maxLength={50}
          returnKeyType="send"
          placeholder="Group title"
          placeholderTextColor="#999"
          style={styles.titleInput}
        />
        <Button
          title="Save"
          style={styles.saveButton}
          onPress={this.onSubmit}
          isLoading={this.props.isLoading}
          disabled={disabled}
          mode="inversed-primary"
        />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleInput: {
    flex: 1,
    height: 30,
    paddingVertical: 0,
    marginRight: 20,
    paddingHorizontal: 10,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    backgroundColor: 'white',
    borderRadius: 10,
  },
  saveButton: {
    width: 60,
    borderRadius: 10,
  },
});
