import MenuContentComponent from './MenuContent.component';
import { fetchMe, logout, meSelector } from '../../src/redux/User';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
  me: meSelector(state),
});

const mapDispatchToProps = {
  fetchMe,
  logout,
};

export const ConnectedMenuContent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuContentComponent);
