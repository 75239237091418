import ConfirmCodeScreenComponent from './ConfirmCodeScreen.component';
import { fetchMe, logout, setAuthToken } from '../../src/redux/User';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withLinkTo } from '../../src/navigation/withLinkTo';

const mapDispatchToProps = {
  fetchMe,
  logout,
  setAuthToken,
};

export const ConnectedConfirmCodeScreen = compose(
  withLinkTo,
  connect(null, mapDispatchToProps)
)(ConfirmCodeScreenComponent);
