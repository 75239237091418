import React from 'react';
import { View, FlatList } from 'react-native';
import ProjectComment from './ProjectComment';

class ProjectConversation extends React.Component {
  renderItem = ({ item, index }) => {
    let margin;
    if (index == 0) {
      margin = 10;
    } else {
      margin = 20;
    }

    return (
      <View style={{ marginLeft: margin }}>
        <ProjectComment
          id={item.id}
          user={item.user}
          comment={item.comment}
          date={item.date}
          private={item.private}
        />
      </View>
    );
  };

  render() {
    return (
      <View style={{ marginTop: 10 }}>
        <FlatList
          numColumns={1}
          data={this.props.messageList}
          renderItem={this.renderItem}
          keyExtractor={(item) => item.id}
        />
      </View>
    );
  }
}

export default ProjectConversation;
