// eslint-disable-next-line import/default
import CodePush from 'react-native-code-push';
import { environment } from '../environment';

const codePushOptionsManual = {
  updateDialog: true,
  installMode: CodePush.InstallMode.IMMEDIATE,
  checkFrequency: CodePush.CheckFrequency.MANUAL,
};

const codePushOptionsAuto = {
  installMode: CodePush.InstallMode.ON_NEXT_RESTART,
  checkFrequency: CodePush.CheckFrequency.ON_APP_START,
};

export const withCodePush = (Component) => {
  if (!environment.featureFlags.isCodePushEnabled) {
    return Component;
  }

  return CodePush(
    environment.featureFlags.isCodePushManual
      ? codePushOptionsManual
      : codePushOptionsAuto
  )(Component);
};
