import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import { Avatar } from 'react-native-elements';
import { accessProject, getProjectPreview } from '../../../../api';
import { adaptProjectPreview } from '../../../../api/Project/adapter';
import { getUrlWithScheme } from '../../../../api/base';

export class CompanyDisclaimer extends React.Component {
  state = {
    loading: true,
    project: null,
  };

  componentDidMount() {
    getProjectPreview(this.props.reference)
      .then(adaptProjectPreview)
      .then((project) => this.setState({ project }))
      .finally(() => this.setState({ loading: false }));
  }

  onAgree = () => {
    this.setState({ loading: true });
    return accessProject(this.props.reference)
      .then(() => {
        if (this.props.onAgree) {
          this.props.onAgree();
        }
      })
      .catch((error) => {
        console.warn('Error when agreeing to disclaimer', { error });
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    if (this.state.loading) {
      return (
        <View style={styles.container}>
          <ActivityIndicator color="black" />
        </View>
      );
    }

    return (
      <View style={styles.container}>
        <Text style={styles.title}>
          {this.state.project
            ? `Before you access to:\n${this.state.project.name}`
            : 'Before you access to this company'}
        </Text>

        {this.state.project ? (
          <View style={{ alignItems: 'center', marginTop: 20 }}>
            <Avatar
              rounded
              size={85}
              placeholderStyle={{
                backgroundColor: this.state.project.logo.color,
              }}
              source={{
                uri: getUrlWithScheme(this.state.project.logo.url_medium),
              }}
            />
          </View>
        ) : null}

        {this.state.project ? (
          <ScrollView style={{ height: '40%' }}>
            <Text style={styles.text}>{this.state.project.description}</Text>
          </ScrollView>
        ) : null}

        <Text style={[styles.text, { fontFamily: 'Freight-Sans-Black' }]}>
          Do you declare having no conflict of interest
          <Text style={styles.text}>
            {' '}
            (and undertake to inform Daphni of any change regarding your
            situation)?
          </Text>
        </Text>

        <View style={styles.buttonContainer}>
          <TouchableOpacity style={styles.button} onPress={this.props.onCancel}>
            <Text style={styles.wText}>Cancel</Text>
          </TouchableOpacity>
          <View style={styles.vLine} />
          <TouchableOpacity style={styles.button} onPress={this.onAgree}>
            <Text style={styles.wTitle}>Yes</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    marginVertical: 20,
  },
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
    width: '60%',
    height: 30,
    marginTop: 50,
    backgroundColor: '#7479ae',
    zIndex: 2,
    borderRadius: 15,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
  },
  button: {
    flex: 1,
    height: 30,
    paddingHorizontal: 17,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center',
    color: '#666666',
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#ffffff',
    marginLeft: 5,
    marginRight: 10,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    marginHorizontal: 10,
    marginTop: 20,
    textAlign: 'center',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
  },
  vLine: {
    width: 1,
    height: '70%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
});
