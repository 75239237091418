import React, { PureComponent } from 'react';
import { StyleSheet } from 'react-native';
import { Button } from '../../../../src/components';

export class RemoveUserOfGroupButton extends PureComponent {
  onPress = () => this.props.removeUser(this.props.userReference);

  render() {
    if (!this.props.isEditingGroup) {
      return null;
    }

    const { isRemovingUser, userReference, userBeingRemoved } = this.props;
    const isUserBeingRemoved =
      isRemovingUser && userBeingRemoved === userReference;

    return (
      <Button
        style={styles.button}
        icon="md-close-circle"
        iconInsteadOfText
        isLoading={isUserBeingRemoved}
        disabled={isRemovingUser}
        onPress={this.onPress}
      />
    );
  }
}

const styles = StyleSheet.create({
  button: {
    backgroundColor: 'transparent',
  },
});
