import React, { PureComponent } from 'react';
import { StyleSheet, Platform, TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import { withNavigation } from '../../../src/navigation/withNavigation';

class NewDiscussionButtonComponent extends PureComponent {
  goToNewDiscussion = () => this.props.navigation.navigate('NewChat');
  render() {
    return (
      <TouchableOpacity style={styles.button} onPress={this.goToNewDiscussion}>
        <Icon
          name="plus-circle"
          type="font-awesome"
          color="#45a9ed"
          size={40}
        />
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    zIndex: 2,
    bottom: 20 + (Platform.OS === 'ios' ? 50 : 0),
    right: 30,
  },
});

export const NewDiscussionButton = withNavigation(NewDiscussionButtonComponent);
