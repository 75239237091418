import React, { PureComponent } from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { theme } from '../../../../lib';

export class SkipButton extends PureComponent {
  render() {
    return (
      <TouchableOpacity
        style={this.props.style}
        onPress={this.props.onPress}
        activeOpacity={0.8}
      >
        <Text style={styles.text}>Skip</Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  text: {
    ...theme.fonts.normal,
    fontSize: 24,
    color: 'white',
  },
});
