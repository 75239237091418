import React from 'react';
import { Text, StyleSheet, InteractionManager } from 'react-native';
import { DiscussionRow, NewDiscussionButton } from './components';
import { Page, ControlledPaginatedFlatList } from '../../src/components';

class ChatListScreenComponent extends React.Component {
  async componentDidMount() {
    InteractionManager.runAfterInteractions(() => {
      this.props.refreshDiscussions(false);
    });
  }

  renderItem = ({ item: discussion }) => (
    <DiscussionRow
      discussion={discussion}
      myReference={this.props.myReference}
    />
  );

  renderEmptyDiscussions = () => (
    <Text style={styles.emptyText}>You don't have conversations yet</Text>
  );

  renderDiscussions = () => (
    <ControlledPaginatedFlatList
      data={this.props.discussions}
      renderItem={this.renderItem}
      renderEmpty={this.renderEmptyDiscussions}
      fetchData={this.props.fetchDiscussions}
      onRefresh={this.props.refreshDiscussions}
      refreshing={this.props.isRefreshing}
      isLoading={this.props.isLoading}
      canFetchMore={this.props.canFetchMore}
    />
  );

  render() {
    return (
      <Page headerColor="#45a9ed" title="Chat">
        {this.renderDiscussions()}
        <NewDiscussionButton />
      </Page>
    );
  }
}

export default ChatListScreenComponent;

const styles = StyleSheet.create({
  emptyText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    marginTop: 50,
    textAlign: 'center',
  },
});
