import { getPrivateApi } from '../base';

const resourceUrl = '/news_posts';

const getNews = async ({ screens, isFeatured, keyword }) => {
  const api = await getPrivateApi();
  return api
    .url(resourceUrl)
    .query(screens ? { screens } : undefined)
    .query(keyword ? { keyword } : undefined)
    .query(isFeatured ? { featured: true } : undefined)
    .get()
    .json();
};

export const getHomeNews = (isFeatured = false) =>
  getNews({ screens: 'home', isFeatured });

export const searchNews = (keyword) => getNews({ keyword });

export const getDaCommunityNews = async () =>
  (await getPrivateApi()).url('/community_news_posts').get().json();
