import { getPrivateApi } from '../base';

const resourceUrl = '/notifications';

export const getNotifications = async (page) => {
  const api = await getPrivateApi();
  return api
    .url(resourceUrl)
    .query({
      page,
    })
    .get()
    .json();
};

export const getNotificationsCount = async () => {
  const api = await getPrivateApi();
  return api
    .url(resourceUrl + '/count')
    .get()
    .json();
};

export const visitNotificationByType = async (type) => {
  const api = await getPrivateApi();
  return api.url(resourceUrl + '/visited').put({
    notification_type: type,
  });
};
