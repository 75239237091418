import React from 'react';
import { DaFlowGenericPage } from './DaFlowGenericPage';

export const DaFlowTermsheet = () => {
  return (
    <DaFlowGenericPage
      daFlowType="termsheet"
      bottomPanel={{
        fetchProjectsParams: {
          state: 'termsheet',
        },
      }}
    />
  );
};
