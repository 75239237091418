import React from 'react';
import { Keyboard, Platform, LayoutAnimation, UIManager } from 'react-native';

UIManager.setLayoutAnimationEnabledExperimental &&
  UIManager.setLayoutAnimationEnabledExperimental(true);

export const keyboardHeightListener = (
  platforms = { ios: true, android: false }
) => (Component) =>
  class KeyboardHeightListener extends React.Component {
    state = {
      keyboardHeight: 0,
    };

    componentDidMount() {
      if (Platform.select(platforms)) {
        this.onKeyboardShowListener = Keyboard.addListener(
          Platform.select({
            ios: 'keyboardWillShow',
            android: 'keyboardDidShow',
          }),
          this.onKeyboardShow
        );
        this.onKeyboardHideListener = Keyboard.addListener(
          Platform.select({
            ios: 'keyboardWillHide',
            android: 'keyboardDidHide',
          }),
          this.onKeyboardHide
        );
      }
    }

    componentWillUnmount() {
      this.onKeyboardShowListener && this.onKeyboardShowListener.remove();
      this.onKeyboardHideListener && this.onKeyboardHideListener.remove();
    }

    getAnimationType = (easing) => {
      const targetAnimation =
        LayoutAnimation.Types[easing] ||
        Platform.select({ ios: 'keyboard', android: 'easeInEaseOut' });

      return Platform.OS === 'android' && targetAnimation === 'keyboard'
        ? 'easeInEaseOut'
        : targetAnimation;
    };

    animate = (duration = 150, easing) => {
      LayoutAnimation.configureNext({
        duration: duration > 10 ? duration : 10,
        update: {
          duration: duration > 10 ? duration : 10,
          type: this.getAnimationType(easing),
        },
      });
    };

    onKeyboardShow = (event) => {
      this.animate(event.duration, event.easing);
      const extraHeightForKeyboardAlternatives = 40;
      this.setState({
        keyboardHeight:
          event.endCoordinates.height +
          Platform.select({
            android: extraHeightForKeyboardAlternatives,
            ios: 0,
          }),
      });
    };

    onKeyboardHide = () => {
      this.animate();
      this.setState({ keyboardHeight: 0 });
    };

    render() {
      return (
        <Component {...this.props} keyboardHeight={this.state.keyboardHeight} />
      );
    }
  };
