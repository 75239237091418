import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Image,
} from 'react-native';

class CategoryButton extends React.Component {
  state = {
    selected: false,
    lastState: false,
  };

  componentDidMount() {
    if (this.props.active) {
      this.setState({ selected: true });
      this.setState({ lastState: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      this.setState({ selected: this.props.active });
    }
  }

  toggleButton() {
    //console.log("Toggle cat button");
    const newState = !this.state.selected;
    this.setState({ selected: newState });
    this.props.onStateChange(newState);
  }

  render() {
    const { selected } = this.state;
    let iconHeight = 35;
    const buttonColor = selected ? '#06b025' : '#666666';
    let fontsize = 16;

    if (this.props.numColumns == 2) {
      iconHeight = 50;
      fontsize = 16;
    }

    return (
      <TouchableOpacity onPress={() => this.toggleButton()}>
        <View
          style={[
            styles.item,
            {
              height: Dimensions.get('window').width / this.props.numColumns,
              width: Dimensions.get('window').width / this.props.numColumns - 3,
            },
          ]}
        >
          <Image
            style={[
              styles.itemIcon,
              { width: iconHeight, height: iconHeight, tintColor: buttonColor },
            ]}
            source={this.props.icon}
            resizeMode="contain"
          />
          <Text
            style={[
              styles.itemText,
              { color: buttonColor, fontSize: fontsize },
            ]}
          >
            {this.props.name}
          </Text>
        </View>
        <View style={styles.bottomLine} />
      </TouchableOpacity>
    );
  }
}

export default CategoryButton;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginVertical: 20,
  },
  item: {
    backgroundColor: '#ffffff',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 1,
  },
  itemText: {
    fontFamily: 'Freight-Sans-Medium',
    textAlign: 'center',
  },
  itemIcon: {
    margin: 5,
    alignSelf: 'center',
  },
  bottomLine: {
    backgroundColor: '#666666',
    width: '90%',
    height: 1,
    alignSelf: 'center',
  },
});
