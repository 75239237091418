import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TextInput,
  Image,
  ActivityIndicator,
} from 'react-native';

import { CheckBox } from 'react-native-elements';
import { localstore } from '../helpers/LocalStore';
import {
  Button,
  innerViewBaseStyles,
  NavigationTouchable,
  Page,
} from '../src/components';
import Deck from '../components/Deck';
import { Video } from '../src/components/Video';
import { KeyboardAvoidingViewExceptOnWeb } from '../src/components/KeyboardAvoidingViewExceptOnWeb';
import { getInvestment } from '../src/api/Investments';
import { getUrlWithScheme } from '../src/api/base';
import {
  adaptProject,
  adaptProjectDeckPages,
} from '../src/api/Project/adapter';
import { getProject } from '../src/api';
import { environment } from '../src/environment';

class CoInvest01Screen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      checked: false,
      showMore: false,
      arrow: 'ios-arrow-down',
      investmentValue: 0,
      errorMessage: '',
      id: -1,
      logo: '',
      name: '',
      digest: '',
      debit: 0,
      credit: 0,
      current: 0,
      totalInvestment: 0,
      investmentNow: 0,
      investmentRemaining: 0,
      remainingTime: '',
      screen1: true,
      screen2: false,
    };
  }

  componentDidMount() {
    this.getCompanyData();

    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result });
      }
    });
  }

  getCompanyData = () => {
    this.setState({ isLoading: true });
    getInvestment(this.props.route.params.id)
      .then((response) => {
        const projectObject = response.data;
        let reference = projectObject.ref;
        if (reference == null) {
          reference = 'none';
        }

        let start_at = projectObject.start_at;
        if (start_at == null) {
          start_at = 'none';
        }

        let end_at = projectObject.end_at;
        if (end_at == null) {
          end_at = 'none';
        }

        let logoPic = 'https:' + projectObject.project.logo.url_medium;
        if (logoPic != null) {
          if (logoPic == 'https:/missing/project.png') {
            logoPic =
              'https://' + environment.API_HOST + '/missing/project.png';
          } else {
            let url = logoPic.toString();
            url = url.replace('staging', 'prod');
            logoPic = url;
          }
        } else {
          logoPic = 'https://' + environment.API_HOST + '/missing/project.png';
        }

        let name = projectObject.project.label;
        if (name == null) {
          name = 'Unknown';
        }

        let digest = projectObject.digest;
        if (digest == null) {
          digest = '';
        }

        let digestPrivate = projectObject.digest_private;
        if (digestPrivate == null) {
          digestPrivate = '';
        }

        let video = projectObject.video;

        let credit = projectObject.transactions.credit;
        if (credit == null) {
          credit = '';
        }

        let debit = projectObject.transactions.debit;
        if (debit == null) {
          debit = '';
        }

        let current = projectObject.transactions.current;
        if (current == null) {
          current = '';
        }

        const videoThumbnail = getUrlWithScheme(projectObject.video_thumbnail);

        const date1 = new Date(projectObject.start_at);
        const date2 = new Date(projectObject.end_at);
        const diffTime = Math.abs(date2 - date1);

        let seconds = parseInt(diffTime, 10);
        var days = Math.floor(seconds / (3600 * 24));
        seconds -= days * 3600 * 24;
        var hrs = Math.floor(seconds / 3600);
        seconds -= hrs * 3600;
        var mnts = Math.floor(seconds / 60);
        seconds -= mnts * 60;

        const remainingTime = projectObject.end_at.substring(0, 10);

        getProject(projectObject.project.ref)
          .then(({ data: project }) => {
            this.setState({
              deckPages: adaptProjectDeckPages(project),
              deckUrl: getUrlWithScheme(project.deck_url),
            });
          })
          .catch((deckError) => {
            // ignore error as showing deck is optional
            console.warn('Error when getting project for deck pages', {
              deckError,
            });
          });

        this.setState(
          {
            reference,
            project: adaptProject(projectObject.project),
            logo: logoPic,
            name,
            digest,
            start_at,
            end_at,
            digestPrivate,
            video,
            videoThumbnail,
            credit,
            debit,
            current,
            remainingTime,
            memoUrl: getUrlWithScheme(projectObject.memo),
          },
          () => {
            this.setState({ loading: false });
          }
        );
      })
      .catch((error) => {
        console.log('Error retrieving coinvest project', error);
        this.setState({ sError: true, loading: false });
      })
      .then(() => {
        this.setState({ isLoading: false });
      });
  };

  toggleCheckButton() {
    const newState = !this.state.checked;
    this.setState({ checked: newState, errorMessage: '' });
  }

  _handleTextChange = (event) => {
    this.setState({ investmentValue: event.nativeEvent.text });
  };

  agreeButton() {
    const bearerToken = this.state.bearerToken;
    if (this.state.checked) {
      fetch(
        'https://' +
          environment.API_HOST +
          '/v4/investments/' +
          this.state.id +
          '/commit',
        {
          // mode: 'no-cors',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + bearerToken,
          },
          body: JSON.stringify({
            investment: {
              debit: this.state.investmentValue,
            },
          }),
        }
      ).then((response) => {
        //  console.log(response);
        if (response.status == 200) {
          console.log(this.state.investmentValue);
          this.setState({ screen2: true });
          this.setState({ screen1: false });
        }
        if (response.status == 403) {
          this.setState({ screen3: true });
          this.setState({ screen2: false });
          this.setState({ screen1: false });
        }
      });
    } else {
      this.setState({
        errorMessage: 'You need to agree to the terms before continuing',
      });
    }
  }

  showOrNot(screen) {
    let applyStyle = {};
    if (this.state.screen3 & (screen == 'screen3')) {
      console.log('me ejecuto screen3');

      applyStyle = {
        display: 'flex',
      };
    } else if (!this.state.screen3 & (screen == 'screen3')) {
      applyStyle = {
        display: 'none',
      };
    }

    if (this.state.screen2 & (screen == 'screen2')) {
      console.log('me ejecuto screen2');
      applyStyle = {
        display: 'flex',
      };
    } else if (!this.state.screen2 & (screen == 'screen2')) {
      applyStyle = {
        display: 'none',
      };
    }

    if (this.state.screen1 & (screen == 'screen1')) {
      console.log('me ejecuto screen1');

      applyStyle = {
        display: 'flex',
      };
    } else if (!this.state.screen1 & (screen == 'screen1')) {
      applyStyle = {
        display: 'none',
      };
    }
    return applyStyle;
  }

  render() {
    let digest;
    if (this.state.showMore) {
      digest = <Text style={styles.text}>{this.state.digest}</Text>;
    } else {
      digest = <Text style={styles.text}>{this.state.digest}</Text>;
    }

    let soFar;
    if (this.state.credit != null && this.state.debit != null) {
      let soFar1 = (this.state.debit * 100) / this.state.credit;
      let soFar2 = soFar1 + '';
      soFar = soFar2.substring(0, 4);
    } else {
      soFar = 0;
    }

    if (this.state.isLoading) {
      return (
        <Page headerColor="#7e0d86" title="Co-Invest">
          <View style={{ marginTop: 20 }}>
            <ActivityIndicator color="#7e0d86" />
          </View>
        </Page>
      );
    }

    return (
      <Page headerColor="#7e0d86" title="Co-Invest">
        <KeyboardAvoidingViewExceptOnWeb
          behavior="padding"
          keyboardVerticalOffset={100}
        >
          <ScrollView
            style={innerViewBaseStyles.contentTopPanelDefaultFix}
            contentContainerStyle={
              innerViewBaseStyles.scrollViewContentContainer
            }
          >
            <View style={{ marginTop: 20, marginHorizontal: 20 }}>
              <Image
                source={{ uri: this.state.logo }}
                resizeMode="contain"
                resizeMethod="scale"
                style={styles.logoImage}
              />

              <Text
                style={[styles.text, { textAlign: 'center', marginTop: 0 }]}
              >
                Remaining time of opportunity:
              </Text>
              <Text style={styles.title}>{this.state.remainingTime}</Text>

              <Text style={[styles.text, { marginTop: 20 }]}>
                {this.state.name}
              </Text>
              {digest}
              {!!this.state.project && (
                <NavigationTouchable
                  style={[styles.button, { marginVertical: 20 }]}
                  to={`/dahouse/project/${this.state.project.reference}`}
                >
                  <View pointerEvents="none">
                    <Button title="More about the company" />
                  </View>
                </NavigationTouchable>
              )}
              {!!this.state.deckPages && !!this.state.deckUrl && (
                <View
                  style={{
                    alignContent: 'center',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <Text style={styles.title}>Deck</Text>
                  <Deck
                    deckPages={this.state.deckPages}
                    deckUrl={this.state.deckUrl}
                    navigation={this.props.navigation}
                  />
                </View>
              )}
              {!!this.state.video && (
                <Video
                  url={this.state.video}
                  thumbnailUrl={this.state.videoThumbnail}
                />
              )}
            </View>

            <View style={styles.line} />
            <View>
              <Text style={styles.title}>Your portfolio</Text>
              <View
                style={[
                  styles.pairedData,
                  { marginHorizontal: 20, marginTop: 5 },
                ]}
              >
                <Text style={[styles.text, { width: '60%' }]}>
                  Co-investment capacity:
                </Text>
                <Text style={styles.text}>€{this.state.credit} </Text>
              </View>
              <View style={[styles.pairedData, { marginHorizontal: 20 }]}>
                <Text style={[styles.text, { width: '60%' }]}>
                  Co-invested so far ({soFar}%):
                </Text>
                <Text style={styles.text}>€{this.state.debit} </Text>
              </View>
            </View>
            <View style={styles.line} />
            <View>
              <Text style={styles.title}>Co-invest deal</Text>
              <View style={{ marginHorizontal: 20, marginTop: 5 }}>
                {!!this.state.memoUrl && (
                  <NavigationTouchable
                    style={{ marginBottom: 10 }}
                    to={`/PDFDeck?deckUrl=${encodeURIComponent(
                      this.state.memoUrl
                    )}`}
                  >
                    <View pointerEvents="none">
                      <Button title="See invest memo" />
                    </View>
                  </NavigationTouchable>
                )}
                <Text style={styles.text}>{this.state.digestPrivate}</Text>
              </View>
              <View style={this.showOrNot('screen1')}>
                <View style={{ marginHorizontal: 20, marginTop: 10 }}>
                  <Text style={styles.lightText}>Amount to co-invest:</Text>
                  <TextInput
                    style={[
                      styles.text,
                      { borderColor: '#999999', borderBottomWidth: 1 },
                    ]}
                    returnKeyType="next"
                    keyboardType="numeric"
                    onChange={this._handleTextChange}
                  />
                </View>
                <View style={[styles.pairedData, { marginVertical: 20 }]}>
                  <CheckBox
                    checked={this.state.checked}
                    onPress={() => this.toggleCheckButton()}
                    containerStyle={{ width: 20 }}
                  />
                  <Text
                    onPress={() => this.toggleCheckButton()}
                    style={[
                      styles.lightText,
                      { width: 250, marginHorizontal: 10 },
                    ]}
                  >
                    I promise to invest €{this.state.investmentValue}, in the
                    co-investment, vehicle (I am aware that this amount can be
                    lowered depending on the total amount of coinvestment
                    commitment received).
                  </Text>
                </View>
                <Text style={[styles.title, { color: 'red' }]}>
                  {this.state.errorMessage}
                </Text>
                <View style={[styles.buttonContainer, { width: 100 }]}>
                  <Text
                    style={styles.wTitle}
                    onPress={() => this.agreeButton()}
                  >
                    I commit
                  </Text>
                </View>
              </View>
              <View style={this.showOrNot('screen2')}>
                <Text
                  style={[
                    styles.text,
                    { marginHorizontal: 20, marginVertical: 20 },
                  ]}
                >
                  You already commited to co-invest {'\n'} €
                  {this.state.investmentValue}{' '}
                </Text>
              </View>

              <View style={this.showOrNot('screen3')}>
                <Text
                  style={[
                    styles.text,
                    { marginHorizontal: 20, marginVertical: 20 },
                  ]}
                >
                  You have already sent a transaction at this project
                </Text>
              </View>
            </View>
          </ScrollView>
        </KeyboardAvoidingViewExceptOnWeb>
      </Page>
    );
  }
}

export default CoInvest01Screen;

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    height: 25,
    padding: 5,
    backgroundColor: '#7e0d86',
    borderRadius: 13,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    textAlign: 'center',
    color: '#666666',
  },
  logoImage: {
    height: 40,
    width: 40,
    alignSelf: 'center',
    marginVertical: 20,
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    textAlign: 'center',
    color: '#ffffff',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
  lightText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#999999',
  },
  pairedData: {
    flexDirection: 'row',
  },
  line: {
    borderBottomColor: '#666666',
    borderBottomWidth: 1,
    margin: 20,
  },
});
