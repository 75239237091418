import React, { Fragment, PureComponent } from 'react';
import {
  ActivityIndicator,
  ImageBackground,
  Platform,
  SafeAreaView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { withNavigation } from '../navigation/withNavigation';
import { StatusBarHeight } from '../lib/StatusBarHeight';
import { theme } from '../lib';
import { desktopOrLargerMinPixel } from '../lib/responsive';
import { withWindowDimensions } from '../hoc';

class PageComponent extends PureComponent {
  renderHeaderLeft = () =>
    this.props.renderHeaderLeft ? this.props.renderHeaderLeft() : null;
  renderHeaderRight = () =>
    this.props.renderHeaderRight ? this.props.renderHeaderRight() : null;
  renderHeaderTitle = () => {
    if (this.props.renderHeaderTitle) {
      return this.props.renderHeaderTitle();
    }

    return (
      <Text
        numberOfLines={1}
        style={[
          styles.title,
          this.props.alignTitleLeft
            ? styles.leftAlignedTitle
            : styles.centerAlignedTitle,
          !!this.props.renderHeaderLeft && styles.titleWithHeaderLeft,
        ]}
      >
        {this.props.title}
      </Text>
    );
  };

  goBack = () => this.props.navigation.goBack();

  renderChildren = () => {
    if (this.props.loading) {
      return (
        <View style={styles.centerChildren}>
          <ActivityIndicator
            size="large"
            color={this.props.loadingColor || this.props.headerColor || 'grey'}
          />
        </View>
      );
    }

    if (this.props.error) {
      return (
        <View style={styles.centerChildren}>
          <Text style={styles.errorText}>
            {typeof this.props.error === 'string'
              ? this.props.error
              : 'Error during loading, please try again later'}
          </Text>
        </View>
      );
    }

    return this.props.children;
  };

  shouldNotOffset = () =>
    this.props.dimensions.width >= desktopOrLargerMinPixel &&
    this.props.withoutAutoOffsetOnDesktop;

  renderContent = () => {
    const content = (
      <View
        style={[
          styles.contentContainer,
          (this.shouldNotOffset() || this.props.withoutTopPanel) && {
            marginTop: 0,
          },
        ]}
      >
        {this.renderChildren()}
      </View>
    );

    if (this.props.imageBackground) {
      return (
        <ImageBackground
          source={require('../../assets/lightBackground.png')}
          style={styles.imageBackground}
          resizeMode="contain"
        >
          {content}
        </ImageBackground>
      );
    }

    return content;
  };

  renderHeaderContent = () => {
    if (this.props.renderHeaderContent) {
      return this.props.renderHeaderContent();
    }

    return (
      <Fragment>
        <TouchableOpacity onPress={this.goBack} style={styles.goBackButton}>
          <Ionicons
            name="ios-arrow-back"
            size={32}
            style={styles.goBackButtonIcon}
            color="#fff"
          />
        </TouchableOpacity>
        {this.renderHeaderLeft()}
        {this.renderHeaderTitle()}
        {this.renderHeaderRight()}
      </Fragment>
    );
  };

  render() {
    return (
      <Fragment>
        <SafeAreaView
          style={[
            styles.statusBarHack,
            { backgroundColor: this.props.headerColor || 'white' },
          ]}
        />
        <SafeAreaView
          style={[
            styles.container,
            {
              backgroundColor: this.props.backgroundColor || 'white',
            },
          ]}
        >
          {this.props.withoutTopPanel ? null : this.shouldNotOffset() ? (
            this.renderHeaderContent()
          ) : (
            <View
              style={[
                styles.topPanel,
                { backgroundColor: this.props.headerColor },
              ]}
            >
              {this.renderHeaderContent()}
            </View>
          )}
          {this.renderContent()}
        </SafeAreaView>
      </Fragment>
    );
  }
}

const GO_BACK_BUTTON_SIZE = 50;
const OFFSET_WORKAROUND = theme.margin.unit * 2;

export const topPanelHeight =
  50 + (Platform.OS === 'ios' ? 0 : StatusBarHeight.height);
const styles = StyleSheet.create({
  statusBarHack: {
    flex: 0,
  },
  container: {
    flex: 1,
  },
  topPanel: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    width: '100%',
    paddingTop: Platform.OS === 'ios' ? 0 : StatusBarHeight.height,
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    height: topPanelHeight,
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: OFFSET_WORKAROUND,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
  },
  centerAlignedTitle: {
    flex: 1,
    textAlign: 'center',
    marginRight: GO_BACK_BUTTON_SIZE - OFFSET_WORKAROUND,
  },
  leftAlignedTitle: {
    flex: 1,
    textAlign: 'left',
  },
  titleWithHeaderLeft: {
    marginLeft: 10,
  },
  goBackButton: {
    alignItems: 'center',
    justifyContent: 'center',
    height: GO_BACK_BUTTON_SIZE,
    width: GO_BACK_BUTTON_SIZE,
  },
  goBackButtonIcon: {
    marginHorizontal: 15,
  },
  contentContainer: {
    flex: 1,
    marginTop: topPanelHeight,
  },
  imageBackground: {
    flex: 1,
    width: '100%',
    height: '100%',
  },
  centerChildren: {
    flex: 1,
    marginTop: 80,
    alignItems: 'center',
  },
  errorText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    textAlign: 'center',
  },
});

export const innerViewBaseStyles = StyleSheet.create({
  // eslint-disable-next-line react-native/no-unused-styles
  contentTopPanelDefaultFix: {
    marginTop: -8,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  contentTopPanelMaxFix: {
    marginTop: -20,
  },
  // eslint-disable-next-line react-native/no-unused-styles
  scrollViewContentContainer: {
    paddingBottom: 60,
  },
});

export const Page = withWindowDimensions(withNavigation(PageComponent));
