import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  FlatList,
  Dimensions,
  TouchableOpacity,
  Image,
} from 'react-native';

const formatData = (data, numColumns) => {
  const numberOfFullRows = Math.floor(data.length / numColumns);

  let numberOfElementsLastRow = data.length - numberOfFullRows * numColumns;
  while (
    numberOfElementsLastRow !== numColumns &&
    numberOfElementsLastRow !== 0
  ) {
    data.push({ key: `blank-${numberOfElementsLastRow}`, empty: true });
    numberOfElementsLastRow++;
  }

  return data;
};

const timelineCategories = [
  {
    id: '01',
    label: 'Select all',
    icon: require('../assets/icons/categories/category_01.png'),
  },
  {
    id: '02',
    label: 'Da Flow',
    icon: require('../assets/icons/menuFlowIcon.png'),
  },
  {
    id: '03',
    label: 'Da House',
    icon: require('../assets/icons/menuHouseIcon.png'),
  },
  {
    id: '04',
    label: 'Legal',
    icon: require('../assets/icons/categories/category_16.png'),
  },
  {
    id: '05',
    label: 'Events',
    icon: require('../assets/icons/eventsIcon.png'),
  },
  {
    id: '06',
    label: 'General',
    icon: require('../assets/icons/menuHomeIcon.png'),
  },
];

class TimelineCategorySelectionOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCategory: 0,
    };
    this._onStateChange = this._onStateChange.bind(this);
  }

  _onStateChange = (id) => {
    const catIndex = timelineCategories.findIndex((c) => {
      return c.id === id;
    });

    console.log('Selected category ' + catIndex);
    this.setState({ selectedCategory: catIndex }, () => {
      this.props.updateSelectedCategory(catIndex);
      this.props.showOverlay(false);
    });
  };

  cancelButton() {
    this.props.showOverlay(false);
  }

  renderItem = ({ item, index }) => {
    if (item.empty === true) {
      return (
        <View
          style={[
            styles.itemInvisible,
            { height: Dimensions.get('window').width / this.props.numColumns },
          ]}
        />
      );
    }

    /*if (index === 0) //First button is for cancel
        {
            return (
                <View style={{ flexDirection: 'row' }}>
                    <TouchableOpacity onPress={() => this.cancelButton()}>
                        <View style={[styles.item, { height: Dimensions.get('window').width / this.props.numColumns, width: Dimensions.get('window').width / this.props.numColumns - 3 }]}>
                            <Ionicons name='ios-arrow-back' size={35} color='#666666' />
                            <Text style={[styles.itemText, { color: '#666666', fontSize: 16 }]}>{item.label}</Text>
                        </View>
                        <View style={styles.bottomLine}></View>
                    </TouchableOpacity>
                </View>
            );
        }*/

    if (index % 3 === 1) {
      //It's the middle item
      return (
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.verticalSpacer} />
          <TouchableOpacity onPress={() => this._onStateChange(item.id)}>
            <View
              style={[
                styles.item,
                {
                  height:
                    Dimensions.get('window').width / this.props.numColumns,
                  width:
                    Dimensions.get('window').width / this.props.numColumns - 3,
                },
              ]}
            >
              <Image
                style={[styles.itemIcon, { tintColor: '#666666' }]}
                source={item.icon}
                resizeMode="contain"
              />
              <Text
                style={[styles.itemText, { color: '#666666', fontSize: 16 }]}
              >
                {item.label}
              </Text>
            </View>
            <View style={styles.bottomLine} />
          </TouchableOpacity>
          <View style={styles.verticalSpacer} />
        </View>
      );
    }
    return (
      <View>
        <TouchableOpacity onPress={() => this._onStateChange(item.id)}>
          <View
            style={[
              styles.item,
              {
                height: Dimensions.get('window').width / this.props.numColumns,
                width:
                  Dimensions.get('window').width / this.props.numColumns - 3,
              },
            ]}
          >
            <Image
              style={[styles.itemIcon, { tintColor: '#666666' }]}
              source={item.icon}
              height={35}
            />
            <Text style={[styles.itemText, { color: '#666666', fontSize: 16 }]}>
              {item.label}
            </Text>
          </View>
          <View style={styles.bottomLine} />
        </TouchableOpacity>
      </View>
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <FlatList
          data={formatData(timelineCategories, this.props.numColumns)}
          style={styles.container}
          renderItem={this.renderItem}
          numColumns={this.props.numColumns}
          keyExtractor={(item) => item.id}
        />
      </View>
    );
  }
}

export default TimelineCategorySelectionOverlay;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  itemInvisible: {
    backgroundColor: 'transparent',
    flex: 1,
    margin: 1,
  },
  verticalSpacer: {
    backgroundColor: '#666666',
    width: 1,
    height: '60%',
    alignSelf: 'center',
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#7479ae',
    zIndex: 3,
    bottom: 0,
    borderRadius: 15,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 30,
    width: '70%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    marginHorizontal: 35,
    textAlign: 'center',
  },
  vLine: {
    width: 1,
    height: '80%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
  item: {
    backgroundColor: '#ffffff',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    margin: 1,
  },
  itemText: {
    fontFamily: 'Freight-Sans-Medium',
    textAlign: 'center',
  },
  itemIcon: {
    width: 35,
    height: 35,
    margin: 5,
    alignSelf: 'center',
  },
  bottomLine: {
    backgroundColor: '#666666',
    width: '90%',
    height: 1,
    alignSelf: 'center',
  },
});
