import { connect } from 'react-redux';
import { EditGroupTitleFormComponent } from './EditGroupTitleForm.component';
import { isLoadingSelector } from '../../../../src/redux/LoadingStatus';
import { changeDiscussionTitle } from '../../../../src/redux/Discussion';

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector('changeDiscussionTitle')(state),
});

const mapDispatchToProps = {
  onSubmit: changeDiscussionTitle,
};

export const ConnectedEditGroupTitleForm = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditGroupTitleFormComponent);
