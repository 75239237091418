import React, { PureComponent } from 'react';
import { StyleSheet, View, Text, Image, Platform } from 'react-native';
import {
  heightPercentageToDP,
  widthPercentageToDP,
} from 'react-native-responsive-screen';
import { theme } from '../../../../lib';
import { SafeAreaScrollView } from '../../../../components';
import { Circle } from '../Circle';

const IMAGE_SIZE = heightPercentageToDP(15);

export class Slide extends PureComponent {
  renderBulletPoint = (bulletPoint, index) => (
    <View key={index} style={styles.bulletPointContainer}>
      <Text style={styles.bulletPoint}>{'• '}</Text>
      <Text style={[styles.bulletPoint, styles.bulletPointText]}>
        {bulletPoint}
      </Text>
    </View>
  );

  render() {
    const { slide } = this.props;

    return (
      <SafeAreaScrollView
        style={[styles.container, { backgroundColor: slide.color }]}
        contentContainerStyle={styles.contentContainer}
      >
        <Circle
          filled
          size={IMAGE_SIZE * 1.5}
          style={[
            styles.circle,
            slide.imageBottomOfCircle && styles.circleWithImageAtBottom,
          ]}
        >
          {slide.ImageComponent ? (
            <slide.ImageComponent width={IMAGE_SIZE} height={IMAGE_SIZE} />
          ) : slide.imageAsset ? (
            <Image
              style={styles.image}
              source={slide.imageAsset}
              resizeMode="contain"
            />
          ) : null}
        </Circle>
        <Text style={styles.title}>{slide.title}</Text>
        <View style={styles.bulletPoints}>
          {slide.bulletPoints.map(this.renderBulletPoint)}
        </View>
      </SafeAreaScrollView>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    paddingTop: Platform.select({ web: 20 }),
  },
  contentContainer: {
    paddingTop: heightPercentageToDP(8),
    paddingBottom: heightPercentageToDP(2),
    alignItems: 'center',
  },
  circle: {
    overflow: 'hidden',
  },
  circleWithImageAtBottom: {
    justifyContent: 'flex-end',
  },
  image:
    Platform.OS === 'web'
      ? { width: 3 * IMAGE_SIZE, height: IMAGE_SIZE }
      : { flex: 1, maxHeight: IMAGE_SIZE },
  title: {
    ...theme.fonts.normal,
    ...theme.fonts.bold,
    color: 'white',
    fontSize: 32,
    marginTop: heightPercentageToDP(8),
    marginBottom: heightPercentageToDP(4),
  },
  bulletPoints: {
    marginHorizontal: widthPercentageToDP(8),
  },
  bulletPointContainer: {
    flexDirection: 'row',
    marginBottom: heightPercentageToDP(1),
  },
  bulletPoint: {
    ...theme.fonts.normal,
    color: 'white',
    fontSize: 24,
  },
  bulletPointText: {
    marginRight: widthPercentageToDP(4),
  },
});
