import React, { useCallback, useState } from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { theme } from '../../../../lib';
import { useResponsive } from '../../../../lib/responsive';
import {
  Project,
  WIDTH_PROJECT_MOBILE,
  WIDTH_PROJECT_DESKTOP,
} from './components';

const projectKeyExtractor = (project) => project.reference;

const renderProject = ({ item: project }) => <Project project={project} />;

export const TopPanel = ({ title, projects }) => {
  const { isDesktopOrLarger } = useResponsive();
  const [flatListSize, setFlatListSize] = useState();
  const onLayout = useCallback(
    ({ nativeEvent }) => setFlatListSize(nativeEvent.layout),
    []
  );

  if (!projects.length) {
    return null;
  }

  const flatListHorizontalPadding = isDesktopOrLarger ? 50 : 10;
  const canScrollProjects = flatListSize
    ? flatListSize.width - 2 * flatListHorizontalPadding <
      projects.length *
        (isDesktopOrLarger ? WIDTH_PROJECT_DESKTOP : WIDTH_PROJECT_MOBILE)
    : true;

  return (
    <View
      style={[
        styles.container,
        !canScrollProjects && styles.containerUnscrollable,
        isDesktopOrLarger && styles.desktopContainer,
      ]}
    >
      <Text style={styles.title}>{title}</Text>
      <FlatList
        style={styles.list}
        scrollEnabled={canScrollProjects}
        contentContainerStyle={[
          { paddingHorizontal: flatListHorizontalPadding },
          !canScrollProjects && styles.listContentUnscrollable,
        ]}
        onLayout={onLayout}
        horizontal
        showsHorizontalScrollIndicator={false}
        data={projects}
        renderItem={renderProject}
        keyExtractor={projectKeyExtractor}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    marginTop: 10,
    marginBottom: 10,
    backgroundColor: '#7479AE',
    paddingTop: 20,
    paddingBottom: 15,
  },
  desktopContainer: {
    marginBottom: 30,
  },
  containerUnscrollable: {
    borderRadius: 50,
    paddingVertical: 20,
  },
  listContentUnscrollable: {
    flex: 1,
    justifyContent: 'space-evenly',
  },
  title: {
    textAlign: 'center',
    ...theme.fonts.bold,
    color: 'white',
    fontSize: 18,
  },
  list: {
    marginVertical: 20,
  },
});
