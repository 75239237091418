import { Alert } from './Alert';

const getErrorMessage = (error) => {
  if (
    error &&
    error.json &&
    error.json.errors &&
    error.json.errors[0] &&
    error.json.errors[0].title
  ) {
    return error.json.errors[0].title;
  }

  return 'Sorry, an unknown error occurred';
};

export const handleError = (error) => {
  const errorMessage = getErrorMessage(error);
  Alert.alert(errorMessage);
};
