import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, ActivityIndicator } from 'react-native';
import { Card } from '../Card';
import { getAppointedUsers } from '../../../../api';
import { adaptUsers } from '../../../../api/User/adapter';
import Daphnipolitan from '../../../../../components/Daphnipolitan/Daphnipolitan';

export const WeekDaphnipolitan = () => {
  const [weekDaphnipolitan, setWeekDaphnipolitan] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getAppointedUsers()
      .then(adaptUsers)
      .then((weekDaphnipolitans) => {
        setIsLoading(false);
        if (weekDaphnipolitans.length) {
          setWeekDaphnipolitan(weekDaphnipolitans[0]);
        }
      })
      .catch((error) => {
        console.error('Error when loading', error);
        setIsLoading(false);
      });
  }, []);

  if (!weekDaphnipolitan) {
    if (!isLoading) {
      return null;
    }

    return (
      <Card>
        <ActivityIndicator size="large" color="grey" />
      </Card>
    );
  }

  return (
    <Card>
      <Text style={styles.title}>Daphnipolitan of the week</Text>
      <Daphnipolitan {...weekDaphnipolitan} />
    </Card>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666666',
    fontFamily: 'Freight-Sans-Black',
    marginBottom: 20,
  },
});
