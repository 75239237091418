import React from 'react';
import { View, StyleSheet, Image, useWindowDimensions } from 'react-native';
const SPLASH_RATIO = 1500 / 2436;

export const LoadingView = () => {
  const dimensions = useWindowDimensions();
  return (
    <View style={styles.container}>
      <Image
        source={require('../../assets/splash.png')}
        style={{
          width: SPLASH_RATIO * dimensions.height,
          height: dimensions.height,
        }}
        resizeMode="contain"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#46a8ef',
    alignItems: 'center',
  },
});
