import { useCallback, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { useResponsive } from '../../../lib/responsive';
import { useFunds } from './useFunds';
import { useHouseProjects } from './useHouseProjects';

export const useDaHousePage = () => {
  const navigation = useNavigation();
  const { isDesktopOrLarger } = useResponsive();
  const [projectCountPerColumns, setProjectCountPerColumns] = useState(
    isDesktopOrLarger ? 3 : 2
  );

  const [activeFundsMap, setActiveFundsMap] = useState({});
  const { funds, isLoading: isLoadingFunds } = useFunds({
    onCompleted: (newFunds) => {
      setActiveFundsMap(
        newFunds.reduce((map, fund) => {
          map[fund.id] = true;
          return map;
        }, {})
      );
    },
  });

  const [isRefreshing, setIsRefreshing] = useState(false);
  const {
    isLoading,
    canFetchMore,
    allProjects,
    fetchMore,
    resetProjectsRef,
  } = useHouseProjects({
    isRefreshing,
    isLoadingFunds,
    activeFundsMap,
  });

  const onRefresh = useCallback(() => {
    setIsRefreshing(true);
    resetProjectsRef.current();
    return fetchMore(0).finally(() => {
      setIsRefreshing(false);
    });
  }, [fetchMore, resetProjectsRef]);

  return {
    navigation,
    isDesktopOrLarger,
    isRefreshing,
    onRefresh,
    isLoading: isLoading || isLoadingFunds,
    canFetchMore,
    allProjects,
    fetchMore,
    funds,
    isLoadingFunds,
    activeFundsMap,
    setActiveFundsMap,
    projectCountPerColumns,
    setProjectCountPerColumns,
  };
};
