import React, { useEffect, useState } from 'react';
import { Text, StyleSheet, ActivityIndicator } from 'react-native';
import { Card } from '../Card';
import { getStatistics } from '../../../../api';
import { StatLine } from '../StatLine';

export const GeneralOverview = () => {
  const [stats, setStats] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getStatistics()
      .then((data) => {
        setIsLoading(false);
        setStats({
          applied: data.projects_count.applied,
          indahouse: data.projects_count.by_state.portfolio,
          investors: data.users_count.investors,
          daphnipolitans: data.users_count.all,
        });
      })
      .catch((error) => {
        console.error('Error when loading', error);
        setIsLoading(false);
      });
  }, []);

  if (!stats) {
    if (!isLoading) {
      return null;
    }

    return (
      <Card>
        <ActivityIndicator size="large" color="grey" />
      </Card>
    );
  }

  return (
    <Card>
      <Text style={styles.title}>General Overview</Text>

      <StatLine label={stats.applied} value={'Applied'} />
      <StatLine label={stats.indahouse} value={'In da house'} />
      <StatLine label={stats.investors} value={'Investors'} />
      <StatLine label={stats.daphnipolitans} value={'Daphnipolitans'} />
    </Card>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666666',
    fontFamily: 'Freight-Sans-Black',
    marginBottom: 20,
  },
});
