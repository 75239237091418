import React, { PureComponent } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Image } from 'react-native';
import { Divider, Icon } from 'react-native-elements';
import {
  formatDiscussionPreview,
  formatDiscussionTitle,
  formatDiscussionAvatar,
} from '../lib';
import { formatRelativeDate } from '../../../src/lib';
import { withNavigation } from '../../../src/navigation/withNavigation';

class DiscussionRowComponent extends PureComponent {
  goToDiscussion = () =>
    this.props.navigation.navigate('ChatWindow', {
      discussionReference: this.props.discussion.reference,
    });

  render() {
    const { lastMessage, unreadMessagesCount, isGroup } = this.props.discussion;

    return (
      <TouchableOpacity style={styles.container} onPress={this.goToDiscussion}>
        <View style={styles.row}>
          <Image
            style={styles.profileImage}
            source={{ uri: formatDiscussionAvatar(this.props.discussion) }}
          />
          <View style={styles.rightPartContainer}>
            <View style={[styles.row, styles.titleRow]}>
              {isGroup && (
                <Icon
                  name="users"
                  type="font-awesome"
                  color="#9a9a9a"
                  size={16}
                  containerStyle={styles.groupIcon}
                />
              )}
              <Text numberOfLines={1} style={styles.title}>
                {formatDiscussionTitle(this.props.discussion)}
              </Text>
            </View>
            <Text numberOfLines={1} style={styles.preview}>
              {formatDiscussionPreview(lastMessage, this.props.myReference)}
            </Text>
            {!!lastMessage && (
              <Text numberOfLines={1} style={styles.date}>
                {formatRelativeDate(lastMessage.date)}
              </Text>
            )}
          </View>
          {unreadMessagesCount > 0 && (
            <View style={styles.unreadCountContainer}>
              <View style={styles.unreadCountBadge} />
            </View>
          )}
        </View>
        <Divider style={styles.line} />
      </TouchableOpacity>
    );
  }
}

export const DiscussionRow = withNavigation(DiscussionRowComponent);

const styles = StyleSheet.create({
  container: {
    alignContent: 'center',
    justifyContent: 'center',
    marginHorizontal: 15,
  },
  row: {
    flexDirection: 'row',
  },
  profileImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
    overflow: 'hidden',
  },
  rightPartContainer: {
    flex: 1,
  },
  titleRow: {
    marginTop: 5,
  },
  title: {
    flex: 1,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 18,
    color: '#707070',
    marginBottom: 5,
  },
  unreadCountContainer: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  unreadCountBadge: {
    width: 14,
    height: 14,
    borderRadius: 7,
    backgroundColor: '#45a9ed',
  },
  groupIcon: {
    marginRight: 5,
  },
  preview: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    marginRight: 15,
    color: '#7C7C7C',
  },
  date: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    flexWrap: 'wrap',
    color: '#a9a9a9',
  },
  line: {
    width: '100%',
    backgroundColor: '#989898',
    marginVertical: 15,
    alignSelf: 'center',
  },
});
