import { getPrivateApi } from '../base';

const resourceUrl = '/timeline';

export const createGetTimeline = (type_of) => async (page) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}`)
    .query(
      type_of
        ? {
            page,
            type_of,
          }
        : { page }
    )
    .get()
    .json();
};
