import { useCallback, useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { useDispatch } from 'react-redux';
import * as Font from 'expo-font';
import { localstore } from '../helpers/LocalStore';
import { fetchMe, setAuthToken, setSeenOnboarding } from './redux/User';

export const useApp = () => {
  const dispatch = useDispatch();
  const [isFontLoaded, setFontLoaded] = useState(false);
  const [hasVerifiedAuthToken, setHasVerifiedAuthToken] = useState(false);

  useEffect(() => {
    Font.loadAsync({
      DaphniFont: require('../assets/fonts/DaphniFont.otf'),
      'Freight-Sans-Book': require('../assets/fonts/Freight-Sans-Book.otf'),
      'Freight-Sans-Black': require('../assets/fonts/Freight-Sans-Black.otf'),
      'Freight-Sans-Medium': require('../assets/fonts/Freight-Sans-Medium.otf'),
      ...Platform.select({
        web: {
          Ionicons: require('react-native-vector-icons/Fonts/Ionicons.ttf'),
          FontAwesome: require('react-native-vector-icons/Fonts/FontAwesome.ttf'),
          MaterialIcons: require('react-native-vector-icons/Fonts/MaterialIcons.ttf'),
        },
      }),
    })
      .catch((error) => {
        console.error('Error loading fonts', error);
      })
      .then(() => {
        setFontLoaded(true);
      });
  }, []);

  const checkIfAuthTokenHasExpired = useCallback(async () => {
    const migratedHasSeenOnboarding = await localstore.getString(
      'hasAlreadySeenOnboarding'
    );
    if (migratedHasSeenOnboarding) {
      dispatch(setSeenOnboarding());
    }

    const token = await localstore.getString('token');
    if (!token) {
      dispatch(setAuthToken(null));
      return;
    }

    return new Promise((resolve, reject) => {
      dispatch(
        fetchMe({
          onSuccess: () => {
            dispatch(setAuthToken(token));
            resolve();
          },
          onFailure: (error) => reject(error),
        })
      );
    });
  }, [dispatch]);

  useEffect(() => {
    checkIfAuthTokenHasExpired()
      .then(() => {
        setHasVerifiedAuthToken(true);
      })
      .catch((error) => {
        console.warn(error);

        dispatch(setAuthToken(null));
        setHasVerifiedAuthToken(true);
      });
  }, [dispatch, checkIfAuthTokenHasExpired]);

  return {
    isReady: hasVerifiedAuthToken && isFontLoaded,
  };
};
