import { useEffect, useRef, useState } from 'react';
import { useDeepCompareCallback } from 'use-deep-compare';
import { adaptProjects } from '../../../api/Project/adapter';
import { adaptPaginationMeta, getProjects } from '../../../api';
import { isEqual } from 'lodash';

export const useHouseProjects = ({
  isRefreshing,
  isLoadingFunds,
  activeFundsMap,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [listCurrentPage, setListCurrentPage] = useState(0);
  const [listLastPage, setListLastPage] = useState(null);
  const [allProjects, setAllProjects] = useState([]);

  const canFetchMore =
    listCurrentPage !== listLastPage && !isLoading && !isRefreshing;

  const lastFilterParams = useRef();

  const fetchMore = useDeepCompareCallback(
    async (forceCurrentPage) => {
      setIsLoading(true);
      try {
        const activeFunds = Object.entries(activeFundsMap)
          // eslint-disable-next-line no-unused-vars
          .filter(([_, active]) => active)
          .map(([id]) => id);

        const response = await getProjects({
          ...(activeFunds.length > 0 &&
            activeFunds.length !== Object.entries(activeFundsMap).length && {
              funds: activeFunds,
            }),
          state: 'portfolio',
          page:
            (forceCurrentPage != null ? forceCurrentPage : listCurrentPage) + 1,
        });
        const projects = adaptProjects(response);
        const { lastPage, currentPage } = adaptPaginationMeta(response);
        setListLastPage(lastPage);
        setListCurrentPage(currentPage);
        setAllProjects((currentProjects) => [...currentProjects, ...projects]);
      } catch (error) {
        console.warn('Error while fetching data', { error });
      } finally {
        setIsLoading(false);
      }
    },
    [activeFundsMap, listCurrentPage]
  );

  const resetProjectsRef = useRef(() => {
    setListCurrentPage(0);
    setAllProjects([]);
  });

  useEffect(() => {
    if (
      isLoadingFunds ||
      (lastFilterParams.current &&
        isEqual(lastFilterParams.current, activeFundsMap))
    ) {
      return;
    }

    lastFilterParams.current = activeFundsMap;
    resetProjectsRef.current();
    fetchMore(0);
  }, [fetchMore, activeFundsMap, isLoadingFunds]);

  return {
    isLoading,
    allProjects,
    canFetchMore,
    fetchMore,
    resetProjectsRef,
  };
};
