import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { Onboarding } from '../pages/Onboarding';

const Stack = createStackNavigator();

export const OnboardingNavigation = () => (
  <Stack.Navigator headerMode="none">
    <Stack.Screen name="Onboarding" component={Onboarding} />
  </Stack.Navigator>
);
