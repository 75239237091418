import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  Dimensions,
  Platform,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../src/lib/StatusBarHeight';

import { Overlay } from 'react-native-elements';

import CompanyDisclaimer from '../overlays/TimelineCompanyDisclaimer';
import ProjectCommentsOverlay from '../overlays/ProjectCommentsOverlay';
import { formatMoney } from '../src/lib';
import { ParsedText } from '../src/components';
import BaseDaphnipolitan from './Daphnipolitan/BaseDaphnipolitan';
import { environment } from '../src/environment';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);

class Company extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDisclaimer: false,
      showProjectCommentsOverlay: false,
      logo: '',
      name: '',
      score: 0,
      money: '0',
    };
    this.showDisclaimer = this.showDisclaimer.bind(this);
    this.showProjectCommentsOverlay = this.showProjectCommentsOverlay.bind(
      this
    );
    this.accessGranted = this.accessGranted.bind(this);
  }

  companyClicked() {
    //this.showProjectCommentsOverlay(true);
    this.getCompanyData();
  }

  getCompanyData = () => {
    console.log('In TimelineCompany project reference ' + this.props.reference);
    console.log('In TimelineCompany bearer token ' + this.props.bearerToken);

    const bearerToken = this.props.bearerToken;
    fetch(
      'https://' +
        environment.API_HOST +
        '/v4/projects/' +
        this.props.reference,
      {
        // mode: 'no-cors',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + bearerToken,
        },
      }
    ).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          let usersObject = json.data;

          let logoPic = 'https:' + usersObject.logo.url_xsmall;
          if (logoPic != null) {
            if (logoPic == 'https:/missing/project.png') {
              logoPic =
                'https://' + environment.API_HOST + '/missing/project.png';
            } else {
              let url = logoPic.toString();
              url = url.replace('staging', 'prod');
              logoPic = url;
            }
          } else {
            logoPic =
              'https://' + environment.API_HOST + '/missing/project.png';
          }

          let name = usersObject.label;
          if (name == null) {
            name = '';
          }

          let score = usersObject.rating.average;
          if (score == null) {
            score = 0;
          }

          let money = usersObject.fundraising_amount;
          if (money == null) {
            money = '0';
          }

          this.setState({ logo: logoPic, name, score, money }, () => {
            this.showProjectCommentsOverlay(true);
            this.setState({ loading: false });
          });
        });
      } else {
        if (response.status == 401) {
          console.log('no permiso');
          this.showDisclaimer(true);
          this.setState({ loading: false });
        } else {
          console.log('Error retrieving complete project: ', response);
          this.setState({ sError: true }, () => {
            this.setState({ loading: false });
          });
        }
      }
    });
  };

  showDisclaimer(show) {
    this.setState({ showDisclaimer: show });
  }

  accessGranted(show) {
    if (show) {
      this.getCompanyData();
    }
    this.setState({ showDisclaimer: false });
    this.showProjectCommentsOverlay(true);
  }

  showProjectCommentsOverlay(show) {
    this.setState({ showProjectCommentsOverlay: show });
  }

  updateComments = () => {
    console.log('update comments');
  };

  render() {
    let projectCommentsOverlay;

    let money = this.state.money;
    if (money == null) {
      money = '0';
    } else {
      money = formatMoney(money);
    }

    let logoSrc = this.state.logo;
    if (logoSrc == null || logoSrc == '') {
      logoSrc = 'https://daphni.com/favicon-128x128.png';
    }

    let avatar = this.props.avatar;
    if (avatar == null) {
      avatar = 'https://' + environment.API_HOST + '/missing/avatar.png';
    }

    let comments = this.props.comments;
    if (comments == null) {
      comments = 0;
    }

    projectCommentsOverlay = (
      <Overlay
        isVisible={this.state.showProjectCommentsOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          { width: WIDTH, height: HEIGHT * 0.9 },
        ]}
        onBackdropPress={() =>
          this.setState({ showProjectCommentsOverlay: false })
        }
      >
        <ProjectCommentsOverlay
          reference={this.props.reference}
          bearerToken={this.props.bearerToken}
          logoSrc={logoSrc}
          score={this.state.score}
          name={this.state.name}
          updateComments={this.updateComments}
          money={money}
        />
      </Overlay>
    );

    const category = (
      <View style={styles.typeNameContainer}>
        <View style={styles.typeContainer}>
          <Text style={[styles.wText]}>Da Flow</Text>
        </View>
        <View style={styles.nameContainer}>
          <Text numberOfLines={1} style={[styles.cText]}>
            {this.props.companyTitle}
          </Text>
        </View>
      </View>
    );

    let companyDisclaimer = (
      <Overlay
        isVisible={this.state.showDisclaimer}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '95%', height: '90%', borderRadius: 20 }}
        onBackdropPress={() => this.showDisclaimer(false)}
      >
        <CompanyDisclaimer
          reference={this.props.reference}
          bearerToken={this.props.bearerToken}
          logoSrc={this.props.companyLogo}
          companyName={this.props.name}
          companySpeech={this.props.companyDescription}
          accessGranted={this.accessGranted}
        />
      </Overlay>
    );

    return (
      <TouchableOpacity onPress={() => this.companyClicked()}>
        {projectCommentsOverlay}
        {companyDisclaimer}
        <View style={styles.pairedData}>
          <BaseDaphnipolitan
            name={this.props.user.name}
            reference={this.props.user.reference}
            profileSrc={this.props.user.avatar}
            avatarSize={30}
            profileImageStyle={styles.profileImage}
            nameTextStyle={styles.text}
          />
          {category}
        </View>
        <ParsedText style={[styles.text, { marginHorizontal: 20 }]}>
          {this.props.content}
        </ParsedText>
        <View style={[styles.pairedData, { marginTop: 5 }]}>
          <Image
            style={{
              width: 16,
              height: 16,
              tintColor: '#666666',
              marginHorizontal: 10,
            }}
            source={require('../assets/icons/messagesIcon.png')}
            resizeMode="contain"
          />
          <Text style={styles.dateText}>{comments}</Text>
          <Text style={[styles.dateText, { marginHorizontal: 20 }]}>
            {this.props.date}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }
}

export default Company;

const styles = StyleSheet.create({
  bottomOverlay: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    padding: 0,
    bottom: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  typeNameContainer: {
    position: 'absolute',
    right: 3,
  },
  typeContainer: {
    alignSelf: 'flex-end',
    alignContent: 'center',
    justifyContent: 'center',
    backgroundColor: '#7479ae',
    width: 70,
    height: 20,
    borderRadius: 10,
    marginLeft: 10,
  },
  nameContainer: {
    marginTop: 3,
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    backgroundColor: '#7479ae',
    minHeight: 20,
    maxWidth: 140,
    borderRadius: 10,
    marginLeft: 10,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'left',
  },
  cText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 13,
    color: '#ffffff',
    textAlign: 'center',
    marginHorizontal: 5,
    marginVertical: 2,
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 13,
    color: '#ffffff',
    textAlign: 'center',
    marginHorizontal: 5,
  },
  dateText: {
    fontSize: 13,
    marginTop: 5,
    flexWrap: 'wrap',
    //color: '#b3b3b3'
    color: '#666666',
  },
  pairedData: {
    flexDirection: 'row',
    marginHorizontal: 10,
    alignItems: 'center',
  },
  profileImage: {
    borderRadius: 15,
    margin: 10,
    marginRight: 5,
    overflow: 'hidden',
  },
});
