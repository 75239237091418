import React from 'react';
import { Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Avatar } from 'react-native-elements';
import { getPotentiallyMissingAvatarUrl } from '../../src/api/base';
import { withNavigation } from '../../src/navigation/withNavigation';

const DEFAULT_AVATAR_SIZE = 45;

const BaseDaphnipolitan = (props) => (
  <BaseDaphnipolitanBaseComponent
    {...props}
    onPress={async () => {
      if (props.onBeforePress) {
        await props.onBeforePress();
      }

      props.navigation.navigate('Daphnipolitan', {
        reference: props.reference,
        onPressChat: props.successfullChat,
        defaultUserData: {
          sectors: props.sectors,
          name: props.name,
          job: props.job,
          city: props.city,
          company: props.company,
          connected_to: props.connected_to,
          country: props.country,
          countryCode: props.countryCode,
          socials: props.socials,
          avatar: getPotentiallyMissingAvatarUrl(props.profileSrc),
        },
      });
    }}
  />
);

export default withNavigation(BaseDaphnipolitan);

export class BaseDaphnipolitanBaseComponent extends React.Component {
  render() {
    const avatarSize = this.props.avatarSize || DEFAULT_AVATAR_SIZE;
    const profilePic = getPotentiallyMissingAvatarUrl(this.props.profileSrc);

    return (
      <TouchableOpacity
        style={[styles.container, this.props.containerStyle]}
        onPress={this.props.onPress}
      >
        <Avatar
          rounded
          size={avatarSize}
          source={{ uri: profilePic }}
          containerStyle={this.props.profileImageStyle}
        />
        <Text
          style={[
            styles.text,
            this.props.nameTextStyle,
            this.props.textColor && { color: this.props.textColor },
          ]}
        >
          {this.props.name}
        </Text>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginLeft: 5,
  },
});
