import React, { useCallback } from 'react';
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  Platform,
  useWindowDimensions,
} from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  desktopOrLargerMinPixel,
  tabletOrLargerMinPixel,
} from '../src/lib/responsive';
import { navigationMenuRoutes } from '../src/navigation/navigationMenuRoutes';

const RouteButton = (props) => {
  const navigation = useNavigation();
  const currentRoute = useRoute();
  const { route } = props;

  const onPress = useCallback(() => navigation.navigate(route.name), [
    navigation,
    route.name,
  ]);

  const isActive = currentRoute.name === route.name;

  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <View
        style={[
          styles.iconContainer,
          isActive && { backgroundColor: route.color },
        ]}
      >
        <Image
          source={route.source}
          resizeMode="contain"
          style={[
            styles.icon,
            isActive && styles.selectedIcon,
            isActive &&
              route.activeSize && {
                width: route.activeSize,
                height: route.activeSize,
              },
            !isActive &&
              route.inactiveSize && {
                width: route.inactiveSize,
                height: route.inactiveSize,
              },
          ]}
        />
      </View>
    </TouchableOpacity>
  );
};

const DaphniFooter = () => {
  const dimensions = useWindowDimensions();
  const renderRoute = useCallback(
    (route) => <RouteButton key={route.name} route={route} />,
    []
  );

  if (dimensions.width >= desktopOrLargerMinPixel) {
    return null;
  }

  return (
    <View style={styles.container}>
      {navigationMenuRoutes.map(renderRoute)}
    </View>
  );
};

export default DaphniFooter;

const styles = StyleSheet.create({
  container: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    zIndex: 2,
    bottom: 0,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    width: '100%',
    height: 50,
    flexDirection: 'row',
    alignItems: 'center',
    maxWidth: tabletOrLargerMinPixel,
    alignSelf: 'center',
    ...Platform.select({
      web: {
        shadowOpacity: 0.8,
        shadowRadius: 3,
      },
    }),
  },
  button: {
    width: '25%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 15,
    marginHorizontal: 20,
    borderRadius: 100,
    width: 36,
    height: 36,
  },
  icon: {
    tintColor: '#666666',
    width: 25,
    height: 25,
  },
  selectedIcon: {
    tintColor: '#ffffff',
  },
});
