import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import { Overlay } from 'react-native-elements';
import CommentWithMentions from './CommentWithMentions';
import { sendArticleComments } from '../../src/api';

class NewComment extends React.Component {
  state = {
    loading: false,
    message: '',
    sentMessageOverlay: false,
    errorMessageOverlay: false,
  };

  showSentMessageOverlay = () => {
    this.setState(
      { sentMessageOverlay: true, message: '', loading: false },
      () => {
        this.props.updateComments();
      }
    );
  };

  showErrorMessageOverlay = () => {
    this.setState({ errorMessageOverlay: true, loading: false });
  };

  setMessage = (newMessage) => {
    this.setState({ message: newMessage });
  };

  sendMessage = async () => {
    this.setState({ loading: true });
    try {
      await sendArticleComments(this.props.id, this.state.message);
      this.setState({ loading: false });
      this.showSentMessageOverlay();
    } catch (err) {
      console.log('Error fetching data-----------', err);
      this.showErrorMessageOverlay();
    }
  };

  render() {
    let sendButton;
    if (this.state.loading) {
      sendButton = (
        <TouchableOpacity>
          <View style={styles.sendButton}>
            <ActivityIndicator size="small" color="grey" />
          </View>
        </TouchableOpacity>
      );
    } else {
      sendButton = (
        <TouchableOpacity onPress={this.sendMessage}>
          <View style={styles.sendButton}>
            <Text style={styles.sendText}>Reply</Text>
          </View>
        </TouchableOpacity>
      );
    }

    let sentMessageOverlay = (
      <Overlay
        isVisible={this.state.sentMessageOverlay}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ height: '10%', width: '90%', borderRadius: 20 }}
        onBackdropPress={() => this.setState({ sentMessageOverlay: false })}
      >
        <Text
          style={[
            styles.text,
            { textAlign: 'center', marginTop: 8, fontSize: 16 },
          ]}
        >
          Your message was sent
        </Text>
      </Overlay>
    );

    let errorMessageOverlay = (
      <Overlay
        isVisible={this.state.errorMessageOverlay}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '90%', height: '15%', borderRadius: 20 }}
        onBackdropPress={() => this.setState({ errorMessageOverlay: false })}
      >
        <Text
          style={[
            styles.text,
            { textAlign: 'center', marginTop: 8, fontSize: 16 },
          ]}
        >
          There was a problem sending your message, try again
        </Text>
      </Overlay>
    );

    return (
      <View style={styles.container}>
        {sentMessageOverlay}
        {errorMessageOverlay}
        <CommentWithMentions
          setMessage={this.setMessage}
          style={styles.commentInput}
        />
        {sendButton}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  commentInput: {
    marginBottom: 20,
  },
  sendButton: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginBottom: 10,
    backgroundColor: '#ffffff',
    height: 25,
    width: 60,
    borderRadius: 13,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginHorizontal: 20,
  },
  sendText: {
    fontFamily: 'Freight-Sans-Black',
    color: '#45a9ed',
    fontSize: 16,
    textAlign: 'center',
  },
});

export default NewComment;
