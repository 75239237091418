import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  TouchableOpacity,
  Linking,
  Image,
  useWindowDimensions,
} from 'react-native';

import HTML from 'react-native-render-html';

const IMAGES_MAX_WIDTH = Dimensions.get('window').width - 50;
const DEFAULT_PROPS = {
  baseFontStyle: {
    fontSize: 16,
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
  },
  tagsStyles: {
    p: {
      fontFamily: 'Freight-Sans-Medium',
      fontStyle: 'normal',
      color: '#666666',
    },
    em: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    i: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
  },
  ignoredStyles: [
    'background-color',
    'padding',
    'font-family',
    'font-size',
    'line-height',
  ],
  imagesMaxWidth: IMAGES_MAX_WIDTH,
  onLinkPress: (evt, href) => {
    Linking.openURL(href);
  },
  debug: false,
};

const News = (props) => {
  const dimensions = useWindowDimensions();
  const imageSize = Math.min(dimensions.width / 2.5, 175);

  let picSrc = props.picSrc;
  if (picSrc == null) {
    picSrc = 'https://daphni.com/favicon-128x128.png';
  }

  let url = props.url;
  if (url == null || url == '') {
    url = 'https://daphni.com/';
  }

  let description = props.description;
  if (description == null) {
    description = (
      <Text numberOfLines={4} style={styles.description}>
        {props.mention}
      </Text>
    );
  } else {
    description = <HTML {...DEFAULT_PROPS} html={props.description} />;
  }

  return (
    <TouchableOpacity onPress={() => Linking.openURL(url)}>
      <View style={[styles.container, props.style]}>
        <Image
          style={[styles.bannerImage, { width: imageSize, height: imageSize }]}
          source={{ uri: picSrc }}
          resizeMode="cover"
        />
        <View style={{ flex: 1 }}>
          <Text numberOfLines={2} style={styles.title}>
            {props.title}
          </Text>
          <View style={{ height: 85, overflow: 'hidden' }}>{description}</View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.source}>{props.source}</Text>
            <Text style={styles.date}>{props.date}</Text>
          </View>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default News;

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    backgroundColor: '#ffffff',
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 10,
    flexWrap: 'wrap',
    color: '#666666',
  },
  description: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    flexWrap: 'wrap',
    color: '#666666',
  },
  date: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    marginTop: 10,
    marginLeft: 5,
  },
  source: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#666666',
    marginTop: 10,
  },
  bannerImage: {
    borderRadius: 15,
    margin: 10,
  },
});
