import React from 'react';
import { Text, View, ScrollView, StyleSheet } from 'react-native';
import { Divider } from 'react-native-elements';
import DaphniEvent from '../DaphniEvent';

const AllEvents = (props) => {
  let allEvents = props.allEvents;
  if (allEvents != null) {
    allEvents = props.allEvents.map((cEvent) => {
      return (
        <View key={cEvent.id}>
          <DaphniEvent
            key={cEvent.id}
            imageSrc={cEvent.imageSrc}
            address={cEvent.address}
            description={cEvent.description}
            summary={cEvent.summary}
            url={cEvent.url}
          />
          <Divider style={styles.line} />
        </View>
      );
    });
  } else {
    allEvents = <Text style={styles.emptyText}>No upcomming events</Text>;
  }

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContentContainer}>
      {allEvents}
    </ScrollView>
  );
};

export default AllEvents;

const styles = StyleSheet.create({
  scrollViewContentContainer: {
    paddingVertical: 20,
  },
  line: {
    height: 1,
    width: '90%',
    backgroundColor: '#666666',
    margin: 20,
  },
  emptyText: {
    fontSize: 16,
    color: 'white',
    textAlign: 'center',
    fontFamily: 'Freight-Sans-Black',
  },
});
