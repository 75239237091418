const initialState = {
  countsByType: {
    timeline: 0,
    discussion: 0,
    notification: 0,
  },
};

export const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_NOTIFICATIONS_COUNT':
      return {
        ...state,
        countsByType: {
          ...state.countsByType,
          ...action.payload.countsByType,
        },
      };
    case 'SET_DISCUSSION_AS_READ':
      return {
        ...state,
        countsByType: {
          ...state.countsByType,
          discussion:
            state.countsByType.discussion -
            action.payload.discussion.unreadMessagesCount,
        },
      };
    default:
      return state;
  }
};
