import { keyBy } from 'lodash';

const initialState = {
  byReference: {},
  list: [],
  currentPage: 0,
  lastPage: null,
};

const replaceKeyOfDiscussion = (
  state,
  discussionReference,
  mergeObjectFromCurrentDiscussion
) => {
  const currentDiscussion = state.byReference[discussionReference];
  return {
    ...state,
    byReference: {
      ...state.byReference,
      [discussionReference]: {
        ...state.byReference[discussionReference],
        ...mergeObjectFromCurrentDiscussion(currentDiscussion),
      },
    },
  };
};

export const discussionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DISCUSSIONS':
      return {
        ...state,
        lastPage: action.payload.lastPage,
        currentPage:
          state.currentPage !== action.payload.lastPage
            ? state.currentPage + 1
            : state.currentPage,
        byReference: {
          ...state.byReference,
          ...keyBy(action.payload.discussions, 'reference'),
        },
        list: [
          ...state.list,
          ...action.payload.discussions.map(
            (discussion) => discussion.reference
          ),
        ],
      };
    case 'SET_DISCUSSION':
      return replaceKeyOfDiscussion(
        state,
        action.payload.discussion.reference,
        () => action.payload.discussion
      );
    case 'SET_DISCUSSION_AS_READ':
      return replaceKeyOfDiscussion(
        state,
        action.payload.discussion.reference,
        () => ({ unreadMessagesCount: 0 })
      );
    case 'SET_DISCUSSION_TITLE':
      return replaceKeyOfDiscussion(
        state,
        action.payload.discussionReference,
        () => ({ title: action.payload.title })
      );
    case 'SET_LAST_DISCUSSION_MESSAGE':
      return replaceKeyOfDiscussion(
        state,
        action.payload.discussionReference,
        () => ({ lastMessage: action.payload.message })
      );
    case 'SET_GROUP_DISCUSSION_USERS':
      return replaceKeyOfDiscussion(
        state,
        action.payload.discussionReference,
        () => ({ otherUsers: action.payload.otherUsers })
      );
    case 'DELETE_USER_FROM_STORED_DISCUSSION':
      return replaceKeyOfDiscussion(
        state,
        action.payload.discussionReference,
        (currentDiscussion) => ({
          otherUsers: currentDiscussion.otherUsers.filter(
            (user) => user.reference !== action.payload.otherUserReference
          ),
        })
      );
    case 'RESET_DISCUSSIONS':
      return initialState;
    default:
      return state;
  }
};
