import React from 'react';
import { usePrepareNavigation } from './usePrepareNavigation';
import { AuthenticatedNavigation } from './AuthenticatedNavigation';
import { UnauthenticatedNavigation } from './UnauthenticatedNavigation';
import { OnboardingNavigation } from './OnboardingNavigation';
import { LoadingNavigation } from './LoadingNavigation';

export const RootNavigation = () => {
  const { navigationState } = usePrepareNavigation();

  switch (navigationState) {
    case 'authenticated':
      return <AuthenticatedNavigation />;
    case 'unauthenticated':
      return <UnauthenticatedNavigation />;
    case 'firstTimeUser':
      return <OnboardingNavigation />;
    default:
      return <LoadingNavigation />;
  }
};
