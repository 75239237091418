import React from 'react';
import {
  Platform,
  Text,
  View,
  StyleSheet,
  Dimensions,
  Linking,
  TouchableOpacity,
  ScrollView,
  FlatList,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../src/lib/StatusBarHeight';

import { Overlay, Divider } from 'react-native-elements';
import Flag from 'react-native-round-flags';
import HTML from 'react-native-render-html';

import PingOverlay from '../overlays/PingOverlay';
import { ShareDealOverlay } from '../overlays/ShareDealOverlay';
import HouseDaphnipolitan from '../components/Daphnipolitan/HouseDaphnipolitan';
import { localstore } from '../helpers/LocalStore';
import { formatMoney } from '../src/lib';
import { innerViewBaseStyles, Page } from '../src/components';
import { HouseCompanyHeader } from '../src/pages/HouseCompany/components/Header';
import { Video } from '../src/components/Video';
import { CompanyDisclaimer } from '../src/pages/Company/components/CompanyDisclaimer';
import { getFund, getFunds } from '../src/api/Fund';
import { adaptFund, adaptFunds } from '../src/api/Fund/adapter';
import { environment } from '../src/environment';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);
const IMAGES_MAX_WIDTH = Dimensions.get('window').width - 50;

const DEFAULT_PROPS = {
  baseFontStyle: {
    fontSize: 16,
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
  },
  tagsStyles: {
    p: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    em: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    i: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
  },
  ignoredStyles: [
    'background-color',
    'padding',
    'font-family',
    'font-size',
    'line-height',
  ],
  imagesMaxWidth: IMAGES_MAX_WIDTH,
  onLinkPress: (evt, href) => {
    Linking.openURL(href);
  },
  debug: false,
};

class HouseCompanyScreen01 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasAccessToProject: true,
      bearerToken: '',
      loading: true,
      serverError: false,
      showPingOverlay: false,
      showShareOverlay: false,
      showShareMessageOverlay: false,
      shareMessage: '',
      reference: '',
      logo: '',
      cover: '',
      name: '',
      activity: '',
      city: '',
      country: '',
      countryCode: '',
      investmentText: '',
      businessValue: '',
      jobrequests: '',
      portfolioNews: '',
      esg: '',
      lastUpdates: '',
      type: '',
      stage: '',
      teamCount: '',
      team: [],
      website: '',
      video: '',
      videoThumbnail: '',
      deckUrl: undefined,
      coverage: [],
      funds: [],
    };
    this.showPingOverlay = this.showPingOverlay.bind(this);
    this.successfullShare = this.successfullShare.bind(this);
  }

  async componentDidMount() {
    const reference = this.props.route.params?.reference || 'nothing';

    localstore.getItem('funds').then((result) => {
      this.setState({ funds: result }, () => {
        // console.log(' los fondos en proyecto son: ' + this.state.funds);
      });
    });

    localstore.getString('role').then((result) => {
      if (result !== null) {
        this.setState({ role: result });
      } else {
        this.setState({ role: 'dp' });
      }
    });

    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result, reference }, () => {
          this.getCompanyData();
        });
      }
    });
  }

  getFund = () => {
    if (this.props.route.params?.fundId) {
      return getFund(this.props.route.params.fundId).then((fundData) =>
        adaptFund(fundData.data)
      );
    }

    return getFunds()
      .then(adaptFunds)
      .then((funds) => {
        return funds.find((fund) => fund.label === this.state.type);
      });
  };

  getDeckURL = async () => {
    const fund = await this.getFund();
    this.setState({ deckUrl: fund?.reportingUrl ?? null });
  };

  getCompanyData = () => {
    const bearerToken = this.state.bearerToken;

    // console.log("HOUSEPROJECT REFERENCE " + this.state.reference);
    if (bearerToken != '' && this.state.reference != 'nothing') {
      fetch(
        'https://' +
          environment.API_HOST +
          '/v4/projects/' +
          this.state.reference,
        {
          // mode: 'no-cors',
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + bearerToken,
          },
        }
      ).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            let usersObject = json.data;

            const objArray = usersObject.team;
            const oCount = Object.keys(objArray).length;
            let steam = [];
            //Add the lead user to the team
            let leadAvatar = usersObject.lead;
            if (leadAvatar != null) {
              leadAvatar = 'https:' + usersObject.lead.avatar.url_small;
              if (leadAvatar == 'https:/missing/avatar.png') {
                leadAvatar =
                  'https://' + environment.API_HOST + '/missing/avatar.png';
              } else {
                let url = leadAvatar.toString();
                url = url.replace('staging', 'prod');
                leadAvatar = url;
              }
            } else {
              leadAvatar =
                'https://' + environment.API_HOST + '/missing/avatar.png';
            }

            if (oCount > 0) {
              for (let j = 0; j < oCount; j++) {
                let username =
                  objArray[j].first_name + ' ' + objArray[j].last_name;
                //console.log("Team member " + username);
                let avatar = 'https:' + objArray[j].avatar.url_small;

                if (avatar != null) {
                  if (avatar == 'https:/missing/avatar.png') {
                    avatar =
                      'https://' + environment.API_HOST + '/missing/avatar.png';
                  } else {
                    let url = avatar.toString();
                    url = url.replace('staging', 'prod');
                    avatar = url;
                  }
                } else {
                  avatar =
                    'https://' + environment.API_HOST + '/missing/avatar.png';
                }
                steam.push({
                  reference: objArray[j].ref,
                  name: username,
                  avatar: avatar,
                  job: objArray[j].position,
                });
              }
            }

            let contacto = usersObject.lead;
            if (contacto != null) {
              contacto = usersObject.lead.ref;
              let referralAvatar = 'https:' + usersObject.lead.avatar.url_small;
              if (referralAvatar != null) {
                if (referralAvatar == 'https:/missing/avatar.png') {
                  referralAvatar =
                    'https://' + environment.API_HOST + '/missing/avatar.png';
                } else {
                  let url = referralAvatar.toString();
                  url = url.replace('staging', 'prod');
                  referralAvatar = url;
                }
              } else {
                referralAvatar =
                  'https://' + environment.API_HOST + '/missing/avatar.png';
              }
              steam.push({
                reference: contacto,
                name:
                  usersObject.lead.first_name +
                  ' ' +
                  usersObject.lead.last_name,
                avatar: referralAvatar,
                job: 'Team in charge',
              });
            }

            let logoPic = 'https:' + usersObject.logo.url_medium;
            if (logoPic != null || logoPic == 'https:') {
              if (logoPic == 'https:/missing/project.png') {
                logoPic =
                  'https://' + environment.API_HOST + '/missing/project.png';
              } else {
                if (usersObject.label != 'ZOE') {
                  let url = logoPic.toString();
                  url = url.replace('staging', 'prod');
                  logoPic = url;
                } else {
                  let url = logoPic.toString();
                  logoPic = url;
                  console.log(logoPic);
                }
              }
            } else {
              logoPic =
                'https://' + environment.API_HOST + '/missing/project.png';
            }

            //url_small
            let cover = usersObject.cover;
            if (cover != null) {
              cover = usersObject.cover.mobile_cover;
              // console.log("COVER PHOTO" + cover);

              if (cover == 'https:/missing/project.png' || cover == null) {
                cover =
                  'https://' + environment.API_HOST + '/missing/project.png';
              } else {
                let url = cover.toString();
                url = url.replace('staging', 'prod');
                cover = url;
                //  console.log(cover);
              }
            } else {
              cover =
                'https://' + environment.API_HOST + '/missing/project.png';
            }

            let name = usersObject.label;
            if (name == null) {
              name = 'Missing name';
            }

            let city = usersObject.city;
            if (city == null) {
              city = 'Unknown';
            }

            let country = usersObject.country;
            if (country == null) {
              country = 'Unkown';
            }

            let countryCode = usersObject.country_code;
            if (countryCode == null) {
              countryCode = 'ZZ';
            }

            let activity = usersObject.business_activity;
            if (activity == null) {
              activity = 'General';
            }

            let investmentText = usersObject.investment_text;
            if (investmentText == null) {
              investmentText = '';
            }

            let companySpeech = usersObject.description;

            let esg = usersObject.esg;

            let lastUpdates = usersObject.lastq_updates;

            let teamCount = usersObject.team_count;
            if (teamCount == null) {
              teamCount = 0;
            }

            let score = usersObject.rating.average;
            if (score == null) {
              score = 0;
            }

            let type = usersObject.funds[0];
            if (type == null) {
              type = 'Color';
            }

            let money = usersObject.fundraising_amount;
            if (money == null) {
              money = 0;
            }

            let stage = usersObject.stage.name;
            if (stage == null) {
              stage = 'Unkown';
            }

            let website = usersObject.website;
            if (website == null) {
              website = 'https://daphni.com';
            }

            let video = usersObject.video;

            let videoThumbnail = usersObject.video_thumbnail;
            if (videoThumbnail != null && videoThumbnail != '') {
              videoThumbnail = usersObject.video_thumbnail;
            } else {
              videoThumbnail =
                'https://' + environment.API_HOST + '/missing/project.png';
            }

            let fCoverage = [];
            let coverage = usersObject.coverage;
            let cCoverage = Object.keys(coverage).length;
            if (cCoverage > 0) {
              for (let j = 0; j < cCoverage; j++) {
                //  console.log("In fetch coverage country " + coverage[j]["name"]);
                fCoverage.push({
                  id: coverage[j].id,
                  country: coverage[j].name,
                  countryCode: coverage[j].code,
                });
              }
            }

            let founded = usersObject.founded_on;

            let launched = usersObject.launched_on;
            if (launched == null) {
              launched = 'not yet';
            }

            let jobrequests = usersObject.job_requests;

            let businessValue = usersObject.business_and_value_proposition;

            let portfolioNews = usersObject.portfolio_news;

            let funds_role = usersObject.funds_role;
            if (funds_role == null) {
              funds_role = '--';
            }

            let daphni_Invested_amount = usersObject.daphni_invested_amount;
            if (daphni_Invested_amount == null) {
              daphni_Invested_amount = '--';
            } else {
              daphni_Invested_amount = formatMoney(
                usersObject.daphni_invested_amount
              );
            }

            let share_capital_ownership = usersObject.share_capital_ownership;
            if (share_capital_ownership == null) {
              share_capital_ownership = '--';
            }

            let fair_value = usersObject.fair_value;
            if (fair_value == null) {
              fair_value = '--';
            }

            let multiple_to_cost = usersObject.multiple_to_cost;
            if (multiple_to_cost == null) {
              multiple_to_cost = '--';
            }

            let main_coinvestors = usersObject.main_coinvestors;
            if (main_coinvestors == null) {
              main_coinvestors = '--';
            }

            this.setState(
              {
                hasAccessToProject: true,
                team: steam,
                logo: logoPic,
                cover,
                name,
                city,
                country,
                countryCode,
                activity,
                investmentText,
                esg,
                companySpeech,
                lastUpdates,
                teamCount,
                score,
                type,
                stage,
                website,
                video,
                videoThumbnail,
                coverage: fCoverage,
                foundedOn: founded,
                launchedOn: launched,
                jobrequests,
                businessValue,
                portfolioNews,
                funds_role,
                daphni_Invested_amount,
                share_capital_ownership,
                fair_value,
                multiple_to_cost,
                main_coinvestors,
              },
              () => {
                this.getDeckURL();
                this.setState({ loading: false });
              }
            );
          });
        } else {
          if (response.status === 401) {
            this.setState({ loading: false, hasAccessToProject: false });
            return;
          }

          console.log('Error retrieving complete project');
          this.setState({ serverError: true }, () => {
            this.setState({ loading: false });
          });
        }
      });
    } else {
      console.log('Error retrieving complete project');
      this.setState({ serverError: true }, () => {
        this.setState({ loading: false });
      });
    }
  };

  showPingOverlay(show) {
    this.setState({ showPingOverlay: show });
  }

  successfullChat = () => {
    this.showShareOverlay(false);
  };

  shareButtonPressed = () => {
    this.showShareOverlay(true);
  };

  showShareOverlay(show) {
    this.setState({ showShareOverlay: show });
  }

  showShareMessageOverlay(show) {
    this.setState({ showShareMessageOverlay: show });
  }

  successfullShare(message) {
    console.log(message);
    this.setState({ shareMessage: message }, () => {
      this.showShareOverlay(false);
      this.showShareMessageOverlay(true);
    });
  }

  renderItem = ({ item }) => {
    return (
      <HouseDaphnipolitan
        bearerToken={this.state.bearerToken}
        reference={item.reference}
        profileSrc={item.avatar}
        name={item.name}
        company={item.company}
        job={item.job}
        sectors={item.sectors}
        connected_to={item.connected_to}
        city={item.city}
        country={item.country}
        countryCode={item.countryCode}
        navigation={this.props.navigation}
      />
    );
  };

  renderDisclaimer = () => {
    const onCancel = () => this.props.navigation.goBack();
    return (
      <Overlay
        isVisible={!this.state.hasAccessToProject}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '95%', height: '90%', borderRadius: 20 }}
        onBackdropPress={onCancel}
      >
        <CompanyDisclaimer
          reference={this.props.route.params.reference}
          onCancel={onCancel}
          onAgree={() => {
            this.setState({ hasAccessToProject: true });
            return this.getCompanyData();
          }}
        />
      </Overlay>
    );
  };

  render() {
    let coverageView;
    let teamList;
    let businessValue;

    let team = this.state.team;
    if (team != null && team != []) {
      teamList = (
        <FlatList
          numColumns={1}
          horizontal={true}
          data={team}
          renderItem={this.renderItem}
          keyExtractor={(item) => item.reference}
        />
      );
    } else {
      console.log('No team members');
    }

    let pingOverlay = (
      <Overlay
        isVisible={this.state.showPingOverlay}
        overlayStyle={{ width: WIDTH - 10, height: 142, borderRadius: 20 }}
        onBackdropPress={() => this.setState({ showPingOverlay: false })}
      >
        <View>
          <PingOverlay
            reference={this.state.reference}
            bearerToken={this.state.bearerToken}
            showPingOverlay={this.showPingOverlay}
          />
        </View>
      </Overlay>
    );

    let shareOverlay = (
      <Overlay
        isVisible={this.state.showShareOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          {
            width: WIDTH,
            height: Platform.OS === 'ios' ? HEIGHT * 0.6 : HEIGHT * 0.5,
          },
        ]}
        onBackdropPress={() => this.setState({ showShareOverlay: false })}
      >
        <ShareDealOverlay
          reference={this.state.reference}
          bearerToken={this.state.bearerToken}
          successfullShare={this.successfullShare}
          successfullChat={this.successfullShare}
          navigation={this.props.navigation}
        />
      </Overlay>
    );

    let sentDealOverlay = (
      <Overlay
        isVisible={this.state.showShareMessageOverlay}
        overlayStyle={{ width: WIDTH - 20, height: 60, borderRadius: 10 }}
        onBackdropPress={() =>
          this.setState({ showShareMessageOverlay: false })
        }
      >
        <View style={{ alignItems: 'center' }}>
          <Text style={[styles.text, { textAlign: 'center' }]}>
            {this.state.shareMessage}
          </Text>
        </View>
      </Overlay>
    );

    businessValue = this.state.businessValue;
    if (businessValue != null && businessValue != '') {
      businessValue = (
        <View style={{ marginHorizontal: 10 }}>
          <HTML {...DEFAULT_PROPS} html={businessValue} />
        </View>
      );
    } else {
      businessValue = (
        <Text style={[styles.text, { marginLeft: 10 }]}>Nothing to show</Text>
      );
    }

    let coverage = this.state.coverage;
    if (coverage != null && coverage.length > 0) {
      coverageView = coverage.map((location) => (
        <View key={location.id}>
          <Flag code={location.countryCode} style={styles.flag} />
          <Text style={[styles.text, { textAlign: 'center' }]}>
            {location.country}
          </Text>
        </View>
      ));
    } else {
      console.log('No locations for this company');
      coverageView = (
        <Text style={[styles.text, { marginLeft: 25 }]}>
          No locations for this company
        </Text>
      );
    }

    const buttondp = (
      <View style={[styles.buttonContainer, { width: '40%' }]}>
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.showPingOverlay(true)}
        >
          <Image
            source={require('../assets/icons/waveIcon.png')}
            resizeMode="contain"
            style={{ width: 20, height: 20 }}
          />
        </TouchableOpacity>
        <View style={styles.vLine} />
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.shareButtonPressed()}
        >
          <Image
            source={require('../assets/icons/shareicon.png')}
            resizeMode="contain"
            style={{ width: 20, height: 20 }}
          />
        </TouchableOpacity>
      </View>
    );

    const buttonft = (
      <View style={[styles.buttonContainer, { width: '20%' }]}>
        <TouchableOpacity
          style={[styles.bCtouch, { flexDirection: 'row' }]}
          onPress={() => this.shareButtonPressed()}
        >
          <Image
            source={require('../assets/icons/shareicon.png')}
            resizeMode="contain"
            style={{ width: 20, height: 20 }}
          />
        </TouchableOpacity>
      </View>
    );

    let roleStyle = {};
    let buttonToShow = null;
    let clickable = false;
    let myfund = this.state.funds;

    if (
      this.state.role == 'team' ||
      this.state.role == 'family' ||
      myfund.includes(this.state.type)
    ) {
      buttonToShow = buttonft;
      roleStyle = {};
      clickable = false;
    } else {
      buttonToShow = buttondp;
      roleStyle = {
        height: 0,
      };
      clickable = true;
    }

    let projectVideo = null;
    if (this.state.video == null) {
      projectVideo = null;
    } else {
      projectVideo = (
        <Video
          url={this.state.video}
          thumbnailUrl={this.state.videoThumbnail}
          withDivider
        />
      );
    }

    return (
      <Page
        headerColor="#00b6ba"
        title={this.state.name}
        error={
          this.state.serverError
            ? 'Error loading project, please try again'
            : false
        }
        loading={this.state.loading}
      >
        {this.renderDisclaimer()}
        {pingOverlay}
        {shareOverlay}
        {sentDealOverlay}
        <ScrollView
          style={innerViewBaseStyles.contentTopPanelMaxFix}
          contentContainerStyle={innerViewBaseStyles.scrollViewContentContainer}
        >
          <HouseCompanyHeader
            type={this.state.type}
            city={this.state.city}
            country={this.state.country}
            countryCode={this.state.countryCode}
            website={this.state.website}
            coverImage={{ uri: this.state.cover }}
          />
          {/* TODO remove this hack once refacto to share the 3 screen's state is done */}
          {this.state.deckUrl !== undefined && (
            <View style={styles.tabContainer}>
              <TouchableOpacity
                style={[styles.tabTouchable]}
                onPress={() =>
                  this.props.navigation.navigate('DaHouseCompany01')
                }
              >
                <View style={styles.tab}>
                  <Text style={styles.text}>At a glance</Text>
                  <View style={styles.selectedTab} />
                </View>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.tabTouchable, roleStyle]}
                onPress={() =>
                  this.props.navigation.navigate('DaHouseCompany03', {
                    reference: this.state.reference,
                    name: this.state.name,
                    cover: this.state.cover,
                    logo: this.state.logo,
                    city: this.state.city,
                    country: this.state.country,
                    countryCode: this.state.countryCode,
                    type: this.state.type,
                    website: this.state.website,
                    deckUrl: this.state.deckUrl,
                    investmentText: this.state.investmentText,
                    lastUpdates: this.state.lastUpdates,
                    esg: this.state.esg,
                    jobrequests: this.state.jobrequests,
                    portfolioNews: this.state.portfolioNews,
                    funds_role: this.state.funds_role,
                    daphni_Invested_amount: this.state.daphni_Invested_amount,
                    share_capital_ownership: this.state.share_capital_ownership,
                    fair_value: this.state.fair_value,
                    multiple_to_cost: this.state.multiple_to_cost,
                    main_coinvestors: this.state.main_coinvestors,
                  })
                }
                disabled={clickable}
              >
                <View style={[styles.tab, roleStyle]}>
                  <Text style={[styles.text, roleStyle]}>Report</Text>
                </View>
              </TouchableOpacity>
            </View>
          )}

          <View style={styles.greenContainer}>
            <View>
              <Text style={styles.wText}>Activity</Text>
              <Text style={styles.wTitle}>{this.state.activity}</Text>
            </View>
            <View style={styles.vLine} />
            <View>
              <Text style={styles.wText}>Stage at investment</Text>
              <Text style={styles.wTitle}>{this.state.stage}</Text>
            </View>
            <View style={styles.vLine} />
            <View>
              <Text style={styles.wText}>Employees</Text>
              <Text style={styles.wTitle}>{this.state.teamCount}</Text>
            </View>
          </View>
          <Divider style={styles.lineStyle} />

          <Text style={[styles.title, { textAlign: 'center' }]}>Team</Text>
          <ScrollView horizontal showsHorizontalScrollIndicator={false}>
            {teamList}
          </ScrollView>
          <Divider style={styles.lineStyle} />

          <Text style={[styles.title, { textAlign: 'center' }]}>News</Text>
          <View style={{ marginHorizontal: 10 }}>
            {this.state.portfolioNews ? (
              <HTML {...DEFAULT_PROPS} html={this.state.portfolioNews} />
            ) : (
              <Text style={styles.text}>No news for this project yet</Text>
            )}
          </View>
          <Divider style={styles.lineStyle} />

          <Text style={[styles.title, { textAlign: 'center' }]}>
            Business and value proposition
          </Text>
          {businessValue}
          <Divider style={styles.lineStyle} />
          {projectVideo}

          <Text style={[styles.title, { textAlign: 'center' }]}>Coverage</Text>
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={false}
            alignContent="center"
          >
            {coverageView}
          </ScrollView>
        </ScrollView>
        {buttonToShow}
      </Page>
    );
  }
}

export default HouseCompanyScreen01;

const styles = StyleSheet.create({
  tab: {
    marginTop: 10,
    alignItems: 'center',
  },
  tabTouchable: {
    height: 60,
    paddingBottom: 10,
    width: '50%',
  },
  greenContainer: {
    backgroundColor: '#00b6ba',
    marginHorizontal: 10,
    marginVertical: 20,
    paddingVertical: 15,
    borderRadius: 20,
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#00b6ba',
    zIndex: 2,
    bottom: 20,
    borderRadius: 30,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  bCtouch: {
    height: 40,
    paddingHorizontal: 25,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  tabContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottomOverlay: {
    position: 'absolute',
    bottom: 0,
    backgroundColor: 'transparent',
  },
  selectedTab: {
    width: '100%',
    height: 6,
    marginTop: 5,
    backgroundColor: '#00b6ba',
  },
  flag: {
    width: 64,
    height: 64,
    marginVertical: 20,
    marginHorizontal: 30,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    color: '#666666',
    fontSize: 16,
    marginBottom: 5,
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 5,
    textAlign: 'center',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#ffffff',
    fontSize: 16,
  },
  lineStyle: {
    width: '90%',
    backgroundColor: '#666666',
    marginTop: 30,
    marginBottom: 20,
    alignSelf: 'center',
  },
  vLine: {
    width: 1,
    height: '70%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
});
