import { theme } from '../../../lib';
import Ufo from '../assets/ufo.svg';
import Flag from '../assets/flag.svg';
import Network from '../assets/network.svg';
import Rocket from '../assets/rocket.svg';

export const onboardingSlides = [
  {
    title: 'da news',
    color: theme.colors.danews,
    ImageComponent: Ufo,
    bulletPoints: [
      'portfolio',
      'events',
      'da flow',
      'community',
      'ecosystem',
      'stats',
    ],
  },
  {
    title: 'da flow',
    color: theme.colors.daflow,
    ImageComponent: Rocket,
    bulletPoints: [
      'Filter deal flow by “interest”',
      'Post a comment (“private” or public)',
      'Ping daphni team in one click',
    ],
  },
  {
    title: 'da house',
    color: theme.colors.dahouse,
    ImageComponent: Flag,
    bulletPoints: [
      'Follow the fund(s) performance',
      'Access the companies news and reporting',
      'Ping us to help our entrepreneurs',
    ],
  },
  {
    title: 'daphnipolis',
    color: theme.colors.daphnipolis,
    imageAsset: require('../assets/daphnipolis.png'),
    imageBottomOfCircle: true,
    bulletPoints: [
      'View all profiles',
      'Filter daphnipolitans by expertise',
      'Ask for introductions',
      'Chat online',
    ],
  },
  {
    title: 'da timeline',
    color: theme.colors.datimeline,
    ImageComponent: Network,
    bulletPoints: [
      'At a glance the activities on the platform',
      'Share your ideas, experiences and needs',
    ],
  },
];
