export * from './Articles';
export * from './Discussion';
export * from './Notification';
export * from './User';
export * from './News';
export * from './BreakingStories';
export * from './Statistics';
export * from './Project';

export const adaptPaginationMeta = ({ meta }) => ({
  currentPage: meta ? meta.current_page : 1,
  lastPage: meta ? meta.total_pages : 1,
});
