import React from 'react';
import { ScrollView, StyleSheet, useWindowDimensions } from 'react-native';
import { desktopOrLargerMinPixel } from '../lib/responsive';
import { theme } from '../lib';

export const SubMenuContainer = ({ style, ...props }) => {
  const dimensions = useWindowDimensions();
  const isDesktop = dimensions.width >= desktopOrLargerMinPixel;

  return (
    <ScrollView
      horizontal
      showsHorizontalScrollIndicator={false}
      style={[
        styles.container,
        isDesktop ? styles.desktopContainer : styles.mobileContainer,
        style,
      ]}
      contentContainerStyle={[
        styles.contentContainer,
        isDesktop
          ? styles.desktopContentContainer
          : styles.mobileContentContainer,
      ]}
      {...props}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    backgroundColor: 'white',
  },
  mobileContainer: {
    paddingVertical: 10,
  },
  desktopContainer: {
    marginHorizontal: '5%',
    height: 70,
    borderRadius: 35,
    ...theme.shadow,
  },
  contentContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  desktopContentContainer: {
    flex: 1,
    paddingHorizontal: 20,
  },
  mobileContentContainer: {
    paddingHorizontal: 10,
  },
});
