import { getPrivateApi } from '../base';

const resourceUrl = '/projects';

export const getProjects = async (query) => {
  const api = await getPrivateApi();
  return api.url(resourceUrl).query(query).get().json();
};

export const getProject = async (reference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${reference}`).get().json();
};

export const getAppointedProjects = async () => {
  const api = await getPrivateApi();
  return api.url(resourceUrl).query({ appointed: true }).get().json();
};

export const getProjectPreview = async (reference) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${reference}/preview`)
    .get()
    .json()
    .then((json) => json.data);
};

export const getProjectComments = async (reference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${reference}/comments`).get().json();
};

export const createProjectComment = async (projectReference, comment) => {
  const api = await getPrivateApi();
  return api
    .url(`${resourceUrl}/${projectReference}/comments`)
    .post({ comment })
    .json();
};

export const likeProject = async (reference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${reference}/love`).post().json();
};

export const dislikeProject = async (reference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${reference}/love`).delete().res();
};

export const followProject = async (reference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${reference}/follow`).post().json();
};

export const unfollowProject = async (reference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${reference}/follow`).delete().res();
};

export const accessProject = async (reference) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${reference}/access`).put().res();
};

export const rateProject = async (reference, rating) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${reference}/rating`).post({ rating }).res();
};
