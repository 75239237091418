import React from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, useWindowDimensions, View } from 'react-native';
import { allDaFlowTypeInfo } from '../../allDaFlowTypeInfo';
import { desktopOrLargerMinPixel } from '../../../../lib/responsive';
import { Settings } from '../Settings';
import { meSelector } from '../../../../redux/User';
import { DaFlowButton } from './components/DaFlowButton';
import { SubmitDealButtonModal } from './components/SubmitDealButtonModal';
import { SubMenuContainer } from '../../../../components';

export const SubMenu = ({
  currentDaFlowType,
  activeSectorsIds,
  setActiveSectorsIds,
  projectCountPerColumns,
  setProjectCountPerColumns,
  isFilteringEnabled,
  setIsFilteringEnabled,
  SettingsAdditionalComponents,
}) => {
  const me = useSelector(meSelector);
  const dimensions = useWindowDimensions();

  const isDesktop = dimensions.width >= desktopOrLargerMinPixel;

  return (
    <SubMenuContainer>
      <View style={styles.leftButtons}>
        {allDaFlowTypeInfo
          .filter((daFlowTypeInfo) => {
            if (!daFlowTypeInfo.needsRole) {
              return true;
            }

            if (!me || !me.role) {
              return false;
            }

            return daFlowTypeInfo.needsRole.includes(me.role);
          })
          .map((daFlowTypeInfo) => (
            <DaFlowButton
              key={daFlowTypeInfo.key}
              to={`/daflow/${daFlowTypeInfo.key}`}
              isActive={daFlowTypeInfo.key === currentDaFlowType}
              style={{
                marginRight: isDesktop ? 30 : 10,
              }}
              label={daFlowTypeInfo.label}
            />
          ))}
        <SubmitDealButtonModal />
      </View>
      {isDesktop && (
        <Settings
          activeSectorsIds={activeSectorsIds}
          setActiveSectorsIds={setActiveSectorsIds}
          projectCountPerColumns={projectCountPerColumns}
          setProjectCountPerColumns={setProjectCountPerColumns}
          isFilteringEnabled={isFilteringEnabled}
          setIsFilteringEnabled={setIsFilteringEnabled}
          AdditionalComponents={SettingsAdditionalComponents}
        />
      )}
    </SubMenuContainer>
  );
};

const styles = StyleSheet.create({
  leftButtons: {
    flexDirection: 'row',
    alignItems: 'center',
  },
});
