import { compose } from 'recompose';
import { connect } from 'react-redux';
import { DeleteGroupButtonComponent } from './DeleteGroupButton.component';
import { deleteDiscussion } from '../../../../src/redux/Discussion';
import { isLoadingSelector } from '../../../../src/redux/LoadingStatus';
import { withNavigation } from '../../../../src/navigation/withNavigation';

const mapStateToProps = (state) => ({
  isLoading: isLoadingSelector('deleteDiscussion')(state),
});

const mapDispatchToProps = {
  deleteDiscussion,
};

const enhance = compose(
  withNavigation,
  connect(mapStateToProps, mapDispatchToProps)
);

export const ConnectedDeleteGroupButton = enhance(DeleteGroupButtonComponent);
