import { Platform } from 'react-native';
import wretch from 'wretch';
import { localstore } from '../../helpers/LocalStore';
import { version } from '../../package.json';
import { environment } from '../environment';

const BASE_API_URL = () => `https://${environment.API_HOST}`;

const publicApi = () =>
  wretch()
    .url(BASE_API_URL() + '/v4')
    .headers({
      'App-Version': version,
      'From-Device': Platform.OS,
    })
    .errorType('json');

export const getPrivateApi = async () => {
  const token = await localstore.getString('token');
  return publicApi().auth(`Bearer ${token}`);
};

export const simpleGetFromResource = async (resource) => {
  const api = await getPrivateApi();
  return api.url(resource).get().json();
};

const MISSING_AVATAR_URL_SUFFIX = '/missing/avatar.png';

export const MISSING_AVATAR_URL = () =>
  BASE_API_URL() + MISSING_AVATAR_URL_SUFFIX;

export const getPotentiallyMissingAvatarUrl = (url) => {
  if (!url || url === MISSING_AVATAR_URL_SUFFIX) {
    return MISSING_AVATAR_URL();
  }

  return getUrlWithScheme(url);
};

const MISSING_PROJECT_URL_SUFFIX = '/missing/project.png';
const MISSING_PROJECT_URL = BASE_API_URL + MISSING_PROJECT_URL_SUFFIX;
export const getPotentiallyMissingProjectUrl = (url) => {
  if (!url || url.endsWith(MISSING_PROJECT_URL_SUFFIX)) {
    return MISSING_PROJECT_URL;
  }

  return getUrlWithScheme(url);
};

export const getUrlWithScheme = (url) => {
  if (!url) {
    return;
  }

  if (url.startsWith('https:')) {
    return url;
  }

  return 'https:' + url;
};
