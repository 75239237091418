import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Image } from 'react-native';
import Flag from 'react-native-round-flags';
import { formatMoney, theme } from '../../../lib';

class HouseCompanyGridItem extends React.Component {
  render() {
    let money = this.props.money;
    if (money == null || money == 0) {
      money = '';
    } else {
      money = '€' + formatMoney(money);
    }

    let sectorsText = this.props.sectors;
    if (sectorsText != null) {
      sectorsText = this.props.sectors.map((tag, id) => (
        <Text key={id} style={styles.sectorText}>
          {tag.tag}
        </Text>
      ));
    }

    let logoSrc = this.props.logoSrc;
    if (logoSrc == null) {
      logoSrc = 'https://daphni.com/favicon-128x128.png';
    }

    return (
      <TouchableOpacity
        onPress={() =>
          this.props.navigation.navigate('DaHouseCompany01', {
            reference: this.props.reference,
            fundId: this.props.fundId,
          })
        }
      >
        <View style={styles.item}>
          <Image
            style={styles.logoImage}
            source={{ uri: logoSrc }}
            resizeMode="contain"
          />
          <View style={styles.pairedData}>
            <Text style={styles.itemTitle}>{this.props.name} </Text>
            <Flag code={this.props.countryCode} style={styles.flag} />
          </View>
          <Text style={styles.itemText}>{this.props.activity}</Text>
          <View style={styles.pairedData}>
            {(this.props.funds?.length
              ? this.props.funds
              : this.props.type
              ? [{ id: this.props.type, label: this.props.type }]
              : []
            ).map((fund) => {
              const fundColors =
                theme.colors.funds[fund.label] || theme.colors.funds.Default;

              return (
                <View
                  key={fund.id}
                  style={[
                    styles.typeContainer,
                    { backgroundColor: fundColors.primary },
                  ]}
                >
                  <Text
                    style={[
                      styles.typeText,
                      { color: fundColors.textOnPrimary },
                    ]}
                  >
                    {fund.label}
                  </Text>
                </View>
              );
            })}
            <Text style={styles.itemTitle}>{money}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  }
}

export default HouseCompanyGridItem;

const styles = StyleSheet.create({
  item: {
    alignItems: 'center',
  },
  typeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    height: 25,
    minWidth: 60,
    paddingHorizontal: 10,
    borderRadius: 13,
  },
  flag: {
    marginVertical: 8,
    width: 16,
    height: 16,
  },
  itemTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#666666',
    fontSize: 16,
    marginVertical: 8,
  },
  itemText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    textAlign: 'center',
  },
  sectorText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginLeft: 10,
  },
  logoImage: {
    width: 120,
    height: 40,
    margin: 10,
  },
  pairedData: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});
