import { ActivityIndicator, StyleSheet, Text, View } from 'react-native';
import AutoScrolling from 'react-native-auto-scrolling';
import React, { useEffect, useState } from 'react';
import { getBreakingStories } from '../../../api';
import { formatBreakingStories } from '../../../api/BreakingStories/formatter';

export const BreakingStories = () => {
  const [loading, setLoading] = useState(true);
  const [breakingStories, setBreakingStories] = useState(null);

  useEffect(() => {
    getBreakingStories()
      .then(formatBreakingStories)
      .then(setBreakingStories)
      .catch((error) => {
        console.log('Error retrieving data', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <View style={styles.stickyBar}>
        <ActivityIndicator size="small" color="white" />
      </View>
    );
  }

  if (!breakingStories) {
    return null;
  }

  return (
    <AutoScrolling style={styles.stickyBar} endPadding={1}>
      <Text style={styles.stickyText}>{breakingStories}</Text>
    </AutoScrolling>
  );
};

const styles = StyleSheet.create({
  stickyBar: {
    paddingTop: 15,
    paddingBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  stickyText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#fff',
    fontSize: 16,
  },
});
