import React from 'react';
import { View, StyleSheet } from 'react-native';
import { theme } from '../../../lib';
import { RenderTypeButton } from '../../../components';
import { ActiveFundsFilter } from './ActiveFundsFilter';

export const Settings = ({
  style,
  funds,
  activeFundsMap,
  setActiveFundsMap,
  projectCountPerColumns,
  setProjectCountPerColumns,
}) => {
  return (
    <View style={[styles.container, style]}>
      <RenderTypeButton
        projectCountPerColumns={projectCountPerColumns}
        setProjectCountPerColumns={setProjectCountPerColumns}
      />
      <View style={styles.separator} />
      <ActiveFundsFilter
        funds={funds}
        activeFundsMap={activeFundsMap}
        setActiveFundsMap={setActiveFundsMap}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  separator: {
    width: 1,
    marginHorizontal: 20,
    alignSelf: 'stretch',
    backgroundColor: theme.colors.text,
  },
});
