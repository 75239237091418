import React from 'react';
import { Text, View, StyleSheet, ActivityIndicator, Image } from 'react-native';
import { getStatistics } from '../../../api';
import { Card } from './Card';
import { StatLine } from './StatLine';
const rocketPic = { source: require('../../../../assets/rocket.jpg') };

class WeeksFlow extends React.PureComponent {
  state = {
    loading: true,
    statistics: null,
  };

  componentDidMount() {
    this.getWeeksStats();
  }

  getWeeksStats = () => {
    this.setState({ loading: true });
    getStatistics()
      .then((statistics) => {
        this.setState({
          loading: false,
          statistics: {
            termsheet: statistics.projects_count.by_state.termsheet || 0,
            dropped: statistics.projects_count.dropped || 0,
            exploration: statistics.projects_count.by_state.exploration || 0,
            new: statistics.projects_count.by_state.new || 0,
          },
        });
      })
      .catch((error) => {
        console.error('Error when loading', error);
        this.setState({ loading: false });
      });
  };

  render() {
    const { statistics, loading } = this.state;

    if (!statistics) {
      if (loading) {
        return (
          <Card>
            <ActivityIndicator size="large" color="grey" />
          </Card>
        );
      }
      return null;
    }

    return (
      <Card style={styles.container}>
        <Text style={styles.title}>Da Flow status</Text>
        <View style={styles.flowContainer}>
          <View style={{ marginRight: 20 }}>
            <Image
              style={{ width: 25, height: 83 }}
              source={rocketPic.source}
              resizeMode="contain"
            />
          </View>
          <View style={{ flex: 1 }}>
            <StatLine
              label={this.state.statistics.termsheet}
              value={'Term Sheets in progress'}
            />
            <StatLine
              label={this.state.statistics.dropped}
              value={'Dropped this week'}
            />
            <StatLine
              label={this.state.statistics.exploration}
              value={'Exploration in progress'}
            />
            <StatLine
              label={this.state.statistics.new}
              value={'New this week'}
            />
          </View>
        </View>
      </Card>
    );
  }
}

export default WeeksFlow;

const styles = StyleSheet.create({
  container: {
    paddingLeft: 24,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginHorizontal: 20,
    textAlign: 'center',
    color: '#666666',
    marginBottom: 24,
  },
  flowContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
});
