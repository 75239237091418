import { takeEvery, call, put } from 'redux-saga/effects';
import { setMe } from './actions';
import { getMe } from '../../api/User';
import { adaptMe } from '../../api/User/adapter';
import { localstore } from '../../../helpers/LocalStore';

export function* fetchMeSaga(action) {
  try {
    const response = yield call(getMe);
    const me = adaptMe(response);
    yield put(setMe(me));

    // TODO remove these once infos are selected via store
    yield call([localstore, 'setString'], 'reference', me.reference);
    localstore.setString('username', me.firstName);
    localstore.setString('first_name', me.firstName);
    localstore.setString('last_name', me.lastName);
    localstore.setString('role', me.role);
    localstore.setString('avatar', me.avatar);
    localstore.setString('bio', me.bio);
    localstore.setString('job', me.job);
    localstore.setString('country', me.country);
    localstore.setString('countryCode', me.countryCode);
    localstore.setItem('sectors', me.sectors);
    localstore.setItem('funds', me.funds);

    const { onSuccess } = action.payload;
    if (onSuccess) {
      onSuccess(me);
    }
  } catch (e) {
    console.warn('[saga] fetchMeSaga', e);
    const { onFailure } = action.payload;
    if (onFailure) {
      onFailure(e);
    }
  }
}

export const userSagas = function* () {
  yield* [takeEvery('FETCH_ME', fetchMeSaga)];
};
