import React from 'react';
import { StyleSheet, Dimensions } from 'react-native';
import { Overlay } from 'react-native-elements';
import DaphnipolitanOverlay from '../../overlays/DaphnipolitanOverlay';
import { BaseDaphnipolitanBaseComponent } from './BaseDaphnipolitan';
import { getPotentiallyMissingAvatarUrl } from '../../src/api/base';

const deviceWidth = Dimensions.get('window').width;

class BaseOverlayDaphnipolitan extends React.Component {
  state = {
    showOverlay: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.reference !== this.props.reference ||
      nextState.showOverlay !== this.state.showOverlay
    );
  }

  showOverlay = (show) => {
    this.setState({ showOverlay: show });
  };

  successfullChat = () => {
    if (this.props.successfullChat) {
      this.props.successfullChat();
    }
    this.setState({ showOverlay: false });
  };

  render() {
    let sectors = this.props.sectors;

    if (sectors != null) {
      if (sectors.length > 3) {
        let temp = [sectors[0], sectors[1], sectors[2]];
        sectors = temp;
      }
    }

    let daphnipolitanOverlay = (
      <Overlay
        isVisible={this.state.showOverlay}
        overlayStyle={[styles.bottomOverlay, { width: deviceWidth }]}
        onBackdropPress={() => this.setState({ showOverlay: false })}
      >
        <DaphnipolitanOverlay
          loadAllData
          reference={this.props.reference}
          sectors={sectors}
          name={this.props.name}
          job={this.props.job}
          city={this.props.city}
          company={this.props.company}
          connected_to={this.props.connected_to}
          country={this.props.country}
          countryCode={this.props.countryCode}
          socials={this.props.socials}
          avatar={getPotentiallyMissingAvatarUrl(this.props.profileSrc)}
          successfullChat={this.successfullChat}
        />
      </Overlay>
    );

    return (
      <>
        {daphnipolitanOverlay}
        <BaseDaphnipolitanBaseComponent
          {...this.props}
          onPress={() => this.showOverlay(true)}
        />
      </>
    );
  }
}

export default BaseOverlayDaphnipolitan;

const styles = StyleSheet.create({
  bottomOverlay: {
    backgroundColor: '#53a7dc',
    position: 'absolute',
    bottom: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    maxHeight: 400,
  },
});
