import React, { Component } from 'react';
import {
  View,
  StyleSheet,
  ActivityIndicator,
  TouchableOpacity,
  Text,
} from 'react-native';
import MentionsTextInput from '@opengenius/react-native-mentions';
import { searchUsers } from '../../src/api/User';
import { adaptUsers } from '../../src/api/User/adapter';
import { DismissKeyboard } from '../../src/components';

const MENTION_TRIGGER = '@';
const TEXTINPUT_MIN_HEIGHT = 60;

class CommentWithMentions extends Component {
  state = {
    value: '',
    keyword: '',
    userSuggestions: [],
    mentionedUsers: {},
    isSearchingUsersByKeyword: {},
  };

  getUserSuggestions = (keyword) => {
    this.setState((state) => ({
      keyword,
      isSearchingUsersByKeyword: {
        ...state.isSearchingUsersByKeyword,
        [keyword]: true,
      },
    }));
    searchUsers(keyword, 0)
      .then(adaptUsers)
      .then((users) => {
        this.setState((state) => ({
          isSearchingUsersByKeyword: {
            ...state.isSearchingUsersByKeyword,
            [keyword]: false,
          },
          userSuggestions: users.map((user) => ({
            id: user.reference,
            name: user.name,
            username: user.name.replace(/ /g, '').toLowerCase(),
          })),
        }));
      })
      .catch((error) => {
        console.warn(error);
        this.setState((state) => ({
          isSearchingUsersByKeyword: {
            ...state.isSearchingUsersByKeyword,
            [keyword]: false,
          },
          userSuggestions: [],
        }));
      });
  };

  renderSuggestionsRow = ({ item: user }, hidePanel) => {
    const iconBackgroundColor =
      alphabetColors[
        Math.floor(user.name.charCodeAt(0) % alphabetColors.length)
      ];

    return (
      <TouchableOpacity onPress={() => this.onSuggestionTap(user, hidePanel)}>
        <View style={styles.suggestionsRowContainer}>
          <View
            style={[
              styles.userIconBox,
              { backgroundColor: iconBackgroundColor },
            ]}
          >
            <Text style={styles.usernameInitials}>
              {user.name.substring(0, 1).toUpperCase()}
            </Text>
          </View>
          <View style={styles.userDetailsBox}>
            <Text style={styles.displayNameText}>{user.name}</Text>
            <Text style={styles.usernameText}>@{user.username}</Text>
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  onSuggestionTap = (user, hidePanel) => {
    hidePanel();
    this.setState(
      (state) => {
        const comment = state.value.slice(
          0,
          -(state.keyword.length + MENTION_TRIGGER.length)
        );

        return {
          userSuggestions: [],
          value: comment + '@' + user.username,
          mentionedUsers: {
            ...state.mentionedUsers,
            [user.username]: user,
          },
        };
      },
      () => {
        this.updateMessage();
      }
    );
  };

  userSuggestionsKeyExtractor = (userSuggestion) => userSuggestion.id;

  onChangeText = (text) => {
    this.setState({ value: text }, () => {
      this.updateMessage();
    });
  };

  updateMessage = () => {
    let message = this.state.value;

    for (const user of Object.values(this.state.mentionedUsers)) {
      message = message.replace(
        new RegExp(`@${user.username}`, 'g'),
        `@[${user.id}|${user.name}]`
      );
    }

    this.props.setMessage(message);
  };

  renderLoadingSuggestions = () => {
    const isLoading = Object.values(this.state.isSearchingUsersByKeyword).some(
      Boolean
    );

    return (
      <View style={styles.loadingSuggestionsContainer}>
        {isLoading ? (
          <ActivityIndicator size="small" color="grey" />
        ) : (
          <Text>No users found</Text>
        )}
      </View>
    );
  };

  render() {
    return (
      <DismissKeyboard>
        <View style={[styles.container, this.props.style]}>
          <MentionsTextInput
            placeholder={this.props.placeholder || 'Write your post'}
            textInputStyle={styles.textInput}
            suggestionsPanelStyle={styles.suggestionsPanel}
            suggestionsPanelContentContainerStyle={
              styles.suggestionsPanelContentContainer
            }
            loadingComponent={this.renderLoadingSuggestions}
            textInputMinHeight={TEXTINPUT_MIN_HEIGHT}
            textInputMaxHeight={TEXTINPUT_MIN_HEIGHT + 60}
            trigger={MENTION_TRIGGER}
            triggerLocation="new-word-only"
            value={this.state.value}
            onChangeText={this.onChangeText}
            triggerCallback={this.getUserSuggestions}
            renderSuggestionsRow={this.renderSuggestionsRow}
            suggestionsData={this.state.userSuggestions}
            keyExtractor={this.userSuggestionsKeyExtractor}
            suggestionRowHeight={40}
            horizontal
          />
        </View>
      </DismissKeyboard>
    );
  }
}

export default CommentWithMentions;

const SUGGESTION_ROW_HEIGHT = 40;
const USER_ICON_SIZE = SUGGESTION_ROW_HEIGHT - 10;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    padding: 20,
    borderRadius: 20,
  },
  textInput: {
    borderWidth: 0,
  },
  suggestionsPanel: {},
  suggestionsPanelContentContainer: {
    paddingHorizontal: 10,
    backgroundColor: 'white',
    borderWidth: 1,
    borderColor: 'rgba(0,0,0,0.3)',
    minWidth: SUGGESTION_ROW_HEIGHT * 1.5,
    borderRadius: SUGGESTION_ROW_HEIGHT,
  },
  suggestionsRowContainer: {
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
  },
  loadingSuggestionsContainer: {
    flex: 1,
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userIconBox: {
    height: USER_ICON_SIZE,
    width: USER_ICON_SIZE,
    borderRadius: USER_ICON_SIZE / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  usernameInitials: {
    color: '#fff',
    fontWeight: '800',
    fontSize: 14,
  },
  userDetailsBox: {
    flex: 1,
    justifyContent: 'center',
    paddingLeft: 10,
    paddingRight: 15,
  },
  displayNameText: {
    fontSize: 13,
    fontWeight: '500',
  },
  usernameText: {
    fontSize: 12,
    color: 'rgba(0,0,0,0.6)',
  },
});

const alphabetColors = [
  '#FFD552',
  '#ffca0b',
  '#9C0D05',
  '#E1DB00',
  '#E99600',
  '#E1DB00',
  '#06BC0C',
  '#06BCAE',
  '#0695BC',
  '#0660BC',
  '#3006BC',
  '#6606BC',
  '#c31616',
  '#BC0680',
  '#BC0642',
  '#BC3406',
  '#BCA106',
  '#535322',
  '#497724',
  '#929292',
  '#606060',
  '#262626',
  '#7B9FAB',
  '#1393BD',
  '#5E13BD',
  '#E208A7',
];
