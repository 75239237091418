import React, { PureComponent } from 'react';
import { Button } from '../../../../src/components';

export class DeleteGroupButtonComponent extends PureComponent {
  onPress = async () => {
    this.props.deleteDiscussion(this.props.discussionReference, () => {
      this.props.navigation.navigate('ChatList');
    });
  };

  render() {
    return (
      <Button
        title="Delete group"
        onPress={this.onPress}
        isLoading={this.props.isLoading}
        style={this.props.style}
      />
    );
  }
}
