/*TODO: In bookmarks (and search) this doesn't work, the server gives back a 500 response */

import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { Avatar } from 'react-native-elements';

import { localstore } from '../helpers/LocalStore';
import { environment } from '../src/environment';

class CompanyDisclaimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uref: '',
      loading: true,
    };
  }

  componentDidMount() {
    console.log('In disclaimer project reference ' + this.props.reference);
    console.log('In disclaimer bearer token ' + this.props.bearerToken);

    let userReference;

    localstore.getString('reference').then((result) => {
      if (result !== null) {
        userReference = result;
        this.setState({ uref: userReference });
      } else {
        this.setState({ uref: 'bad' });
      }
    });
  }

  agreeButton() {
    let reference = this.props.reference;
    let bearerToken = this.props.bearerToken;
    let uref = this.state.uref;
    console.log('Mi referencia de user es:  ' + uref);

    fetch(
      'https://' +
        environment.API_HOST +
        '/v4/projects/' +
        reference +
        '/access',
      {
        // mode: 'no-cors',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + bearerToken,
        },
        body: JSON.stringify({
          user_ref: uref,
        }),
      }
    ).then((response) => {
      console.log('Company disclaimer server response ' + response.status);
      if (response.status == 201) {
        this.props.accessGranted(true);
        this.setState({ sError: true, loading: false });
      }
    });
  }

  cancelButton() {
    this.props.accessGranted(false);
  }

  render() {
    let companyName = this.props.companyName;
    let logoSrc = this.props.logoSrc;
    console.log('Logo source ' + logoSrc);
    if (logoSrc == null) {
      logoSrc = 'https://' + environment.API_HOST + '/missing/project.png';
    }

    let companySpeech = this.props.companySpeech;

    return (
      <View>
        <Text style={styles.title}>
          Before you access to:{'\n'}
          {companyName}
        </Text>

        <View style={{ alignItems: 'center', marginTop: 20 }}>
          <Avatar rounded size={85} source={{ uri: logoSrc }} />
        </View>

        <ScrollView style={{ height: '40%' }}>
          <Text style={styles.text}>{companySpeech}</Text>
        </ScrollView>

        <Text style={[styles.text, { fontFamily: 'Freight-Sans-Black' }]}>
          Do you declare having no conflict of interest
          <Text style={styles.text}>
            (and undertake to inform Daphni of any change regarding your
            situation)?
          </Text>
        </Text>

        <View style={[styles.buttonContainer, { width: '60%' }]}>
          <TouchableOpacity
            style={[
              { height: 30, paddingHorizontal: 17, justifyContent: 'center' },
            ]}
            onPress={() => this.cancelButton()}
          >
            <Text style={styles.wText}>Cancel</Text>
          </TouchableOpacity>
          <View style={styles.vLine} />
          <TouchableOpacity
            style={[
              { height: 30, paddingHorizontal: 17, justifyContent: 'center' },
            ]}
            onPress={() => this.agreeButton()}
          >
            <Text style={styles.wTitle}>Yes</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

export default CompanyDisclaimer;

const styles = StyleSheet.create({
  whiteContainer: {
    paddingVertical: 10,
    borderRadius: 20,
    backgroundColor: '#ffffff',
    height: '100%',
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#7479ae',
    zIndex: 2,
    bottom: -50,
    borderRadius: 15,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 30,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  companyLogo: {
    justifyContent: 'center',
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 10,
    textAlign: 'center',
    color: '#666666',
  },
  wTitle: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#ffffff',
    marginLeft: 5,
    marginRight: 10,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    marginHorizontal: 10,
    marginTop: 20,
    textAlign: 'justify',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    marginLeft: 10,
    marginRight: 5,
  },
  pairedData: {
    flexDirection: 'row',
    marginLeft: 20,
  },
  vLine: {
    width: 1,
    height: '70%',
    backgroundColor: '#ffffff',
    alignSelf: 'center',
  },
  line: {
    borderBottomColor: '#666666',
    borderBottomWidth: 1,
    margin: 20,
  },
});
