import { useEffect, useState } from 'react';
import { getFunds } from '../../../api/Fund';
import { adaptFunds } from '../../../api/Fund/adapter';

export const useFunds = ({ onCompleted } = {}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [funds, setFunds] = useState([]);

  useEffect(() => {
    getFunds()
      .then(adaptFunds)
      .then((newFunds) => {
        setFunds(newFunds);
        if (onCompleted) {
          onCompleted(newFunds);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    funds,
  };
};
