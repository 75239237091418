import React, { Fragment } from 'react';
import {
  Platform,
  StyleSheet,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  ActivityIndicator,
  SafeAreaView,
} from 'react-native';
import { StatusBarHeight } from '../../src/lib/StatusBarHeight';
import { localstore } from '../../helpers/LocalStore';
import { navigation, theme } from '../../src/lib';
import { DismissKeyboard } from '../../src/components/DismissKeyboard';
import { environment } from '../../src/environment';

class ConfirmCodeScreenComponent extends React.Component {
  state = {
    isLoading: false,
    email: '',
    code: '',
    token: '',
    bearerToken: '',
    errorTxt: '',
  };

  componentDidMount() {
    localstore.getString('email').then((result) => {
      if (result !== null) {
        this.setState({ email: result });
      } else {
        this.props.navigation.navigate('Login');
      }
    });
  }

  _handleTextChange = (event) => {
    this.setState({ code: event.nativeEvent.text });
  };

  async enterButton() {
    this.setState({ isLoading: true });
    fetch('https://' + environment.API_HOST + '/v4/tokens/check', {
      // mode: 'no-cors',
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: {
          email: this.state.email,
          authorization_code: this.state.code,
        },
      }),
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          this.setState({ token: json.data.token }, () => {
            this.getBearerToken();
          });
        });
      } else {
        this.setState({ errorTxt: 'Invalid code', isLoading: false });
      }
    });
  }

  async getBearerToken() {
    fetch('https://' + environment.API_HOST + '/v4/tokens/jwt', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        token: {
          email: this.state.email,
          token: this.state.token,
        },
      }),
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          this.setState({ bearerToken: json.data.jwt }, async () => {
            await localstore.setString('token', this.state.bearerToken);
            this.getUserData();
          });
        });
      } else {
        this.setState({ errorTxt: 'Error connecting to server, try again' });
      }
    });
  }

  getUserData() {
    this.props.fetchMe({
      onSuccess: async () => {
        this.props.setAuthToken(this.state.bearerToken);
        if (navigation.deeplinkPath) {
          try {
            await new Promise((resolve) => setTimeout(resolve, 500));
            this.props.linkTo(navigation.deeplinkPath);
          } catch (error) {
            console.warn(error);
          }
        }
      },
      onFailure: () => this.props.logout(),
    });
  }

  render() {
    return (
      <Fragment>
        <SafeAreaView style={{ backgroundColor: '#45a9ed', flex: 0 }} />
        <SafeAreaView style={{ backgroundColor: '#45a9ed', flex: 1 }}>
          <DismissKeyboard>
            <View style={styles.container}>
              <Text style={styles.daphniText}>daphni</Text>
              <TextInput
                onChange={this._handleTextChange}
                placeholderTextColor={'#45a9ed'}
                style={styles.inputText}
                maxLength={5}
                keyboardType="numeric"
                textContentType="oneTimeCode"
                autoCompleteType="off"
                placeholder="o   o   o   o   o"
              />
              <Text style={styles.instructionText}>
                Check your email, click the link or copy the temporary code.
              </Text>
              <TouchableOpacity
                onPress={this.enterButton.bind(this)}
                disabled={this.state.isLoading}
              >
                {!this.state.isLoading ? (
                  <View style={styles.buttonContainer}>
                    <Text style={styles.buttonText}>Enter</Text>
                  </View>
                ) : (
                  <View style={styles.buttonContainer}>
                    <ActivityIndicator color={theme.colors.secondary} />
                  </View>
                )}
              </TouchableOpacity>
              <Text style={styles.errorText}>{this.state.errorTxt}</Text>

              <View style={styles.disclaimerContainer}>
                <Text style={styles.disclaimerText}>
                  Restricted to the daphni community
                </Text>
              </View>
            </View>
          </DismissKeyboard>
        </SafeAreaView>
      </Fragment>
    );
  }
}

export default ConfirmCodeScreenComponent;

const styles = StyleSheet.create({
  container: {
    marginTop: Platform.OS === 'ios' ? 0 : StatusBarHeight.height,
    backgroundColor: '#45a9ed',
    width: '100%',
    height: '100%',
  },
  daphniText: {
    fontFamily: 'DaphniFont',
    fontSize: 100,
    marginTop: 120,
    color: '#ffffff',
    textAlign: 'center',
  },
  inputText: {
    fontFamily: 'Freight-Sans-Medium',
    width: '60%',
    height: 35,
    textAlign: 'center',
    fontSize: 15,
    color: '#45a9ed',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    alignSelf: 'center',
  },
  instructionText: {
    fontFamily: 'Freight-Sans-Medium',
    width: '60%',
    fontSize: 16,
    color: '#ffffff',
    textAlign: 'center',
    flexWrap: 'wrap',
    margin: 20,
    alignSelf: 'center',
  },
  buttonContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 20,
    width: 100,
    height: 30,
    margin: 20,
    alignSelf: 'center',
    justifyContent: 'center',
  },
  buttonText: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    color: '#45a9ed',
    marginVertical: 5,
    marginHorizontal: 10,
    textAlign: 'center',
  },
  disclaimerContainer: {
    position: 'absolute',
    zIndex: 2,
    bottom: 0,
    height: 50,
    alignSelf: 'center',
  },
  disclaimerText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    textAlign: 'center',
  },
  errorText: {
    marginTop: 20,
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: 'red',
    textAlign: 'center',
  },
});
