import React, { useState } from 'react';
import { DaFlowGenericPage } from './DaFlowGenericPage';
import { StyleSheet, Switch, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { theme } from '../../lib';
import { useResponsive } from '../../lib/responsive';
import { canUserBeAttributedProjectsSelector } from '../../redux/User';

const renderFilterCheckbox = ({
  isDesktopOrLarger,
  label,
  onValueChange,
  value,
}) => (
  <>
    <Text style={[styles.text, !isDesktopOrLarger && styles.mobileText]}>
      {label}
    </Text>
    <Switch
      ios_backgroundColor="#ccc"
      onValueChange={onValueChange}
      value={value}
    />
  </>
);

export const DaFlowPending = () => {
  const { isDesktopOrLarger } = useResponsive();
  const [showRated, setShowRated] = useState(false);
  const [showMyProjects, setShowMyProjects] = useState(true);
  const canUserBeAttributedProjects = useSelector(
    canUserBeAttributedProjectsSelector
  );

  return (
    <DaFlowGenericPage
      daFlowType="pending"
      showGoodProjectIndicator={canUserBeAttributedProjects && !showMyProjects}
      bottomPanel={{
        fetchProjectsParams: {
          state: 'pending',
          sort:
            canUserBeAttributedProjects && !showMyProjects
              ? '-rating,-rating_count'
              : 'created_at',
          ...(canUserBeAttributedProjects &&
            showMyProjects && {
              attributed_only: true,
            }),
          show_rated: showRated,
        },
      }}
      SettingsAdditionalComponents={[
        renderFilterCheckbox({
          isDesktopOrLarger,
          label: 'Show rated',
          onValueChange: setShowRated,
          value: showRated,
        }),
        canUserBeAttributedProjects &&
          renderFilterCheckbox({
            isDesktopOrLarger,
            label: 'Show only my projects',
            onValueChange: setShowMyProjects,
            value: showMyProjects,
          }),
      ]}
    />
  );
};

const styles = StyleSheet.create({
  text: {
    marginRight: 5,
    ...theme.fonts.normal,
  },
  mobileText: {
    color: 'white',
  },
});
