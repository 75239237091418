import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { theme } from '../../../../../lib';
import { NavigationTouchable } from '../../../../../components';
import React from 'react';

export const DaFlowButton = ({
  to,
  isActive,
  textStyle,
  style,
  label,
  ...touchableProps
}) => {
  const Container = to ? NavigationTouchable : TouchableOpacity;
  return (
    <Container
      to={to}
      style={[styles.button, isActive && styles.activeButton, style]}
      {...touchableProps}
    >
      <Text
        style={[
          styles.buttonText,
          isActive && styles.activeButtonText,
          textStyle,
        ]}
      >
        {label}
      </Text>
    </Container>
  );
};

const styles = StyleSheet.create({
  button: {
    backgroundColor: '#E5E5E5',
    paddingHorizontal: 15,
    paddingVertical: 10,
    borderRadius: 75,
  },
  activeButton: {
    backgroundColor: '#2BA596',
  },
  buttonText: {
    ...theme.fonts.bold,
  },
  activeButtonText: {
    color: 'white',
  },
});
