import React from 'react';
import {
  ImageBackground,
  Linking,
  Text,
  View,
  useWindowDimensions,
  StyleSheet,
} from 'react-native';
import Flag from 'react-native-round-flags';
import { theme } from '../../../lib';
const defaultCoverImage = require('../../../../assets/dummy_data/companyHeader.jpg');

const getFundColors = (type) => {
  const fundColors = theme.colors.funds[type] || theme.colors.funds.Default;

  return {
    backgroundColor: fundColors.primary,
    textColor: fundColors.textOnPrimary,
  };
};

export const HouseCompanyHeader = (props) => {
  const dimensions = useWindowDimensions();
  const { backgroundColor, textColor } = getFundColors(props.type);

  return (
    <ImageBackground
      source={props.coverImage || defaultCoverImage}
      style={{ width: dimensions.width, height: 200, justifyContent: 'center' }}
    >
      <View style={[styles.whiteContainer, { width: 150 }]}>
        <Text style={styles.text}>
          {props.city}, {props.country}
        </Text>
        <Flag code={props.countryCode} style={styles.bannerFlag} />
      </View>
      <View style={styles.webContainer}>
        <View style={[styles.whiteContainer, { width: 180 }]}>
          <Text
            style={styles.text}
            onPress={() => Linking.openURL(props.website)}
          >
            {props.website}
          </Text>
        </View>
        <View style={[styles.typeContainer, { backgroundColor }]}>
          <Text style={[styles.typeText, { color: textColor }]}>
            {props.type}
          </Text>
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  whiteContainer: {
    backgroundColor: '#ffffff',
    marginLeft: 60,
    marginTop: 5,
    paddingHorizontal: 8,
    paddingVertical: 3,
    borderRadius: 5,
  },
  typeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    height: 20,
    width: 60,
    borderRadius: 10,
    alignSelf: 'center',
    marginLeft: 10,
  },
  webContainer: {
    maxHeight: 60,
    flexDirection: 'row',
    width: '80%',
    justifyContent: 'space-between',
  },
  bannerFlag: {
    position: 'absolute',
    right: -10,
    zIndex: 3,
    width: 20,
    height: 20,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
  },
});
