import React, { PureComponent } from 'react';
import { View, StyleSheet, FlatList, Text } from 'react-native';
import { Page } from '../../src/components';
import { formatDiscussionTitle } from '../ChatListScreen/lib';
import {
  GroupActions,
  EditGroupTitleForm,
  RemoveUserOfGroupButton,
} from './components';
import BaseDaphnipolitan from '../../components/Daphnipolitan/BaseDaphnipolitan';

export class GroupSettingsScreenComponent extends PureComponent {
  state = {
    isEditingGroup: false,
    userBeingRemoved: null,
  };

  onEditGroup = () =>
    this.setState({
      isEditingGroup: true,
    });

  removeUser = (userReference) => {
    this.setState({ userBeingRemoved: userReference }, () => {
      this.props.removeUserOfGroupDiscussion(
        this.props.discussion.reference,
        userReference
      );
    });
  };

  renderRemoveUserButton = (user) => (
    <RemoveUserOfGroupButton
      isEditingGroup={this.state.isEditingGroup}
      isRemovingUser={this.props.isRemovingUser}
      userBeingRemoved={this.state.userBeingRemoved}
      userReference={user.reference}
      removeUser={this.removeUser}
    />
  );

  renderUser = ({ item: user }) => {
    return (
      <View style={styles.userRow}>
        <BaseDaphnipolitan
          reference={user.reference}
          profileSrc={user.avatar}
          name={`${user.firstName} ${user.lastName}`}
        />
        {this.renderRemoveUserButton(user)}
      </View>
    );
  };

  renderEmptyUserList = () => (
    <Text style={styles.emptyText}>No users in this group yet.</Text>
  );

  renderSeparator = () => <View style={styles.separator} />;

  renderActions = () => (
    <GroupActions
      style={styles.actions}
      isEditingGroup={this.state.isEditingGroup}
      onEditGroup={this.onEditGroup}
      discussionReference={this.props.discussion.reference}
    />
  );

  renderEditGroupNameForm = () => {
    const { reference, title } = this.props.discussion;
    return (
      <EditGroupTitleForm
        discussionReference={reference}
        discussionTitle={title}
      />
    );
  };

  render() {
    const { discussion, isRemovingUser } = this.props;
    if (!discussion) {
      return null;
    }

    const { isEditingGroup, userBeingRemoved } = this.state;
    return (
      <Page
        headerColor="#45a9ed"
        title={formatDiscussionTitle(discussion)}
        alignTitleLeft
        renderHeaderTitle={
          this.state.isEditingGroup ? this.renderEditGroupNameForm : null
        }
      >
        <FlatList
          data={discussion.otherUsers}
          renderItem={this.renderUser}
          contentContainerStyle={styles.contentContainer}
          ItemSeparatorComponent={this.renderSeparator}
          ListEmptyComponent={this.renderEmptyUserList}
          ListFooterComponent={this.renderActions}
          extraData={JSON.stringify({
            isEditingGroup,
            isRemovingUser,
            userBeingRemoved,
          })}
        />
      </Page>
    );
  }
}

const styles = StyleSheet.create({
  separator: {
    height: 15,
  },
  actions: {
    marginTop: 20,
  },
  contentContainer: {
    paddingVertical: 20,
    marginHorizontal: 20,
  },
  emptyText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
  userRow: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
