import React, { Fragment } from 'react';
import {
  Dimensions,
  Text,
  View,
  StyleSheet,
  Platform,
  TouchableOpacity,
  SafeAreaView,
} from 'react-native';
import { StatusBarHeight } from '../../src/lib/StatusBarHeight';
import Ionicons from 'react-native-vector-icons/Ionicons';

import {
  formatDiscussionAvatar,
  formatDiscussionTitle,
} from '../ChatListScreen/lib';
import { Button } from '../../src/components';

const WIDTH = Dimensions.get('window').width;

class NewChatScreenComponent extends React.Component {
  createNewGroupChat = async () => {
    this.props.createGroupDiscussion((discussion) => {
      this.props.navigation.navigate('ChatWindow', {
        discussionReference: discussion.reference,
        title: formatDiscussionTitle(discussion),
        avatar: formatDiscussionAvatar(discussion),
      });
    });
  };

  goBack = () => this.props.navigation.goBack();
  goToChatFindUsers = () => this.props.navigation.navigate('ChatFindUsers');

  render() {
    return (
      <Fragment>
        <SafeAreaView style={{ backgroundColor: '#45a9ed', flex: 0 }} />
        <SafeAreaView style={{ backgroundColor: '#fff', flex: 1 }}>
          <View style={styles.container}>
            <View style={styles.topPanel}>
              <TouchableOpacity
                onPress={this.goBack}
                style={[
                  {
                    height: 50,
                    width: 50,
                    margin: 0,
                    justifyContent: 'center',
                  },
                ]}
              >
                <Ionicons
                  name="ios-arrow-back"
                  size={32}
                  style={{ marginHorizontal: 15 }}
                  color="#ffffff"
                />
              </TouchableOpacity>
              <Text style={styles.mainTitle}>New Chat</Text>
            </View>
            <View style={{ marginTop: 60 }}>
              <Button
                onPress={this.createNewGroupChat}
                style={styles.newGroupButton}
                isLoading={this.props.isCreatingGroupDiscussion}
                mode="primary"
                title="Create a group"
              />
              <TouchableOpacity onPress={this.goToChatFindUsers}>
                <View style={styles.searchBar}>
                  <Ionicons
                    name="ios-search"
                    style={{ marginVertical: 5, marginHorizontal: 10 }}
                    size={20}
                    color={'#666'}
                  />
                  <Text style={styles.text}>Find users</Text>
                </View>
              </TouchableOpacity>
            </View>
          </View>
        </SafeAreaView>
      </Fragment>
    );
  }
}

export default NewChatScreenComponent;

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#ffffff',
  },
  topPanel: {
    position: 'absolute',
    paddingTop: Platform.OS === 'ios' ? 0 : StatusBarHeight.height,
    height: 50 + (Platform.OS === 'ios' ? 0 : StatusBarHeight.height),
    zIndex: 2,
    top: 0,
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#45a9ed',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
  },
  mainTitle: {
    width: WIDTH - 100,
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
    textAlign: 'center',
    alignSelf: 'center',
  },
  newGroupButton: {
    marginHorizontal: 10,
    marginTop: 20,
  },
  searchBar: {
    width: WIDTH - 20,
    height: 30,
    borderRadius: 15,
    marginVertical: 10,
    backgroundColor: '#eaeaea',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
});
