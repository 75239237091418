import React, { PureComponent } from 'react';
import { getAppointedProjects } from '../../../../api';
import { adaptProject } from '../../../../api/Project/adapter';
import { ActivityIndicator, StyleSheet, Text } from 'react-native';
import Company from './HomeCompany';
import { Card } from '../Card';

export class CompanyOfTheWeek extends PureComponent {
  state = {
    loading: true,
    project: null,
  };

  componentDidMount() {
    this.getCompanyOfTheWeek();
  }

  getCompanyOfTheWeek = () => {
    this.setState({ loading: true });
    getAppointedProjects()
      .then(({ data: projects }) => {
        if (!projects.length) {
          return null;
        }

        return adaptProject(projects[0]);
      })
      .then((project) => {
        this.setState({
          loading: false,
          project,
        });
      })
      .catch((error) => {
        console.error('Error when loading', error);
        this.setState({ loading: false });
      });
  };

  render() {
    const { project, loading } = this.state;
    if (!project) {
      if (!loading) {
        return null;
      }

      return (
        <Card>
          <ActivityIndicator size="large" color="grey" />
        </Card>
      );
    }

    return (
      <Card>
        <Text style={styles.title}>Company of the week</Text>
        <Company
          reference={project.reference}
          name={project.name}
          logoSrc={project.logo.url_medium}
          description={project.description}
          country={project.country}
          countryCode={project.countryCode}
          sectors={project.sectors}
          score={project.rating.average}
          money={project.fundraisingAmount}
          comments={project.commentCount}
        />
      </Card>
    );
  }
}

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginHorizontal: 20,
    textAlign: 'center',
    color: '#666666',
  },
});
