import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  Platform,
  TouchableOpacity,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../../src/lib/StatusBarHeight';

import { Badge } from 'react-native-elements';
import Ionicons from 'react-native-vector-icons/Ionicons';

import { localstore } from '../../helpers/LocalStore';
import { desktopOrLargerMinPixel } from '../../src/lib/responsive';
import { DesktopNavigationMenu } from '../../src/components/DesktopNavigationMenu';
import { withWindowDimensions } from '../../src/hoc';
import { theme } from '../../src/lib';
import { environment } from '../../src/environment';

let notificationPic = {
  source: require('../../assets/icons/notificationIcon01.png'),
};

let messagePic = {
  source: require('../../assets/icons/notificationIcon02.png'),
};

const DESKTOP_HEADER_HEIGHT = 80;
export const DESKTOP_HEADER_OFFSET = DESKTOP_HEADER_HEIGHT + 40;

class DaphniHeaderComponent extends React.Component {
  state = {
    avatar: 'https://' + environment.API_HOST + '/missing/avatar.png',
  };

  componentDidMount() {
    localstore.getString('avatar').then((result) => {
      if (result !== null) {
        this.setState({ avatar: result });
      } else {
        this.setState({
          avatar: 'https://' + environment.API_HOST + '/missing/avatar.png',
        });
      }
    });
  }

  searchPressed() {
    this.props.navigation.navigate('SearchModal', { index: this.props.index });
  }

  toggleDrawer = () => {
    this.props.navigation.toggleDrawer();
  };

  renderNavigationMenu = () => {
    if (this.props.dimensions.width < desktopOrLargerMinPixel) {
      return null;
    }

    return (
      <View style={styles.headerNavigationMenuContainer}>
        <DesktopNavigationMenu />
      </View>
    );
  };

  render() {
    const index = this.props.index;
    let colorBg;
    switch (index) {
      case 0: //HOME
        colorBg = '#7e0d86';
        break;

      case 1: //DaFlow
        colorBg = '#7479ae';
        break;

      case 2: //DaHouse
        colorBg = '#00b6ba';
        break;

      case 3: //Daphnipolis
        colorBg = '#45a9ed';
        break;

      default:
        //Other?
        colorBg = '#000';
        break;
    }

    const isDesktop = this.props.dimensions.width >= desktopOrLargerMinPixel;

    const EverythingExceptProfileImageContainer = ({ children }) =>
      isDesktop ? (
        <View
          style={{
            flex: 1,
            marginLeft: 10,
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          {children}
        </View>
      ) : (
        children
      );

    const iconContainerStyle = isDesktop
      ? {
          backgroundColor: colorBg,
          height: 32,
          width: 32,
          borderRadius: 64,
          alignItems: 'center',
          justifyContent: 'center',
        }
      : undefined;
    const iconSizeStyle = isDesktop
      ? { width: 18, height: 18 }
      : { width: 20, height: 20 };

    const searchColor = isDesktop ? '#666' : colorBg;

    return (
      <View
        style={[
          styles.container,
          {
            backgroundColor: isDesktop ? 'white' : colorBg,
          },
          isDesktop
            ? {
                height: 80,
                borderRadius: 40,
                top: 30,
                left: 72,
                right: 72,
                ...theme.shadow,
                justifyContent: 'flex-start',
              }
            : undefined,
        ]}
      >
        <TouchableOpacity
          hitSlop={{ top: 15, left: 15, right: 15, bottom: 15 }}
          onPress={this.toggleDrawer}
        >
          <Image
            style={
              !isDesktop ? styles.profileImage : styles.desktopProfileImage
            }
            source={{ uri: this.state.avatar }}
          />
        </TouchableOpacity>
        <EverythingExceptProfileImageContainer>
          {this.renderNavigationMenu()}
          <TouchableOpacity
            style={[{ height: 50, justifyContent: 'center' }]}
            onPress={() => this.searchPressed()}
          >
            <View
              style={[
                styles.searchBar,
                {
                  width: (isDesktop ? 0.25 : 0.6) * this.props.dimensions.width,
                },
                isDesktop
                  ? {
                      height: 40,
                      borderRadius: 50,
                      borderWidth: 1,
                      borderColor: searchColor,
                      paddingHorizontal: 15,
                    }
                  : undefined,
              ]}
            >
              <Ionicons
                name="ios-search"
                style={{ margin: 5 }}
                size={20}
                color={searchColor}
              />
              <Text
                style={[
                  styles.searchText,
                  {
                    color: searchColor,
                  },
                ]}
              >
                How can I help you?
              </Text>
            </View>
          </TouchableOpacity>
          <View style={{ flexDirection: 'row' }}>
            <TouchableOpacity
              style={styles.touchablearea}
              onPress={() => this.props.navigation.navigate('Timeline')}
            >
              <View style={styles.notificationImage}>
                <View style={iconContainerStyle}>
                  <Image
                    source={notificationPic.source}
                    style={iconSizeStyle}
                    resizeMode="contain"
                  />
                </View>
                {this.props.hasTimelineNotifications && (
                  <Badge
                    status="error"
                    containerStyle={{
                      position: 'absolute',
                      top: -4,
                      right: -4,
                    }}
                  />
                )}
              </View>
            </TouchableOpacity>
            <View style={{ width: 5 }} />
            <TouchableOpacity
              style={styles.touchablearea}
              onPress={() => this.props.navigation.navigate('ChatList')}
            >
              <View style={styles.notificationImage}>
                <View style={iconContainerStyle}>
                  <Image
                    source={messagePic.source}
                    style={iconSizeStyle}
                    resizeMode="contain"
                  />
                </View>
                {this.props.hasDiscussionNotifications && (
                  <Badge
                    status="error"
                    containerStyle={{
                      position: 'absolute',
                      top: -4,
                      right: -4,
                    }}
                  />
                )}
              </View>
            </TouchableOpacity>
          </View>
        </EverythingExceptProfileImageContainer>
      </View>
    );
  }
}

export default withWindowDimensions(DaphniHeaderComponent);

const AVATAR_SIZE = 35;
const DESKTOP_AVATAR_SIZE = 72;

const styles = StyleSheet.create({
  touchablearea: {
    height: 50,
    paddingHorizontal: 7,
    justifyContent: 'center',
  },
  container: {
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: 0,
    right: 0,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    height: 50 + (Platform.OS === 'ios' ? 0 : StatusBarHeight.height),
    paddingTop: Platform.OS === 'ios' ? 0 : StatusBarHeight.height,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  profileImage: {
    width: AVATAR_SIZE,
    height: AVATAR_SIZE,
    borderRadius: AVATAR_SIZE / 2,
    borderWidth: 2,
    borderColor: '#ffffff',
    margin: 5,
  },
  desktopProfileImage: {
    width: DESKTOP_AVATAR_SIZE,
    height: DESKTOP_AVATAR_SIZE,
    borderRadius: DESKTOP_AVATAR_SIZE,
    marginLeft: 4,
  },
  searchBar: {
    height: '60%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  searchText: {
    fontFamily: 'Freight-Sans-Medium',
    textAlign: 'center',
    fontSize: 16,
  },
});
