import { connect } from 'react-redux';
import { GroupSettingsScreenComponent } from './GroupSettingsScreen.component';
import {
  discussionByReferenceSelector,
  removeUserOfGroupDiscussion,
} from '../../src/redux/Discussion';
import { isLoadingSelector } from '../../src/redux/LoadingStatus';

const mapStateToProps = (state, ownProps) => ({
  discussion: discussionByReferenceSelector(
    ownProps.route.params?.discussionReference
  )(state),
  isRemovingUser: isLoadingSelector('removeUserOfGroupDiscussion')(state),
});

const mapDispatchToProps = {
  removeUserOfGroupDiscussion,
};

export const ConnectedGroupSettingsScreen = connect(
  mapStateToProps,
  mapDispatchToProps
)(GroupSettingsScreenComponent);
