import React from 'react';
import {
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from 'react-native';
import { innerViewBaseStyles, Page, SubMenuContainer } from '../../components';
import { isCloseToBottom } from '../../lib/isCloseToBottom';
import { DESKTOP_HEADER_OFFSET } from '../../../components/DaphniHeader/DaphniHeader.component';
import DaphniFooter from '../../../components/DaphniFooter';
import DaphniHeader from '../../../components/DaphniHeader';
import { useDaHousePage } from './hooks/useDaHousePage';
import { theme } from '../../lib';
import { FundsStats } from './components/FundsStats';
import { BreakingStories } from './components/BreakingStories';
import { Settings } from './components/Settings';
import { HouseProjects } from './components/HouseProjects';

export const DaHouse = () => {
  const {
    navigation,
    isDesktopOrLarger,
    isRefreshing,
    onRefresh,
    isLoading,
    canFetchMore,
    allProjects,
    fetchMore,
    funds,
    isLoadingFunds,
    activeFundsMap,
    setActiveFundsMap,
    projectCountPerColumns,
    setProjectCountPerColumns,
  } = useDaHousePage();
  const dimensions = useWindowDimensions();

  const renderHeaderContent = () => (
    <DaphniHeader navigation={navigation} index={2} />
  );

  return (
    <Page
      headerColor={theme.colors.dahouse}
      backgroundColor={theme.colors.orange}
      renderHeaderContent={renderHeaderContent}
      withoutAutoOffsetOnDesktop
    >
      <ScrollView
        style={innerViewBaseStyles.contentTopPanelDefaultFix}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={onRefresh}
            tintColor="white"
          />
        }
        scrollEventThrottle={2}
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent) && canFetchMore) {
            fetchMore();
          }
        }}
      >
        <View
          style={[
            styles.titleSection,
            {
              paddingTop: isDesktopOrLarger ? DESKTOP_HEADER_OFFSET : undefined,
            },
          ]}
        >
          <Text style={styles.flowText}>da house</Text>
        </View>
        <FundsStats funds={funds} isLoadingFunds={isLoadingFunds} />
        <BreakingStories />
        <View
          style={{
            backgroundColor: theme.colors.dahouse,
            paddingTop: theme.margin.unit,
          }}
        >
          {isDesktopOrLarger && (
            <View style={{ alignItems: 'center' }}>
              <SubMenuContainer
                style={{ marginTop: 10, maxWidth: dimensions.width * 0.5 }}
              >
                <Settings
                  projectCountPerColumns={projectCountPerColumns}
                  setProjectCountPerColumns={setProjectCountPerColumns}
                  funds={funds}
                  activeFundsMap={activeFundsMap}
                  setActiveFundsMap={setActiveFundsMap}
                />
              </SubMenuContainer>
            </View>
          )}
          <HouseProjects
            funds={funds}
            isLoading={isLoading}
            isRefreshing={isRefreshing}
            projects={allProjects}
            projectCountPerColumns={projectCountPerColumns}
          />
        </View>
      </ScrollView>
      {!isDesktopOrLarger && (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={styles.mobileSettingsContainer}
          contentContainerStyle={styles.mobileSettingsContentContainer}
        >
          <Settings
            style={styles.mobileSettings}
            projectCountPerColumns={projectCountPerColumns}
            setProjectCountPerColumns={setProjectCountPerColumns}
            funds={funds}
            activeFundsMap={activeFundsMap}
            setActiveFundsMap={setActiveFundsMap}
          />
        </ScrollView>
      )}
      <DaphniFooter />
    </Page>
  );
};

const styles = StyleSheet.create({
  titleSection: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  flowText: {
    fontFamily: 'DaphniFont',
    fontSize: 72,
    marginTop: 10,
    color: '#ffffff',
    textAlign: 'center',
  },
  mobileSettingsContainer: {
    position: 'absolute',
    left: 20,
    right: 20,
    bottom: 65,
    zIndex: 2,
    borderRadius: 30,
    backgroundColor: theme.colors.dahouse,
    borderColor: 'white',
    borderWidth: 1,
    ...theme.shadow,
  },
  mobileSettingsContentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  mobileSettings: {
    alignSelf: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});
