import { useSelector } from 'react-redux';
import { authTokenSelector, hasSeenOnboardingSelector } from '../redux/User';

const getNavigationState = ({ hasSeenOnboarding, authToken }) => {
  if (!hasSeenOnboarding) {
    return 'firstTimeUser';
  }

  return authToken ? 'authenticated' : 'unauthenticated';
};

export const usePrepareNavigation = () => {
  const authToken = useSelector(authTokenSelector);
  const hasSeenOnboarding = useSelector(hasSeenOnboardingSelector);

  return {
    navigationState: getNavigationState({
      hasSeenOnboarding,
      authToken,
    }),
  };
};
