import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
  TouchableOpacity,
} from 'react-native';
import { Icon, ButtonGroup, Overlay } from 'react-native-elements';
import { localstore } from '../../helpers/LocalStore';
import CommentWithMentions from './CommentWithMentions';
import { createProjectComment } from '../../src/api';

class NewComment extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      message: '',
      selectedIndex: 1,
      publicColor: '#2fad2d',
      privateColor: '#6c6c6c',
      sentMessageOverlay: false,
      errorMessageOverlay: false,
    };
    this.updateIndex = this.updateIndex.bind(this);
  }

  componentDidMount() {
    localstore.getString('role').then((result) => {
      if (result !== null) {
        this.setState({ role: result });
      } else {
        this.setState({ role: 'dp' });
      }
    });
  }

  showSentMessageOverlay() {
    //this.setState({ sentMessageOverlay: true, message: '' });
    this.setState({ message: '' });
    this.props.successfullMessageSent();
  }

  showErrorMessageOverlay() {
    this.setState({ errorMessageOverlay: true });
  }

  _handleTextChange = (text) => {
    this.setState({ message: text });
  };

  async sendMessage() {
    this.setState({ loading: true });
    try {
      await createProjectComment(this.props.reference, {
        content: this.state.message,
        is_private: this.state.selectedIndex != 1,
      });

      this.showSentMessageOverlay();
    } catch (err) {
      console.log('Error fetching data-----------', err);
      this.showErrorMessageOverlay();
    } finally {
      this.setState({ loading: false });
    }
  }

  updateComments() {
    console.log('NEWCOMMENT: Update comments to show the new one');
    this.props.updateComments();
  }

  updateIndex(selectedIndex) {
    let lastIndex = this.state.selectedIndex;
    if (lastIndex != selectedIndex) {
      let color1 = this.state.publicColor;
      let color2 = this.state.privateColor;
      this.setState({ publicColor: color2 });
      this.setState({ privateColor: color1 });
    }
    this.setState({ selectedIndex });
  }

  render() {
    let sendButton;
    if (this.state.loading) {
      sendButton = (
        <TouchableOpacity>
          <View style={styles.sendButton}>
            <ActivityIndicator size="small" color="grey" />
          </View>
        </TouchableOpacity>
      );
    } else {
      sendButton = (
        <TouchableOpacity onPress={() => this.sendMessage()}>
          <View style={styles.sendButton}>
            <Text style={styles.sendText}>Send</Text>
          </View>
        </TouchableOpacity>
      );
    }

    let errorMessageOverlay = (
      <Overlay
        isVisible={this.state.errorMessageOverlay}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '90%', height: '15%', borderRadius: 20 }}
        onBackdropPress={() => this.setState({ errorMessageOverlay: false })}
      >
        <Text
          style={[
            styles.text,
            { textAlign: 'center', marginTop: 8, fontSize: 16 },
          ]}
        >
          There was a problem sending your message, try again
        </Text>
      </Overlay>
    );

    const privateBtn = () => (
      <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        <Icon name="key" type="font-awesome" color="#666" size={14} />
        <Text style={styles.text}>Private</Text>
        <Icon
          name="circle"
          type="font-awesome"
          color={this.state.privateColor}
          size={16}
        />
      </View>
    );
    const publicBtn = () => (
      <View style={{ flexDirection: 'row', justifyContent: 'space-around' }}>
        <Icon name="users" type="font-awesome" color="#666" size={14} />
        <Text style={styles.text}>Public</Text>
        <Icon
          name="circle"
          type="font-awesome"
          color={this.state.publicColor}
          size={16}
        />
      </View>
    );

    const buttons = [{ element: privateBtn }, { element: publicBtn }];
    const { selectedIndex } = this.state;

    // if (this.state.role == 'team' || this.state.role == 'family') {

    const btnGroup = (
      <ButtonGroup
        onPress={this.updateIndex}
        selectedIndex={selectedIndex}
        buttons={buttons}
        containerStyle={styles.buttonGroup}
        selectedButtonStyle={styles.selectedButton}
        disabledSelectedStyle={styles.disabledButton}
        buttonStyle={styles.button}
      />
    );

    return (
      <View style={this.props.style}>
        {errorMessageOverlay}
        <CommentWithMentions
          setMessage={this._handleTextChange}
          placeholder="Leave your comment"
          style={styles.commentInput}
        />
        {btnGroup}
        {sendButton}
      </View>
    );
  }
}

export default NewComment;

const styles = StyleSheet.create({
  commentInput: {
    marginHorizontal: 10,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  sendButton: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 10,
    backgroundColor: '#ffffff',
    height: 25,
    width: 60,
    borderRadius: 13,
  },
  buttonGroup: {
    marginTop: -10,
    backgroundColor: '#ffffff',
    marginHorizontal: 10,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    flexDirection: 'row',
    height: 25,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginHorizontal: 20,
  },
  sendText: {
    fontFamily: 'Freight-Sans-Black',
    color: '#7479ae',
    fontSize: 16,
    textAlign: 'center',
  },
  button: {},
  selectedButton: {
    backgroundColor: '#cdcdcd',
  },
  disabledButton: {
    backgroundColor: '#858585',
  },
});
