export const initialState = {};

export const loadingStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING_START':
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          isLoading: true,
          error: undefined,
        },
      };
    case 'LOADING_END':
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          isLoading: false,
          error: undefined,
        },
      };
    case 'LOADING_ERROR':
      return {
        ...state,
        [action.payload.key]: {
          ...state[action.payload.key],
          isLoading: false,
          error: action.payload.error,
        },
      };
    default:
      return state;
  }
};
