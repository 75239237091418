import {
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  useWindowDimensions,
  View,
} from 'react-native';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Overlay } from 'react-native-elements';
import { meSelector } from '../../../../../redux/User';
import { theme } from '../../../../../lib';
import FiltersIcon from '../../../../../../assets/icons/filters.svg';
import {
  tabletOrLargerMinPixel,
  useResponsive,
} from '../../../../../lib/responsive';
import { DaFlowButton } from './DaFlowButton';
import { availableSectors } from '../../../availableSectors';
import { SectorButton } from './SectorButton';

export const ActiveSectorsButtonModal = ({
  activeSectorsIds,
  setActiveSectorsIds,
}) => {
  const me = useSelector(meSelector);
  const [isSectorModalOpened, setIsSectorModalOpened] = useState(false);
  const { isDesktopOrLarger } = useResponsive();
  const [selectedSectorsIds, setSelectedSectorsIds] = useState(
    activeSectorsIds
  );
  const selectAll = useCallback(() => {
    setSelectedSectorsIds(availableSectors.map((sector) => sector.id));
  }, []);
  const unselectAll = useCallback(() => {
    setSelectedSectorsIds([]);
  }, []);
  const toggleOne = useCallback((sectorId) => {
    setSelectedSectorsIds((selectedIds) => {
      if (selectedIds.includes(sectorId)) {
        return selectedIds.filter(
          (selectedSectorId) => selectedSectorId !== sectorId
        );
      }

      return [...selectedIds, sectorId];
    });
  }, []);
  const confirm = useCallback(() => {
    setActiveSectorsIds(selectedSectorsIds);
    setIsSectorModalOpened(false);
  }, [setActiveSectorsIds, selectedSectorsIds]);

  const areAllSectorsSelected =
    selectedSectorsIds.length === availableSectors.length;

  const dimensions = useWindowDimensions();

  const [scrollViewLayout, setScrollViewLayout] = useState();

  const sectorButtonSize = scrollViewLayout
    ? Math.max(75, scrollViewLayout.width / (isDesktopOrLarger ? 5 : 3))
    : 0;

  return (
    <>
      <Overlay
        isVisible={isSectorModalOpened}
        transparent
        animated
        animationType="fade"
        overlayStyle={styles.overlay}
        onBackdropPress={() => setIsSectorModalOpened(false)}
      >
        <View>
          <ScrollView
            style={{ maxHeight: 0.5 * dimensions.height }}
            contentContainerStyle={styles.allSectorsContentContainer}
            onContentSizeChange={(width, height) =>
              setScrollViewLayout({ width, height })
            }
          >
            <SectorButton
              isActive={areAllSectorsSelected}
              image={require('../../../../../../assets/icons/categories/category_01.png')}
              label={areAllSectorsSelected ? 'Unselect all' : 'Select all'}
              onPress={areAllSectorsSelected ? unselectAll : selectAll}
              style={{ width: sectorButtonSize }}
              isDesktopOrLarger={isDesktopOrLarger}
            />
            {availableSectors.map((sector) => (
              <SectorButton
                key={sector.id}
                label={sector.label}
                image={sector.icon}
                isActive={selectedSectorsIds.includes(sector.id)}
                onPress={() => toggleOne(sector.id)}
                style={{ width: sectorButtonSize }}
                isDesktopOrLarger={isDesktopOrLarger}
              />
            ))}
          </ScrollView>
          <View style={styles.modalConfirmButtons}>
            <DaFlowButton
              label="Cancel"
              onPress={() => setIsSectorModalOpened(false)}
            />
            <View style={styles.confirmButtonSeparator} />
            <DaFlowButton isActive label="Confirm" onPress={confirm} />
          </View>
        </View>
      </Overlay>
      <TouchableOpacity
        hitSlop={{ top: 20, right: 20, left: 20, bottom: 20 }}
        style={styles.buttonContainer}
        onPress={() => setIsSectorModalOpened(true)}
      >
        <Text style={[styles.text, !isDesktopOrLarger && styles.mobileText]}>
          {me && me.firstName ? me.firstName : 'User'}'s filter
        </Text>
        {isDesktopOrLarger ? (
          <FiltersIcon />
        ) : (
          <Image
            source={require('../../../../../../assets/icons/filters.png')}
            resizeMode="contain"
            style={styles.mobileButtonIcon}
          />
        )}
      </TouchableOpacity>
    </>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileButtonIcon: {
    width: 15,
    height: 15,
  },
  text: {
    marginRight: 5,
    ...theme.fonts.normal,
  },
  mobileText: {
    color: 'white',
  },
  overlay: {
    maxWidth: tabletOrLargerMinPixel,
    alignSelf: 'stretch',
    margin: 'auto',
    paddingVertical: 20,
    paddingHorizontal: 0,
    borderRadius: 20,
    backgroundColor: 'white',
    ...theme.shadow,
  },
  allSectorsContentContainer: {
    marginHorizontal: 20,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  modalConfirmButtons: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  confirmButtonSeparator: {
    width: 10,
  },
});
