import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { compose } from 'recompose';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { withCodePush, withDeeplinkListener, withRedux } from './src/hoc';
import { navigation } from './src/lib';
import { RootNavigation } from './src/navigation/RootNavigation';
import { linkingConfig } from './src/navigation/linkingConfig';
import { LoadingView } from './src/navigation/LoadingView';
import { useApp } from './src/useApp';

const navigationDocumentTitleOptions = {
  formatter: (options, route) => {
    const name = options?.title ?? route?.name;
    return ['daphni', name].filter(Boolean).join(' | ');
  },
};

const App = () => {
  const { isReady } = useApp();
  if (!isReady) {
    return <LoadingView />;
  }

  return (
    <SafeAreaProvider>
      <NavigationContainer
        ref={navigation.navigationRef}
        linking={linkingConfig}
        documentTitle={navigationDocumentTitleOptions}
      >
        <RootNavigation />
      </NavigationContainer>
    </SafeAreaProvider>
  );
};

const enhance = compose(withCodePush, withRedux, withDeeplinkListener);

export default enhance(App);
