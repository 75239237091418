import React, { useCallback } from 'react';
import {
  ActivityIndicator,
  FlatList,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { useResponsive } from '../../../lib/responsive';
import HouseCompanyGridItem from './HouseCompanyGrid';
import HouseCompany from './HouseCompany';
import { theme } from '../../../lib';
import { useNavigation } from '@react-navigation/native';
import { innerViewBaseStyles } from '../../../components';

const projectKeyExtractor = (project) => project.reference;

export const HouseProjects = ({
  funds,
  isLoading,
  isRefreshing,
  projects,
  projectCountPerColumns,
}) => {
  const navigation = useNavigation();
  const { isDesktopOrLarger } = useResponsive();

  const renderItem = useCallback(
    ({ item: project }) => {
      const projectFunds = project.funds
        .map((fundLabel) => funds?.find((fund) => fund.label === fundLabel))
        .filter(Boolean);

      return (
        <View
          style={[
            styles.companyContainer,
            isDesktopOrLarger && styles.desktopCompanyContainer,
          ]}
        >
          {projectCountPerColumns === 1 ||
          (isDesktopOrLarger && projectCountPerColumns === 2) ? (
            <HouseCompany
              reference={project.reference}
              logoSrc={project.logo.url_medium}
              name={project.name}
              description={project.description}
              activity={project.businessActivity}
              country={project.country}
              countryCode={project.countryCode}
              money={project.daphniInvestedAmount}
              numColumns={projectCountPerColumns}
              fundId={projectFunds[0]?.id}
              funds={projectFunds}
              type={project.funds[0]}
              navigation={navigation}
            />
          ) : (
            <HouseCompanyGridItem
              reference={project.reference}
              logoSrc={project.logo.url_medium}
              name={project.name}
              description={project.description}
              activity={project.businessActivity}
              country={project.country}
              countryCode={project.countryCode}
              money={project.daphniInvestedAmount}
              type={project.funds[0]}
              fundId={projectFunds[0]?.id}
              funds={projectFunds}
              numColumns={projectCountPerColumns}
              navigation={navigation}
            />
          )}
        </View>
      );
    },
    [funds, isDesktopOrLarger, navigation, projectCountPerColumns]
  );

  const renderListFooter = useCallback(
    () => (
      <View style={styles.paginationLoaderContainer}>
        {isLoading && !isRefreshing ? (
          <ActivityIndicator
            size={projects.length ? 'small' : 'large'}
            color="white"
          />
        ) : null}
      </View>
    ),
    [projects.length, isLoading, isRefreshing]
  );

  const renderListEmpty = useCallback(
    () =>
      !isLoading && (
        <Text style={styles.emptyText}>
          There are no projects with this criteria
        </Text>
      ),
    [isLoading]
  );

  return (
    <View style={styles.container}>
      <FlatList
        contentContainerStyle={innerViewBaseStyles.scrollViewContentContainer}
        key={projectCountPerColumns}
        numColumns={projectCountPerColumns}
        scrollEnabled={false}
        data={projects}
        keyExtractor={projectKeyExtractor}
        renderItem={renderItem}
        ListEmptyComponent={renderListEmpty}
        ListFooterComponent={renderListFooter}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingBottom: 40,
  },
  emptyText: {
    textAlign: 'center',
    ...theme.fonts.normal,
    color: 'white',
  },
  paginationLoaderContainer: {
    marginTop: 20,
    minHeight: 40,
  },
  companyContainer: {
    flex: 1,
    backgroundColor: 'white',
    padding: 20,
    borderRadius: 20,
    margin: 5,
  },
  desktopCompanyContainer: {
    margin: 10,
  },
});
