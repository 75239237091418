import React from 'react';
import { StyleSheet, View, Text, Image } from 'react-native';
import { ParsedText } from '../../src/components';
import publicIconImage from '../../assets/icons/publicicon.png';
import privateIconImage from '../../assets/icons/privateicon.png';
import BaseOverlayDaphnipolitan from '../Daphnipolitan/BaseOverlayDaphnipolitan';

const ProjectComment = (props) => {
  const isPrivateIcon =
    props.private === 'yes' ? privateIconImage : publicIconImage;

  return (
    <View>
      <View style={styles.topContainer}>
        <BaseOverlayDaphnipolitan
          name={props.user.name}
          reference={props.user.reference}
          profileSrc={props.user.avatar}
          avatarSize={35}
          profileImageStyle={styles.profileImage}
          nameTextStyle={styles.nameText}
        />
        <View style={styles.iconView}>
          <Image style={styles.privateIcon} source={isPrivateIcon.source} />
        </View>
      </View>
      <View style={styles.whiteContainer}>
        <ParsedText style={styles.text}>{props.comment}</ParsedText>
        <View style={{ flexDirection: 'row', marginTop: 5 }}>
          <Text style={styles.text}>{props.date}</Text>
        </View>
      </View>
    </View>
  );
};

export default ProjectComment;

const styles = StyleSheet.create({
  topContainer: {
    flexDirection: 'row',
    position: 'absolute',
    zIndex: 2,
    top: 0,
    left: -5,
  },
  iconView: {
    justifyContent: 'center',
    alignItems: 'center',
    marginHorizontal: 5,
    marginTop: 8,
    width: 20,
    height: 15,
  },
  privateIcon: {
    resizeMode: 'contain',
    maxWidth: 20,
    maxHeight: 15,
  },
  whiteContainer: {
    marginTop: 26,
    marginLeft: 20,
    marginRight: 10,
    padding: 10,
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  profileImage: {
    borderWidth: 2,
    borderColor: '#ffffff',
    margin: 5,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    marginHorizontal: 10,
  },
  nameText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#ffffff',
    fontSize: 16,
    alignSelf: 'center',
    marginBottom: 5,
  },
});
