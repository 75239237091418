import React from 'react';
import {
  Dimensions,
  Platform,
  StyleSheet,
  Text,
  View,
  ScrollView,
  FlatList,
  ActivityIndicator,
  TouchableOpacity,
  RefreshControl,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../src/lib/StatusBarHeight';
import { isCloseToBottom } from '../src/lib/isCloseToBottom';
import { Overlay } from 'react-native-elements';

import DaphniHeader from '../components/DaphniHeader';
import DaphniFooter from '../components/DaphniFooter';
import DaphnipolitanGridItem from '../components/Daphnipolitan/DaphnipolitanGridItem';
import CategorySelectionScreen from '../overlays/CategorySelectionScreen';
import { localstore } from '../helpers/LocalStore';
import { Page } from '../src/components';
import { WeekDaphnipolitan } from '../src/pages/Home/components/WeekDaphnipolitan';
import { desktopOrLargerMinPixel } from '../src/lib/responsive';
import { DESKTOP_HEADER_OFFSET } from '../components/DaphniHeader/DaphniHeader.component';
import { withWindowDimensions } from '../src/hoc';
import { availableSectors } from '../src/pages/DaFlow/availableSectors';
import { environment } from '../src/environment';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);

const numColumns = 2;

const daphnipolitanStandard = [
  {
    id: '00',
    label: 'Select all',
    icon: require('../assets/icons/categories/category_01.png'),
    active: true,
  },
  ...availableSectors.map((sector) => ({ ...sector, active: true })),
];

class DaphnipolitansScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bearerToken: '',
      currentPage: 1,
      lastPage: -1,
      filterBtn: require('../assets/icons/onfilter.png'),
      username: '',
      loadingUsers: true,
      fetchingFromServer: false,
      users: [],
      activeCategoriesString: '',
      useCategories: true,
      daphnipolitansCategories: [],
      daphnipolitansActiveCategories: 0,
      showDaphnipolitansCategoriesOverlay: false,
    };

    this.updateDaphnipolitansCategories = this.updateDaphnipolitansCategories.bind(
      this
    );
    this.showDaphnipolitansCategoriesOverlay = this.showDaphnipolitansCategoriesOverlay.bind(
      this
    );
  }

  wait(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }

  async componentDidMount() {
    localstore.getString('username').then((result) => {
      if (result !== null) {
        this.setState({ username: result });
      } else {
        this.setState({ username: 'Username' });
      }
    });

    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result }, () => {
          const tagSector = this.props.route.params?.tag || '';
          if (tagSector != '') {
            let newCategories = Object.assign(daphnipolitanStandard);

            let category;
            for (let i = 0; i < newCategories.length; i++) {
              category = Object.assign(newCategories[i]);
              if (category.id == tagSector) {
                category.active = true;
              } else {
                category.active = false;
              }
              newCategories[i] = category;
            }

            const activeCategoriesString = '&sectors=' + tagSector;
            this.setState(
              {
                daphnipolitansCategories: newCategories,
                daphnipolitansActiveCategories: 1,
                activeCategoriesString,
              },
              () => {
                this.getUsers();
              }
            );
          } else {
            //localstore.deleteKey('daphnipolitansCategories');
            localstore.getItem('daphnipolitansCategories').then((result) => {
              let activeCategories = 0;
              let activeCategoriesString = '';
              if (result !== null) {
                for (let i = 0; i < result.length; i++) {
                  if (result[i].active) {
                    activeCategoriesString =
                      activeCategoriesString + '&sectors=' + result[i].id;
                    activeCategories++;
                  }
                }
                this.setState(
                  {
                    daphnipolitansCategories: result,
                    daphnipolitansActiveCategories: activeCategories,
                    activeCategoriesString,
                  },
                  () => {
                    this.getUsers();
                  }
                );
              } else {
                localstore.setItem(
                  'daphnipolitansCategories',
                  daphnipolitanStandard
                );
                let catNumber = daphnipolitanStandard.length - 1;
                this.setState(
                  {
                    daphnipolitansCategories: daphnipolitanStandard,
                    daphnipolitansActiveCategories: catNumber,
                  },
                  () => {
                    this.getUsers();
                  }
                );
              }
            });
          }
        });
      } else {
        console.log('Error retrieving token');
        this.setState({ loadingUsers: false });
      }
    });
  }

  showDaphnipolitansCategoriesOverlay(show) {
    this.setState({ showDaphnipolitansCategoriesOverlay: show });
  }

  updateDaphnipolitansCategories = (updatedCategories, activeCategories) => {
    let activeCategoriesString = '';

    for (let i = 0; i < updatedCategories.length - 1; i++) {
      if (updatedCategories[i].active) {
        activeCategoriesString =
          activeCategoriesString + '&sectors=' + updatedCategories[i].id;
      }
    }

    localstore.setItem('daphnipolitansCategories', updatedCategories);

    this.setState(
      {
        loadingUsers: true,
        daphnipolitansActiveCategories: activeCategories,
        activeCategoriesString,
        daphnipolitansCategories: updatedCategories,
        currentPage: 1,
        lastPage: -1,
        users: [],
      },
      () => {
        this.getUsers();
      }
    );
  };

  fetchMore = () => {
    if (!this.state.fetchingFromServer) {
      this.setState({ fetchingFromServer: true }, () => {
        this.getUsers();
      });
    }
  };

  reloadUsers = () => {
    if (!this.state.fetchingFromServer) {
      this.setState(
        {
          loadingUsers: true,
          fetchingFromServer: true,
          currentPage: 1,
          lastPage: -1,
          users: [],
        },
        () => {
          this.getUsers();
        }
      );
    }
  };

  getUsers = () => {
    const bearerToken = this.state.bearerToken;
    let catArray = this.state.activeCategoriesString;
    let currentPage = this.state.currentPage;
    let lastPage = this.state.lastPage;

    if (
      !this.state.useCategories ||
      this.state.daphnipolitansActiveCategories >=
        daphnipolitanStandard.length - 1
    ) {
      catArray = '';
    }
    console.log('Get users catArray ' + catArray);

    if (currentPage != lastPage) {
      fetch(
        'https://' +
          environment.API_HOST +
          '/v4/users?page=' +
          currentPage +
          '&' +
          catArray,
        {
          // mode: 'no-cors',
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + bearerToken,
          },
        }
      ).then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            let metaObject = json.meta;
            if (metaObject != null) {
              lastPage = metaObject.total_pages;
              if (metaObject.next_page != null) {
                currentPage++;
              }
            }

            let usersObject = json.data;
            var count = Object.keys(usersObject).length;
            let wUsers = [];

            for (let i = 0; i < count; i++) {
              let sectorsObj = usersObject[i].sectors;
              var sCount = Object.keys(sectorsObj).length;
              let sSectors = [];

              if (sCount > 3) {
                sCount = 3;
              }
              for (let j = 0; j < sCount; j++) {
                sSectors.push({
                  id: sectorsObj[j].id,
                  name: sectorsObj[j].name,
                });
                //console.log("Daphnipolitan sector " + sectorsObj[j]["name"]);
              }

              let profilePic = 'https:' + usersObject[i].avatar.url_xsmall;
              if (profilePic != null) {
                let url = profilePic.toString();
                url = url.replace('staging', 'prod');
                profilePic = url;
              } else {
                profilePic =
                  'https://' + environment.API_HOST + '/missing/avatar.png';
              }
              wUsers.push({
                reference: usersObject[i].ref,
                name:
                  usersObject[i].first_name + ' ' + usersObject[i].last_name,
                company: usersObject[i].company,
                profileSrc: profilePic,
                city: usersObject[i].city,
                country: usersObject[i].country,
                connected_to: usersObject[i].connected_to,
                countryCode: usersObject[i].country_code,
                job: usersObject[i].position,
                sectors: [...sSectors],
                socials: {
                  twitter: usersObject[i].twitter,
                  facebook: usersObject[i].facebook,
                  linkedin: usersObject[i].linkedin,
                },
              });
            }
            this.setState(
              {
                users: [...this.state.users, ...wUsers],
                lastPage,
                currentPage,
                fetchingFromServer: false,
              },
              () => {
                this.setState({ loadingUsers: false });
              }
            );
          });
        } else {
          console.log('Error retrieving users data');
          this.setState({ loadingUsers: false, fetchingFromServer: false });
        }
      });
    } else {
      console.log('no more pages to load');
      this.setState({ loadingUsers: false, fetchingFromServer: false });
    }
  };

  toggleFilters() {
    const newState = !this.state.useCategories;
    if (!newState) {
      console.log('Filters on ' + newState);
      this.setState(
        {
          filterBtn: require('../assets/icons/offfilter.png'),
          users: [],
          useCategories: false,
          loadingUsers: true,
          fetchingFromServer: true,
          currentPage: 1,
          lastPage: -1,
        },
        () => {
          this.getUsers();
        }
      );
    } else {
      console.log('Filters off ' + newState);
      this.setState(
        {
          filterBtn: require('../assets/icons/onfilter.png'),
          users: [],
          useCategories: true,
          loadingUsers: true,
          fetchingFromServer: true,
          currentPage: 1,
          lastPage: -1,
        },
        () => {
          this.getUsers();
        }
      );
    }
  }

  renderGridItem = ({ item, index }) => {
    if (item.empty === true) {
      return <View key={index} style={[styles.itemInvisible]} />;
    }

    return (
      <View key={item.ref} style={styles.daphnipolitanContainer}>
        <DaphnipolitanGridItem
          bearerToken={this.state.bearerToken}
          reference={item.reference}
          profileSrc={item.profileSrc}
          name={item.name}
          company={item.company}
          job={item.job}
          sectors={item.sectors}
          city={item.city}
          connected_to={item.connected_to}
          country={item.country}
          countryCode={item.countryCode}
          numColumns={numColumns}
          navigation={this.props.navigation}
          socials={item.socials}
        />
        {index % 2 === 0 && <View style={styles.verticalSpacer} />}
      </View>
    );
  };

  _onRefresh() {
    this.setState({ refreshing: true });
    const renderval = Math.floor(Math.random() * 100 + 1);
    this.setState({ rerender: renderval });
    this.reloadUsers();

    console.log('me refresco daflownew');
    this.wait(1000).then(() => {
      this.setState({ refreshing: false });
    });
  }

  renderHeaderContent = () => (
    <DaphniHeader navigation={this.props.navigation} index={3} />
  );

  render() {
    let loadingUsers = this.state.loadingUsers;
    let fetching = this.state.fetchingFromServer;
    let filterBtn = this.state.filterBtn;
    let users;

    let daphnipolitansCatOverlay = (
      <Overlay
        isVisible={this.state.showDaphnipolitansCategoriesOverlay}
        overlayStyle={[
          styles.fullscreenCategoriesOverlay,
          { width: WIDTH, height: HEIGHT },
        ]}
        onBackdropPress={() =>
          this.setState({ showDaphnipolitansCategoriesOverlay: false })
        }
      >
        <CategorySelectionScreen
          categories={this.state.daphnipolitansCategories}
          numColumns={3}
          showOverlay={this.showDaphnipolitansCategoriesOverlay}
          updateCategories={this.updateDaphnipolitansCategories}
        />
      </Overlay>
    );

    if (!loadingUsers) {
      let usersData = this.state.users;
      if (usersData.length > 0) {
        users = (
          <FlatList
            numColumns={numColumns}
            data={usersData}
            renderItem={this.renderGridItem}
            keyExtractor={(item) => item.reference}
          />
        );
      } else {
        users = (
          <View style={[styles.loadingContainer, { marginBottom: 50 }]}>
            <Text style={styles.text}>No users meet this criteria</Text>
          </View>
        );
      }
    } else {
      users = (
        <View style={[styles.loadingContainer]}>
          <ActivityIndicator size="large" color="grey" />
        </View>
      );
    }

    if (this.state.fetchingFromServer) {
      fetching = (
        <ActivityIndicator
          size="large"
          color="grey"
          style={{ paddingBottom: 40 }}
        />
      );
    }

    return (
      <Page
        headerColor="#45a9ed"
        renderHeaderContent={this.renderHeaderContent}
        withoutAutoOffsetOnDesktop
      >
        {daphnipolitansCatOverlay}
        <ScrollView
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContentContainer}
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={this._onRefresh.bind(this)}
            />
          }
          onScroll={({ nativeEvent }) => {
            if (isCloseToBottom(nativeEvent)) {
              this.fetchMore();
            }
          }}
        >
          <View
            style={[
              styles.titleSection,
              {
                paddingTop:
                  this.props.dimensions.width >= desktopOrLargerMinPixel
                    ? DESKTOP_HEADER_OFFSET
                    : undefined,
              },
            ]}
          >
            <Text style={styles.daphnipolisText}>daphnipolis</Text>
          </View>
          <View style={styles.tabContainer}>
            <TouchableOpacity
              style={{ width: '50%' }}
              onPress={() => this.props.navigation.navigate('Timeline')}
            >
              <View>
                <Text style={styles.text}>Da Timeline</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ width: '50%' }}
              onPress={() => this.props.navigation.navigate('Daphnipolitans')}
            >
              <View>
                <Text style={[styles.text, { color: '#45a9ed' }]}>
                  Daphnipolitans
                </Text>
                <View style={styles.selectedTab} />
              </View>
            </TouchableOpacity>
          </View>
          <View>
            <WeekDaphnipolitan />
            <View style={[styles.whiteContainer, { marginTop: 20 }]}>
              {users}
              {fetching}
            </View>
          </View>
        </ScrollView>
        <View style={[styles.buttonContainer, { width: '60%' }]}>
          <TouchableOpacity
            style={[styles.bCtouch, { flexDirection: 'row' }]}
            onPress={() => this.showDaphnipolitansCategoriesOverlay(true)}
          >
            <Text style={styles.wText}>{this.state.username}'s filter</Text>
            <Image
              source={require('../assets/icons/filters.png')}
              resizeMode="contain"
              style={{ width: 15, height: 15 }}
            />
            <View style={styles.categoryNumContainer}>
              <Text style={styles.categoryText}>
                {this.state.daphnipolitansActiveCategories}
              </Text>
            </View>
          </TouchableOpacity>
          <TouchableOpacity
            style={[styles.bCtouch, { flexDirection: 'row' }]}
            onPress={() => this.toggleFilters()}
          >
            <Image
              source={filterBtn}
              resizeMode="contain"
              style={{ width: 31, height: 15 }}
            />
          </TouchableOpacity>
        </View>
        <DaphniFooter />
      </Page>
    );
  }
}

export default withWindowDimensions(DaphnipolitansScreen);

const styles = StyleSheet.create({
  scrollView: {
    marginTop: -10,
    backgroundColor: '#45a9ed',
  },
  scrollViewContentContainer: {
    paddingBottom: 60,
  },
  daphnipolitanContainer: {
    flex: 1 / numColumns,
    aspectRatio: 1,
    flexDirection: 'row',
  },
  loadingContainer: {
    backgroundColor: '#ffffff',
    alignContent: 'center',
    justifyContent: 'center',
    borderRadius: 20,
    padding: 20,
  },
  titleSection: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffcf56',
  },
  tabContainer: {
    backgroundColor: '#ffffff',
    width: '100%',
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  whiteContainer: {
    paddingBottom: 10,
    marginBottom: 20,
    borderRadius: 20,
    backgroundColor: '#ffffff',
  },
  fullscreenCategoriesOverlay: {
    backgroundColor: '#ffffff',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: WIDTH,
    height: HEIGHT,
  },
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#45a9ed',
    zIndex: 2,
    bottom: 65,
    borderRadius: 30,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 40,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  bCtouch: {
    height: 40,
    paddingHorizontal: 10,
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  selectedTab: {
    width: '100%',
    height: 6,
    marginTop: 4.5,
    backgroundColor: '#45a9ed',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
    marginTop: 12,
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#ffffff',
    marginLeft: 10,
    marginRight: 5,
  },
  daphnipolisText: {
    fontFamily: 'DaphniFont',
    fontSize: 72,
    marginTop: Platform.OS === 'ios' ? 10 : StatusBarHeight.height,
    color: '#ffffff',
    textAlign: 'center',
  },
  categoryNumContainer: {
    width: 20,
    height: 20,
    backgroundColor: '#ffffff',
    borderRadius: 10,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
  },
  categoryText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#7479ae',
  },
  verticalSpacer: {
    backgroundColor: '#666666',
    width: 1,
    height: '90%',
    alignSelf: 'center',
  },
});
