import React, { useCallback } from 'react';
import {
  StyleSheet,
  TouchableOpacity,
  Image,
  useWindowDimensions,
} from 'react-native';
import { openUrl } from '../../../lib';
import { getBannerSize } from './BannerSize';

const Banner = (props) => {
  const dimensions = useWindowDimensions();
  const onPress = useCallback(async () => {
    if (props.url) {
      await openUrl(props.url);
    }
  }, [props.url]);

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={onPress}
      activeOpacity={0.9}
    >
      <Image
        source={{ uri: props.imgSrc }}
        style={[styles.image, getBannerSize(dimensions)]}
        resizeMode="cover"
      />
    </TouchableOpacity>
  );
};

export default Banner;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    borderRadius: 20,
  },
});
