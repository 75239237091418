/* eslint-disable import/no-unused-modules */

export class Alert {
  static alert = (title, message = '') =>
    new Promise((resolve) => {
      window.alert([title, message].filter(Boolean).join('\n'));
      resolve({ response: 'ok' });
    });

  static confirm = (question, description) =>
    new Promise((resolve) => {
      const hasAccepted = window.confirm(
        [question, description].filter(Boolean).join('\n')
      );

      if (hasAccepted) {
        resolve({ response: 'yes' });
      } else {
        resolve({ response: 'cancel' });
      }
    });
}
