import React from 'react';
import MyInvestments from '../components/MyInvestments/MyInvestments';
import { localstore } from '../helpers/LocalStore';
import { Page } from '../src/components';
import { formatMoney } from '../src/lib';
import { environment } from '../src/environment';

class InvestmentsScreen extends React.Component {
  state = {
    bearerToken: '',
    projects: [],
    isLoading: true,
  };

  componentDidMount() {
    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result }, () => {
          this.getData();
        });
      }
    });
  }

  getData = () => {
    this.setState({ isLoading: true });
    const bearerToken = this.state.bearerToken;

    fetch('https://' + environment.API_HOST + '/v4/transactions', {
      // mode: 'no-cors',
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((json) => {
            let fundsObject = json.data;
            var count = Object.keys(fundsObject).length;
            let funds = [];
            for (let i = 0; i < count; i++) {
              const date = fundsObject[i].created_at.substring(0, 10);

              if (fundsObject[i].debit == null) {
                funds.push({
                  id: i + 1,
                  money: formatMoney(fundsObject[i].credit),
                  investDate: date,
                  funds: fundsObject[i].fund.label,
                  state: fundsObject[i].state,
                });
              }
            }

            let projectObject = json.data;
            var countp = Object.keys(projectObject).length;
            let projects = [];

            for (let i = 0; i < countp; i++) {
              if (projectObject[i].project != null) {
                let logoPic =
                  'https:' + projectObject[i].project.logo.url_medium;
                if (logoPic != null) {
                  if (logoPic == 'https:/missing/project.png') {
                    logoPic =
                      'https://' +
                      environment.API_HOST +
                      '/missing/project.png';
                  }
                } else {
                  logoPic =
                    'https://' + environment.API_HOST + '/missing/project.png';
                }
                const date = projectObject[i].created_at.substring(0, 10);

                projects.push({
                  id: projectObject[i].project.ref,
                  companyName: projectObject[i].project.label,
                  description: projectObject[i].project.description,
                  logoSrc: logoPic,
                  country: projectObject[i].project.country,
                  countryCode: projectObject[i].project.country_code,
                  money: formatMoney(projectObject[i].debit),
                  investDate: date,
                  funds: projectObject[i].project.funds,
                  state: projectObject[i].state,
                });
              }
            }
            this.setState({ projects, funds });
          });
        } else {
          console.log('Error retrieving Investment Transactions Funds');
        }
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    return (
      <Page headerColor="#ff9233" title="My Investments">
        <MyInvestments
          funds={this.state.funds}
          investments={this.state.projects}
          navigation={this.props.navigation}
          isLoading={this.state.isLoading}
        />
      </Page>
    );
  }
}
export default InvestmentsScreen;
