import { combineReducers } from 'redux';
import { loadingStatusReducer } from './LoadingStatus';
import { discussionReducer } from './Discussion';
import { notificationReducer } from './Notification';
import { userReducer } from './User';

const appReducer = combineReducers({
  loadingStatus: loadingStatusReducer,
  discussion: discussionReducer,
  notification: notificationReducer,
  user: userReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === 'LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
