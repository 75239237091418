import React from 'react';
import { View, StyleSheet, useWindowDimensions } from 'react-native';

export const Card = ({ style, children }) => {
  const dimensions = useWindowDimensions();
  const marginTop = Math.max((dimensions.width * 3) / 100, 30);
  return (
    <View style={[styles.container, { marginTop }, style]}>{children}</View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 32,
    borderRadius: 20,
    backgroundColor: '#ffffff',
  },
});
