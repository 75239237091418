import React from 'react';
import {
  Platform,
  StyleSheet,
  View,
  Text,
  ActivityIndicator,
} from 'react-native';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { allowLandscape } from '../../../components/allowLandscape';
import { StatusBarHeight } from '../../lib/StatusBarHeight';
import { PDFView } from './components/PDFView';

class PDFDeckScreen extends React.Component {
  isDeckMissing = () =>
    (this.props.route.params?.deckUrl || 'missing') === 'missing';

  state = {
    isLoading: true,
    error: this.isDeckMissing()
      ? 'This project does not have their deck uploaded yet. Try again later.'
      : null,
  };

  onLoad = () => this.setState({ error: null, isLoading: false });
  onError = (error) => {
    console.log('Error loading PDF', {
      error,
      url: this.props.route.params?.deckUrl,
    });

    this.setState({
      error: error?.message || 'Unknown error',
      isLoading: false,
    });
  };

  renderLoadStatus = () => {
    if (this.state.error) {
      return <Text>{this.state.error}</Text>;
    }

    if (this.state.isLoading) {
      return <ActivityIndicator size="large" color="grey" />;
    }

    return null;
  };

  renderContent = () => {
    if (this.isDeckMissing()) {
      return null;
    }

    return (
      <PDFView
        url={this.props.route.params?.deckUrl}
        onLoad={this.onLoad}
        onError={this.onError}
      />
    );
  };

  render() {
    return (
      <View style={styles.container}>
        <Ionicons
          name="ios-close-circle"
          size={32}
          style={styles.backButton}
          color="#ffcf56"
          onPress={() => this.props.navigation.goBack()}
        />
        {this.renderContent()}
        {(this.state.isLoading || !!this.state.error) && (
          <View style={styles.loadStatusContainer}>
            {this.renderLoadStatus()}
          </View>
        )}
      </View>
    );
  }
}

export const PDFDeck = allowLandscape()(PDFDeckScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ffffff',
  },
  loadStatusContainer: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  backButton: {
    position: 'absolute',
    top: 10 + (Platform.OS === 'ios' ? 20 : StatusBarHeight.height),
    right: 10,
    zIndex: 2,
  },
});
