import React from 'react';
import {
  RefreshControl,
  ScrollView,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { innerViewBaseStyles, Page } from '../../components';
import { DESKTOP_HEADER_OFFSET } from '../../../components/DaphniHeader/DaphniHeader.component';
import DaphniHeader from '../../../components/DaphniHeader';
import { isCloseToBottom } from '../../lib/isCloseToBottom';
import { SubMenu, Settings, TopPanel, BottomPanel } from './components';
import { theme } from '../../lib';
import DaphniFooter from '../../../components/DaphniFooter';
import { useDaflowGenericPage } from './hooks/useDaflowGenericPage';

export const DaFlowGenericPage = ({
  topPanel,
  showGoodProjectIndicator,
  bottomPanel,
  daFlowType,
  SettingsAdditionalComponents,
}) => {
  const {
    navigation,
    isDesktopOrLarger,
    isRefreshing,
    onRefresh,
    isLoading,
    canFetchMore,
    allProjects,
    fetchMore,
    topPanelProjects,
    activeSectorsIds,
    setActiveSectorsIds,
    isFilteringEnabled,
    setIsFilteringEnabled,
    projectCountPerColumns,
    setProjectCountPerColumns,
  } = useDaflowGenericPage({ topPanel, bottomPanel, daFlowType });

  const renderHeaderContent = () => (
    <DaphniHeader navigation={navigation} index={1} />
  );

  return (
    <Page
      headerColor="#7479ae"
      backgroundColor="#2ba596"
      renderHeaderContent={renderHeaderContent}
      withoutAutoOffsetOnDesktop
    >
      <ScrollView
        style={innerViewBaseStyles.contentTopPanelDefaultFix}
        contentContainerStyle={innerViewBaseStyles.scrollViewContentContainer}
        refreshControl={
          <RefreshControl
            refreshing={isRefreshing}
            onRefresh={onRefresh}
            tintColor="white"
          />
        }
        scrollEventThrottle={2}
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent) && canFetchMore) {
            fetchMore();
          }
        }}
      >
        <View
          style={[
            styles.titleSection,
            {
              paddingTop: isDesktopOrLarger ? DESKTOP_HEADER_OFFSET : undefined,
            },
          ]}
        >
          <Text style={styles.flowText}>da flow</Text>
        </View>
        <View>
          <SubMenu
            currentDaFlowType={daFlowType}
            activeSectorsIds={activeSectorsIds}
            setActiveSectorsIds={setActiveSectorsIds}
            projectCountPerColumns={projectCountPerColumns}
            setProjectCountPerColumns={setProjectCountPerColumns}
            isFilteringEnabled={isFilteringEnabled}
            setIsFilteringEnabled={setIsFilteringEnabled}
            SettingsAdditionalComponents={SettingsAdditionalComponents}
          />
          {!!topPanel && (
            <TopPanel title={topPanel.title} projects={topPanelProjects} />
          )}
          <BottomPanel
            isLoading={isLoading}
            isRefreshing={isRefreshing}
            projects={allProjects}
            projectCountPerColumns={projectCountPerColumns}
            daFlowType={daFlowType}
            showGoodProjectIndicator={showGoodProjectIndicator}
          />
        </View>
      </ScrollView>
      {!isDesktopOrLarger && (
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          style={styles.mobileSettingsContainer}
          contentContainerStyle={styles.mobileSettingsContentContainer}
        >
          <Settings
            style={styles.mobileSettings}
            activeSectorsIds={activeSectorsIds}
            setActiveSectorsIds={setActiveSectorsIds}
            projectCountPerColumns={projectCountPerColumns}
            setProjectCountPerColumns={setProjectCountPerColumns}
            isFilteringEnabled={isFilteringEnabled}
            setIsFilteringEnabled={setIsFilteringEnabled}
            AdditionalComponents={SettingsAdditionalComponents}
          />
        </ScrollView>
      )}
      <DaphniFooter />
    </Page>
  );
};

const styles = StyleSheet.create({
  titleSection: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  flowText: {
    fontFamily: 'DaphniFont',
    fontSize: 72,
    marginTop: 10,
    color: '#ffffff',
    textAlign: 'center',
  },
  mobileSettingsContainer: {
    position: 'absolute',
    left: 20,
    right: 20,
    bottom: 65,
    zIndex: 2,
    borderRadius: 30,
    backgroundColor: '#7479ae',
    ...theme.shadow,
  },
  mobileSettingsContentContainer: {
    flexGrow: 1,
    justifyContent: 'center',
  },
  mobileSettings: {
    alignSelf: 'center',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
});
