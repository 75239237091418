import React from 'react';
import { useWindowDimensions } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { createDrawerNavigator } from '@react-navigation/drawer';
import HomeScreen from '../pages/Home';
import AllEventsScreen from '../../screens/AllEventsScreen';
import NewsScreen from '../../screens/NewsScreen';
import HouseCompanyScreen01 from '../../screens/HouseCompanyScreen01';
import HouseCompanyScreen03 from '../../screens/HouseCompanyScreen03';
import DaphnipolitansScreen from '../../screens/DaphnipolitansScreen';
import TimelineScreen from '../../screens/TimelineScreen';
import BookmarksScreen from '../../screens/BookmarksScreen';
import InvestmentsScreen from '../../screens/InvestmentsScreen';
import PingsScreen from '../../screens/PingsScreen';
import LikesScreen from '../../screens/LikesScreen';
import NotificationsScreen from '../../screens/NotificationsScreen';
import CompleteCompanyScreen from '../../screens/CompleteCompanyScreen';
import CoInvest01Screen from '../../screens/CoInvest01Screen';
import CoInvest02Screen from '../../screens/CoInvest02Screen';
import CommentsScreen from '../../screens/CommentsScreen';
import EditProfileScreen from '../../screens/EditProfileScreen';
import ChatListScreen from '../../screens/ChatListScreen';
import ChatScreen from '../../screens/ChatScreen';
import GroupSettingsScreen from '../../screens/GroupSettingsScreen';
import SearchOverlay from '../../overlays/SearchOverlay';
import CategorySelectionScreen from '../../overlays/CategorySelectionScreen';
import TopicSelectionScreen from '../../screens/TopicSelectionScreen';
import NewChatScreen from '../../screens/NewChatScreen';
import ChatFindUsersOverlay from '../../overlays/ChatFindUsersOverlay';
import ChatAddUsersOverlay from '../../overlays/ChatAddUsersOverlay';
import { DaphnipolitanScreen } from '../pages/Daphnipolitan';
import { PDFDeck } from '../pages/PDFDeck';
import MenuContent from '../../components/MenuContent';
import { tabletOrLargerMinPixel } from '../lib/responsive';
import {
  DaFlowPending,
  DaFlowNew,
  DaFlowExploration,
  DaFlowTermsheet,
  DaFlowDropped,
} from '../pages/DaFlow';
import { DaHouse } from '../pages/DaHouse';

const RootStack = createStackNavigator();
const MainStack = createStackNavigator();
const Drawer = createDrawerNavigator();

const MainStackNavigation = () => {
  return (
    <MainStack.Navigator
      initialRouteName="Home"
      headerMode="none"
      screenOptions={{ gestureEnabled: false }}
    >
      <MainStack.Screen name="Home" component={HomeScreen} />
      <MainStack.Screen name="AllEvents" component={AllEventsScreen} />
      <MainStack.Screen name="AllNews" component={NewsScreen} />
      <MainStack.Screen name="DaFlowNew" component={DaFlowNew} />
      <MainStack.Screen
        name="DaFlowExploration"
        component={DaFlowExploration}
      />
      <MainStack.Screen name="DaFlowPending" component={DaFlowPending} />
      <MainStack.Screen name="DaFlowTermsheet" component={DaFlowTermsheet} />
      <MainStack.Screen name="DaFlowDropped" component={DaFlowDropped} />
      <MainStack.Screen name="DaHouse" component={DaHouse} />
      <MainStack.Screen
        name="DaHouseCompany01"
        component={HouseCompanyScreen01}
      />
      <MainStack.Screen
        name="DaHouseCompany03"
        component={HouseCompanyScreen03}
      />
      <MainStack.Screen
        name="Daphnipolitans"
        component={DaphnipolitansScreen}
      />
      <MainStack.Screen name="Timeline" component={TimelineScreen} />
      <MainStack.Screen name="Bookmarks" component={BookmarksScreen} />
      <MainStack.Screen name="Investments" component={InvestmentsScreen} />
      <MainStack.Screen name="Pings" component={PingsScreen} />
      <MainStack.Screen name="MyLikes" component={LikesScreen} />
      <MainStack.Screen
        name="AllNotifications"
        component={NotificationsScreen}
      />
      <MainStack.Screen
        name="CompleteCompany"
        component={CompleteCompanyScreen}
      />
      <MainStack.Screen name="PDFDeck" component={PDFDeck} />
      <MainStack.Screen name="CoInvest01" component={CoInvest01Screen} />
      <MainStack.Screen name="CoInvest02" component={CoInvest02Screen} />
      <MainStack.Screen name="Comments" component={CommentsScreen} />
      <MainStack.Screen name="EditProfile" component={EditProfileScreen} />
      <MainStack.Screen name="ChatList" component={ChatListScreen} />
      <MainStack.Screen name="ChatWindow" component={ChatScreen} />
      <MainStack.Screen name="GroupSettings" component={GroupSettingsScreen} />
    </MainStack.Navigator>
  );
};

const DrawerNavigation = () => {
  const dimensions = useWindowDimensions();

  return (
    <Drawer.Navigator
      drawerStyle={{
        width: dimensions.width >= tabletOrLargerMinPixel ? 350 : '83%',
      }}
      drawerContent={(props) => <MenuContent {...props} />}
    >
      <Drawer.Screen name="Main" component={MainStackNavigation} />
    </Drawer.Navigator>
  );
};
export const AuthenticatedNavigation = () => {
  return (
    <RootStack.Navigator mode="modal" headerMode="none">
      <RootStack.Screen name="Drawer" component={DrawerNavigation} />
      <RootStack.Screen name="SearchModal" component={SearchOverlay} />
      <RootStack.Screen
        name="CategorySelection"
        component={CategorySelectionScreen}
      />
      <RootStack.Screen
        name="TopicSelection"
        component={TopicSelectionScreen}
      />
      <RootStack.Screen name="NewChat" component={NewChatScreen} />
      <RootStack.Screen name="ChatFindUsers" component={ChatFindUsersOverlay} />
      <RootStack.Screen name="ChatAddUser" component={ChatAddUsersOverlay} />
      <RootStack.Screen
        name="Daphnipolitan"
        component={DaphnipolitanScreen}
        options={{
          cardStyle: { backgroundColor: 'transparent' },
          cardOverlayEnabled: true,
          cardStyleInterpolator: ({ current: { progress } }) => ({
            cardStyle: {
              opacity: progress.interpolate({
                inputRange: [0, 0.5, 0.9, 1],
                outputRange: [0, 0.25, 0.7, 1],
              }),
            },
            overlayStyle: {
              opacity: progress.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0.5],
                extrapolate: 'clamp',
              }),
            },
          }),
        }}
      />
    </RootStack.Navigator>
  );
};
