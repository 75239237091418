import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { useResponsive } from '../lib/responsive';
import GalleryIcon from '../../assets/icons/gallery.svg';
import ListIcon from '../../assets/icons/list.svg';
import { theme } from '../lib';

export const RenderTypeButton = ({
  projectCountPerColumns,
  setProjectCountPerColumns,
}) => {
  const { isDesktopOrLarger } = useResponsive();
  const minProjectCountPerColumns = isDesktopOrLarger ? 2 : 1;

  return (
    <TouchableOpacity
      hitSlop={{ top: 20, right: 20, left: 20, bottom: 20 }}
      style={styles.buttonContainer}
      onPress={() =>
        setProjectCountPerColumns((current) => {
          if (current === minProjectCountPerColumns) {
            return current + 1;
          }
          return current - 1;
        })
      }
    >
      <Text style={[styles.text, !isDesktopOrLarger && styles.mobileText]}>
        View
      </Text>
      {isDesktopOrLarger ? (
        projectCountPerColumns === 2 ? (
          <GalleryIcon />
        ) : (
          <ListIcon />
        )
      ) : (
        <Image
          source={
            projectCountPerColumns === 1
              ? require('../../assets/icons/gallery.png')
              : require('../../assets/icons/list.png')
          }
          resizeMode="contain"
          style={styles.mobileButtonIcon}
        />
      )}
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mobileButtonIcon: {
    width: 15,
    height: 15,
  },
  text: {
    marginRight: 5,
    ...theme.fonts.normal,
  },
  mobileText: {
    color: 'white',
  },
});
