import React from 'react';
import { Text, View, StyleSheet, TouchableOpacity, Image } from 'react-native';

import { ParsedText } from '../src/components';
import BaseDaphnipolitan from './Daphnipolitan/BaseDaphnipolitan';

class TimelineArticle extends React.Component {
  articleClicked() {
    this.props.navigation.navigate('Comments', {
      id: this.props.id,
    });
  }

  render() {
    let comments = this.props.comments;
    if (comments == null) {
      comments = 0;
    }

    let category = 'Da Flow';
    let categoryColor = '#53095b';
    switch (this.props.typeOf) {
      case 'da_house':
        category = 'Da House';
        categoryColor = '#00b6ba';
        break;

      case 'wiki':
        category = 'Wiki';
        categoryColor = '#53095b';
        break;

      case 'question':
        category = 'General';
        categoryColor = '#53095b';
        break;

      case 'events':
        category = 'Events';
        categoryColor = '#f55074';
        break;

      case 'legal':
        category = 'Legal';
        categoryColor = '#ff9233';
        break;

      default:
        category = 'General';
        categoryColor = '#53095b';
        break;
    }

    return (
      <TouchableOpacity onPress={() => this.articleClicked()}>
        <View style={styles.pairedData}>
          <BaseDaphnipolitan
            name={this.props.user.name}
            reference={this.props.user.reference}
            profileSrc={this.props.user.avatar}
            avatarSize={30}
            profileImageStyle={styles.profileImage}
            nameTextStyle={styles.text}
          />
          <View style={styles.typeNameContainer}>
            <View
              style={[styles.typeContainer, { backgroundColor: categoryColor }]}
            >
              <Text style={[styles.wText]}>{category}</Text>
            </View>
          </View>
        </View>
        {!!this.props.content && (
          <ParsedText
            numberOfLines={3}
            style={[styles.text, { marginHorizontal: 20 }]}
          >
            {this.props.content}
          </ParsedText>
        )}
        <View style={[styles.pairedData, { marginTop: 5 }]}>
          <Image
            style={{
              width: 16,
              height: 16,
              tintColor: '#666666',
              marginHorizontal: 10,
            }}
            source={require('../assets/icons/messagesIcon.png')}
            resizeMode="contain"
          />
          <Text style={styles.dateText}>{comments}</Text>
          <Text style={[styles.dateText, { marginHorizontal: 20 }]}>
            {this.props.date}
          </Text>
        </View>

        {!!this.props.latestComment && (
          <View
            style={{
              marginLeft: 40,
              marginTop: 10,
            }}
          >
            <View style={styles.pairedData}>
              <BaseDaphnipolitan
                name={this.props.latestComment.user.name}
                reference={this.props.latestComment.user.reference}
                profileSrc={this.props.latestComment.user.avatar}
                avatarSize={20}
                profileImageStyle={styles.profileImage}
                nameTextStyle={styles.text}
              />
              <View style={styles.typeNameContainer} />
            </View>
            {!!this.props.latestComment.content && (
              <ParsedText
                numberOfLines={3}
                style={[styles.text, { marginHorizontal: 20 }]}
              >
                {this.props.latestComment.content}
              </ParsedText>
            )}
            <View style={[styles.pairedData, { marginTop: 5 }]}>
              <Text style={[styles.dateText, { marginHorizontal: 12 }]}>
                {this.props.latestComment.date}
              </Text>
            </View>
          </View>
        )}
      </TouchableOpacity>
    );
  }
}

export default TimelineArticle;

const styles = StyleSheet.create({
  typeNameContainer: {
    position: 'absolute',
    right: 3,
  },
  typeContainer: {
    alignSelf: 'flex-end',
    alignContent: 'center',
    justifyContent: 'center',
    width: 70,
    height: 20,
    borderRadius: 10,
    marginLeft: 10,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'left',
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 13,
    color: '#ffffff',
    textAlign: 'center',
    marginHorizontal: 5,
  },
  dateText: {
    fontSize: 13,
    marginTop: 5,
    flexWrap: 'wrap',
    //color: '#b3b3b3'
    color: '#666666',
  },
  mention: {
    fontSize: 16,
    fontFamily: 'Freight-Sans-Medium',
    backgroundColor: 'rgba(36, 77, 201, 0.05)',
    color: '#244dc9',
  },
  pairedData: {
    flexDirection: 'row',
    marginHorizontal: 10,
    alignItems: 'center',
  },
  profileImage: {
    borderRadius: 15,
    margin: 10,
    marginRight: 5,
    overflow: 'hidden',
  },
});
