import React from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Keyboard,
} from 'react-native';
import { Avatar } from 'react-native-elements';
import { PaginatedFlatList } from '../../src/components/PaginatedFlatList';
import { adaptUsers } from '../../src/api/User/adapter';
import { searchUsers } from '../../src/api/User';
import { SearchBar } from '../../src/components/SearchBar';
import { Page } from '../../src/components';

class ChatFindUsersOverlayComponent extends React.Component {
  state = {
    search: '',
  };

  list = null;
  setListRef = (ref) => (this.list = ref);

  updateSearch = (text) => this.setState({ search: text });

  searchButton = (event) => {
    const keyword = event.nativeEvent.text;
    Keyboard.dismiss();
    this.setState({ search: keyword }, () => {
      this.list.resetData();
    });
  };

  createNewChat = (otherUser) => {
    this.props.navigation.navigate('ChatWindow', {
      otherUserReference: otherUser.reference,
      title: `${otherUser.firstName || ''} ${otherUser.lastName || ''}`,
      avatar: otherUser.avatar,
    });
  };

  renderUserItem = ({ item: user }) => {
    return (
      <View>
        <TouchableOpacity
          style={{
            flexDirection: 'row',
            alignItems: 'center',
            marginHorizontal: 10,
          }}
          onPress={() => this.createNewChat(user)}
        >
          <Avatar rounded size={45} source={{ uri: user.avatar }} />
          <Text style={[styles.text, { marginLeft: 5 }]}>
            {user.firstName || ''} {user.lastName || ''}
          </Text>
        </TouchableOpacity>
        <View style={[styles.line, styles.separator]} />
      </View>
    );
  };

  renderEmpty = () => (
    <Text style={[styles.text, { margin: 20 }]}>
      {!this.state.search
        ? 'Find users in the Daphni universe!'
        : 'Nothing found\nTry another word'}
    </Text>
  );

  fetchUsers = (page) => searchUsers(this.state.search, page);

  renderList = () => {
    return (
      <PaginatedFlatList
        ref={this.setListRef}
        renderItem={this.renderUserItem}
        renderEmpty={this.renderEmpty}
        fetchData={this.fetchUsers}
        adaptData={adaptUsers}
      />
    );
  };

  render() {
    return (
      <Page withoutTopPanel>
        <SearchBar
          containerStyle={styles.searchBar}
          onChangeText={this.updateSearch}
          onSubmitEditing={this.searchButton}
        />
        <View style={styles.line} />
        {this.renderList()}
      </Page>
    );
  }
}
export default ChatFindUsersOverlayComponent;

const styles = StyleSheet.create({
  searchBar: {
    marginTop: 10,
    marginHorizontal: 20,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
  line: {
    borderBottomColor: '#666666',
    borderBottomWidth: 1,
    marginTop: 20,
    marginHorizontal: 20,
  },
  separator: {
    marginBottom: 20,
  },
});
