import { useResponsive } from '../../../../../lib/responsive';
import { StyleSheet, View } from 'react-native';
import Company from '../../../../../../components/Company';
import React from 'react';

export const WIDTH_PROJECT_MOBILE = 320;
export const WIDTH_PROJECT_DESKTOP = 400;

export const Project = ({ project }) => {
  const { isDesktopOrLarger } = useResponsive();
  return (
    <View
      style={[
        styles.projectContainer,
        isDesktopOrLarger && styles.desktopProjectContainer,
      ]}
    >
      <Company
        reference={project.reference}
        logoSrc={project.logo.url_medium}
        name={project.name}
        country={project.country}
        countryCode={project.countryCode}
        sectors={project.sectors}
        score={project.rating.average}
        money={project.fundraisingAmount}
        comments={project.comments}
        description={project.description}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  projectContainer: {
    borderRadius: 20,
    backgroundColor: '#ffffff',
    marginRight: 10,
    padding: 10,
    width: WIDTH_PROJECT_MOBILE,
  },
  desktopProjectContainer: {
    padding: 25,
    width: WIDTH_PROJECT_DESKTOP,
  },
});
