export const availableSectors = [
  {
    id: '1',
    label: 'Agriculture Food',
    icon: require('../../../assets/icons/categories/category_03.png'),
  },
  {
    id: '2',
    label: 'Human resources Workforce',
    icon: require('../../../assets/icons/categories/category_14.png'),
  },
  {
    id: '3',
    label: 'Culture / Art Entertainment',
    icon: require('../../../assets/icons/categories/category_06.png'),
  },
  {
    id: '4',
    label: 'Social / Impact Green',
    icon: require('../../../assets/icons/categories/category_22.png'),
  },
  {
    id: '5',
    label: 'Security Blockchain',
    icon: require('../../../assets/icons/categories/category_20.png'),
  },
  {
    id: '6',
    label: 'Gaming',
    icon: require('../../../assets/icons/categories/category_11.png'),
  },
  {
    id: '7',
    label: 'Infrastructure',
    icon: require('../../../assets/icons/categories/category_15.png'),
  },
  {
    id: '8',
    label: 'Advertising Marketing',
    icon: require('../../../assets/icons/categories/category_02.png'),
  },
  {
    id: '9',
    label: 'Fintech Insurtech',
    icon: require('../../../assets/icons/categories/category_10.png'),
  },
  {
    id: '10',
    label: 'Hardware Physical Products',
    icon: require('../../../assets/icons/categories/category_12.png'),
  },
  {
    id: '11',
    label: 'Education',
    icon: require('../../../assets/icons/categories/category_09.png'),
  },
  {
    id: '12',
    label: 'Logistics Mobility',
    icon: require('../../../assets/icons/categories/category_17.png'),
  },
  {
    id: '14',
    label: 'Health Medcare',
    icon: require('../../../assets/icons/categories/category_13.png'),
  },
  {
    id: '15',
    label: 'Deep tech',
    icon: require('../../../assets/icons/categories/category_08.png'),
  },
  {
    id: '16',
    label: 'Retail',
    icon: require('../../../assets/icons/categories/category_19.png'),
  },
  {
    id: '17',
    label: 'Real estate',
    icon: require('../../../assets/icons/categories/category_18.png'),
  },
  {
    id: '18',
    label: 'Legal tech',
    icon: require('../../../assets/icons/categories/category_16.png'),
  },
  {
    id: '21',
    label: 'Data/AI',
    icon: require('../../../assets/icons/categories/category_07.png'),
  },
  {
    id: '23',
    label: 'Ecommerce',
    icon: require('../../../assets/icons/categories/category_30.png'),
  },
  {
    id: '25',
    label: 'Travel / Leisure Hospitality',
    icon: require('../../../assets/icons/categories/category_23.png'),
  },
  {
    id: '26',
    label: 'Smart City',
    icon: require('../../../assets/icons/categories/category_21.png'),
  },
  {
    id: '28',
    label: 'Community Scocial Network',
    icon: require('../../../assets/icons/categories/category_04.png'),
  },
  {
    id: '29',
    label: 'Corporate process Business Services',
    icon: require('../../../assets/icons/categories/category_05.png'),
  },
  {
    id: '30',
    label: 'Wellness',
    icon: require('../../../assets/icons/categories/category_31.png'),
  },
];
