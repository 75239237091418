import { connect } from 'react-redux';
import { compose } from 'recompose';
import HomeScreenComponent from './HomeScreen.component';
import { fetchNotificationsCount } from '../../redux/Notification';
import { withAppForegroundListener } from '../../hoc';

const mapDispatchToProps = {
  fetchNotificationsCount,
};

const enhance = compose(
  withAppForegroundListener,
  connect(null, mapDispatchToProps)
);

export const ConnectedHomeScreen = enhance(HomeScreenComponent);
