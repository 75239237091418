import React, { PureComponent } from 'react';
import { Linking } from 'react-native';
import { navigation } from '../lib';

export const withDeeplinkListener = (Component) =>
  class DeeplinkListenerHOC extends PureComponent {
    componentDidMount() {
      Linking.getInitialURL().then((url) => this._handleDeeplink({ url }));
      Linking.addEventListener('url', this._handleDeeplink);
    }

    componentWillUnmount() {
      Linking.removeEventListener('url', this._handleDeeplink);
    }

    _handleDeeplink = ({ url }) => {
      if (url) {
        navigation.setDeeplink(url);
      }
    };

    render() {
      return <Component {...this.props} />;
    }
  };
