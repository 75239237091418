import React, { useCallback } from 'react';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Image, StyleSheet, TouchableOpacity, View } from 'react-native';
import { navigationMenuRoutes } from '../navigation/navigationMenuRoutes';
import { theme } from '../lib';

const RouteButton = (props) => {
  const navigation = useNavigation();
  const currentRoute = useRoute();
  const { route } = props;

  const onPress = useCallback(() => navigation.navigate(route.name), [
    navigation,
    route.name,
  ]);

  const isActive = currentRoute.name === route.name;

  return (
    <TouchableOpacity style={styles.button} onPress={onPress}>
      <View
        style={[
          styles.iconContainer,
          isActive && { backgroundColor: route.color },
        ]}
      >
        <Image
          source={route.source}
          resizeMode="contain"
          style={[
            styles.icon,
            !isActive && { tintColor: route.color },
            isActive &&
              route.activeSize && {
                width: route.activeSize,
                height: route.activeSize,
              },
            !isActive &&
              route.inactiveSize && {
                width: route.inactiveSize,
                height: route.inactiveSize,
              },
          ]}
        />
      </View>
    </TouchableOpacity>
  );
};

export const DesktopNavigationMenu = () => {
  const renderRoute = useCallback(
    (route) => <RouteButton key={route.name} route={route} />,
    []
  );

  return (
    <View style={styles.container}>
      {navigationMenuRoutes.map(renderRoute)}
    </View>
  );
};

const ICON_CONTAINER_SIZE = 60;
const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  button: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.margin.unit * 3,
  },
  iconContainer: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: ICON_CONTAINER_SIZE / 2,
    width: ICON_CONTAINER_SIZE,
    height: ICON_CONTAINER_SIZE,
  },
  icon: {
    tintColor: 'white',
    width: 35,
    height: 35,
  },
});
