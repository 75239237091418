const userStateSelector = (state) => state.user;

export const meSelector = (state) => userStateSelector(state).me;

export const myReferenceSelector = (state) => {
  const me = meSelector(state);
  return me ? me.reference : null;
};

export const hasSeenOnboardingSelector = (state) => {
  return userStateSelector(state).hasSeenOnboarding;
};

export const authTokenSelector = (state) => {
  return userStateSelector(state).authToken;
};

export const canUserBeAttributedProjectsSelector = (state) => {
  const { projectsAttribution } = meSelector(state);
  return projectsAttribution != null && projectsAttribution > 0;
};
