import { connect } from 'react-redux';
import TimelineScreenComponent from './TimelineScreen.component';
import { markNotificationAsVisited } from '../../src/redux/Notification';

const mapDispatchToProps = (dispatch) => ({
  markAsVisited: () => dispatch(markNotificationAsVisited('timeline')),
});

export const ConnectedTimelineScreen = connect(
  null,
  mapDispatchToProps
)(TimelineScreenComponent);
