import React from 'react';
import {
  ActivityIndicator,
  Text,
  View,
  StyleSheet,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { Picker } from '@react-native-community/picker';

import { localstore } from '../helpers/LocalStore';
import { fetchMe } from '../src/redux/User';
import { connect } from 'react-redux';
import { Page } from '../src/components';
import { environment } from '../src/environment';

class EditProfileScreen extends React.Component {
  state = {
    bearerToken: '',
    name: '',
    lastName: '',
    country_code: '',
    city: '',
    companyName: '',
    facebook: '',
    twitter: '',
    linkedIn: '',
    email: '',
    phone: '',
    loading: false,
  };

  componentDidMount() {
    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result }, () => {
          this.loadProfile();
        });
      }
    });
  }

  nameChanged = (event) => {
    this.setState({ name: event.nativeEvent.text });
  };

  lastNameChanged = (event) => {
    this.setState({ lastName: event.nativeEvent.text });
  };

  cityChanged = (event) => {
    this.setState({ city: event.nativeEvent.text });
  };

  companyChanged = (event) => {
    this.setState({ companyName: event.nativeEvent.text });
  };

  facebookChanged = (event) => {
    this.setState({ facebook: event.nativeEvent.text });
  };

  twitterChanged = (event) => {
    this.setState({ twitter: event.nativeEvent.text });
  };

  linkedinChanged = (event) => {
    this.setState({ linkedIn: event.nativeEvent.text });
  };

  emailChanged = (event) => {
    this.setState({ email: event.nativeEvent.text });
  };

  phoneChanged = (event) => {
    this.setState({ phone: event.nativeEvent.text });
  };

  connectedToChanged = (event) => {
    this.setState({ connected_to: event.nativeEvent.text });
  };

  positionChanged = (event) => {
    this.setState({ position: event.nativeEvent.text });
  };

  loadProfile() {
    fetch('https://' + environment.API_HOST + '/v4/me', {
      // mode: 'no-cors',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + this.state.bearerToken,
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((json) => {
          let usersObject = json.data;
          this.setState({
            name: usersObject.first_name,
            lastName: usersObject.last_name,
            position: usersObject.position,
            city: usersObject.city,
            country_code: usersObject.country_code,
            connected_to: usersObject.connected_to,
            companyName: usersObject.company,
            facebook: usersObject.facebook,
            linkedIn: usersObject.linkedin,
            twitter: usersObject.twitter,
            email: usersObject.email,
            phone: usersObject.phone,
          });
        });
      }
    });
  }

  saveButton = () => {
    this.setState({ loading: true });
    const bearerToken = this.state.bearerToken;

    fetch('https://' + environment.API_HOST + '/v4/update_me', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
      body: JSON.stringify({
        user: {
          first_name: this.state.name,
          last_name: this.state.lastName,
          position: this.state.position,
          city: this.state.city,
          company: this.state.companyName,
          country: this.state.country_code,
          facebook: this.state.facebook,
          linkedin: this.state.linkedIn,
          twitter: this.state.twitter,
          email: this.state.email,
          phone: this.state.phone,
          connected_to: this.state.connected_to,
        },
      }),
    }).then(() => {
      this.props.fetchMe({
        onSuccess: () => this.props.navigation.goBack(),
        onFailure: () => this.props.navigation.goBack(),
      });
    });
  };

  renderHeaderRight = () => (
    <TouchableOpacity
      onPress={this.saveButton}
      disabled={this.state.loading}
      style={[
        {
          height: 50,
          width: 50,
          marginTop: 30,
        },
      ]}
    >
      {this.state.loading ? (
        <ActivityIndicator
          style={styles.activityPos}
          color="#fff"
          size={'small'}
        />
      ) : (
        <Text style={styles.saveTitle}>Save</Text>
      )}
    </TouchableOpacity>
  );

  render() {
    return (
      <Page
        headerColor="#45a9ed"
        title="Edit my profile"
        renderHeaderRight={this.renderHeaderRight}
      >
        <ScrollView
          contentContainerStyle={{ paddingVertical: 20 }}
          style={{ marginHorizontal: 30 }}
        >
          <View>
            <Text style={styles.title}>Basic info</Text>
            <Text style={styles.text}>Name</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              onChange={this.nameChanged}
              placeholder="First name"
              value={this.state.name}
            />

            <Text style={styles.text}>Last name</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="Last name"
              onChange={this.lastNameChanged}
              value={this.state.lastName}
            />

            <Text style={styles.text}>City</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="City"
              onChange={this.cityChanged}
              value={this.state.city}
            />

            <Text style={styles.text}>Country</Text>
            <Picker
              selectedValue={this.state.country_code}
              style={
                (styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 })
              }
              onValueChange={(itemValue) =>
                this.setState({ country_code: itemValue })
              }
            >
              <Picker.Item label="Afghanistan" value="AF" />
              <Picker.Item label="Albania" value="AL" />
              <Picker.Item label="Algeria" value="DZ" />
              <Picker.Item label="American Samoa" value="AS" />
              <Picker.Item label="Andorra" value="AD" />
              <Picker.Item label="Angola" value="AO" />
              <Picker.Item label="Anguilla" value="AI" />
              <Picker.Item label="Antarctica" value="AQ" />
              <Picker.Item label="Antigua and Barbuda" value="AG" />
              <Picker.Item label="Argentina" value="AR" />
              <Picker.Item label="Armenia" value="AM" />
              <Picker.Item label="Aruba" value="AW" />
              <Picker.Item label="Australia" value="AU" />
              <Picker.Item label="Austria" value="AT" />
              <Picker.Item label="Azerbaijan" value="AZ" />
              <Picker.Item label="Bahamas (the)" value="BS" />
              <Picker.Item label="Bahrain" value="BH" />
              <Picker.Item label="Bangladesh" value="BD" />
              <Picker.Item label="Barbados" value="BB" />
              <Picker.Item label="Belarus" value="BY" />
              <Picker.Item label="Belgium" value="BE" />
              <Picker.Item label="Belize" value="BZ" />
              <Picker.Item label="Benin" value="BJ" />
              <Picker.Item label="Bermuda" value="BM" />
              <Picker.Item label="Bhutan" value="BT" />
              <Picker.Item
                label="Bolivia (Plurinational State of)"
                value="BO"
              />
              <Picker.Item
                label="Bonaire, Sint Eustatius and Saba"
                value="BQ"
              />
              <Picker.Item label="Bosnia and Herzegovina" value="BA" />
              <Picker.Item label="Botswana" value="BW" />
              <Picker.Item label="Bouvet Island" value="BV" />
              <Picker.Item label="Brazil" value="BR" />
              <Picker.Item
                label="British Indian Ocean Territory (the)"
                value="IO"
              />
              <Picker.Item label="Brunei Darussalam" value="BN" />
              <Picker.Item label="Bulgaria" value="BG" />
              <Picker.Item label="Burkina Faso" value="BF" />
              <Picker.Item label="Burundi" value="BI" />
              <Picker.Item label="Cabo Verde" value="CV" />
              <Picker.Item label="Cambodia" value="KH" />
              <Picker.Item label="Cameroon" value="CM" />
              <Picker.Item label="Canada" value="CA" />
              <Picker.Item label="Cayman Islands (the)" value="KY" />
              <Picker.Item label="Central African Republic (the)" value="CF" />
              <Picker.Item label="Chad" value="TD" />
              <Picker.Item label="Chile" value="CL" />
              <Picker.Item label="China" value="CN" />
              <Picker.Item label="Christmas Island" value="CX" />
              <Picker.Item label="Cocos (Keeling) Islands (the)" value="CC" />
              <Picker.Item label="Colombia" value="CO" />
              <Picker.Item label="Comoros (the)" value="KM" />
              <Picker.Item
                label="Congo (the Democratic Republic of the)"
                value="CD"
              />
              <Picker.Item label="Congo (the)" value="CG" />
              <Picker.Item label="Cook Islands (the)" value="CK" />
              <Picker.Item label="Costa Rica" value="CR" />
              <Picker.Item label="Croatia" value="HR" />
              <Picker.Item label="Cuba" value="CU" />
              <Picker.Item label="Curaçao" value="CW" />
              <Picker.Item label="Cyprus" value="CY" />
              <Picker.Item label="Czechia" value="CZ" />
              <Picker.Item label="Côte d'Ivoire" value="CI" />
              <Picker.Item label="Denmark" value="DK" />
              <Picker.Item label="Djibouti" value="DJ" />
              <Picker.Item label="Dominica" value="DM" />
              <Picker.Item label="Dominican Republic (the)" value="DO" />
              <Picker.Item label="Ecuador" value="EC" />
              <Picker.Item label="Egypt" value="EG" />
              <Picker.Item label="El Salvador" value="SV" />
              <Picker.Item label="Equatorial Guinea" value="GQ" />
              <Picker.Item label="Eritrea" value="ER" />
              <Picker.Item label="Estonia" value="EE" />
              <Picker.Item label="Eswatini" value="SZ" />
              <Picker.Item label="Ethiopia" value="ET" />
              <Picker.Item
                label="Falkland Islands (the) [Malvinas]"
                value="FK"
              />
              <Picker.Item label="Faroe Islands (the)" value="FO" />
              <Picker.Item label="Fiji" value="FJ" />
              <Picker.Item label="Finland" value="FI" />
              <Picker.Item label="France" value="FR" />
              <Picker.Item label="French Guiana" value="GF" />
              <Picker.Item label="French Polynesia" value="PF" />
              <Picker.Item
                label="French Southern Territories (the)"
                value="TF"
              />
              <Picker.Item label="Gabon" value="GA" />
              <Picker.Item label="Gambia (the)" value="GM" />
              <Picker.Item label="Georgia" value="GE" />
              <Picker.Item label="Germany" value="DE" />
              <Picker.Item label="Ghana" value="GH" />
              <Picker.Item label="Gibraltar" value="GI" />
              <Picker.Item label="Greece" value="GR" />
              <Picker.Item label="Greenland" value="GL" />
              <Picker.Item label="Grenada" value="GD" />
              <Picker.Item label="Guadeloupe" value="GP" />
              <Picker.Item label="Guam" value="GU" />
              <Picker.Item label="Guatemala" value="GT" />
              <Picker.Item label="Guernsey" value="GG" />
              <Picker.Item label="Guinea" value="GN" />
              <Picker.Item label="Guinea-Bissau" value="GW" />
              <Picker.Item label="Guyana" value="GY" />
              <Picker.Item label="Haiti" value="HT" />
              <Picker.Item
                label="Heard Island and McDonald Islands"
                value="HM"
              />
              <Picker.Item label="Holy See (the)" value="VA" />
              <Picker.Item label="Honduras" value="HN" />
              <Picker.Item label="Hong Kong" value="HK" />
              <Picker.Item label="Hungary" value="HU" />
              <Picker.Item label="Iceland" value="IS" />
              <Picker.Item label="India" value="IN" />
              <Picker.Item label="Indonesia" value="ID" />
              <Picker.Item label="Iran (Islamic Republic of)" value="IR" />
              <Picker.Item label="Iraq" value="IQ" />
              <Picker.Item label="Ireland" value="IE" />
              <Picker.Item label="Isle of Man" value="IM" />
              <Picker.Item label="Israel" value="IL" />
              <Picker.Item label="Italy" value="IT" />
              <Picker.Item label="Jamaica" value="JM" />
              <Picker.Item label="Japan" value="JP" />
              <Picker.Item label="Jersey" value="JE" />
              <Picker.Item label="Jordan" value="JO" />
              <Picker.Item label="Kazakhstan" value="KZ" />
              <Picker.Item label="Kenya" value="KE" />
              <Picker.Item label="Kiribati" value="KI" />
              <Picker.Item
                label="Korea (the Democratic People's Republic of)"
                value="KP"
              />
              <Picker.Item label="Korea (the Republic of)" value="KR" />
              <Picker.Item label="Kuwait" value="KW" />
              <Picker.Item label="Kyrgyzstan" value="KG" />
              <Picker.Item
                label="Lao People's Democratic Republic (the)"
                value="LA"
              />
              <Picker.Item label="Latvia" value="LV" />
              <Picker.Item label="Lebanon" value="LB" />
              <Picker.Item label="Lesotho" value="LS" />
              <Picker.Item label="Liberia" value="LR" />
              <Picker.Item label="Libya" value="LY" />
              <Picker.Item label="Liechtenstein" value="LI" />
              <Picker.Item label="Lithuania" value="LT" />
              <Picker.Item label="Luxembourg" value="LU" />
              <Picker.Item label="Macao" value="MO" />
              <Picker.Item label="Madagascar" value="MG" />
              <Picker.Item label="Malawi" value="MW" />
              <Picker.Item label="Malaysia" value="MY" />
              <Picker.Item label="Maldives" value="MV" />
              <Picker.Item label="Mali" value="ML" />
              <Picker.Item label="Malta" value="MT" />
              <Picker.Item label="Marshall Islands (the)" value="MH" />
              <Picker.Item label="Martinique" value="MQ" />
              <Picker.Item label="Mauritania" value="MR" />
              <Picker.Item label="Mauritius" value="MU" />
              <Picker.Item label="Mayotte" value="YT" />
              <Picker.Item label="Mexico" value="MX" />
              <Picker.Item
                label="Micronesia (Federated States of)"
                value="FM"
              />
              <Picker.Item label="Moldova (the Republic of)" value="MD" />
              <Picker.Item label="Monaco" value="MC" />
              <Picker.Item label="Mongolia" value="MN" />
              <Picker.Item label="Montenegro" value="ME" />
              <Picker.Item label="Montserrat" value="MS" />
              <Picker.Item label="Morocco" value="MA" />
              <Picker.Item label="Mozambique" value="MZ" />
              <Picker.Item label="Myanmar" value="MM" />
              <Picker.Item label="Namibia" value="NA" />
              <Picker.Item label="Nauru" value="NR" />
              <Picker.Item label="Nepal" value="NP" />
              <Picker.Item label="Netherlands (the)" value="NL" />
              <Picker.Item label="New Caledonia" value="NC" />
              <Picker.Item label="New Zealand" value="NZ" />
              <Picker.Item label="Nicaragua" value="NI" />
              <Picker.Item label="Niger (the)" value="NE" />
              <Picker.Item label="Nigeria" value="NG" />
              <Picker.Item label="Niue" value="NU" />
              <Picker.Item label="Norfolk Island" value="NF" />
              <Picker.Item label="Northern Mariana Islands (the)" value="MP" />
              <Picker.Item label="Norway" value="NO" />
              <Picker.Item label="Oman" value="OM" />
              <Picker.Item label="Pakistan" value="PK" />
              <Picker.Item label="Palau" value="PW" />
              <Picker.Item label="Palestine, State of" value="PS" />
              <Picker.Item label="Panama" value="PA" />
              <Picker.Item label="Papua New Guinea" value="PG" />
              <Picker.Item label="Paraguay" value="PY" />
              <Picker.Item label="Peru" value="PE" />
              <Picker.Item label="Philippines (the)" value="PH" />
              <Picker.Item label="Pitcairn" value="PN" />
              <Picker.Item label="Poland" value="PL" />
              <Picker.Item label="Portugal" value="PT" />
              <Picker.Item label="Puerto Rico" value="PR" />
              <Picker.Item label="Qatar" value="QA" />
              <Picker.Item label="Republic of North Macedonia" value="MK" />
              <Picker.Item label="Romania" value="RO" />
              <Picker.Item label="Russian Federation (the)" value="RU" />
              <Picker.Item label="Rwanda" value="RW" />
              <Picker.Item label="Réunion" value="RE" />
              <Picker.Item label="Saint Barthélemy" value="BL" />
              <Picker.Item
                label="Saint Helena, Ascension and Tristan da Cunha"
                value="SH"
              />
              <Picker.Item label="Saint Kitts and Nevis" value="KN" />
              <Picker.Item label="Saint Lucia" value="LC" />
              <Picker.Item label="Saint Martin (French part)" value="MF" />
              <Picker.Item label="Saint Pierre and Miquelon" value="PM" />
              <Picker.Item
                label="Saint Vincent and the Grenadines"
                value="VC"
              />
              <Picker.Item label="Samoa" value="WS" />
              <Picker.Item label="San Marino" value="SM" />
              <Picker.Item label="Sao Tome and Principe" value="ST" />
              <Picker.Item label="Saudi Arabia" value="SA" />
              <Picker.Item label="Senegal" value="SN" />
              <Picker.Item label="Serbia" value="RS" />
              <Picker.Item label="Seychelles" value="SC" />
              <Picker.Item label="Sierra Leone" value="SL" />
              <Picker.Item label="Singapore" value="SG" />
              <Picker.Item label="Sint Maarten (Dutch part)" value="SX" />
              <Picker.Item label="Slovakia" value="SK" />
              <Picker.Item label="Slovenia" value="SI" />
              <Picker.Item label="Solomon Islands" value="SB" />
              <Picker.Item label="Somalia" value="SO" />
              <Picker.Item label="South Africa" value="ZA" />
              <Picker.Item
                label="South Georgia and the South Sandwich Islands"
                value="GS"
              />
              <Picker.Item label="South Sudan" value="SS" />
              <Picker.Item label="Spain" value="ES" />
              <Picker.Item label="Sri Lanka" value="LK" />
              <Picker.Item label="Sudan (the)" value="SD" />
              <Picker.Item label="Suriname" value="SR" />
              <Picker.Item label="Svalbard and Jan Mayen" value="SJ" />
              <Picker.Item label="Sweden" value="SE" />
              <Picker.Item label="Switzerland" value="CH" />
              <Picker.Item label="Syrian Arab Republic" value="SY" />
              <Picker.Item label="Taiwan (Province of China)" value="TW" />
              <Picker.Item label="Tajikistan" value="TJ" />
              <Picker.Item label="Tanzania, United Republic of" value="TZ" />
              <Picker.Item label="Thailand" value="TH" />
              <Picker.Item label="Timor-Leste" value="TL" />
              <Picker.Item label="Togo" value="TG" />
              <Picker.Item label="Tokelau" value="TK" />
              <Picker.Item label="Tonga" value="TO" />
              <Picker.Item label="Trinidad and Tobago" value="TT" />
              <Picker.Item label="Tunisia" value="TN" />
              <Picker.Item label="Turkey" value="TR" />
              <Picker.Item label="Turkmenistan" value="TM" />
              <Picker.Item label="Turks and Caicos Islands (the)" value="TC" />
              <Picker.Item label="Tuvalu" value="TV" />
              <Picker.Item label="Uganda" value="UG" />
              <Picker.Item label="Ukraine" value="UA" />
              <Picker.Item label="United Arab Emirates (the)" value="AE" />
              <Picker.Item
                label="United Kingdom of Great Britain and Northern Ireland (the)"
                value="GB"
              />
              <Picker.Item
                label="United States Minor Outlying Islands (the)"
                value="UM"
              />
              <Picker.Item label="United States of America (the)" value="US" />
              <Picker.Item label="Uruguay" value="UY" />
              <Picker.Item label="Uzbekistan" value="UZ" />
              <Picker.Item label="Vanuatu" value="VU" />
              <Picker.Item
                label="Venezuela (Bolivarian Republic of)"
                value="VE"
              />
              <Picker.Item label="Viet Nam" value="VN" />
              <Picker.Item label="Virgin Islands (British)" value="VG" />
              <Picker.Item label="Virgin Islands (U.S.)" value="VI" />
              <Picker.Item label="Wallis and Futuna" value="WF" />
              <Picker.Item label="Western Sahara" value="EH" />
              <Picker.Item label="Yemen" value="YE" />
              <Picker.Item label="Zambia" value="ZM" />
              <Picker.Item label="Zimbabwe" value="ZW" />
              <Picker.Item label="Åland Islands" value="AX" />
            </Picker>

            <Text style={styles.text}>Connected To</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="Connected To"
              onChange={this.connectedToChanged}
              value={this.state.connected_to}
            />
          </View>

          <View>
            <Text style={styles.title}>Profile</Text>
            <Text style={styles.text}>Company</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="Your Company"
              onChange={this.companyChanged}
              value={this.state.companyName}
            />
            <Text style={styles.text}>Position</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="Your Position"
              onChange={this.positionChanged}
              value={this.state.position}
            />
          </View>

          <View>
            <Text style={styles.title}>Social</Text>
            <Text style={styles.text}>Facebook (URL)</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="facebook.com"
              keyboardType="url"
              onChange={this.facebookChanged}
              value={this.state.facebook}
            />

            <Text style={styles.text}>Twitter (URL)</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="twitter.com"
              keyboardType="url"
              onChange={this.twitterChanged}
              value={this.state.twitter}
            />

            <Text style={styles.text}>LinkedIn (URL)</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="linkedin.com"
              keyboardType="url"
              onChange={this.linkedinChanged}
              value={this.state.linkedIn}
            />
          </View>
          <View>
            <Text style={styles.title}>For Daphni eyes only</Text>
            <Text style={styles.text}>Email</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="email@company.com"
              keyboardType="email-address"
              onChange={this.emailChanged}
              value={this.state.email}
            />
            <Text style={styles.text}>Phone</Text>
            <TextInput
              style={[
                styles.inputText,
                { borderColor: '#999999', borderBottomWidth: 1 },
              ]}
              returnKeyType="next"
              placeholder="161616161616"
              keyboardType="phone-pad"
              onChange={this.phoneChanged}
              value={this.state.phone}
            />
          </View>
        </ScrollView>
      </Page>
    );
  }
}

const mapDispatchToProps = {
  fetchMe,
};

export default connect(null, mapDispatchToProps)(EditProfileScreen);

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Freight-Sans-Black',
    color: '#9c9c9c',
    fontSize: 16,
    marginVertical: 10,
  },
  saveTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#ffffff',
    fontSize: 16,
    marginBottom: 5,
    position: 'absolute',
    right: 15,
  },
  activityPos: {
    color: '#ffffff',
    marginBottom: 5,
    position: 'absolute',
    right: 15,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#9c9c9c',
    fontSize: 16,
    marginTop: 10,
  },
  inputText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#999999',
    fontSize: 16,
    marginTop: 10,
  },
});
