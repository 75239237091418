import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import LoginScreen from '../../screens/LoginScreen';
import ConfirmCodeScreen from '../../screens/ConfirmCodeScreen';

const Stack = createStackNavigator();

export const UnauthenticatedNavigation = () => (
  <Stack.Navigator headerMode="none">
    <Stack.Screen name="Login" component={LoginScreen} />
    <Stack.Screen name="Confirmation" component={ConfirmCodeScreen} />
  </Stack.Navigator>
);
