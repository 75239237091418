import React, { PureComponent } from 'react';
import { AppState } from 'react-native';

export const withAppForegroundListener = (Component) =>
  class AppForegroundHOC extends PureComponent {
    state = {
      appState: AppState.currentState,
    };

    listener = null;
    setListener = (listener) => (this.listener = listener);

    componentDidMount() {
      AppState.addEventListener('change', this._handleAppStateChange);
    }

    componentWillUnmount() {
      AppState.removeEventListener('change', this._handleAppStateChange);
    }

    _handleAppStateChange = (nextAppState) => {
      if (
        this.state.appState.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        this.onAppForeground();
      }
      this.setState({ appState: nextAppState });
    };

    onAppForeground = () => {
      if (this.listener) {
        this.listener();
      }
    };

    render() {
      return (
        <Component
          {...this.props}
          setAppForegroundListener={this.setListener}
        />
      );
    }
  };
