import { getPrivateApi } from '../base';

const resourceUrl = '/funds';

export const getFunds = async (query) => {
  const api = await getPrivateApi();
  return api.url(resourceUrl).query(query).get().json();
};

export const getFund = async (id) => {
  const api = await getPrivateApi();
  return api.url(`${resourceUrl}/${id}`).get().json();
};
