import React from 'react';
import {
  Text,
  View,
  ScrollView,
  Dimensions,
  Image,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import HTML from 'react-native-render-html';
import { Divider } from 'react-native-elements';
import DaphniNews from '../DaphniNews';
import { openUrl } from '../../src/lib';

const IMAGES_MAX_WIDTH = Dimensions.get('window').width - 50;
const DEFAULT_PROPS = {
  baseFontStyle: {
    fontSize: 16,
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
  },
  tagsStyles: {
    p: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    em: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
    i: {
      fontFamily: 'Freight-Sans-Medium',
      textAlign: 'justify',
      fontStyle: 'normal',
      color: '#666666',
    },
  },
  ignoredStyles: [
    'background-color',
    'padding',
    'font-family',
    'font-size',
    'line-height',
  ],
  imagesMaxWidth: IMAGES_MAX_WIDTH,
  onLinkPress: (evt, href) => {
    openUrl(href);
  },
  debug: false,
};

const AllNews = (props) => {
  let allNews = props.allNews;
  if (allNews != null) {
    allNews = props.allNews.map((article) => {
      return (
        <View
          key={article.id}
          style={{
            marginHorizontal: 10,
          }}
        >
          <DaphniNews
            key={article.id}
            picSrc={article.picSrc}
            title={article.title}
            description={article.description}
            date={article.date}
            source={article.source}
            url={article.url}
          />
          <Divider style={styles.line} />
        </View>
      );
    });
  }

  let picSrc = props.featured.picSrc;
  if (picSrc == null) {
    picSrc = 'https://daphni.com/favicon-128x128.png';
  }

  const dimensions = useWindowDimensions();
  const imageSize = Math.min(dimensions.width / 2, 175);

  return (
    <ScrollView contentContainerStyle={styles.scrollViewContentContainer}>
      {!!props.featured && (
        <>
          <TouchableOpacity
            style={styles.featuredContainer}
            activeOpacity={0.9}
            onPress={() => openUrl(props.featured.url)}
          >
            <Image
              style={[
                styles.featuredImage,
                { width: imageSize, height: imageSize },
              ]}
              source={{ uri: picSrc }}
              resizeMode="cover"
              defaultSource={require('../../assets/daphni.png')}
            />
            <Text style={[styles.featuredText, styles.featuredTitleText]}>
              {props.featured.title}
            </Text>
            <HTML {...DEFAULT_PROPS} html={props.featured.description} />
            <Text style={[styles.featuredText, styles.featuredDateText]}>
              {props.featured.date}
            </Text>
          </TouchableOpacity>
          <Divider style={styles.line} />
        </>
      )}
      {allNews}
    </ScrollView>
  );
};

export default AllNews;

const styles = StyleSheet.create({
  scrollViewContentContainer: {
    paddingVertical: 20,
  },
  featuredContainer: {
    marginTop: 10,
    marginHorizontal: 20,
    alignItems: 'center',
  },
  featuredImage: {
    borderRadius: 15,
    marginBottom: 10,
  },
  featuredText: {
    marginVertical: 5,
    flexWrap: 'wrap',
    fontSize: 16,
    color: '#666666',
    textAlign: 'center',
  },
  featuredTitleText: {
    fontFamily: 'Freight-Sans-Black',
  },
  featuredDateText: {
    fontFamily: 'Freight-Sans-Medium',
  },
  line: {
    marginVertical: 15,
  },
});
