import { connect } from 'react-redux';
import DaphniHeaderComponent from './DaphniHeader.component';
import {
  hasDiscussionNotificationsSelector,
  hasTimelineNotificationsSelector,
} from '../../src/redux/Notification';

const mapStateToProps = (state) => ({
  hasTimelineNotifications: hasTimelineNotificationsSelector(state),
  hasDiscussionNotifications: hasDiscussionNotificationsSelector(state),
});

export const ConnectedDaphniHeader = connect(mapStateToProps)(
  DaphniHeaderComponent
);
