import React from 'react';
import {
  Platform,
  StyleSheet,
  Text,
  View,
  ScrollView,
  ActivityIndicator,
  RefreshControl,
} from 'react-native';
import { BatchPush } from '@bam.tech/react-native-batch';
import { StatusBarHeight } from '../../lib/StatusBarHeight';
import AutoScrolling from 'react-native-auto-scrolling';

import DaphniHeader from '../../../components/DaphniHeader';
import DaphniFooter from '../../../components/DaphniFooter';
import Carrousel from './components/Carrousel';
import WeeksFlow from './components/WeeksFlow';
import HomeNews from './components/HomeNews';
import { localstore } from '../../../helpers/LocalStore';
import { configureBatchUser } from '../../lib/Batch';
import { getBreakingStories } from '../../api';
import { Page } from '../../components';
import { CompanyOfTheWeek } from './components/CompanyOfTheWeek';
import { withWindowDimensions } from '../../hoc';
import {
  desktopOrLargerMinPixel,
  tabletOrLargerMinPixel,
} from '../../lib/responsive';
import { PopularDaphniTags } from './components/PopularDaphniTags';
import { DaCommunityNews } from './components/DaCommunityNews';
import { DaphnipolisStats } from './components/DaphnipolisStats';
import { GeneralOverview } from './components/GeneralOverview';
import { WeekDaphnipolitan } from './components/WeekDaphnipolitan';
import { DaBestReferers } from './components/DaBestReferers';
import { DESKTOP_HEADER_OFFSET } from '../../../components/DaphniHeader/DaphniHeader.component';
import { formatBreakingStories } from '../../api/BreakingStories/formatter';

class HomeScreenComponent extends React.Component {
  state = {
    bearerToken: '',
    stickytext: '',
    loadingBreakingNews: true,
    loading: true,
    rerender: 1,
  };

  wait(timeout) {
    return new Promise((resolve) => {
      setTimeout(resolve, timeout);
    });
  }
  async componentDidMount() {
    localstore.getString('token').then((result) => {
      if (result !== null) {
        this.setState({ bearerToken: result });
        this.getStickyText();
        this.setState({ loading: false });
      }
    });

    localstore.getString('reference').then((reference) => {
      configureBatchUser(reference);
    });

    const onForeground = () => {
      BatchPush.clearBadge();
      this.props.fetchNotificationsCount();
    };

    onForeground();
    this.props.setAppForegroundListener(onForeground);
  }

  getStickyText = () => {
    getBreakingStories()
      .then(formatBreakingStories)
      .then((breakingStories) => {
        this.setState({
          stickytext: breakingStories,
          loadingBreakingNews: false,
        });
      })
      .catch((error) => {
        console.warn('[HomeScreen] getStickyText', { error });
        this.setState({
          stickytext: '',
          loadingBreakingNews: false,
        });
      });
  };

  _onRefresh() {
    this.setState({ refreshing: true, loadingBreakingNews: true }, () => {
      const renderval = Math.floor(Math.random() * 100 + 1);
      this.setState({ rerender: renderval }, () => {
        this.getStickyText();
        this.wait(1000).then(() => {
          this.setState({ refreshing: false });
        });
      });
    });
  }

  renderBreakingNews = () => {
    if (this.state.loadingBreakingNews) {
      return (
        <View style={styles.stickyBar}>
          <ActivityIndicator size="small" color="grey" />
        </View>
      );
    }

    if (!this.state.stickytext) {
      return null;
    }

    return (
      <AutoScrolling style={styles.stickyBar} endPadding={1}>
        <Text style={styles.stickyText}>{this.state.stickytext}</Text>
      </AutoScrolling>
    );
  };

  renderHeaderContent = () => (
    <DaphniHeader navigation={this.props.navigation} index={0} />
  );

  render() {
    if (this.state.loading) {
      return <ActivityIndicator size="large" color="grey" />;
    }

    return (
      <Page
        headerColor="#7e0d86"
        renderHeaderContent={this.renderHeaderContent}
        withoutAutoOffsetOnDesktop
      >
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={this._onRefresh.bind(this)}
            />
          }
          style={styles.scrollView}
          contentContainerStyle={styles.scrollViewContentContainer}
          stickyHeaderIndices={[1]}
          showsVerticalScrollIndicator={false}
          removeClippedSubviews={true}
        >
          <View
            style={[
              styles.bannerContainer,
              {
                paddingTop:
                  this.props.dimensions.width >= desktopOrLargerMinPixel
                    ? DESKTOP_HEADER_OFFSET
                    : undefined,
              },
            ]}
          >
            <Text style={styles.homeText}>da news</Text>
            <Carrousel
              bearerToken={this.state.bearerToken}
              navigation={this.props.navigation}
              rerender={this.state.rerender}
            />
          </View>
          {this.renderBreakingNews()}
          {this.props.dimensions.width >= tabletOrLargerMinPixel ? (
            <View style={styles.desktopContentContainer}>
              <View style={styles.desktopColumnSeparator} />
              <View style={styles.desktopColumnContainer}>
                <CompanyOfTheWeek />
                <WeekDaphnipolitan />
                <DaBestReferers />
                <GeneralOverview />
              </View>
              <View style={styles.desktopColumnSeparator} />
              <View style={styles.desktopColumnContainer}>
                <WeeksFlow
                  bearerToken={this.state.bearerToken}
                  navigation={this.props.navigation}
                  rerender={this.state.rerender}
                />
                <DaCommunityNews />
                <DaphnipolisStats />
                <PopularDaphniTags />
              </View>
              <View style={styles.desktopColumnSeparator} />
              <View style={styles.desktopColumnContainer}>
                <HomeNews
                  bearerToken={this.state.bearerToken}
                  navigation={this.props.navigation}
                  rerender={this.state.rerender}
                />
              </View>
              <View style={{ width: 40 }} />
            </View>
          ) : (
            <>
              <CompanyOfTheWeek />
              <WeeksFlow
                bearerToken={this.state.bearerToken}
                navigation={this.props.navigation}
                rerender={this.state.rerender}
              />
              <DaCommunityNews />
              <WeekDaphnipolitan />
              <DaBestReferers />
              <DaphnipolisStats />
              <PopularDaphniTags />
              <HomeNews
                bearerToken={this.state.bearerToken}
                navigation={this.props.navigation}
                rerender={this.state.rerender}
              />
              <GeneralOverview />
            </>
          )}
        </ScrollView>
        <DaphniFooter />
      </Page>
    );
  }
}

export default withWindowDimensions(HomeScreenComponent);

const styles = StyleSheet.create({
  scrollView: {
    marginTop: -8,
    backgroundColor: '#7e0d86',
  },
  scrollViewContentContainer: {
    paddingBottom: 60,
  },
  bannerContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#53095b',
  },
  homeText: {
    fontFamily: 'DaphniFont',
    fontSize: 72,
    marginTop: Platform.OS === 'ios' ? 10 : StatusBarHeight.height,
    color: '#ffffff',
    textAlign: 'center',
  },
  stickyBar: {
    paddingTop: 15,
    paddingBottom: 10,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#53095b',
  },
  stickyText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#fff',
    fontSize: 16,
  },
  desktopContentContainer: {
    flexDirection: 'row',
  },
  desktopColumnSeparator: {
    width: '3%',
  },
  desktopColumnContainer: {
    flex: 1,
  },
});
