import React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';
import { Avatar } from 'react-native-elements';
import Flag from 'react-native-round-flags';

class DaphnipolitanGridItem extends React.Component {
  render() {
    let profilePic = this.props.profileSrc;
    if (profilePic == null) {
      profilePic = 'https://daphni.com/favicon-128x128.png';
    }

    return (
      <TouchableOpacity
        style={styles.container}
        onPress={() =>
          this.props.navigation.navigate('Daphnipolitan', {
            reference: this.props.reference,
            defaultUserData: {
              sectors: this.props.sectors,
              name: this.props.name,
              job: this.props.job,
              city: this.props.city,
              company: this.props.company,
              connected_to: this.props.connected_to,
              country: this.props.country,
              countryCode: this.props.countryCode,
              socials: this.props.socials,
              avatar: profilePic,
            },
          })
        }
      >
        <View style={styles.item}>
          <View style={styles.imageView}>
            <Avatar rounded size={65} source={{ uri: profilePic }} />
            <Flag code={this.props.countryCode} style={styles.flag} />
          </View>
          <Text style={styles.itemTitle}>{this.props.name}</Text>
          <Text style={styles.itemText}>{this.props.job}</Text>
          <Text style={styles.itemText}>{this.props.company}</Text>
        </View>
        <View style={styles.bottomLine} />
      </TouchableOpacity>
    );
  }
}

export default DaphnipolitanGridItem;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
  },
  flag: {
    position: 'absolute',
    bottom: 5,
    right: -8,
    width: 30,
    height: 30,
  },
  item: {
    flex: 1,
    padding: 5,
    alignItems: 'center',
  },
  imageView: {
    alignContent: 'center',
    margin: 5,
    marginTop: 20,
  },
  itemTitle: {
    fontFamily: 'Freight-Sans-Black',
    color: '#666666',
    fontSize: 16,
    marginBottom: 5,
    textAlign: 'center',
  },
  itemText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    textAlign: 'center',
    fontSize: 16,
  },
  bottomLine: {
    backgroundColor: '#666666',
    width: '90%',
    height: 1,
    alignSelf: 'center',
  },
});
