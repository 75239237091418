import React from 'react';
import { StyleSheet, View, ActivityIndicator, Text } from 'react-native';
import AllNews from '../components/AllNews/AllNews';
import { getHomeNews } from '../src/api';
import { adaptNews } from '../src/api/News/adapter';
import { Page } from '../src/components';

class NewsScreen extends React.Component {
  state = {
    loading: true,
    featured: null,
    allNews: null,
  };

  componentDidMount() {
    this.getHomeNews();
  }

  getHomeNews = () => {
    getHomeNews()
      .then(adaptNews)
      .then((news) => {
        if (news.length > 0) {
          this.setState({
            featured: news[0],
            allNews: news.splice(1, news.length),
          });
        } else {
          this.setState({ allNews: null, featured: null });
        }
      })
      .catch((error) => console.warn('NewsScreen.getHomeNews', { error }))
      .finally(() => this.setState({ loading: false }));
  };

  renderContent = () => {
    if (!this.state.allNews) {
      return (
        <View style={styles.loadingContainer}>
          {this.state.loading ? (
            <ActivityIndicator size="large" color="#f55074" />
          ) : (
            <Text>No news</Text>
          )}
        </View>
      );
    }

    return (
      <AllNews
        featured={this.state.featured}
        allNews={this.state.allNews}
        navigation={this.props.navigation}
      />
    );
  };

  render() {
    return (
      <Page headerColor="#f55074" title="News">
        {this.renderContent()}
      </Page>
    );
  }
}

export default NewsScreen;

const styles = StyleSheet.create({
  loadingContainer: {
    marginTop: 50,
    backgroundColor: '#ffffff',
    alignContent: 'center',
    justifyContent: 'center',
    padding: 20,
  },
});
