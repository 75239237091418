import React from 'react';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { Overlay } from 'react-native-elements';
import { useIsFocused } from '@react-navigation/native';
import DaphnipolitanOverlay from '../../../overlays/DaphnipolitanOverlay';
import { tabletOrLargerMinPixel } from '../../lib/responsive';

export const DaphnipolitanScreen = ({ navigation, route }) => {
  const isFocused = useIsFocused();
  const reference = route.params?.reference;
  const onPressChat = route.params?.onPressChat;
  const defaultUserData = route.params?.defaultUserData;
  const dimensions = useWindowDimensions();

  return (
    <Overlay
      isVisible={isFocused}
      overlayStyle={[
        styles.bottomOverlay,
        {
          width:
            dimensions.width > tabletOrLargerMinPixel
              ? dimensions.width / 3
              : '100%',
        },
      ]}
      onBackdropPress={() => navigation.goBack()}
    >
      <DaphnipolitanOverlay
        loadAllData
        reference={reference}
        successfullChat={onPressChat}
        {...defaultUserData}
      />
    </Overlay>
  );
};

const styles = StyleSheet.create({
  bottomOverlay: {
    position: 'absolute',
    backgroundColor: 'transparent',
    padding: 0,
    bottom: 0,
  },
});
