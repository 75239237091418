import React, { PureComponent } from 'react';
import { ActivityIndicator, FlatList, StyleSheet, View } from 'react-native';

export class ControlledPaginatedFlatList extends PureComponent {
  fetchMore = () => {
    if (this.props.canFetchMore) {
      this.props.fetchData();
    }
  };

  renderEmpty = () =>
    !this.props.isLoading && this.props.renderEmpty
      ? this.props.renderEmpty(this.props)
      : null;

  renderPaginationLoader = () => (
    <View style={styles.paginationLoaderContainer}>
      {this.props.isLoading && !this.props.refreshing ? (
        <ActivityIndicator
          size={this.props.data.length ? 'small' : 'large'}
          color="grey"
        />
      ) : null}
    </View>
  );

  keyExtractor = (item) =>
    this.props.keyExtractor ? this.props.keyExtractor(item) : item.reference;

  getExtraData = () =>
    (JSON.stringify(this.props.extraData) || '') + String(this.props.isLoading);

  render() {
    const { canFetchMore, fetchData, ...rest } = this.props;
    return (
      <FlatList
        {...rest}
        contentContainerStyle={[
          styles.contentContainer,
          this.props.contentContainerStyle,
        ]}
        keyExtractor={this.keyExtractor}
        ListEmptyComponent={this.renderEmpty}
        ListFooterComponent={this.renderPaginationLoader}
        onRefresh={this.props.onRefresh}
        refreshing={this.props.refreshing}
        onEndReached={this.fetchMore}
        onEndReachedThreshold={0.2}
        extraData={this.getExtraData()}
      />
    );
  }
}

const styles = StyleSheet.create({
  contentContainer: {
    paddingTop: 20,
    paddingBottom: 40,
  },
  paginationLoaderContainer: {
    marginTop: 20,
    minHeight: 40,
  },
});
