import React from 'react';
import { Text, View, StyleSheet, Image } from 'react-native';

import Flag from 'react-native-round-flags';
import { formatMoney, theme } from '../src/lib';
import { Rating } from '../src/components/Rating';
import { NavigationTouchable } from '../src/components';

class Company extends React.Component {
  render() {
    const fundColors =
      theme.colors.funds[this.props.type] || theme.colors.funds.Default;

    let money = this.props.money;
    if (money == null) {
      money = '0';
    } else {
      money = formatMoney(money);
    }

    let sectorsText;
    let sectors = this.props.sectors;

    if (sectors != null) {
      if (sectors.length > 3) {
        let temp = [sectors[0], sectors[1], sectors[2]];
        sectors = temp;
      }

      sectorsText = sectors.map((tag, id) => (
        <Text key={id} style={styles.text}>
          {tag.tag}
        </Text>
      ));
    } else {
      console.log('No sectors for this company');
      sectorsText = <Text style={styles.text}>General</Text>;
    }

    let logoSrc = this.props.logoSrc;
    if (logoSrc == null) {
      logoSrc = 'https://daphni.com/favicon-128x128.png';
    }

    return (
      <NavigationTouchable to={`/project/${this.props.reference}`}>
        <View>
          <View style={styles.container}>
            <View style={styles.leftContainer}>
              <Image
                source={{ uri: logoSrc }}
                resizeMethod="scale"
                resizeMode="contain"
                style={styles.logoImage}
              />
              <View style={styles.nameFlag}>
                <Text style={styles.title}>{this.props.name}</Text>
                <Flag code={this.props.countryCode} style={styles.flag} />
              </View>
              {sectorsText}
            </View>
            <View style={styles.rightContainer}>
              <Text style={styles.text}>€{money}</Text>
              <Text style={styles.text}>Requested</Text>
              <Rating value={this.props.score} />
              <View
                style={[
                  styles.typeContainer,
                  { backgroundColor: fundColors.primary },
                ]}
              >
                <Text
                  style={[styles.text, { color: fundColors.textOnPrimary }]}
                >
                  {this.props.type}
                </Text>
              </View>
            </View>
          </View>
        </View>
      </NavigationTouchable>
    );
  }
}

export default Company;

const styles = StyleSheet.create({
  container: {
    padding: 5,
    flexDirection: 'row',
  },
  leftContainer: {
    width: '70%',
    marginLeft: 10,
    marginTop: 10,
  },
  rightContainer: {
    marginTop: 10,
  },
  nameFlag: {
    flexDirection: 'row',
  },
  logoImage: {
    width: 40,
    height: 40,
  },
  flag: {
    marginTop: 10,
    marginLeft: 3,
    width: 16,
    height: 16,
  },
  typeContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    margin: 5,
    height: 25,
    width: 60,
    borderRadius: 13,
  },
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    marginTop: 10,
    color: '#666666',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
});
