import { adaptSmallUser } from '../User/adapter';
import { adaptProject } from '../Project/adapter';

const formatMessage = (message, isoDate, user, isRead, project) => ({
  id: 'id' + isoDate + user.reference + message.slice(0, 40),
  message,
  date: isoDate,
  user,
  isRead,
  project,
});

const adaptLastMessage = (discussion) => {
  if (!discussion.last_message) {
    return null;
  }

  return formatMessage(
    discussion.last_message.message,
    discussion.last_message_at,
    adaptSmallUser(discussion.last_message.user)
  );
};

export const adaptDiscussion = (discussion) => {
  const lastMessage = adaptLastMessage(discussion);
  const otherUsers = discussion.discussion_users.map(({ user }) =>
    adaptSmallUser(user)
  );
  const adminUser = discussion.user_admin
    ? adaptSmallUser(discussion.user_admin)
    : null;
  const isGroup = discussion.type_of === 'group';

  const adaptedBaseDiscussion = {
    reference: discussion.ref,
    isGroup,
    lastMessage,
    adminUser,
    unreadMessagesCount: discussion.unread_count,
  };

  if (isGroup) {
    return {
      ...adaptedBaseDiscussion,
      title: discussion.title,
      otherUsers,
    };
  }

  return {
    ...adaptedBaseDiscussion,
    otherUser: otherUsers[0],
  };
};

export const adaptDiscussions = ({ data: apiDiscussions }) =>
  apiDiscussions.map(adaptDiscussion).filter(Boolean);

export const adaptSentDiscussionMessage = ({ data: apiSentMessage }) =>
  formatMessage(
    apiSentMessage.message,
    new Date().toISOString(),
    adaptSmallUser(apiSentMessage.user)
  );

const adaptDiscussionMessage = (apiMessage) =>
  formatMessage(
    apiMessage.message,
    apiMessage.created_at,
    adaptSmallUser(apiMessage.by_user),
    apiMessage.is_read,
    apiMessage.project ? adaptProject(apiMessage.project) : null
  );

export const adaptDiscussionMessages = ({ data: apiMessages }) =>
  apiMessages.map(adaptDiscussionMessage);
