import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Dimensions,
  Linking,
  TouchableOpacity,
  Image,
} from 'react-native';

import Ionicons from 'react-native-vector-icons/Ionicons';
import { innerViewBaseStyles, Page } from '../src/components';
import { environment } from '../src/environment';

class CoInvest02Screen extends React.Component {
  state = {
    checked: false,
    showMore: false,
    arrow: 'ios-arrow-down',
    investmentValue: '120000',
    errorMessage: '',
    reference: '',
    logoSrc: '',
    name: '',
    speech: '',
    myInvestment: 0,
    totalInvestment: 0,
    investmentNow: 0,
    investmentRemaining: 0,
    remainingTime: '',
    liveDiscussion: '',
    videoThumbnail: '',
    videoUrl: '',
  };

  toggleShowMore() {
    const newState = !this.state.showMore;
    if (newState) {
      this.setState({ arrow: 'ios-arrow-up', showMore: newState });
    } else {
      this.setState({ arrow: 'ios-arrow-down', showMore: newState });
    }
  }

  render() {
    let logo = this.state.logoSrc;
    if (logo == null || logo == '') {
      logo = 'https://' + environment.API_HOST + '/missing/project.png';
    }

    let speech;
    if (this.state.showMore) {
      speech = <Text style={styles.text}>{this.state.speech}</Text>;
    } else {
      speech = (
        <Text numberOfLines={3} style={styles.text}>
          {this.state.speech}
        </Text>
      );
    }

    let videoThumbnail = this.state.videoThumbnail;
    if (videoThumbnail == null || videoThumbnail == '') {
      videoThumbnail =
        'https://' + environment.API_HOST + '/missing/project.png';
    }

    let videoUrl = this.state.videoUrl;
    if (videoUrl == null || videoUrl == '') {
      videoUrl = 'https://youtube.com';
    }

    return (
      <Page headerColor="#7e0d86" title="Co-Invest">
        <ScrollView
          style={innerViewBaseStyles.contentTopPanelDefaultFix}
          contentContainerStyle={innerViewBaseStyles.scrollViewContentContainer}
        >
          <View style={{ marginTop: 20, marginHorizontal: 20 }}>
            <Image
              style={{ width: 50, height: 50, alignSelf: 'center' }}
              source={{ uri: logo }}
              resizeMode="contain"
            />
            <Text style={[styles.text, { textAlign: 'center', marginTop: 20 }]}>
              Remaining time of opportunity:
            </Text>
            <Text style={styles.title}>{this.state.remainingTime}</Text>

            <Text style={[styles.text, { marginTop: 20 }]}>
              {this.state.name}
            </Text>
            {speech}
            <TouchableOpacity
              style={{
                marginTop: 5,
                width: 36,
                height: 36,
                alignSelf: 'center',
                backgroundColor: '#7e0d86',
                borderRadius: 18,
              }}
              onPress={() => this.toggleShowMore()}
            >
              <Ionicons
                name={this.state.arrow}
                style={{ alignSelf: 'center' }}
                size={32}
                color="#ffffff"
              />
            </TouchableOpacity>
          </View>

          <View style={styles.line} />
          <View style={{ alignContent: 'center', alignItems: 'center' }}>
            <Image
              source={{ uri: videoThumbnail }}
              width={Dimensions.get('window').width * 0.8}
              height={Dimensions.get('window').height * 0.25}
            />
            <Ionicons
              name="ios-play-circle"
              size={64}
              style={{ position: 'absolute', top: 25 }}
              color="#cdcdcd"
              onPress={() => Linking.openURL(videoUrl)}
            />
          </View>
          <View style={styles.line} />
          <View>
            <Text style={styles.title}>Your portfolio</Text>
            <View
              style={[
                styles.pairedData,
                { marginHorizontal: 20, marginTop: 5 },
              ]}
            >
              <Text style={[styles.text, { width: '60%' }]}>
                Co-investment capacity:
              </Text>
              <Text style={styles.text}>€{this.state.totalInvestment} </Text>
            </View>
            <View style={[styles.pairedData, { marginHorizontal: 20 }]}>
              <Text style={[styles.text, { width: '60%' }]}>
                Co-investmed so far:
              </Text>
              <Text style={styles.text}>€{this.state.investmentNow} </Text>
            </View>
            <View
              style={[
                styles.pairedData,
                { marginHorizontal: 20, display: 'none' },
              ]}
            >
              <Text style={[styles.text, { width: '60%' }]}>
                Remaining to co-invest:
              </Text>
              <Text style={styles.text}>
                €{this.state.investmentRemaining}{' '}
              </Text>
            </View>
          </View>
          <View style={styles.line} />
          <View>
            <Text style={styles.title}>Co-invest deal</Text>
            <Text style={[styles.text, { marginTop: 5, marginHorizontal: 20 }]}>
              About the series B, Lorem ipsum dolor sit amet, consectetuer
              adipiscing elit, sed diam nonummy nibh euismod
            </Text>

            <Text
              style={[
                styles.text,
                { marginHorizontal: 20, marginVertical: 20 },
              ]}
            >
              You already commited to co-invest {'\n'} €
              {this.state.myInvestment}{' '}
            </Text>
            <Text
              style={[
                styles.lightText,
                {
                  textAlign: 'center',
                  borderBottomWidth: 1,
                  borderColor: '#999999',
                },
              ]}
              onPress={() => this.props.navigation.goBack()}
            >
              Cancel commitment
            </Text>
          </View>
        </ScrollView>
      </Page>
    );
  }
}

export default CoInvest02Screen;

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Freight-Sans-Black',
    fontSize: 16,
    textAlign: 'center',
    color: '#666666',
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#666666',
  },
  lightText: {
    fontFamily: 'Freight-Sans-Medium',
    fontSize: 16,
    color: '#999999',
  },
  pairedData: {
    flexDirection: 'row',
  },
  line: {
    borderBottomColor: '#666666',
    borderBottomWidth: 1,
    margin: 20,
  },
});
