import React, { useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import { adaptSectors } from '../../../../api/Sectors/adapter';
import { getMostUsedSectors } from '../../../../api/Sectors';
import { Card } from '../Card';

export const PopularDaphniTags = () => {
  const [sectors, setSectors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    getMostUsedSectors()
      .then(adaptSectors)
      .then((newSectors) => {
        setIsLoading(false);
        setSectors(newSectors);
      })
      .catch((error) => {
        console.error('Error when loading', error);
        setIsLoading(false);
      });
  }, []);

  if (!sectors.length) {
    if (!isLoading) {
      return null;
    }

    return (
      <Card>
        <ActivityIndicator size="large" color="grey" />
      </Card>
    );
  }
  return (
    <Card>
      <Text style={styles.title}>Popular Daphnipolitans tags</Text>
      <View style={styles.tagsContainer}>
        {sectors.map((sector) => (
          <TouchableOpacity
            onPress={() =>
              navigation.navigate('Daphnipolitans', {
                tag: sector.id,
              })
            }
            key={sector.id}
            style={styles.tagBackground}
          >
            <Text style={styles.tagText}>{sector.name}</Text>
          </TouchableOpacity>
        ))}
      </View>
    </Card>
  );
};

const styles = StyleSheet.create({
  title: {
    textAlign: 'center',
    fontSize: 16,
    color: '#666666',
    fontFamily: 'Freight-Sans-Black',
  },
  tagsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 24,
    justifyContent: 'center',
  },
  tagBackground: {
    backgroundColor: '#eaeaea',
    borderRadius: 20,
    margin: 2,
  },
  tagText: {
    fontSize: 16,
    color: '#666666',
    marginVertical: 5,
    marginHorizontal: 10,
    fontFamily: 'Freight-Sans-Medium',
  },
});
