import React, { PureComponent } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import CodePushUpdateButton from '@bam.tech/react-native-component-code-push-update-button';
// eslint-disable-next-line import/default
import CodePush from 'react-native-code-push';
import { version } from '../../package.json';
import { environment } from '../environment';
import { theme } from '../lib';

const renderCodePushUpdateButton = () => {
  if (
    !environment.featureFlags.isCodePushEnabled ||
    !environment.featureFlags.isCodePushManual
  ) {
    return null;
  }

  return <CodePushUpdateButton style={styles.codePushButton} />;
};

export class AppVersion extends PureComponent {
  state = {
    appVersion: version,
  };

  async componentDidMount() {
    const codePushUpdate = await CodePush.getUpdateMetadata();
    if (codePushUpdate) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        appVersion: `${version} - ${codePushUpdate.label}`,
      });
    }
  }

  render() {
    return (
      <View style={this.props.style}>
        <Text style={[styles.version, this.props.textStyle]}>
          {this.state.appVersion}
        </Text>
        {renderCodePushUpdateButton()}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  version: {
    ...theme.fonts.normal,
    fontSize: 12,
    textAlign: 'center',
  },
  codePushButton: {
    alignSelf: 'center',
    marginVertical: 8,
  },
});
