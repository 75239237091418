import React from 'react';
import { Image, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { theme } from '../../../../../lib';

export const SectorButton = ({
  label,
  isActive,
  image,
  onPress,
  style,
  isDesktopOrLarger,
}) => {
  const buttonColor = isActive ? '#06b025' : theme.colors.text;
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[
        styles.sectorButton,
        isDesktopOrLarger && styles.desktopSectorButton,
        style,
      ]}
    >
      <Image
        style={[styles.sectorImage, { tintColor: buttonColor }]}
        source={image}
        resizeMode="contain"
      />
      <Text style={styles.sectorText}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  sectorButton: {
    alignItems: 'center',
    padding: 5,
  },
  desktopSectorButton: {
    flexDirection: 'row',
    padding: 10,
  },
  sectorImage: {
    width: 35,
    height: 35,
    margin: 5,
  },
  sectorText: {
    flex: 1,
    ...theme.fonts.normal,
  },
});
