import React, { PureComponent } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Avatar } from 'react-native-elements';
import { formatRelativeDate, theme } from '../../../src/lib';
import { NavigationTouchable, ParsedText } from '../../../src/components';
import { getUrlWithScheme } from '../../../src/api/base';

export class Message extends PureComponent {
  renderLinkedProject = (message) => {
    const { project } = message;
    if (!project) {
      return null;
    }

    return (
      <NavigationTouchable
        to={`/project/${project.reference}`}
        style={styles.projectBubbleContainer}
      >
        <View style={[styles.bubble, styles.projectBubble]}>
          <Avatar
            rounded
            size={40}
            placeholderStyle={{
              backgroundColor: project.logo.color,
            }}
            containerStyle={[
              styles.projectLogoContainer,
              {
                borderColor: project.logo.color,
              },
            ]}
            source={{
              uri: getUrlWithScheme(project.logo.url_xsmall),
            }}
          />
          <Text style={styles.project}>{message.project.name}</Text>
        </View>
      </NavigationTouchable>
    );
  };

  render() {
    const { isMyMessage, message } = this.props;
    return (
      <View
        style={[
          styles.messageContainer,
          isMyMessage && styles.myMessageContainer,
        ]}
      >
        <View
          style={[
            styles.bubble,
            styles.messageBubble,
            isMyMessage && styles.myMessageBubble,
          ]}
        >
          {!isMyMessage && (
            <Text
              style={styles.otherUser}
            >{`${message.user.firstName} ${message.user.lastName}`}</Text>
          )}
          <ParsedText style={[styles.message, isMyMessage && styles.myMessage]}>
            {message.message}
          </ParsedText>
        </View>
        {this.renderLinkedProject(message)}
        <Text style={[styles.date, isMyMessage && styles.myDate]}>
          {formatRelativeDate(message.date)}
        </Text>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  messageContainer: {
    alignSelf: 'flex-start',
    marginRight: 20,
  },
  myMessageContainer: {
    alignSelf: 'flex-end',
    marginLeft: 20,
    marginRight: 0,
  },
  bubble: {
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
  },
  messageBubble: {
    alignSelf: 'flex-start',
    backgroundColor: 'white',
  },
  myMessageBubble: {
    alignSelf: 'flex-end',
    backgroundColor: '#45a9ed',
  },
  otherUser: {
    color: '#45a9ed',
    marginBottom: 5,
  },
  message: {
    color: '#666',
  },
  date: {
    marginLeft: 5,
    color: '#666',
    marginTop: 10,
    fontSize: 12,
  },
  myDate: {
    textAlign: 'right',
    marginRight: 5,
    marginLeft: 0,
  },
  myMessage: {
    color: 'white',
  },
  projectBubbleContainer: {
    alignSelf: 'flex-start',
  },
  projectBubble: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#eee',
    marginTop: 10,
  },
  projectLogoContainer: {
    borderWidth: 1,
    marginRight: 10,
  },
  project: {
    color: theme.colors.text,
    fontWeight: 'bold',
  },
});
