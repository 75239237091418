import { takeEvery, call, put } from 'redux-saga/effects';
import {
  getNotificationsCount,
  visitNotificationByType,
} from '../../api/Notification';
import { adaptNotificationsCounts } from '../../api/Notification/adapter';
import { setNotificationsCount } from './actions';

export function* fetchNotificationsCountSaga() {
  try {
    const response = yield call(getNotificationsCount);
    const countsByType = adaptNotificationsCounts(response);
    yield put(setNotificationsCount(countsByType));
  } catch (e) {
    console.warn('[saga] fetchNotificationsCountSaga', e);
  }
}

export function* markNotificationAsVisitedSaga(action) {
  try {
    yield put(setNotificationsCount({ [action.payload.type]: 0 }));
    yield call(visitNotificationByType, action.payload.type);
  } catch (e) {
    console.warn('[saga] markNotificationAsVisitedSaga', e);
  }
}

export const notificationSagas = function* () {
  yield* [
    takeEvery('FETCH_NOTIFICATIONS_COUNT', fetchNotificationsCountSaga),
    takeEvery('MARK_NOTIFICATION_VISITED', markNotificationAsVisitedSaga),
  ];
};
