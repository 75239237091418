import React from 'react';
import {
  Platform,
  Text,
  View,
  StyleSheet,
  Dimensions,
  ActivityIndicator,
  ScrollView,
  TouchableOpacity,
  Keyboard,
  Animated,
  Image,
} from 'react-native';
import { StatusBarHeight } from '../src/lib/StatusBarHeight';
import { Icon, Overlay } from 'react-native-elements';
import { theme } from '../src/lib';
import { MultilineTextInput } from '../src/components/MultilineTextInput';
import { environment } from '../src/environment';
import { createProjectComment, rateProject } from '../src/api';

const WIDTH = Dimensions.get('window').width;
const HEIGHT =
  Dimensions.get('window').height -
  (Platform.OS === 'ios' ? 20 : StatusBarHeight.height);

class RatingOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      publicComment: '',
      privateComment: '',
      rating: 1,
      sentRating: false,
      sentMessageOverlay: false,
      errorMessageOverlay: false,
    };
    this.keyboardHeight = new Animated.Value(0);
  }

  componentDidMount() {
    this.keyboardWillShowSub = Keyboard.addListener(
      'keyboardWillShow',
      this.keyboardWillShow
    );
    this.keyboardWillHideSub = Keyboard.addListener(
      'keyboardWillHide',
      this.keyboardWillHide
    );
  }

  componentWillUnmount() {
    this.keyboardWillShowSub.remove();
    this.keyboardWillHideSub.remove();
  }

  keyboardWillShow = (event) => {
    Animated.parallel([
      Animated.timing(this.keyboardHeight, {
        duration: event.duration,
        toValue: event.endCoordinates.height * 1.1,
        useNativeDriver: false,
      }),
    ]).start();
  };

  keyboardWillHide = (event) => {
    Animated.parallel([
      Animated.timing(this.keyboardHeight, {
        duration: event.duration,
        toValue: 0,
        useNativeDriver: false,
      }),
    ]).start();
  };

  showSentMessageOverlay() {
    this.props.successfullRating();
  }

  showErrorMessageOverlay = (errorMsg) => {
    this.setState({ errorMessage: errorMsg, errorMessageOverlay: true });
  };

  _handlePublicTextChange = (event) => {
    this.setState({ publicComment: event.nativeEvent.text });
  };

  _handlePrivateTextChange = (event) => {
    this.setState({ privateComment: event.nativeEvent.text });
  };

  ratingCompleted(score) {
    this.setState({ rating: score });
  }

  submitRating = async () => {
    this.setState({ loading: true });
    try {
      await Promise.all([this.sendRating(), this.sendPublicMessage()]);
      this.showSentMessageOverlay();
    } catch (error) {
      this.showErrorMessageOverlay(
        error.json?.errors?.[0]?.title ?? error.message
      );
    } finally {
      this.setState({ loading: false });
    }
  };

  sendRating = async () => {
    return rateProject(this.props.reference, {
      appeal: this.state.rating,
      comment: this.state.privateComment,
    });
  };

  sendPublicMessage = async () => {
    if (!this.state.publicComment) {
      return;
    }

    return createProjectComment(this.props.reference, {
      content: this.state.publicComment,
      is_private: false,
    });
  };

  render() {
    let rating = this.state.rating;
    let stars = [];
    for (let i = 0; i < 4; i++) {
      let tColor = '#cdcdcd';
      if (i < rating) {
        tColor = '#f1c40f';
      }

      let score = i + 1;

      stars = [
        ...stars,
        <TouchableOpacity key={i} onPress={() => this.ratingCompleted(score)}>
          <Image
            style={{
              tintColor: tColor,
              marginHorizontal: 5,
              height: 30,
              width: 30,
            }}
            source={require('../assets/icons/starIcon.png')}
            resizeMode="contain"
          />
        </TouchableOpacity>,
      ];
    }

    let logoSrc = this.props.logoSrc;
    if (logoSrc == null) {
      logoSrc = 'https://' + environment.API_HOST + '/missing/project.png';
    }

    let sendButton;
    if (this.state.loading) {
      sendButton = (
        <TouchableOpacity>
          <View style={styles.sendButton}>
            <ActivityIndicator size="small" color="grey" />
          </View>
        </TouchableOpacity>
      );
    } else {
      sendButton = (
        <TouchableOpacity onPress={() => this.submitRating()}>
          <View style={styles.sendButton}>
            <Text style={styles.sendText}>Submit</Text>
          </View>
        </TouchableOpacity>
      );
    }

    let sentMessageOverlay = (
      <Overlay
        isVisible={this.state.sentMessageOverlay}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '90%', height: '10%', borderRadius: 20 }}
        onBackdropPress={() => this.setState({ sentMessageOverlay: false })}
      >
        <Text
          style={[
            styles.text,
            { textAlign: 'center', marginTop: 8, fontSize: 16 },
          ]}
        >
          Rate sent
        </Text>
      </Overlay>
    );

    let errorMessageOverlay = (
      <Overlay
        isVisible={this.state.errorMessageOverlay}
        style={{ backgroundColor: '#ffffff' }}
        overlayStyle={{ width: '90%', height: '15%', borderRadius: 20 }}
        onBackdropPress={() => this.setState({ errorMessageOverlay: false })}
      >
        <Text
          style={[
            styles.text,
            { textAlign: 'center', marginTop: 8, fontSize: 16 },
          ]}
        >
          {this.state.errorMessage}
        </Text>
      </Overlay>
    );

    return (
      <View style={styles.mainContainer}>
        {sentMessageOverlay}
        {errorMessageOverlay}
        <ScrollView style={styles.ScrollView}>
          <Animated.View
            style={[
              styles.mainContainer,
              { marginBottom: this.keyboardHeight },
            ]}
          >
            <View style={styles.topContainer}>
              <Text style={styles.text}>Rate {this.props.name}</Text>
              <Image
                style={[styles.logo, { width: 150, height: 50 }]}
                source={{ uri: logoSrc }}
                resizeMode="contain"
              />
              <View
                style={{
                  flexDirection: 'row',
                  width: WIDTH,
                  justifyContent: 'center',
                }}
              >
                {stars}
              </View>
              {/*<Rating
                        type='custom'
                        ratingImage={require('../assets/icons/reviewStarIcon.png')}
                        ratingColor='#f1c40f'
                        ratingBackgroundColor='#cdcdcd'
                        imageSize={30}
                        ratingCount={4}
                        onFinishRating={this.ratingCompleted}
                    />*/}
            </View>

            <View style={styles.commentsContainer}>
              <View style={styles.NewComment}>
                <View style={{ margin: 10 }}>
                  <Icon
                    name="users"
                    type="font-awesome"
                    color="#666"
                    size={14}
                  />
                </View>
                <MultilineTextInput
                  onChange={this._handlePublicTextChange}
                  style={[styles.text, styles.textBox]}
                  value={this.state.publicComment}
                  numberOfLines={4}
                  placeholder="Leave a public comment"
                />
              </View>
              <View style={styles.copyIconContainer}>
                <TouchableOpacity
                  style={styles.copyIconButton}
                  onPress={() => {
                    this.setState((state) => ({
                      privateComment: state.publicComment,
                    }));
                  }}
                >
                  <Icon
                    name="content-copy"
                    size={2 * theme.margin.unit}
                    color="white"
                  />
                </TouchableOpacity>
              </View>
              <View style={styles.NewComment}>
                <View style={{ margin: 10 }}>
                  <Icon name="key" type="font-awesome" color="#666" size={14} />
                </View>
                <MultilineTextInput
                  onChange={this._handlePrivateTextChange}
                  style={[styles.text, styles.textBox]}
                  value={this.state.privateComment}
                  numberOfLines={4}
                  placeholder="Leave a private comment"
                />
              </View>
              {sendButton}
            </View>
          </Animated.View>
        </ScrollView>
      </View>
    );
  }
}

export default RatingOverlay;

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#ffffff',
    width: WIDTH,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    height: HEIGHT * 0.9,
  },
  textBox: {
    minHeight: 60,
    width: WIDTH * 0.7,
  },
  topContainer: {
    height: HEIGHT * 0.3,
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  ScrollView: {
    left: -10,
    width: WIDTH,
    backgroundColor: '#7479ae',
    borderTopLeftRadius: 25,
    borderTopRightRadius: 25,
  },
  commentsContainer: {
    backgroundColor: '#7479ae',
    //position: 'absolute',
    //bottom: 0,
    //left: -10,
    width: WIDTH,
    height: HEIGHT * 0.6,
    paddingVertical: 10,
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
  },
  NewComment: {
    flexDirection: 'row',
    backgroundColor: '#fff',
    borderRadius: 10,
    width: WIDTH - 40,
    paddingVertical: theme.margin.unit,
    marginTop: 20,
    marginLeft: 20,
  },
  sendButton: {
    alignSelf: 'center',
    justifyContent: 'center',
    marginTop: 10,
    backgroundColor: '#ffffff',
    height: 25,
    width: 80,
    borderRadius: 13,
  },
  logo: {
    marginTop: 5,
  },
  text: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#666666',
    fontSize: 16,
    textAlign: 'center',
  },
  sendText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#7479ae',
    fontSize: 16,
    textAlign: 'center',
  },
  copyIconContainer: {
    alignItems: 'flex-end',
    marginTop: 2 * theme.margin.unit,
    marginRight: theme.margin.unit,
  },
  copyIconButton: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(1,1,1,0.1)',
    borderRadius: 50,
    padding: theme.margin.unit / 2,
  },
});
