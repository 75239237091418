import React from 'react';
import {
  Text,
  View,
  StyleSheet,
  Dimensions,
  TouchableOpacity,
  Image,
} from 'react-native';
import { Overlay } from 'react-native-elements';

import TimelineConversation from '../components/Comments/TimelineConversation';
import NewComment from '../components/Comments/ArticleReplyNewComment';
import { keyboardHeightListener } from '../components/keyboardHeightListener';
import { Page } from '../src/components';
import { getArticleComments } from '../src/api';
import {
  adaptArticle,
  adaptArticleComments,
} from '../src/api/Articles/adapter';
import { getArticle } from '../src/api/Articles';
import { tabletOrLargerMinPixel } from '../src/lib/responsive';

class CommentsScreen extends React.Component {
  state = {
    conversation: [],
    article: null,
    showReplyOverlay: false,
    loading: true,
  };

  async componentDidMount() {
    this.getProjectComments();
  }

  getProjectComments = () => {
    const articleId = this.props.route.params?.id;

    const getArticlePromise = getArticle(articleId)
      .then(adaptArticle)
      .then((article) => {
        this.setState({ article });
      });

    const getArticleCommentsPromise = getArticleComments(articleId)
      .then(adaptArticleComments)
      .then((comments) => {
        this.setState({ conversation: comments });
      });

    Promise.all([getArticlePromise, getArticleCommentsPromise])
      .then(() => {
        this.setState({ loading: false, showReplyOverlay: false });
      })
      .catch((error) => {
        console.log('Error retrieving data', { error });
        this.setState({ loading: false });
      });
  };

  showReplyOverlay = (show) => {
    this.setState({ showReplyOverlay: show });
  };

  updateComments = () => {
    this.setState({ loading: true }, () => {
      this.getProjectComments();
      this.showReplyOverlay(false);
    });
  };

  renderNewCommentOverlay = () => {
    const dimensions = Dimensions.get('window');
    return (
      <Overlay
        isVisible={this.state.showReplyOverlay}
        overlayStyle={[
          styles.bottomOverlay,
          {
            width:
              dimensions.width > tabletOrLargerMinPixel
                ? dimensions.width / 3
                : '100%',
            height: 'auto',
            paddingBottom: this.props.keyboardHeight || 10,
          },
        ]}
        onBackdropPress={() => this.setState({ showReplyOverlay: false })}
      >
        <NewComment
          updateComments={this.updateComments}
          id={this.props.route.params?.id}
        />
      </Overlay>
    );
  };

  renderFooterButtons = () => (
    <View style={[styles.buttonContainer, { width: '30%' }]}>
      <TouchableOpacity
        style={{ flexDirection: 'row' }}
        onPress={() => this.showReplyOverlay(true)}
      >
        <Text style={[styles.wText, { marginRight: 10 }]}>Reply</Text>
        <Image
          source={require('../assets/icons/messagesIcon.png')}
          style={{ width: 15, height: 15 }}
          resizeMode="contain"
        />
      </TouchableOpacity>
    </View>
  );

  renderComments = () => (
    <TimelineConversation
      article={this.state.article}
      messages={this.state.conversation}
      isLoading={this.state.loading}
    />
  );

  render() {
    return (
      <Page headerColor="#45a9ed" title="Comments">
        {this.renderNewCommentOverlay()}
        {this.renderComments()}
        {this.renderFooterButtons()}
      </Page>
    );
  }
}

export default keyboardHeightListener({ ios: true })(CommentsScreen);

const styles = StyleSheet.create({
  buttonContainer: {
    position: 'absolute',
    backgroundColor: '#45a9ed',
    zIndex: 2,
    bottom: 20,
    borderRadius: 15,
    shadowColor: '#000000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.6,
    shadowRadius: 2,
    height: 30,
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignSelf: 'center',
  },
  bottomOverlay: {
    backgroundColor: '#45a9ed',
    position: 'absolute',
    bottom: 0,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
  },
  wText: {
    fontFamily: 'Freight-Sans-Medium',
    color: '#ffffff',
    fontSize: 16,
    textAlign: 'center',
  },
});
