import React, { useCallback } from 'react';
import { TouchableOpacity } from 'react-native';
import { Icon } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';

export const GroupSettingsButton = ({ discussion, style }) => {
  const navigation = useNavigation();

  const discussionReference = discussion?.reference;
  const onPress = useCallback(
    () =>
      navigation.navigate('GroupSettings', {
        discussionReference,
      }),
    [navigation, discussionReference]
  );

  if (!discussion || !discussion.isGroup) {
    return null;
  }

  return (
    <TouchableOpacity style={style} onPress={onPress} activeOpacity={0.7}>
      <Icon name="info-circle" type="font-awesome" color="white" size={30} />
    </TouchableOpacity>
  );
};
